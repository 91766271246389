import React from 'react';

interface NavItem {
    label: string;
    link: string;
}

interface NavProps {
    style: React.CSSProperties;
    //commonProps?: any;
    items?: NavItem[];
    itemStyle: React.CSSProperties;

}

const Nav: React.FC<NavProps> = ({ style, itemStyle, items }) => {
    return (
        <nav style={style}>
            {items?.map((item, index) => (
                <span key={index} style={itemStyle}>
                    {item.label}
                </span>
            ))}
        </nav>
    );
};

export default Nav;

import { useState, useEffect, Fragment } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./SelectField.scss";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { FormModal } from "components/modals/FormModal/FormModal";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import Icon from "components/Icon/Icon";


const SelectField = (props: any) => {
  let {
    name,
    editable,
    label,
    primaryKey,
    secondaryKey,
    thumbnailKey,
    onChange,
    defaultValue,
    editPath,
    formFields,
    formTitle,
    createPath,
    filters = [],
    searchPath,
    searchFields = [],
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    }
  }, [open]);

  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);
    try {
      const hasQueryParams = searchPath.includes('?');
      const prefix = hasQueryParams ? '&' : '?';

      let url = `${urls.server}/api/${searchPath}${prefix}search_term=${event.target.value
        }&offset=0&limit=20&filters=${encodeURIComponent(JSON.stringify(filters))}&search_fields=${encodeURIComponent(JSON.stringify(searchFields))}`;

      const res = await AxiosInterceptor.get(`${url}`);
      const data = await res?.data;
      setOptions(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="select-field-container">
      <div>
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            <div>
              {label && <label>{label}</label>}
              {value ? <span>{value[primaryKey]} </span> : <span>-</span>}
            </div>
          </div>
        ) : (
          <div className="editable-content">
            <div className="autocomplete-wrapper">
              {label && <label>{label}</label>}
              <Autocomplete
                noOptionsText={noOptionsText}
                loadingText="Buscando..."
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value) =>
                  option[primaryKey] === value[primaryKey]
                }
                getOptionLabel={(option) => option[primaryKey]}
                options={options || []}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.id}>
                    {thumbnailKey && <img src={`${urls.server}${option[thumbnailKey]}`} alt="" style={{ marginRight: 8, width: 20, height: 20 }} />}
                    <span>{option[primaryKey]}</span>
                    {secondaryKey && <span style={{ marginLeft: 8, color: '#888' }}>{option[secondaryKey]}</span>}
                  </Box>
                )}
                loading={loading}
                value={value || null}
                onChange={(event: any, newValue: any | null) => {
                  onChange(newValue);
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    hiddenLabel
                    onChange={handleChange}
                    onFocus={handleChange}
                    {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            {formFields && createPath && (
              <IconButton
                color="primary"
                onClick={() => {
                  setFormModalIsOpen(true);
                }}
                aria-label="add record"
                size="small"
              >
                <Icon name="add" />
              </IconButton>
            )}
          </div>
        )}
      </div>

      {editable && (
        <div>
          {(mode == 'edit' && editable) && (
            <FieldEditControls
              value={value}
              fieldName={name}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )}
        </div>
      )}

      <FormModal
        isOpen={formModalIsOpen}
        onClose={() => { setFormModalIsOpen(false) }}
        title={formTitle}
        fields={formFields}
        createPath={createPath}
        mode="create"
      />
    </div>
  );
};

export { SelectField };

import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import "./IconPickerField.scss";
import { IconPickerModal } from "components/modals/IconPickerModal/IconPickerModal";
import Icon from "components/Icon/Icon";

const IconPickerField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    onlyTypes,
    onlyWith,
    //readonly,
    //onlyWrappedIcons,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [iconPickerModalIsOpen, setIconPickerModalIsOpen] = useState(false);
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="icon-picker-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              {value ? <Icon name={value} /> : <span>-</span>}
            </div>
          ) : (
            <div className="editable-content">
              {value ? (
                <div className="selected-icon">
                  <a
                    onClick={() => {
                      //setIconPickerModalIsOpen(true);
                      setValue(null)
                    }}
                  >
                    <Icon name={value} />
                  </a>
                </div>
              ) : (
                <div className="icon-picker-btn-wrapper">
                  <a
                    onClick={() => {
                      setIconPickerModalIsOpen(true);
                    }}
                  >
                    <Icon name="select" />
                    <span>Seleccionar icono</span>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <IconPickerModal
        isOpen={iconPickerModalIsOpen}
        onlyTypes={onlyTypes}
        onlyWith={onlyWith}
        //onlyWrappedIcons={onlyWrappedIcons}
        onClose={(res: any) => {
          setIconPickerModalIsOpen(false)
          if (res) {
            setValue(res.name);
          }
        }}
      />
    </div>
  );
};

export { IconPickerField };

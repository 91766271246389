const urls = {
  // api: "http://127.0.0.1:8000/api",
  // server: "http://127.0.0.1:8000",
  // api: "https://senserver.nubelon.org/api",
  // server: "https://senserver.nubelon.org",
  api: process.env.REACT_APP_API_URL,
  server: process.env.REACT_APP_SERVER_URL,
  // ... otras urls
};
export default urls;

import React from "react";
import Lottie from "lottie-react";
import saltaAnimationData from "../files/salta.json";
import cafayateAnimationData from "../files/cafayate.json";
import iruyaAnimationData from "../files/iruya.json";
import officeAnimationData from "../files/office.json";
import cachiAnimationData from "../files/cachi.json";
import chartAnimationData from "../files/chart.json";
import hospitalAnimationData from "../files/hospital.json";
import schoolAnimationData from "../files/school.json";
import potholedStreetAnimationData from "../files/potholed-street.json";

import './FooterAnimation.scss';

const FooterAnimation = ({ name }: any) => {
  let animationData: any = saltaAnimationData;
  switch (name) {
    case "salta":
      animationData = saltaAnimationData;
      break;
    case "chart":
      animationData = chartAnimationData;
      break;
    case "cafayate":
      animationData = cafayateAnimationData;
      break;
    case "iruya":
      animationData = iruyaAnimationData;
      break;
    case "cachi":
      animationData = cachiAnimationData;
      break;
    case "office":
      animationData = officeAnimationData;
      break;
    case "hospital":
      animationData = hospitalAnimationData;
      break;
    case "school":
      animationData = schoolAnimationData;
      break;
    case "potholedStreet":
      animationData = potholedStreetAnimationData;
      break;
    default:
      break;
  }
  return (
    <div
      className="footer-animation-container"
      style={{
        position: "fixed",
        bottom: "0px",
        left: 0,
        width: "100%"
      }}>
      <div
        style={{
          width: "100%",
          height: "0",
          paddingBottom: "24%",
          position: "relative",
        }}
      >
        {
          <Lottie
            loop={name == 'chart' ? true : false}
            animationData={animationData}
            style={{ position: "absolute", width: "100%", height: "100%" }}
          />
        }
      </div>
    </div>
  );
};

export default FooterAnimation;

import { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./MultiCheckboxField.scss";

const MultiCheckboxField = (props: any) => {
  let {
    defaultValue,
    editable,
    options,
    label,
    editPath,
    onChange,
    name,
    mode
  }:
    any = props;
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
  const [isEditEnabled, setIsEditEnabled] = useState(false);


  useEffect(() => {
    if (onChange) onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    //setSelectedOptions(defaultValue);

  }, [defaultValue]);

  const handleOptionChange = (optionName: string) => {
    setSelectedOptions((prevSelected: any) => {
      const newSelected = prevSelected.includes(optionName)
        ? prevSelected.filter((name: string) => name !== optionName)
        : [...prevSelected, optionName];
      return newSelected;
    });
  };

  return (
    <>
      <div className="field-container multi-checkbox-field-container">
        <div className="field-header">
          {label && (
            <label>
              <span>{label}</span>
            </label>
          )}
          {editable && (
            <div>
              {(mode == 'edit' && editable) && (
                <FieldEditControls
                  value={selectedOptions}
                  fieldName={name}
                  editPath={editPath}
                  onEditStart={() => {
                    setIsEditEnabled(true);
                  }}
                  onEditCancel={(originalValue) => {
                    setIsEditEnabled(false);
                    setSelectedOptions(originalValue);
                  }}
                  onEditEnd={(success: boolean, originalValue) => {
                    setIsEditEnabled(false);
                    if (!success) {
                      setSelectedOptions(originalValue);
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="field-body">
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              {options.map((option: any, index: number) => (
                <div key={index} className="checkbox-item">
                  {selectedOptions?.includes(option.name) ? (
                    <CheckBoxIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                  <span className="checkbox-label">{option.displayName}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="editable-content">
              {options.map((option: any, index: number) => (
                <a key={index} className="checkbox-item" onClick={() => handleOptionChange(option.name)}>
                  {selectedOptions?.includes(option.name) ? (
                    <CheckBoxIcon color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                  <span className="checkbox-label">{option.displayName}</span>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { MultiCheckboxField };

import React, { useEffect, useState, useCallback } from 'react';
import AxiosInterceptor from "../../../AxiosInterceptor";
import './AutoSaveButton.scss';
import urls from 'config/urls';
import { IconButton } from '@mui/material';
import Icon from 'components/Icon/Icon';
import { Spinner } from 'components/Spinner/Spinner';
import { Toast } from 'components/Toast/Toast';

type AutoSaveButtonProps = {
    savePath: string;
    data: any;
    interval?: number;
    icon?: string;
    onSaveSuccess?: () => void; // Nueva prop
};

const AutoSaveButton: React.FC<AutoSaveButtonProps> = ({ savePath, data, interval = 10000, icon = 'save', onSaveSuccess }) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [previousData, setPreviousData] = useState<any>(data);
    const [processing, setProcessing] = useState(false);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");

    const saveData = useCallback(async () => {
        try {
            setProcessing(true);
            const res = await AxiosInterceptor.put(
                `${urls.server}/api/${savePath}`, { data }
            );
            const resData = res?.data;

            setPreviousData(data);
            setProcessing(false);
            setToastMessage(`Guardado correctamente`);
            setToastIsOpen(true);
            setToastSeverity("success");

            if (onSaveSuccess) {
                onSaveSuccess(); // Llama a la función onSaveSuccess si está definida
            }
        } catch (error: any) {
            setProcessing(false);
            setToastMessage(error.toString());
            setToastIsOpen(true);
            setToastSeverity("error");
        }
    }, [savePath, data, onSaveSuccess]);

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(previousData)) {
            if (timer) {
                clearTimeout(timer);
            }

            const newTimer = setTimeout(() => {
                saveData();
            }, interval);

            setTimer(newTimer);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [data, previousData, interval, saveData]);

    const handleSaveButtonClick = async () => {
        if (timer) {
            clearTimeout(timer);
        }
        await saveData();
    };

    return (
        <>
            <IconButton
                className="auto-save-button-container"
                color="inherit"
                onClick={handleSaveButtonClick}
                aria-label="save"
                disabled={processing}
            >
                {
                    processing ? <Spinner size={"sm"} /> :
                        <Icon name={icon} />
                }
            </IconButton>
            <Toast
                isOpen={toastIsOpen}
                onClose={() => setToastIsOpen(false)}
                message={toastMessage}
                severity={toastSeverity}
            />
        </>
    );
};

export default AutoSaveButton;

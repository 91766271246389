import * as React from "react";
import Popover from "@mui/material/Popover";

import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Icon from "components/Icon/Icon";
const DividerGroupsPopover = ({ options, onChange }: any) => {
  React.useEffect(() => {
    if (options.length > 0) setSelectedOption(options[0]);
  }, [options]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [selectedOption, setSelectedOption] = React.useState(options[0]);
  const handleSelectOption = (item: any) => {
    setSelectedOption(item);
    setAnchorEl(null);
    onChange(item);
  };
  return (
    <div>
      <Button
        variant="text"
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        {selectedOption?.abbr}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="divider-groups">
          <h2>Agrupar por</h2>
          <div>
            {options.map((item: any, index: number) => {
              return (
                <Button
                  key={index}
                  endIcon={
                    item.name == selectedOption?.name ? <Icon name="check" /> : null
                  }
                  className={
                    item.name == selectedOption?.name ? "selected" : ""
                  }
                  onClick={() => handleSelectOption(item)}
                >
                  {item.displayName} ({item.abbr})
                </Button>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export { DividerGroupsPopover };

import { IconButton } from "@mui/material";
import React, { useState } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./PlayPauseCell.scss";
import Icon from "components/Icon/Icon";
const PlayPauseCell = (props: any) => {
  const { sendPath, data, onChange } = props;
  const [processing, setProcessing] = useState(false);
  const [value, setValue] = useState(data);
  const handleClick = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(`${urls.server}/api/${sendPath}`, {
        data: !value,
      });
      const resData = await res?.data;
      setProcessing(false);
      setValue((prev: boolean) => !prev);
      onChange(!value)
    } catch (error: any) {
      setProcessing(false);
    }
  };

  return (
    <div className="play-pause-cell-container">
      {processing ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <IconButton
          color={value ? "primary" : "inherit"}
          aria-label="play pause btn"
          onClick={handleClick}
        >
          {value ? <Icon name="pause" /> : <Icon name="play" />}
        </IconButton>
      )}
    </div>
  );
};

export { PlayPauseCell };

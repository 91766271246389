import React, { useState } from 'react'
import Modal from 'components/Modal/Modal';
import './ImageCropperModal.scss';
import Cropper from 'react-easy-crop';
import { blobToBase64, getCroppedImg } from "./cropImage";
interface ImageCropperModalProps {
  isOpen: boolean;
  aspect?: number;
  image: string;
  onClose: (res?: any) => void;

}

const ImageCropperModal: React.FC<ImageCropperModalProps> = ({ isOpen, aspect = 1, image, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null as any)
  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, aspect);
    setCroppedImage(await blobToBase64(croppedImage))

  };
  return (
    <Modal
      title={'Cortar imagen'}
      className={'image-cropper-modal-container'}
      isOpen={isOpen}
      onClose={() => { onClose() }}
      hasDoneBtn
      onDone={() => {
        onClose(croppedImage);
      }}
    >
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </Modal>
  )
}

export default ImageCropperModal
// components/configurations/NavConfiguration.tsx
import React from 'react';
import { ColorField } from 'components/fields/ColorField/ColorField';

import { RadioField } from 'components/fields/RadioField/RadioField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import SizeField from '../../SizeField/SizeField';

interface NavConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const NavConfiguration: React.FC<NavConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <ExpandableSection title="Márgenes (item)">
                <SizeField
                    label="Margen arriba (item)"
                    field="item.marginTop"
                    isEnabled={getFieldValues('item.marginTop').value !== null}
                    value={getFieldValues('item.marginTop').value}
                    unit={getFieldValues('item.marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo (item)"
                    field="item.marginBottom"
                    isEnabled={getFieldValues('item.marginBottom').value !== null}
                    value={getFieldValues('item.marginBottom').value}
                    unit={getFieldValues('item.marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda (item)"
                    field="item.marginLeft"
                    isEnabled={getFieldValues('item.marginLeft').value !== null}
                    value={getFieldValues('item.marginLeft').value}
                    unit={getFieldValues('item.marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha (item)"
                    field="item.marginRight"
                    isEnabled={getFieldValues('item.marginRight').value !== null}
                    value={getFieldValues('item.marginRight').value}
                    unit={getFieldValues('item.marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>

            <ExpandableSection title="Márgenes internos (item)">
                <SizeField
                    label="Margen interno arriba (item)"
                    field="item.paddingTop"
                    isEnabled={getFieldValues('item.paddingTop').value !== null}
                    value={getFieldValues('item.paddingTop').value}
                    unit={getFieldValues('item.paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo (item)"
                    field="item.paddingBottom"
                    isEnabled={getFieldValues('item.paddingBottom').value !== null}
                    value={getFieldValues('item.paddingBottom').value}
                    unit={getFieldValues('item.paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha (item)"
                    field="item.paddingRight"
                    isEnabled={getFieldValues('item.paddingRight').value !== null}
                    value={getFieldValues('item.paddingRight').value}
                    unit={getFieldValues('item.paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda (item)"
                    field="item.paddingLeft"
                    isEnabled={getFieldValues('item.paddingLeft').value !== null}
                    value={getFieldValues('item.paddingLeft').value}
                    unit={getFieldValues('item.paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>

            <ExpandableSection title="Colores (item)">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.item?.color || null : localConfig?.item?.responsive?.[selectedTab]?.color || localConfig?.item?.color || null}
                        label={"Color (item)"}
                        onChange={(color: any) => handleChange('item.color', color)}
                    />
                </div>
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.item?.backgroundColor || null : localConfig?.item?.responsive?.[selectedTab]?.backgroundColor || localConfig?.item?.backgroundColor || null}
                        label={"Color de fondo (item)"}
                        onChange={(color: any) => handleChange('item.backgroundColor', color)}
                    />
                </div>
            </ExpandableSection>

            <ExpandableSection title="Borde (item)">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.item?.borderColor || null : localConfig?.item?.responsive?.[selectedTab]?.borderColor || localConfig?.item?.borderColor || null}
                        label={"Color borde (item)"}
                        onChange={(color: any) => handleChange('item.borderColor', color)}
                    />
                </div>
                <div className="action-wrapper">
                    <SizeField
                        label="Grosor del borde (item)"
                        field="item.borderWidth"
                        isEnabled={getFieldValues('item.borderWidth').value !== null}
                        value={getFieldValues('item.borderWidth').value}
                        unit={getFieldValues('item.borderWidth').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.item?.borderStyle || 'none' : localConfig?.item?.responsive?.[selectedTab]?.borderStyle || localConfig?.item?.borderStyle || 'none'}
                        label={"Estilo de borde (item)"}
                        options={[
                            { name: "none", displayName: "Sin borde" },
                            { name: "solid", displayName: "Sólido" },
                            { name: "dotted", displayName: "Puntos" },
                            { name: "dashed", displayName: "Líneas" },
                        ]}
                        onChange={(type: string) => handleChange('item.borderStyle', type)}
                    />
                </div>
            </ExpandableSection>

            <ExpandableSection title="Bordes redondeados (item)">
                <SizeField
                    label="Borde arriba izquierda (item)"
                    field="item.borderTopLeftRadius"
                    isEnabled={getFieldValues('item.borderTopLeftRadius').value !== null}
                    value={getFieldValues('item.borderTopLeftRadius').value}
                    unit={getFieldValues('item.borderTopLeftRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde arriba derecha (item)"
                    field="item.borderTopRightRadius"
                    isEnabled={getFieldValues('item.borderTopRightRadius').value !== null}
                    value={getFieldValues('item.borderTopRightRadius').value}
                    unit={getFieldValues('item.borderTopRightRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde abajo izquierda (item)"
                    field="item.borderBottomLeftRadius"
                    isEnabled={getFieldValues('item.borderBottomLeftRadius').value !== null}
                    value={getFieldValues('item.borderBottomLeftRadius').value}
                    unit={getFieldValues('item.borderBottomLeftRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde abajo derecha (item)"
                    field="item.borderBottomRightRadius"
                    isEnabled={getFieldValues('item.borderBottomRightRadius').value !== null}
                    value={getFieldValues('item.borderBottomRightRadius').value}
                    unit={getFieldValues('item.borderBottomRightRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
        </>
    );
};

export default NavConfiguration;

import { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./ColorField.scss";
import Icon from "components/Icon/Icon";
import colorsConfig from "config/colors";

const ColorField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    recordId,
    name,
    disablePrimary, // New prop
    size = 'small' // small, medium o large
  }: any = props;

  const resolveDefaultValue = (value: any) => {
    return value;
  };

  const [value, setValue] = useState(resolveDefaultValue(defaultValue) as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(resolveDefaultValue(defaultValue));
  }, [defaultValue]);

  const colors = [
    "#0046BF",
    "#00A7A7",
    "#00A700",
    "#A7A700",
    "#fa7121",
    "#A70000",
    "#A700A7",
    "#650086",
    "#222222",
    "#7f7f7f",
  ];
  const additionalColors = [
    "#364750",
    "#4d6572",
    "#638192",
    "#7b98ad",
    "#f2f2f4"
  ];
  const specialColors = [
    { name: "primary", label: "Principal" },
    { name: "danger", label: "Peligro" },
    { name: "warning", label: "Advertencia" },
    { name: "success", label: "Éxito" },
    { name: "info", label: "Información" },
  ];
  const amts: any = [-0.4, -0.25, 0, 0.25, 0.4];
  const lightenDarkenColor = (hex: string, amount?: number) => {
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length === 6) {
      hex = hex[0] + hex[1] + hex[2] + hex[3] + hex[4] + hex[5];
    }
    amount = amount || 0;

    let rgb = "#";
    let c: any;
    for (let i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * amount), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;

  const handleSelectColor = (
    event: React.MouseEvent<HTMLButtonElement>,
    color: string,
    amount: number
  ) => {
    event.preventDefault();
    setValue(lightenDarkenColor(color, amount));
    setAnchorEl(null);
    setCustomColor(null);
  };

  const handleSelectSpecialColor = (
    event: React.MouseEvent<HTMLButtonElement>,
    colorName: string
  ) => {
    if (disablePrimary && colorName === "primary") return;
    event.preventDefault();
    setValue(colorName);
    setAnchorEl(null);
    setCustomColor(null);
  };

  const [customColor, setCustomColor] = useState(null as any);
  const handleChangeCustomColor = (e: any) => {
    setCustomColor(e.target.value);
  };

  const handleSelectCustomColor = () => {
    setValue(`#${customColor}`);
    setAnchorEl(null);
    setCustomColor(null);
  };

  return (
    <div className="color-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !isEditEnabled ? (
          <div className="readonly-content">
            {value ? (
              <span
                className="readonly-color"
                style={{ backgroundColor: colorsConfig[value] || value }}
              ></span>
            ) : (
              <div
                style={{}}
                className="no-value">
                <Icon name="color" />
                <span>Sin color</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {value ?
              <a
                className="selected-color"
                style={{ backgroundColor: colorsConfig[value] || value }}
                onClick={() => {
                  setValue(null)
                }}>
                <span>
                  <Icon name={"close"} />
                </span>
              </a>
              :
              <div className="color-picker">
                <a
                  onClick={(e: any) => handlePresentPopover(e)}
                ></a>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="popover-colors-table-wrapper">
                    <table>
                      <tbody>
                        {colors.map((color: string, r: number) => {
                          return (
                            <tr key={r}>
                              {amts.map((amount: number, c: number) => {
                                return (
                                  <td key={c}>
                                    <a
                                      onClick={(e: any) =>
                                        handleSelectColor(e, color, amount)
                                      }
                                      style={{
                                        backgroundColor: lightenDarkenColor(
                                          color,
                                          amount
                                        ),
                                      }}
                                    ></a>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                        <tr>
                          {additionalColors.map((color: string, i: number) => (
                            <td key={i}>
                              <a
                                onClick={(e: any) =>
                                  handleSelectColor(e, color, 0)
                                }
                                style={{ backgroundColor: color }}
                              ></a>
                            </td>
                          ))}
                        </tr>
                        <tr>
                          {specialColors.map((color, i) => (
                            <td key={i}>
                              <a
                                onClick={(e: any) =>
                                  handleSelectSpecialColor(e, color.name)
                                }
                                style={{
                                  backgroundColor: colorsConfig[color.name],
                                  opacity: disablePrimary && color.name === "primary" ? 0.5 : 1,
                                  cursor: disablePrimary && color.name === "primary" ? "default" : "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "30px",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  textAlign: "center"
                                }}
                              >
                                {/* {color.label} */}
                              </a>
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <span style={{ backgroundColor: "#" + customColor }}></span>
                      <div>
                        <strong>#</strong>
                        <input
                          type="text"
                          value={customColor || ""}
                          onChange={(e) => handleChangeCustomColor(e)}
                        />
                        <IconButton
                          aria-label="send color"
                          onClick={handleSelectCustomColor}
                          disabled={
                            customColor?.length !== 3 && customColor?.length !== 6
                          }
                        >
                          <Icon name="check" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </Popover>
              </div>
            }
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export { ColorField };

export const getFields = () => {
  const data: any = {
    indicator: [
      {
        type: "text",
        displayName: "Nombre del indicador",
        name: "display_name",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "periodicity",
        displayName: "Periodicidad",
        name: "periodicity",
        description: null,
        size: 12,
        editable: null,
        editableIf: null,
        validations: {},
      },
      {
        type: "tags",
        displayName: "Etiquetas",
        name: "tags",
        description: null,
        size: 12,
        tagGroup: {
          id: 3,
        },
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "text",
        displayName: "Código",
        name: "code",
        description: "EJ: IND001, IND002, etc.",
        size: 4,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "text",
        displayName: "Unid. de nedida",
        name: "measurement_unit",
        description: "Ej: $, Mil. $, US$, Mill. US$, etc.",
        size: 4,
        editable: true,
        editableIf: null,
        validations: {},
      },

      {
        type: "integer",
        displayName: "Precisión decimal",
        name: "decimal_precision",
        description: null,
        size: 4,
        showIf: null,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "list",
        displayName: "Variables que intervienen",
        name: "variables",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        //defaultValue: ["yyy", "oooo"],
        //itemPlaceholder: "mi@email.com",
        validations: {},
      },
      {
        type: "longText",
        displayName: "Limitaciones del indicador",
        name: "limitations",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        displayName: "Metodología de cálculo",
        name: "calculation_methodology",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      {
        type: "longText",
        displayName: "Objetivo",
        name: "objective",
        description: null,
        size: 12,
        editable: true,
        editableIf: null,
        validations: {},
      },
      // {
      //   type: "radio",
      //   displayName: "Tipo de indicador",
      //   name: "type",
      //   description: null,
      //   //options: ["Simple", "Único desglose", "Múltiples desgloses"],
      //   options: [
      //     { name: "simple", displayName: "Simple" },
      //     { name: "single_breakdown", displayName: "Único desglose" },
      //     { name: "multi_breakdown", displayName: "Múltiples desgloses" },
      //   ],
      //   size: 12,
      //   showIf: null,
      //   editable: null,
      //   editableIf: null,
      //   validations: {},
      // },
    ],
  };

  return data;
};

import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { formatNumber } from "../../../../utils/formatNumber";
import "./SimpleIndicatorTable.scss";
const SimpleIndicatorTable = ({ data, impactType, decimalPrecision = 2 }: any) => {
  const dataCopy = [...data];
  const reversedData = dataCopy.reverse();
  const getArrowColor = (percentageDifference: number) => {
    if (percentageDifference > 0) {
      return impactType === "negative" ? "error" : "success";
    } else if (percentageDifference < 0) {
      return impactType === "negative" ? "success" : "error";
    }
    return "inherit";
  };
  return (
    <div className="simple-indicator-table-container">
      <table>
        <thead>
          <tr>
            <th>Periodo</th>
            <th style={{ textAlign: "right" }}>Valor</th>
            <th style={{ textAlign: "right" }}>Variación (%)</th>
          </tr>
        </thead>
        <tbody>
          {reversedData.map((item: any, index: number) => (
            <tr key={item.period}>
              <td>{item.display_period}</td>
              <td>
                <span className="amount-wrapper">
                  {formatNumber(item.value, decimalPrecision)}
                </span>
              </td>
              <td>
                {index != reversedData.length - 1 && (
                  <span className="variation-wrapper">
                    {item?.percentage_difference
                      ? formatNumber(item?.percentage_difference.toFixed(2)) +
                      "%"
                      : "-"}
                    {item?.percentage_difference !== 0 ? (
                      item?.percentage_difference > 0 ? (
                        <ArrowDropUpIcon
                          color={getArrowColor(item.percentage_difference)}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          color={getArrowColor(item.percentage_difference)}
                        />
                      )
                    ) : null}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { SimpleIndicatorTable };

import React, { useEffect, useState } from "react";
import { AnimatedNumber } from "../../AnimatedNumber/AnimatedNumber";
import "./MiniFilledShapeChart.scss";
import Icon from "components/Icon/Icon";

interface DataItem {
  label: string;
  value: number;
  color: string;
  icon: string;
  height?: number; // Agregamos height como opcional
}

interface MiniFilledShapeChartProps {
  data: DataItem[];
  decimalPrecision: number;
}

const MiniFilledShapeChart = (props: MiniFilledShapeChartProps) => {
  const { data, decimalPrecision } = props;
  const [updatedData, setUpdatedData] = useState<DataItem[]>([]);

  const squareSize = data?.length > 2 ? 140 / data.length : 50;

  useEffect(() => {
    if (data?.length) {
      // Sumar todos los valores de los items
      const totalValue = data.reduce((acc, item) => acc + item.value, 0);

      // Calcular el porcentaje (height) y actualizar los datos
      const dataWithHeight = data.map(item => ({
        ...item,
        height: (item.value / totalValue) * 100,
      }));

      setUpdatedData(dataWithHeight);
    }
  }, [data]);

  return (
    <div className="mini-filled-shape-chart-container">
      {updatedData?.map((item: DataItem, index: number) => {
        return (
          <div key={index}>
            <div
              style={{
                width: squareSize + "px",
                height: squareSize + "px",
              }}
            >
              <div
                style={{
                  width: squareSize + "px",
                  height: squareSize + "px",
                }}
              >
                <Icon name={item?.icon} color="#fff" size={squareSize} />
              </div>
              <div
                style={{
                  height: squareSize - 2 + "px",
                  width: squareSize - 2 + "px",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: item.color,
                  height: item.height ? item.height - 2 + "%" : "0%",
                  width: squareSize - 2 + "px",
                }}
              ></div>
            </div>
            <div>
              <div>
                <AnimatedNumber
                  value={item.value}
                  duration="500"
                  color={item?.color}
                  fontSize={data?.length > 2 ? ".6em" : ".7em"}
                  decimalPrecision={decimalPrecision}
                  type="decimal"
                />
              </div>
              <span className="label" style={{ fontSize: ".7em" }}>{item.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { MiniFilledShapeChart };

import React from "react";
import "./WorkflowsCard.scss";
import { BasicIndicator } from "../../../../components/BasicIndicator/BasicIndicator";
const WorkflowsCard = (props: any) => {
  const { title, config, data } = props;
  return (
    <div className="workflows-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <BasicIndicator
          color={config.color}
          value={data?.workflowsCount}
          label={"Workflows"}
        />
      </div>
    </div>
  );
};

export { WorkflowsCard };

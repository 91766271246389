import React, { useEffect } from 'react';

interface T2Props {
    textContent: string;
    selected: boolean;
    onChange: (text: string) => void;
    style: React.CSSProperties;

}

const T2: React.FC<T2Props> = ({ textContent, selected, onChange, style }) => {
    useEffect(() => {
        console.log('style', style)
    }, [style])
    const handleTextareaClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return selected ? (
        <textarea
            autoFocus
            value={textContent}
            onChange={(e) => onChange(e.target.value)}
            onClick={handleTextareaClick}

            style={{ ...style, resize: 'none', border: 'none', outline: 'none' }}
        />
    ) : (
        <h1 style={style}>{textContent}</h1>

    );
};

export default T2;
import { useState, useEffect, Fragment } from "react";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";
import "./MultiSelectFilter.scss";

import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
const MultiSelectFilter = ({
  initialValue,
  label,
  onChange,
  searchPath,
  primaryKey,
}: any) => {
  const [selectedOperator] = useState("in");
  const [value, setValue] = useState(initialValue || []);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isActive = value.length > 0;

    if (onChange) {
      onChange({
        operator: selectedOperator,
        value: value.map((tag: any) => tag.id),
        active: isActive
      });
    }
    console.log(primaryKey)
  }, [value, selectedOperator]);

  const handleChange = async (event: any) => {
    try {
      if (event.target.value != "") {
        setNoOptionsText("Sin resultado");
      } else {
        setNoOptionsText("Buscar...");
      }
      setLoading(true);

      let url = `${urls.server}/api/${searchPath}?search_term=${event.target.value}&offset=0&limit=20`;

      const res = await AxiosInterceptor.get(`${url}`);
      const data = await res?.data;
      setOptions(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
      setNoOptionsText("Buscar...");
    }
  }, [open]);
  return (
    <div className="multi-select-filter-container">
      <label className="filter-label">{label}</label>
      <div className="autocomplete-wrapper">
        <Autocomplete
          multiple
          noOptionsText={noOptionsText}
          loadingText="Buscando..."
          //sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: any, value: any) =>
            option[primaryKey] === value[primaryKey]
          }
          getOptionLabel={(option: any) => option[primaryKey]}
          options={options}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option[primaryKey]}
            </Box>
          )}
          loading={loading}
          onChange={(event: any, newValue: any | null) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              hiddenLabel
              onChange={handleChange}
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          value={value}
        />
      </div>
    </div>
  );
};

export { MultiSelectFilter };

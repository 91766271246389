export const getPaymentMethods = () => {
  const data: any = [
    {
      name: "cash",
      displayName: "Efectivo",
      amount: 0,
    },
    {
      name: "credit_card",
      displayName: "Tarjeta de crédito",
      amount: 0,
    },
    {
      name: "debit_card",
      displayName: "Tarjeta de débito",
      amount: 0,
    },
    {
      name: "mercado_pago",
      displayName: "Mercado Pago",
      amount: 0,
    },
    {
      name: "bank_payment",
      displayName: "Pago bancario",
      amount: 0,
    },
  ];

  return data;
};

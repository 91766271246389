import React, { useEffect, useState } from "react";
import "./MyDebtPage.scss";
import { Header } from "components/Header/Header";
import { Spinner } from "components/Spinner/Spinner";

const MyDebtPage = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState('' as any);
  const [data, setData] = useState([]);
  const [moreHeaderActions, setMoreHeaderActions] = useState([] as any);

  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }
  const loadData = async () => {
    setLoading(true);
    // const res = await AxiosInterceptor.get(
    //   `${urls.server}/api/my/ring-binders`
    // );
    // setData(res?.data);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="my-debt-page-container page-container">
      <Header
        title="Mi deuda"
        hasSearchBar
        onSearchChange={handleSearchChange}
        moreActions={moreHeaderActions}
        onSelectMoreAction={(res: any) => {
        }}
      />
      {!loading ?
        <>
          <div className="page-body">

          </div>
        </>

        : <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>}

    </div>
  );
};

export { MyDebtPage };

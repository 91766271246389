import React, { useState, useEffect } from "react";
import "./SingleBreakdownIndicatorCardBody.scss";
import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import { MiniMultiLineChart } from "components/miniCharts/MiniMultiLineChart/MiniMultiLineChart";
import { MiniFilledShapeChart } from "components/miniCharts/MiniFilledShapeChart/MiniFilledShapeChart";
import { MiniGroupedColumnChart } from "components/miniCharts/MiniGroupedColumnChart/MiniGroupedColumnChart";
import { MiniStackedColumnChart } from "components/miniCharts/MiniStackedColumnChart/MiniStackedColumnChart";
import { MiniBreakdownChart } from "components/miniCharts/MiniBreakdownChart/MiniBreakdownChart";
import { MiniPieChart } from "components/miniCharts/MiniPieChart/MiniPieChart";
import colors from "config/colors";
import { MiniColumnLineChart } from "components/miniCharts/MiniColumnLineChart/MiniColumnLineChart";
const SingleBreakdownIndicatorCardBody = (props: any) => {
  const { data = [], config } = props;
  const [currentIndex, setCurrentIndex] = useState(data?.length - 1);
  const [currentItem, setCurrentItem] = useState(data[data?.length - 1]);
  const [currentSubIndex, setCurrentSubIndex] = useState(0);
  const [currentSubItem, setCurrentSubItem] = useState({} as any);

  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [currentValue, setCurrentValue] = useState(0);
  const [currentColor, setCurrentColor] = useState(colors.primary);
  const [currentLabel, setCurrentLabel] = useState(null);

  useEffect(() => {
    setCurrentPeriod(data[data?.length - 1].display_period);
    setCurrentValue(data[data?.length - 1].data[data[data?.length - 1].data.length - 1].value);
    setCurrentColor(data[data?.length - 1].data[data[data?.length - 1].data.length - 1].color);
    setCurrentLabel(data[data?.length - 1].data[data[data?.length - 1].data.length - 1].label);

    setTimeout(() => {
      setCurrentItem(data[data?.length - 1]);

      if (
        config.chartType === "breakdownChart" ||
        config.chartType === "pieChart"
      ) {

        // const sortedData = [...data[data?.length - 1]?.data].sort(
        //   (a: any, b: any) => b.value - a.value
        // );
        //setCurrentSubItem(sortedData[0]);
        setCurrentSubItem(data[data?.length - 1]?.data[data[data?.length - 1]?.data.length - 1]);

      }
    }, 100);
  }, []);
  useEffect(() => {
    const newData = data?.slice(-10);
    setCurrentItem(newData[currentIndex]);
  }, [currentIndex]);
  useEffect(() => {
    if (
      config.chartType === "breakdownChart" ||
      config.chartType === "pieChart"
    ) {

      // const sortedData = [...data[data?.length - 1]?.data].sort(
      //   (a: any, b: any) => b.value - a.value
      // );
      //setCurrentSubItem(sortedData[currentSubIndex]);
      setCurrentSubItem(data[data?.length - 1]?.data[currentSubIndex])
    }
  }, [currentSubIndex]);
  return (
    <div className="single-breakdown-indicator-card-body-container">
      {data.length > 0 ? (
        <>
          <div className="current-data">

            <strong>{currentPeriod}</strong>

            {(config.chartType != "pieChart" && config.chartType != "filledShapeChart") ?
              <>
                <span
                  style={{
                    color: currentColor,
                  }}
                >
                  {currentLabel}
                </span>
                <div>
                  <AnimatedNumber
                    value={currentValue}
                    duration="500"
                    color={currentColor}
                    fontWeight={900}
                    fontSize={currentValue > 9999 ? "15px" : "17px"}
                    decimalPrecision={config.decimalPrecision}
                    type={"decimal"}
                  />
                  <span style={{
                    color: colors.textTint
                  }}>({config?.measurementUnit}</span>
                </div>
              </> : <>
                <div style={{
                  fontSize: "13px",
                  marginBottom: "3px"
                }}><b style={{
                  display: "inline-block",
                  marginRight: "3px"
                }}>Unid. med.:</b> {config?.measurementUnit}</div>
              </>
            }
          </div>
          <div className="chart-wrapper">
            {(() => {
              switch (config.chartType) {
                case "multiLineChart":
                  return (
                    <MiniMultiLineChart
                      data={data?.slice(-10)}
                      height={"75"}
                      decimalPrecision={config?.decimalPrecision}
                      onPointSelect={({ period, value, label, color }: any) => {
                        console.log(period, value, label, color);

                        setCurrentPeriod(period)
                        setCurrentValue(value)
                        setCurrentColor(color)
                        setCurrentLabel(label)
                      }}

                    />
                  );
                case "columnLineChart":
                  return (
                    <MiniColumnLineChart
                      data={data?.slice(-10)}
                      height={"75"}
                      decimalPrecision={config?.decimalPrecision}
                      onElementSelect={({ period, value, label, color }: any) => {
                        console.log(period, value, label, color);

                        setCurrentPeriod(period)
                        setCurrentValue(value)
                        setCurrentColor(color)
                        setCurrentLabel(label)
                      }}

                    />
                  );
                case "filledShapeChart":
                  return <MiniFilledShapeChart data={currentItem?.data} decimalPrecision={config?.decimalPrecision} />;
                case "groupedColumnChart":
                  return (
                    <MiniGroupedColumnChart
                      data={data?.slice(-10)}
                      onSubColumnSelect={({ period, value, label, color }: any) => {
                        setCurrentPeriod(period)
                        setCurrentValue(value)
                        setCurrentColor(color)
                        setCurrentLabel(label)
                      }}
                    />
                  );
                case "stackedColumnChart":
                  return (
                    <MiniStackedColumnChart
                      data={data?.slice(-10)}
                      onSubColumnSelect={({ period, value, label, color }: any) => {
                        console.log(period, value, label, color);

                        setCurrentPeriod(period)
                        setCurrentValue(value)
                        setCurrentColor(color)
                        setCurrentLabel(label)
                      }}

                    />
                  );
                case "breakdownChart":
                  return (
                    <MiniBreakdownChart
                      data={currentItem?.data}
                      onItemSelect={({ color, label, value }: any) => {
                        setCurrentValue(value)
                        setCurrentColor(color)
                        setCurrentLabel(label)
                      }}
                    />
                  );
                case "pieChart":
                  return (
                    <div className="pie-chart-wrapper">
                      <MiniPieChart
                        data={currentItem?.data}
                        height={85}
                        onSelectItem={(index: number) => {
                          console.log(index);
                          setCurrentSubIndex(index);
                        }}
                      />
                      <div className="pie-chart-current-sub-item">
                        <AnimatedNumber
                          value={currentSubItem?.value}
                          duration="500"
                          color={currentSubItem?.color}
                          fontWeight={900}
                          decimalPrecision={config.decimalPrecision}
                          type={"decimal"}
                          fontSize={
                            currentSubItem?.value > 9999 ? "13px" : "16px"
                          }
                        />
                        <span
                          style={{
                            color: currentSubItem?.color,
                            fontSize: 13,
                            lineHeight: 1.2,
                            //display: "block",
                          }}
                        >
                          {currentSubItem.label}
                        </span>
                      </div>
                    </div>
                  );
              }
            })()}
          </div>
        </>
      ) : (
        <div className="no-data">- {config?.measurementUnit} </div>
      )}
    </div>
  );
};

export { SingleBreakdownIndicatorCardBody };

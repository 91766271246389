import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Spinner } from "components/Spinner/Spinner";
import { useTextEditorField } from "./TextEditorFieldContext";
import { ReadonlyTextEditorField } from "./ReadonlyTextEditorField/ReadonlyTextEditorField";
import { EditableTextEditorField } from "./EditableTextEditorField/EditableTextEditorField";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";


const TextEditorField = ({
  defaultValue,
  editable,
  label,
  editPath,
  onChange,
  tableName,
  name,
  recordId,
  //readonly,
  mode
}: any) => {
  //const [data, setData] = useState(defaultValue as any);

  const { handleAddItem, items, setItems, handleSortEnd } =
    useTextEditorField();
  //const [processing, setProcessing] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  // const getValueSessionStorage = (key: string) => {
  //   let array = sessionStorage.getItem(key);
  //   if (array === null) return null;
  //   return JSON.parse(array);
  // };
  // const handleSave = async () => {
  //   if (tableName) tableName = tableName.replace(/_/g, "-");
  //   const url = tableName
  //     ? `${urls.server}/api/${tableName}/${recordId}/${name}`
  //     : `${urls.server}/api/${editPath}`;
  //   setProcessing(true);
  //   try {
  //     const res = await AxiosInterceptor.put(`${url}`, { data: items });
  //     const resData = await res?.data;
  //     const { message } = resData;
  //     setAlertMsg(message.toString());
  //     setEnabledEdition(false);
  //     setProcessing(false);
  //     setOpenAlert(true);
  //     setSeverity("success");
  //     sessionStorage.removeItem(name);
  //   } catch (error: any) {
  //     setEnabledEdition(false);
  //     setProcessing(false);
  //     setAlertMsg(error.toString());
  //     setOpenAlert(true);
  //     setSeverity("error");
  //     setItems(getValueSessionStorage(name));
  //   }
  // };
  // const enableEdition = () => {
  //   setEnabledEdition(true);

  //   sessionStorage.setItem(name, JSON.stringify(defaultValue));
  // };
  useEffect(() => {
    if (onChange) {
      onChange(items);
    }
  }, [items]);
  useEffect(() => {
    setItems(defaultValue || []);
  }, []);

  // interface Item {
  //   content: string;
  //   fontSize: number;
  //   fontWeight: number;
  //   label: string;
  //   textAlign: string;
  //   type: string;
  // }
  // const isDisabled = (current: Array<Item>, prev: Array<Item>) => {
  //   return (
  //     current?.length === prev?.length &&
  //     current.every((value, index) => value.content === prev[index].content)
  //   );
  // };

  return (
    <div className="text-editor-field-container">
      {/* <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {enabledEdition ? (
              <>
                {processing ? (
                  <IconButton color="primary" disabled>
                    <Spinner size="sm" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={handleSave}
                    disabled={isDisabled(items, getValueSessionStorage(name))}
                    color="primary"
                    size="large"
                  >
                    <Icon name="check" />
                  </IconButton>
                )}

                <a
                  onClick={() => {
                    setEnabledEdition(false);
                    setItems(getValueSessionStorage(name));
                  }}
                >
                  <Icon name="close" />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <Icon name="pencil" />
                </a>
              </>
            )}
          </div>
        )}
      </div> */}
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={items}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setItems(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setItems(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <ReadonlyTextEditorField data={items} />
          ) : (
            <EditableTextEditorField
              data={items}
              onSortEnd={handleSortEnd}
              onAddItem={handleAddItem}
              onChange={(res: any) => {
                console.log(res);
                setItems(res);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { TextEditorField };

import React, { useState, useEffect, useCallback, useRef } from "react";
import "./ContentBuilderModal.scss";
import Modal from "components/Modal/Modal";
import { Fab } from "@mui/material";
import Icon from "components/Icon/Icon";
import ContentTree from "components/ContentTree/ContentTree";

interface ModalProps {
  isOpen: boolean;
  title: string;
  data: any;
  onClose: (treeData?: any) => void; // Actualizado para aceptar treeData
}

interface TreeNode {
  type: string;
  tagName?: string;
  attributes?: any;
  children?: TreeNode[];
  content?: string;
  showIf?: string;
}

const ContentBuilderModal: React.FC<ModalProps> = ({ isOpen, title, data, onClose }) => {
  const [treeData, setTreeData] = useState<TreeNode[]>(data || []);
  const prevTreeDataRef = useRef<TreeNode[]>(treeData);

  useEffect(() => {
    setTreeData(data || []);
  }, [data]);

  const handleOnClose = () => {
    const processedTreeData = treeData.map(processNodeAttributes);
    onClose(processedTreeData);
  };

  const handleTreeChange = (data: any) => {
    if (JSON.stringify(prevTreeDataRef.current) !== JSON.stringify(data)) {
      setTreeData(data);
      prevTreeDataRef.current = data;
      console.log('Tree data updated:', data);
    }
  };

  const processNodeAttributes = (node: TreeNode): TreeNode => {
    let attributes;
    try {
      attributes = typeof node.attributes === 'string' ? JSON.parse(node.attributes) : node.attributes;
    } catch (e) {
      attributes = node.attributes;
    }

    return {
      ...node,
      attributes,
      children: node.children ? node.children.map(processNodeAttributes) : []
    };
  };

  if (!isOpen) return null;
  return (
    <>
      <Modal
        title={title}
        className="content-builder-modal-container"
        isOpen={isOpen}
        onClose={() => onClose()}
        style={{}}
      >
        <ContentTree onChange={handleTreeChange} data={treeData} /> {/* Pasar data como prop */}
        <pre>{JSON.stringify(treeData, null, 2)}</pre>
        <Fab
          className="fab"
          color="primary"
          aria-label="send"
          onClick={handleOnClose}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 0
          }}
        >
          <Icon name="check" />
        </Fab>
      </Modal>
    </>
  );
};

export { ContentBuilderModal };

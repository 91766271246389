import React, { useEffect, useState } from "react";
import {
  Fab,
} from "@mui/material";

import "./TagsModal.scss";
import Modal from "components/Modal/Modal";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
import DynamicList from "components/DynamicList/DynamicList";
import Icon from "components/Icon/Icon";

interface ModalProps {
  isOpen: boolean;
  groupDisplayName: string;
  groupName: string;
  onClose: (refreshOnClose?: boolean) => void;
}

const TagsModal: React.FC<ModalProps> = ({
  isOpen,
  groupDisplayName,
  groupName,
  onClose,

}) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  //const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  const [editFormDialogIsOpen, setEditFormDialogIsOpen] = useState(false);
  const [createFormDialogIsOpen, setCreateFormDialogIsOpen] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [selectedItem, setSelectedItem] = useState({} as any);

  const subtitle = `<b>Grupo:</b> ${groupDisplayName}`;
  const formFields: any[] = [
    {
      type: "text",
      name: "name",
      displayName: "Nombre",
      size: 12,
      showInModes: ['create', 'globalEdit']
    },
    {
      type: "pastelColor",
      name: "color",
      displayName: "Color",
      size: 12,
      showInModes: ['create', 'globalEdit']
    },
  ];
  const itemCells: any[] = [
    {
      type: "icon",
      name: "tag",
      colorKey: "color",
      size: 24,

    },
    {
      type: "interpolatedContent",
      htmlContent: `<div class='tag-display-name'>{{name}}</div>`,
    }
  ]
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);
    } else { setShouldRefresh(false); }
  }, [isOpen])
  return (
    <>
      <Modal
        title={"Etiquetas"}
        subtitle={subtitle}
        className={'tags-modal-container'}
        isOpen={isOpen}
        onClose={onClose}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
      >
        {
          isOpen &&
          <>
            <DynamicList
              searchTerm={searchTerm}
              listPath={`tags?group_name=${groupName}`}
              //deletePath={`tags/{{id}}`}
              sortPath={`tags/sort?tag_group_name=${groupName}`}
              //deleteConfirmationMessage={"¿Esta seguro que desea eliminar la etiqueta <b>{{name}}</b>?"}
              forceRefresh={shouldRefresh}
              onRefreshComplete={handleRefreshComplete}
              onSelectItemAction={(res: any) => {
                console.log(res);
                if (res?.actionName == "edit") {
                  setSelectedItem(res?.currentItem);
                  setEditFormDialogIsOpen(true);
                }
              }}
              hiddenActiveFilters={[]}
              activeFilters={[]}
              //onSortEnd={handleSortEnd}
              leftCells={itemCells}
              itemActions={[
                {
                  name: 'edit',
                  displayName: 'Eliminar',
                  icon: 'pencil',
                },
                {
                  name: 'delete',
                  displayName: 'Eliminar',
                  icon: 'delete',
                  deletePath: `tags/{{id}}`,
                  deleteConfirmationMessage: "¿Esta seguro que desea eliminar la etiqueta <b>{{name}}</b>?"
                }
              ]}

              // onDeleteItem={onRemoveItem}
              // onEditItem={onEditItem}
              // onSortEnd={onSortEnd}
              noContentText='No hay etiquetas agregados'
              noContentIcon='tags'
              isSortable
            // style={{
            //     overflowY: 'scroll',
            //     height: '85%'
            // }}
            //itemStyle={{}}
            // canDelete
            // canEdit
            />
            <FormDialog
              isOpen={createFormDialogIsOpen}
              onClose={(data: any) => {
                setCreateFormDialogIsOpen(false);
                if (data) {
                  console.log(data);
                  setShouldRefresh(true);
                }
              }}
              title={"Crear etiqueta"}
              size="sm"
              fields={formFields}
              createPath="tags"
              extraData={{
                'group_name': groupName
              }}
              mode="create"
            />
            <FormDialog
              isOpen={editFormDialogIsOpen}
              onClose={(data: any) => {
                setEditFormDialogIsOpen(false);
                if (data) {
                  console.log(data);
                  setShouldRefresh(true);
                }
              }}
              title={"Editar etiqueta"}
              size="sm"
              fields={formFields}
              editPath={`tags/${selectedItem?.id}`}
              extraData={{
                'group_name': groupName
              }}
              defaultData={selectedItem}
              mode="globalEdit"
            />
            <Fab
              className="fab"
              color="primary"
              aria-label="add"
              onClick={() => {
                setCreateFormDialogIsOpen(true);
              }}
            >
              <Icon name="add" />
            </Fab>


          </>

        }

      </Modal>



    </>
  );
};

export { TagsModal };

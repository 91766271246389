import React, { useState } from 'react';
import Popover from "@mui/material/Popover";
import './ProfileButton.scss';
import { useAuth } from 'contexts/AuthContext';
import urls from "config/urls";
interface ProfileButtonProps {
    style?: React.CSSProperties;
    profileThumbnailStyle: React.CSSProperties;
    popoverStyle: any;
    profileImageStyle: React.CSSProperties;
    profileNameStyle: React.CSSProperties;
    profileEmailStyle: React.CSSProperties;
    authButtonStyle: React.CSSProperties;
    displayMode?: 'popover' | 'expanded'; // Nueva prop para controlar el modo de visualización
}

const ProfileButton: React.FC<ProfileButtonProps> = ({
    style,
    profileThumbnailStyle,
    popoverStyle,
    profileImageStyle,
    profileNameStyle,
    profileEmailStyle,
    authButtonStyle,
    displayMode = 'popover', // Valor predeterminado
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { getUser } = useAuth();

    const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div style={{

        }}>
            {displayMode === 'popover' ? (
                <>
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ...style
                        }}
                        onClick={handlePresentPopover}>
                        {
                            getUser()?.profile_picture ? <img style={profileThumbnailStyle} src={`${urls.server}${getUser().profile_picture}`} alt="" /> : <img style={profileThumbnailStyle} src={"../assets/profile-image.svg"} alt="" />
                        }

                    </button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        className="profile-button-popover"
                        PaperProps={{
                            sx: popoverStyle,
                        }}
                    >
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 5
                        }}>
                            <img style={profileImageStyle} src={"assets/profile-image.svg"} alt="" />
                        </div>

                        <div style={profileNameStyle}>
                            Mi usuario
                        </div>
                        <div style={profileEmailStyle}>
                            mi@email.com
                        </div>
                    </Popover>
                </>
            ) : (
                <div className='expanded-profile'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5
                    }}>
                        <img style={profileImageStyle} src={"assets/profile-image.svg"} alt="" />
                    </div>
                    <div style={profileNameStyle}>
                        Mi usuario
                    </div>
                    <div style={profileEmailStyle}>
                        mi@email.com
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileButton;
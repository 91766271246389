import React, { useState } from "react";
import Icon from "components/Icon/Icon";
import { Fab } from "@mui/material";
import { CommentsDrawer } from "../../../../components/drawers/CommentsDrawer/CommentsDrawer";
import "./InteractiveActions.scss";
import { InteractiveActionItem } from "../InteractiveActionItem/InteractiveActionItem";
const InteractiveActions = ({
  actions,
  commentCount,
  onChange,
  stepDisplayName,
  stepEntryId,
  canExecute,
}: any) => {
  const [openComments, setOpenComments] = useState(false);
  return (
    <div className="interactive-actions-container">
      {canExecute ? (
        <div>
          {actions?.map((action: any, index: number) => {
            return (
              // <a key={index}>
              //   {action.display_name}
              //   <Icon name={action.icon?.name} />
              // </a>
              <InteractiveActionItem
                key={index}
                name={action.name}
                displayName={action.display_name}
                icon={action.icon}
                params={action.params}
                formTitle={action.form_title}
                formFields={action.form_fields}
                extraData={action.extra_data}
                status={action.status}
                stepEntryId={stepEntryId}
                id={action.id}
                isDisabled={action.is_disabled}
                onChange={onChange}
              />
            );
          })}
        </div>
      ) : (
        <div className="no-content">
          <Icon name="lockedTap" />
          <p>Acciones bloqueadas</p>
        </div>
      )}
      <Fab
        aria-label="present comments"
        size="small"
        sx={{
          position: "fixed",
          bottom: 30,
          right: 16,
          overflow: "inherit",
        }}
        className="comment-fab"
        onClick={() => {
          setOpenComments(true);
        }}
      >
        <span className="badge">
          {commentCount > 99 ? "+99" : commentCount}
        </span>
        <Icon name="comment" />
      </Fab>
      <CommentsDrawer
        open={openComments}
        setOpen={setOpenComments}
        stepDisplayName={stepDisplayName}
        stepEntryId={stepEntryId}
        onClose={(res: boolean) => {
          if (res) onChange(true);
        }}
      />
    </div>
  );
};

export { InteractiveActions };

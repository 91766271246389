// components/Configuration/HeaderConfiguration.tsx
import React from 'react';
import { CheckboxField } from 'components/fields/CheckboxField/CheckboxField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';

import { ColorField } from 'components/fields/ColorField/ColorField';
import SizeField from '../../SizeField/SizeField';

interface HeaderConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const HeaderConfiguration: React.FC<HeaderConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <div className="action-wrapper">
                <CheckboxField
                    label={"Se colapsa?"}
                    onChange={(value: any) => handleChange('isCollapsed', value)}
                    defaultValue={selectedTab === 'default' ? localConfig?.isCollapsed : localConfig?.responsive?.[selectedTab]?.isCollapsed || localConfig?.isCollapsed}
                    description={""}
                />
            </div>
            <ExpandableSection title="Cajon">
                <div className="action-wrapper">
                    <SizeField
                        label="Ancho máximo (Cajón)"
                        field="drawer.maxWidth"
                        isEnabled={getFieldValues('drawer.maxWidth').value !== null}
                        value={getFieldValues('drawer.maxWidth').value}
                        unit={getFieldValues('drawer.maxWidth').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.drawer?.backgroundColor || null : localConfig?.drawer?.responsive?.[selectedTab]?.backgroundColor || localConfig?.drawer?.backgroundColor || null}
                        label={"Color de fondo (Cajón)"}
                        onChange={(color: any) => handleChange('drawer.backgroundColor', color)}
                    />
                </div>
            </ExpandableSection>
        </>
    );
};

export default HeaderConfiguration;

import React from "react";
import "./WorkflowStepperCell.scss";

const WorkflowStepperCell = ({ steps, stepsPerGroup = 3 }: any) => {
  // Función auxiliar para dividir el array en grupos de tamaño "stepsPerGroup"
  const chunkArray = (array: any[], size: number) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  // Dividimos los pasos en grupos de tamaño "stepsPerGroup"
  const stepGroups = chunkArray(steps, stepsPerGroup);

  return (
    <div className="workflow-stepper-cell-container">
      {stepGroups.map((group: any[], groupIndex: number) => (
        <div key={groupIndex} className="step-group">
          {group.map((item: any, index: number) => (
            <div key={index} className="step">
              <div className="step-name">
                <h3>{item.display_name}</h3>
              </div>
              <span>{groupIndex * stepsPerGroup + index + 1}</span>
              {/* {item.description && <div>{item.description}</div>} */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export { WorkflowStepperCell };

export const project = {
  name: "Nubelon",
  // slug: "nubelon",
  // colorPrimary: "#e16945",
  // colorPrimaryShade: "#b45033",
  // colorPrimaryTint: "#f8dad1",
  // colorSecondary: "#0d3880",
  // colorSecondaryShade: "#95c121",
  // colorSecondaryTint: "#95c121",
  version: "v1.0.0",
};

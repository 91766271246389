import React, { useState, useEffect } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./GroupedColumnChart.scss";
import { formatNumber } from "../../../utils/formatNumber";
interface SubItem {
  label: string;
  value: number;
  color: string;
  breakdown_id: number;
  height?: number;
}

interface Item {
  label: string;
  data: SubItem[];
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f2f2f4",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    //maxHeight: "100px",
  },
}));
const GroupedColumnChart = (props: any) => {
  const { data, height = 200, decimalPrecision = 2, measurementUnit } = props;
  const [items, setItems] = useState(data as any);
  const [xAxisItems, setXAxisItems] = useState([] as any);
  const [yAxisItems, setYAxisItems] = useState([] as any);
  const [legendItems, setLegendItems] = useState([] as any);
  const [filteredItems, setFilteredItems] = useState(items);
  const addHeightToItems = (items: Item[], height: number): Item[] => {
    const maxValue = Math.max(
      ...items.flatMap((item) => item.data.map((data) => data.value))
    );
    const maxHeight = height;

    return items.map((item) => ({
      ...item,
      data: item.data.map((data) => ({
        ...data,
        height: Math.round((data.value / maxValue) * maxHeight),
      })),
    }));
  };

  function getXAxisItems(n: number) {
    if (n < 2 || n > items.length) {
      //throw new Error("Invalid value for n");
      return [];
    }

    const result = [];
    const step = (items.length - 1) / (n - 1);

    for (let i = 0; i < n; i++) {
      const index = Math.round(i * step);
      result.push(items[index].display_period);
    }

    return result;
  }

  function getYAxisItems(n: number) {
    if (n < 2) {
      //throw new Error("Invalid value for n");
      return [];
    }
    const selectedCount = legendItems.filter(
      (item: any) => item.selected
    ).length;
    if (selectedCount == 0) {
      return [];
    }
    // Extraer todos los objetos "data" y combinarlos en un solo array
    const allDataItems = filteredItems.flatMap((item: any) => item.data);

    // Encontrar el valor máximo entre todos los objetos "data"
    const maxValue = Math.max(...allDataItems.map((item: any) => item.value));

    // Si maxValue es 0, retorna un array vacío
    if (maxValue === 0) {
      return [];
    }

    const interval = maxValue / (n - 1);
    const result = [];

    for (let i = 0; i < n; i++) {
      result.push(Math.round(i * interval * 100) / 100); // Redondear a 2 decimales
    }

    // Ordenar los valores en orden descendente
    result.sort((a, b) => b - a);

    return result;
  }

  function getLegendItems() {
    const legendItems: any = [];

    items.forEach((item: any) => {
      item.data.forEach((subItem: any) => {
        if (!legendItems.some((entry: any) => entry.label === subItem.label)) {
          legendItems.push({
            label: subItem.label,
            color: subItem.color || "#ccc",
            selected: true,
          });
        }
      });
    });

    return legendItems;
  }

  useEffect(() => {
    if (data) {
      setItems([]);
      const n = 8;
      const newData = data.length > n ? data.slice(-n) : data;
      const columns = addHeightToItems(newData, height);
      setTimeout(() => {
        setItems(columns);
      }, 100);
    }
  }, [data]);
  function isSelected(label: string, legendItems: any) {
    const item = legendItems.find((item: any) => item.label === label);
    return item ? item.selected : false;
  }
  useEffect(() => {
    if (items && legendItems && legendItems.length > 0) {
      // Calcula el valor máximo en el primer array considerando solo los elementos seleccionados
      const maxValue = items.reduce((max: number, item: any) => {
        return Math.max(
          max,
          ...item.data
            .filter((subItem: any) => isSelected(subItem.label, legendItems))
            .map((subItem: any) => subItem.value)
        );
      }, 0);

      const newFilteredItems = items.map((item: any) => {
        return {
          ...item,
          data: item.data
            .filter((subItem: any) => isSelected(subItem.label, legendItems))
            .map((subItem: any) => {
              return {
                ...subItem,
                height: adjustHeight(subItem.value, maxValue),
              };
            }),
        };
      });

      setFilteredItems(newFilteredItems);
    }
  }, [legendItems]);
  useEffect(() => {
    if (items && items.length > 0) {
      setXAxisItems(() => getXAxisItems(items.length > 6 ? 6 : items.length));
      //if (items.length > 0) setYAxisItems(() => getYAxisItems(5));
      setLegendItems(() => getLegendItems());
    }
  }, [items]);
  useEffect(() => {
    if (items && filteredItems && filteredItems.length > 0)
      setYAxisItems(() => getYAxisItems(5));
    console.log("filteredItems", filteredItems);
  }, [filteredItems]);

  function adjustHeight(value: number, maxValue: number) {
    if (maxValue === 0) {
      return 0;
    }
    return (value * height) / maxValue;
  }

  function handleLegendItemClick(index: number) {
    setLegendItems((prev: any) => {
      const prevCopy: any = [...prev];
      prevCopy[index].selected = !prevCopy[index].selected;
      return prevCopy;
    });
  }

  return (
    <>
      <div className="grouped-column-chart-container">
        <div className="chart">
          <div>
            <div className="y-axis" style={{ height: height + "px" }}>
              {yAxisItems.map((item: any, index: number) => {
                return <span key={index}>{formatNumber(item, decimalPrecision)}</span>;
              })}
            </div>
            <div className="main-content" style={{ height: height + "px" }}>
              {filteredItems?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="columns-wrapper"
                    style={{
                      height: height + "px",
                    }}
                  >
                    {item.data.map((subItem: any, subIndex: number) => {
                      return (
                        <HtmlTooltip
                          key={subIndex}
                          title={
                            <React.Fragment>
                              <div className="sub-column-tooltip">
                                <strong>{item.display_period}</strong>
                                <span style={{
                                  color: subItem.color
                                }}>{subItem.label}</span>
                                <div>
                                  <strong style={{
                                    color: subItem.color
                                  }}>{formatNumber(subItem.value, decimalPrecision)}</strong>
                                  <span>({measurementUnit})</span>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <a
                            key={subIndex}
                            style={{
                              height: subItem.height + "px",
                              background: subItem.color || "#ccc",
                            }}
                          ></a>
                        </HtmlTooltip>);
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="x-axis">
            {xAxisItems.map((item: any, index: number) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
        </div>
        <div className="legend">
          {legendItems.map((item: any, index: number) => {
            return (
              <a
                className="legend-item"
                key={index}
                onClick={() => {

                  handleLegendItemClick(index);
                }}
              >
                <span
                  className="legend-color"
                  style={{
                    backgroundColor: item.selected ? item.color : "#ccc",
                  }}
                ></span>
                <span className="legend-label">{item.label}</span>
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { GroupedColumnChart };

import { useState, useEffect } from "react";
import "./ContentBuilderField.scss";

import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { ContentBuilderModal } from "components/modals/ContentBuilderModal/ContentBuilderModal";
import Icon from "components/Icon/Icon";
import { Button } from "@mui/material";
const ContentBuilderField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    //readonly,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [contentBuilderModalIsOpen, setContentBuilderModalIsOpen] = useState(false);

  useEffect(() => {
    if (onChange) {
      console.log('hola')
      onChange(data);
    }
  }, [data]);

  return (
    <div className="content-builder-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content"></div>
          ) : (
            <div className="editable-content">
              <Button
                onClick={() => {
                  setContentBuilderModalIsOpen(true);
                }}
                startIcon={<Icon name="tools" />}
              >
                Abrir armador
              </Button>
            </div>
          )}
        </div>
      </div>
      <ContentBuilderModal
        isOpen={contentBuilderModalIsOpen}
        onClose={(res) => {
          console.log(res);
          setContentBuilderModalIsOpen(false);
          if (res) {
            setData(res)
            onChange(res)
          }
        }}
        title="Armador de contenido"
        data={data || []}
      />
    </div>
  );
};

export { ContentBuilderField };

import { useState, useEffect, Fragment } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import "./AddressPickerField.scss";
import {
  //TextField as MatTextField,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";


import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { AddressFormModal } from "components/modals/AddressFormModal/AddressFormModal";
import Icon from "components/Icon/Icon";

const AddressPickerField = (props: any) => {
  let {
    //id,
    name,
    editable,
    label,
    primaryKey = 'full_address',
    onChange,
    defaultValue,
    editPath,
    //readonly,
    filters = [],
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);


  useEffect(() => {
    if (!open) {
      setOptions([]);
      setLoading(false);
    } else {
      setNoOptionsText("Buscar...");
    }
  }, [open]);

  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);
    let url = `${urls.server}/api/addresses?search_term=${event.target.value
      }&offset=0&limit=20&filters=${encodeURIComponent(JSON.stringify(filters))}`;

    const res = await AxiosInterceptor.get(`${url}`);
    const data = await res?.data;
    setOptions(data);
    setLoading(false);
  };

  return (
    <div className="address-picker-field-container">
      <div>
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            <div>
              {label && <label>{label}</label>}
              {value && <span>{value[primaryKey]}</span>}
            </div>
          </div>
        ) : (
          <div className="editable-content">
            <div className="autocomplete-wrapper">
              {label && <label>{label}</label>}
              <Autocomplete
                noOptionsText={noOptionsText}
                loadingText="Buscando domicilio..."
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value) =>
                  option[primaryKey] === value[primaryKey]
                }
                getOptionLabel={(option) => option[primaryKey]}
                options={options || []}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option[primaryKey]}
                  </Box>
                )}
                loading={loading}
                value={value || null}
                onChange={(event: any, newValue: any | null) => {
                  onChange(newValue);
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    hiddenLabel
                    onChange={handleChange}
                    onFocus={handleChange}
                    {...params}
                    size="small"
                    //label={label}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>

            <IconButton
              color="primary"
              onClick={() => {
                setFormModalIsOpen(true);
              }}
              aria-label="add address"
              size="small"
            >
              <Icon name="add" />
            </IconButton>

          </div>
        )}
      </div>

      {editable && (
        <>
          {(mode == 'edit' && editable) && (
            <FieldEditControls
              value={value}
              fieldName={name}
              fieldType={'addressPicker'}
              editPath={editPath}
              onEditStart={() => {
                setIsEditEnabled(true);
              }}
              onEditCancel={(originalValue) => {
                setIsEditEnabled(false);
                setValue(originalValue);
              }}
              onEditEnd={(success: boolean, originalValue) => {
                setIsEditEnabled(false);
                if (!success) {
                  setValue(originalValue);
                }
              }}
            />
          )}</>
      )}
      <AddressFormModal
        isOpen={formModalIsOpen}
        mode={'create'}
        onClose={() => { setFormModalIsOpen(false) }}
      />
    </div>
  );
};

export { AddressPickerField };

import React, { useState, useEffect } from "react";

import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Fab } from "@mui/material";
import "./SelectModal.scss";
import { FormModal } from "../FormModal/FormModal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Spinner } from "../../Spinner/Spinner";
import Modal from "components/Modal/Modal";
import Icon from "components/Icon/Icon";

interface ModalProps {
  isOpen: boolean;
  formFields: any[];
  filters?: any[];
  title: string;
  primaryKey: string;
  secondaryKey?: string;
  formTitle?: string;
  listPath?: string;
  onClose: (item?: any) => void;
}
const SelectModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  listPath,
  filters = [],
  primaryKey,
  secondaryKey,
  formTitle,
  formFields,
}) => {
  //const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const loadRecords = async ({ offset }: any) => {
    try {
      if (offset) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      //tableName = tableName.replace(/_/g, "-");
      let url = `${urls.server
        }/api/${listPath}?offset=${offset}&limit=${limit}&search_term=${searchTerm}&filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`;
      const res = await AxiosInterceptor.get(url);
      const data = await res?.data;
      if (offset > 0) {
        setRecords((prev: any) => [...prev, ...data]);
      } else {
        setRecords(data);
      }
      setLoadingMore(false);
      setLoading(false);
      if (data.length < limit) setHasMore(false);
    } catch (error: any) {
      setAlertMsg("Error al listar los registros");
      setAlertIsOpen(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isOpen) loadRecords({ offset: 0 });
  }, [searchTerm, filters, isOpen]);
  useEffect(() => {
    if (offset > 0) loadRecords({ offset });
  }, [offset]);
  return (
    <>
      <Modal
        title={title}
        className={'select-modal-container'}
        isOpen={isOpen}
        onClose={() => { onClose() }}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
      >

        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && records.length == 0 && (
          <div className="empty-container">
            <Icon name="documents" />
            <p>No hay registros</p>
          </div>
        )}
        {!loading && records.length > 0 && (
          <>
            {records.map((item: any, index: number) => {
              return (
                <a
                  key={index}
                  onClick={() => {
                    onClose(item);
                  }}
                >
                  <div>
                    <strong>{item[primaryKey]}</strong>
                    {secondaryKey && <span>{item[secondaryKey] || "-"}</span>}
                  </div>

                  <ChevronRightIcon />
                </a>
              );
            })}
          </>
        )}
        {formTitle && formFields && (
          <Fab
            className="fab"
            color="primary"
            aria-label="add"
            onClick={() => {
              setFormModalIsOpen(true);
            }}
          >
            <Icon name="add" />
          </Fab>
        )}
        <FormModal
          isOpen={formModalIsOpen}
          onClose={(data: any) => {
            setFormModalIsOpen(false);
            // console.log("onClose", data);
            // if (data.refresh) loadRecords({ offset: 0 });
          }}
          title={formTitle || ''}
          fields={formFields}
        //tableName={tableName}
        />
      </Modal>

    </>
  );
};

export { SelectModal };

import React, { useState } from "react";
import "./MyRingBindersExpandedCard.scss";
const MyRingBindersExpandedCard = (props: any) => {
  const { title, data } = props;
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="my-ring-binders-expanded-card-container">
      <div className="expanded-card-header">
        <h2>{title}</h2>

      </div>
      <div className="expanded-card-body">

      </div>
    </div>
  );
};

export { MyRingBindersExpandedCard };

// export const formatDate = (dateString: string) => {
//   const date = new Date(dateString + 'T00:00:00Z'); // Agrega la parte de tiempo en UTC

//   const day = date.getUTCDate().toString().padStart(2, "0");
//   const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
//   const year = date.getUTCFullYear().toString();

//   return `${day}/${month}/${year}`;
// };
export const formatDate = (dateString: string) => {
  // Verifica si dateString contiene una parte de tiempo con "T"
  const dateWithTime = /T\d{2}:\d{2}:\d{2}/.test(dateString);
  const date = dateWithTime ? new Date(dateString) : new Date(dateString + 'T00:00:00Z');

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear().toString();

  return `${day}/${month}/${year}`;
};

import { useState, useEffect } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { Snackbar, Alert, Button } from "@mui/material";
import "./UserGroupsField.scss";
import Icon from "components/Icon/Icon";

const UserGroupsField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    tableName,
    name,
    recordId,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleSave = () => {
    setEnabledEdition(false);
  };
  const enableEdition = () => {
    setEnabledEdition(true);
  };
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  const editValue = async () => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/update-${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const data = {
        data: value,
      };
      const res = await AxiosInterceptor.post(`${url}`, data);
      const resData = await res?.data;

      setEnabledEdition(false);
      setProcessing(false);
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };

  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <div className="user-groups-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(recordId || editPath) && editable && (
          <div>
            {enabledEdition ? (
              <>
                <a onClick={handleSave}>
                  <Icon name="check" />
                </a>
                <a onClick={() => setEnabledEdition(false)}>
                  <Icon name="close" />
                </a>
              </>
            ) : (
              <>
                <a onClick={enableEdition}>
                  <Icon name="pencil" />
                </a>
              </>
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {recordId && editable && !enabledEdition ? (
            <span>{JSON.stringify(value)}</span>
          ) : (
            <div className="editable-content"></div>
          )}
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { UserGroupsField };

import React from "react";
import "./MyRingBindersCard.scss";
const MyRingBindersCard = (props: any) => {
  const { title, data, config } = props;
  return (
    <div className="my-ring-binders-card-container">
      <h2 className="card-title">{title}</h2>
      <div>

      </div>
    </div>
  );
};

export { MyRingBindersCard };

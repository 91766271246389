import React from 'react'
import './TaskDetailDrawer.scss';
import { DynamicForm } from 'components/DynamicForm/DynamicForm';
import Paper from 'components/Paper/Paper';
import Icon from 'components/Icon/Icon';
interface DrawerProps {
    taskId: number | null;
    fields: any;
    isVisible: boolean;
    onHide: (res?: any) => void
}
const TaskDetailDrawer: React.FC<DrawerProps> = ({ fields, taskId, isVisible = false, onHide }) => {
    const [data, setData] = React.useState({});
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = React.useState(false);
    return (

        <div className={`task-detail-drawer-container ${isVisible ? "visible" : "hidden"} `}>
            {
                isVisible && <>
                    <a onClick={() => { onHide() }}>
                        <Icon name="close" />
                    </a>
                    <a onClick={() => { if (isSaveButtonEnabled) onHide(data) }} className={isSaveButtonEnabled ? "" : "disabled"}>
                        <Icon name="check" />
                    </a>
                </>
            }
            <div className='task-detail-drawer-body'>
                <Paper>
                    <DynamicForm
                        fields={fields}
                        title={"Form. tarea"}
                        onChange={() => { }}
                    //recordId={taskId}
                    //tableName={"tasks"}
                    />
                </Paper>

            </div>
        </div>
    )
}

export default TaskDetailDrawer
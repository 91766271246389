import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import './ExpandableSection.scss';

interface ExpandableSectionProps {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
    collapsedIconDirection?: 'down' | 'right'; // Nueva prop
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ title, subtitle, children, collapsedIconDirection = 'down' }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    // Determina la rotación inicial del ícono basado en la prop `collapsedIconDirection`
    const getIconRotation = () => {
        if (expanded) return 'rotate(180deg)';
        return collapsedIconDirection === 'right' ? 'rotate(-90deg)' : 'rotate(0deg)';
    };

    return (
        <div className={'expandable-section-container'}>
            <div
                className='expandable-section-header'
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    borderBottom: expanded ? '1px solid #ccc' : 'none',
                }}
                onClick={handleToggle}
            >
                <div>
                    <h6>{title}</h6>
                    {subtitle && <span>{subtitle}</span>}
                </div>
                <KeyboardArrowDownIcon
                    sx={{ transform: getIconRotation(), transition: 'transform 0.3s' }}
                />
            </div>
            <Collapse in={expanded}>
                <div style={{ padding: '5px' }}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
};

export default ExpandableSection;

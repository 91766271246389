
import React from "react";
import { ContentViewer } from "../ContentViewer/ContentViewer";
const ReadonlyTextEditorField = (props: any) => {
  const { data = [] } = props;
  return (
    <div className="readonly-text-editor-field-container">
      <ContentViewer data={data} />
    </div>
  );
};

export { ReadonlyTextEditorField };

import React, { useState, useEffect } from "react";
import "./MiniGroupedColumnChart.scss";

const MiniGroupedColumnChart = (props: any) => {
  const { data, height = 60, onSubColumnSelect } = props;
  const [items, setItems] = useState([] as any);
  const [selectedSubColumn, setSelectedSubColumn] = useState<any>({ itemIndex: null, subItemIndex: null });
  const maxSubColumns = 20; // Variable para el máximo de subcolumnas permitidas

  const limitSubColumns = (items: any, maxSubColumns: number): any => {
    let totalSubColumns = 0;
    let limitedItems = [];

    for (let i = items.length - 1; i >= 0; i--) {
      const itemSubColumns = items[i].data.length;
      if (totalSubColumns + itemSubColumns > maxSubColumns) {
        break;
      }
      totalSubColumns += itemSubColumns;
      limitedItems.unshift(items[i]);
    }

    // Si el último periodo tiene más subcolumnas que el máximo permitido, limitamos las subcolumnas
    if (limitedItems.length > 0 && limitedItems[0].data.length > maxSubColumns) {
      limitedItems[0].data = limitedItems[0].data.slice(-maxSubColumns);
    }

    return limitedItems;
  };

  const addHeightToItems = (items: any, height: number): any => {
    const maxValue = Math.max(
      ...items.flatMap((item: any) => item.data.map((data: any) => data.value))
    );
    const maxHeight = height;

    return items.map((item: any) => ({
      ...item,
      data: item.data.map((data: any) => ({
        ...data,
        height: Math.round((data.value / maxValue) * maxHeight),
      })),
    }));
  };

  const handleClick = (itemIndex: number, subItemIndex: number, item: any, subItem: any) => {
    const period = item?.display_period;
    const value = subItem?.value;
    const label = subItem?.label;
    const color = subItem?.color;
    if (onSubColumnSelect) {
      onSubColumnSelect({ period, value, label, color });
    }
    setSelectedSubColumn({ itemIndex, subItemIndex });
    setTimeout(() => {
      setSelectedSubColumn({ itemIndex, subItemIndex });
    }, 0);
  };

  useEffect(() => {
    let limitedData = limitSubColumns(data, maxSubColumns);
    let newData = addHeightToItems(limitedData, height);
    setItems(newData);

    // Seleccionar la última subcolumna del gráfico por defecto
    if (newData.length > 0) {
      const lastItemIndex = newData.length - 1;
      const lastSubItemIndex = newData[lastItemIndex].data.length - 1;
      setSelectedSubColumn({ itemIndex: lastItemIndex, subItemIndex: lastSubItemIndex });
    }
  }, [data, height]);

  return (
    <>
      <div className="mini-grouped-column-chart-container">
        <div className="main-content" style={{ height: height + "px" }}>
          {items?.map((item: any, itemIndex: number) => (
            <div
              key={itemIndex}
              className="columns-wrapper"
              style={{ height: height + "px" }}
            >
              {item.data.map((subItem: any, subItemIndex: number) => (
                <a
                  key={subItemIndex}
                  style={{
                    height: subItem.height + "px",
                    background: subItem.color || "#ccc",
                    border: selectedSubColumn.itemIndex === itemIndex && selectedSubColumn.subItemIndex === subItemIndex ? `2px solid ${subItem.color}` : "none"
                  }}
                  onClick={() => handleClick(itemIndex, subItemIndex, item, subItem)}
                ></a>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { MiniGroupedColumnChart };

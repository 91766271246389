import React from "react";
import { IconButton } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { TextEditorFieldItem } from "../TextEditorFieldItem";
import { SortableContainer } from "react-sortable-hoc";
import Icon from "components/Icon/Icon";

const SortableList = SortableContainer<any>(({ items }: any) => {
  return (
    <div className="text-editor-field-item-wrapper">
      {items?.map((item: any, index: number) => (
        <TextEditorFieldItem
          key={`item-${index}`}
          item={item}
          i={index}
          index={index}
        />
      ))}
    </div>
  );
});
const EditableTextEditorField = (props: any) => {
  const { data = [], onAddItem, onSortEnd } = props;
  return (
    <div className="editable-text-editor-field-container">
      <div className="text-editor-field-items">
        <SortableList items={data} onSortEnd={onSortEnd} useDragHandle />
      </div>
      <div className="text-editor-field-btns">
        <IconButton aria-label="add h1" onClick={() => onAddItem("h1")}>
          <Icon name="t1" />
        </IconButton>
        <IconButton aria-label="add h2" onClick={() => onAddItem("h2")}>
          <Icon name="t2" />
        </IconButton>
        <IconButton aria-label="add h3" onClick={() => onAddItem("h3")}>
          <Icon name="t3" />
        </IconButton>
        <IconButton aria-label="add text" onClick={() => onAddItem("text")}>
          <Icon name="txt" />
        </IconButton>
        <IconButton
          aria-label="add paragraph"
          onClick={() => onAddItem("paragraph")}
        >
          <Icon name="paragraph" />
        </IconButton>
        <IconButton aria-label="add table" onClick={() => onAddItem("table")}>
          <Icon name="grid" />
        </IconButton>
        <IconButton aria-label="add list" onClick={() => onAddItem("list")}>
          <Icon name="list" />
        </IconButton>
        <IconButton aria-label="add image" onClick={() => onAddItem("image")}>
          <ImageIcon />
        </IconButton>
        <IconButton aria-label="add code" onClick={() => onAddItem("code")}>
          <Icon name="code" />
        </IconButton>
        {/* <IconButton aria-label="delete" onClick={() => onAddItem("file")}>
          <InsertDriveFileIcon />
        </IconButton> */}
      </div>
    </div>
  );
};

export { EditableTextEditorField };

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from "axios";
import urls from 'config/urls';

interface IconData {
    name: string;
    displayName: string;
    paths: { d: string; color?: string }[];
    hasWrapper: boolean;
    hasInverted: boolean;
}

interface IconContextType {
    icons: IconData[];
    loading: boolean;
}

const IconContext = createContext<IconContextType | undefined>(undefined);

export const useIcons = () => {
    const context = useContext(IconContext);
    if (!context) {
        throw new Error('useIcons must be used within an IconProvider');
    }
    return context;
};

export const IconProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [icons, setIcons] = useState<IconData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const res = await axios.get(`${urls.server}/api/public/icons`);
                const data: IconData[] = res.data.map((icon: any) => ({
                    ...icon,
                    hasWrapper: icon.has_wrapper === 1,
                    hasInverted: icon.has_inverted === 1,
                    displayName: icon.display_name,
                }));
                setIcons(data);
                localStorage.setItem('icons', JSON.stringify(data));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching icons', error);
                setLoading(false);
            }
        };

        fetchIcons();
    }, []);

    return (
        <IconContext.Provider value={{ icons, loading }}>
            {children}
        </IconContext.Provider>
    );
};

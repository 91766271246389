import Icon from "components/Icon/Icon";
import "./TagsCell.scss";
const TagsCell = ({ data }: any) => {
  return (
    <div className="tags-cell-container">
      {data.map((item: any, index: number) => {
        return (
          <span
            key={index}
            style={{
              color: item.color,
              borderColor: item.color,
              borderRadius: 99,
              borderWidth: 2,
            }}
          >
            <Icon name="tag" />
            <span>{item.name}</span>
          </span>
        );
      })}
    </div>
  );
};

export { TagsCell };

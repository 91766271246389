import React, { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "./MarkdownField.scss";
import MarkdownPreviewModal from "components/modals/MarkdownPreviewModal";
import { Portal } from "@mui/material";

const MarkdownField = (props: any) => {
    const {
        defaultValue,
        editable,
        label,
        editPath,
        onChange,
        name,
        mode
    }: any = props;
    const [value, setValue] = useState(defaultValue as any);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (onChange) onChange(value);
    }, [value]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value); // Actualiza el valor de `value` con el contenido del textarea
    };

    return (
        <div className="markdown-field-container">
            <div className="field-header">
                {label && (
                    <label>
                        <span>{label}</span>
                    </label>
                )}
                {editable && (
                    <div>
                        {(mode === 'edit' && editable) && (
                            <FieldEditControls
                                value={value}
                                fieldName={name}
                                editPath={editPath}
                                onEditStart={() => setIsEditEnabled(true)}
                                onEditCancel={(originalValue) => {
                                    setIsEditEnabled(false);
                                    setValue(originalValue);
                                }}
                                onEditEnd={(success: boolean, originalValue) => {
                                    setIsEditEnabled(false);
                                    if (!success) {
                                        setValue(originalValue);
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="field-body">
                <div>
                    {mode === 'readonly' ||
                        (mode === 'edit' && editable && !isEditEnabled) ||
                        (mode === 'edit' && !editable) ? (
                        <div className="readonly-content">
                            {/* Aquí puedes mostrar el contenido renderizado en Markdown si lo deseas */}
                            {/* <ReactMarkdown>{value}</ReactMarkdown> */}
                            <button onClick={(event) => {
                                event.preventDefault();
                                setIsOpen(true)
                            }}>Open Markdown Preview</button>
                        </div>
                    ) : (
                        <div className="editable-content">
                            <textarea
                                value={value}
                                onChange={handleTextareaChange}
                                placeholder="Escribe tu contenido en Markdown aquí..."
                                style={{ width: "100%", height: "200px", marginBottom: "10px" }}
                            />
                            <button onClick={(event) => {
                                event.preventDefault();
                                setIsOpen(true)
                            }}>Open Markdown Preview</button>
                        </div>
                    )}
                </div>
            </div>
            <Portal>
                <MarkdownPreviewModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    markdownContent={value} // Pasa el valor actual de `value` al modal
                    backdropStyles={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                    windowStyles={{ padding: "20px", maxWidth: "600px" }}
                    zIndex={9999}
                />
            </Portal>
        </div>
    );
};

export { MarkdownField };

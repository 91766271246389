import React from "react";
import "./ReadonlyBillingItem.scss";
import { formatNumber } from "../../../../utils/formatNumber";

const ReadonlyBillingItem = ({
  data,
  transactionType,
}: //documentType,
  any) => {
  return (
    <tr className="readonly-billing-item-container">
      <td data-column-header={"Cantidad"}>
        <span>{data?.quantity}</span>
      </td>
      <td data-column-header={"Detalle"}>
        <span>{data?.name}</span>
      </td>
      <td data-column-header={"Precio unitario"}>
        <span>
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.unit_price)}
          </div>
        </span>
      </td>
      <td data-column-header={"Desc. (%)"}>
        <span>
          <div className="amount-wrapper">
            {formatNumber(data?.discount_percentage)}
            <span>%</span>
          </div>
        </span>
      </td>
      <td data-column-header={"Desc. ($)"}>
        <span>
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.discount_amount)}
          </div>
        </span>
      </td>
      <td data-column-header={"Subtotal"}>
        <span>
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.subtotal)}
          </div>
        </span>
      </td>
      <td data-column-header={"Impuesto"}>
        <span>
          {
            // data[ // transactionType == "sale" // ? "sale" + "_value_added_tax"
            // : "purchase" + "_value_added_tax" // ]?.name
          }
          <div className="amount-wrapper">
            <span>$</span> {formatNumber(data?.tax_amount)}
          </div>
        </span>
      </td>
      <td data-column-header={"Subtotal con imp."}>
        <span>
          <div
            className="amount-wrapper"
            style={{ fontWeight: 700, fontSize: "1.1em" }}
          >
            <span>$</span> {formatNumber(data?.subtotal_with_tax)}
          </div>
        </span>
      </td>
    </tr>
  );
};

export { ReadonlyBillingItem };

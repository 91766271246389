import React, { useEffect } from "react";
import { SvgIcon } from "@mui/material";
import colors from "config/colors";

const DynamicIcon = (props: any) => {
    const { size, paths, style } = props;

    useEffect(() => {
    }, []);

    const getPrimaryColor = () => {
        return localStorage.getItem("primaryColor") || colors.primary;
    };

    return (
        <SvgIcon style={{ ...style, fontSize: size }} viewBox="0 0 100 100">
            {paths && paths?.map((path: any, index: number) => (
                <path
                    key={index}
                    fill={path.color === "primary" ? getPrimaryColor() : (colors[path.color] || path.color)}
                    d={path.d}
                />
            ))}
        </SvgIcon>
    );
};

export { DynamicIcon };

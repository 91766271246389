import React, { useEffect, useState, useRef } from 'react'
import Modal from 'components/Modal/Modal';
import './RingBinderDetailModal.scss';
import { Dividers } from 'components/Dividers/Dividers';
import { Fab } from "@mui/material";
import Icon from 'components/Icon/Icon';
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import interpolateString from 'utils/interpolateString';
import FileList from 'components/FileList/FileList';

interface ModalProps {
  title: string;
  isOpen: boolean;
  binderId: number;
  //canDeleteFiles?: boolean;
  canUploadFiles?: boolean;
  canLinkFilesToUsers?: boolean;
  mode?: 'all' | 'my';
  onClose: (refreshOnClose?: boolean) => void;
}

const RingBinderDetailModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  binderId,
  //canDeleteFiles = false,
  canUploadFiles = false,
  canLinkFilesToUsers = false,
  mode,
  onClose
}) => {

  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilters, setActiveFilters] = useState([] as any);
  const [activeTabFilters, setActiveTabFilters] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [processing, setProcessing] = useState(false);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  const filters: any[] = [];
  const dividerGroups: any[] = [];

  const handleChange = async (e: any) => {
    const file: File = e.target.files[0];
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const base64Content = reader.result.split(',')[1]; // Obtén solo la parte base64
      const extension = file.name.split('.').pop();
      const fileSize = file.size;
      const fileName = file.name.substring(0, file.name.lastIndexOf('.'));

      const data = {
        content: base64Content,
        extension,
        size: fileSize,
        name: fileName,
        binderId: binderId,
      };
      await saveFile(data);

      // Reinicia el valor del input file
      if (uploadInputRef.current) {
        uploadInputRef.current.value = "";
      }
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };
  const saveFile = async ({ content, extension, binderId, size, name }: any) => {
    const savePath = mode === 'my' ? 'my/ring-binder-files' : 'ring-binder-files'
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/${savePath}`, {
        file_content: content,
        extension,
        name,
        binder_id: binderId,
      });
      const resData = await res?.data;

      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };

  if (!isOpen) return <></>;
  return (
    <>
      <Modal
        title={title}
        className={'ring-binder-detail-modal-container'}
        isOpen={isOpen}
        onClose={() => { onClose(refreshOnClose) }}
        hasSearchBar={true}
        filters={filters}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
        onActiveFiltersChange={(res) => {
          setActiveFilters(res);
        }}
      >
        <div>
          {isOpen && dividerGroups && dividerGroups.length > 0 && (
            <Dividers
              groups={dividerGroups}
              onChange={(res: any) => {
                setActiveTabFilters(res)
              }}
            />
          )}
          {isOpen && (
            <>
              <div className='list-wrapper'>
                <FileList
                  listPath={mode === 'my' ? 'my/ring-binder-files' : 'ring-binder-files'}
                  searchTerm={searchTerm}
                  //canDeleteFiles={canDeleteFiles}
                  canLinkFilesToUsers={canLinkFilesToUsers}
                  hiddenActiveFilters={[['ring_binder_id', binderId]]}
                  activeFilters={[]}
                  mode={mode}
                />

              </div>
              <input
                ref={uploadInputRef}
                type="file"
                //accept={}
                className="upload-input"
                onChange={handleChange}
              />
              {
                canUploadFiles ? <Fab
                  color="primary"
                  aria-label="upload"
                  sx={{
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                  }}
                  disabled={processing}
                  onClick={() => {
                    uploadInputRef.current && uploadInputRef.current.click()
                  }}
                >
                  <Icon name="upload" style={{ marginRight: 0 }} />
                </Fab> : ""
              }

            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export { RingBinderDetailModal }
import React from 'react';
import { Parser } from 'expr-eval';
interface InterpolatedContentProps {
    template: string; // El template HTML con marcadores para interpolación
    data: { [key: string]: any }; // El objeto de datos para la interpolación
}

const InterpolatedContent: React.FC<InterpolatedContentProps> = ({ template, data }) => {

    // Función para realizar la interpolación de datos en el template
    const renderTemplate = (template: string, data: { [key: string]: any }): string => {
        if (data) {
            const parser = new Parser();

            // Encuentra todas las coincidencias de {{variable}} en el template
            const matches = template?.match(/{{\s*[\w\.]+\s*}}/g) || [];

            // Reemplaza cada coincidencia por su valor correspondiente del objeto de datos
            matches.forEach((match) => {
                const path = match.slice(2, -2).trim(); // quita los {{ y }}
                try {
                    // Evalúa el path en el contexto del objeto de datos
                    const value: any = parser.evaluate(path, data) || '';
                    // Reemplaza la coincidencia en el template por el valor
                    template = template.replace(new RegExp(match, 'g'), value);
                } catch (error) {
                    console.error(`Error parsing ${path}:`, error);
                    // Reemplaza con un string vacío si hay un error al evaluar la expresión
                    template = template.replace(new RegExp(match, 'g'), '');
                }
            });
        }


        return template;
    };

    // Renderizar el template con datos interpolados como HTML
    const renderedTemplate = renderTemplate(template, data);

    return (
        <span
            dangerouslySetInnerHTML={{ __html: renderedTemplate }}
        />
    );
};

export default InterpolatedContent;

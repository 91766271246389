import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { DataTableExpandedCard } from "cards/dataTable/components/DataTableExpandedCard/DataTableExpandedCard";
import { SettingsExpandedCard } from "cards/settings/components/SettingsExpandedCard/SettingsExpandedCard";
import { TasksExpandedCard } from "cards/tasks/components/TasksExpandedCard/TasksExpandedCard";
import { IndicatorExpandedCard } from "cards/indicator/components/IndicatorExpandedCard/IndicatorExpandedCard";
import { MyTasksExpandedCard } from "cards/tasks/components/MyTasksExpandedCard/MyTasksExpandedCard";
import { RankingExpandedCard } from "cards/ranking/components/RankingExpandedCard/RankingExpandedCard";
import { WorkflowsExpandedCard } from "cards/workflows/components/WorkflowsExpandedCard/WorkflowsExpandedCard";
import { WorkflowEntriesExpandedCard } from "cards/workflowEntries/components/WorkflowEntriesExpandedCard/WorkflowEntriesExpandedCard";
import { IndicatorGroupExpandedCard } from "cards/indicatorGroup/components/IndicatorGroupExpandedCard/IndicatorGroupExpandedCard";
import { RingBindersExpandedCard } from 'cards/ringBinders/components/RingBindersExpandedCard/RingBindersExpandedCard';
import { DataListExpandedCard } from 'cards/dataList/components/DataListExpandedCard/DataListExpandedCard';
import { MyRingBindersExpandedCard } from 'cards/ringBinders/components/MyRingBindersExpandedCard/MyRingBindersExpandedCard';
import Icon from 'components/Icon/Icon';
import { MultiActionExpandedCard } from 'cards/multiAction/components/MultiActionExpandedCard/MultiActionExpandedCard';
import "./ExpandedCardModal.scss";
import { WebsiteBuilderExpandedCard } from 'cards/websiteBuilder/components/WebsiteBuilderExpandedCard/WebsiteBuilderExpandedCard';
interface ModalProps {
  isOpen: boolean;
  type: string;
  title: string;
  data: any;
  config: string;
  id: number;
  onClose: () => void;
  onChange?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandedCardModal: React.FC<ModalProps> = ({ isOpen, type, title, data, config, id, onClose, onChange }) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => {
        onClose();
        if (refreshOnClose && onChange) onChange()
      }}
      TransitionComponent={Transition}
      className={`expanded-card-modal-container`}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: "20px",
          paddingRight: "35px",
        },
      }}
    >
      <div className='modal-window'>

        <a onClick={() => {
          onClose();
          if (refreshOnClose && onChange) onChange()
        }}>
          <Icon name="exitFullscreen" />
        </a>

        <div>
          <div>
            {(() => {
              switch (type) {

                case "myTasks":
                  return (
                    <MyTasksExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "tasks":
                  return (
                    <TasksExpandedCard
                      title={title}
                      data={data}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "multiAction":
                  return (
                    <MultiActionExpandedCard title={title} data={data} config={config} setRefreshOnClose={setRefreshOnClose} />
                  );

                case "dataTable":
                  return (
                    <DataTableExpandedCard
                      title={title}
                      config={config}
                      cardId={id}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "settings":
                  return (
                    <SettingsExpandedCard
                      title={title}
                      config={config}
                      cardId={id}
                    />
                  );
                case "indicator":
                  return (
                    <IndicatorExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      id={id}
                    />
                  );
                case "indicatorGroup":
                  return (
                    <IndicatorGroupExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      id={id}
                    />
                  );
                case "ranking":
                  return <RankingExpandedCard title={title} data={data} />;

                case "ringBinders":
                  return (
                    <RingBindersExpandedCard title={title} data={data} config={config} />
                  );
                case "myRingBinders":
                  return (
                    <MyRingBindersExpandedCard title={title} data={data} config={config} />
                  );

                case "dataList":
                  return (
                    <DataListExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );
                case "websiteBuilder":
                  return (
                    <WebsiteBuilderExpandedCard title={title} data={data} config={config} />
                  );
                case "workflows":
                  return (
                    <WorkflowsExpandedCard
                      title={title}
                      data={data}
                      config={config}
                      setRefreshOnClose={setRefreshOnClose}
                    />
                  );

                case "workflowEntries":
                  return (
                    <WorkflowEntriesExpandedCard
                      title={title}
                      data={data}
                      config={config}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>

    </Dialog>
  );
};

export default ExpandedCardModal;
import React, { useState, useEffect } from "react";
import "./EditablePaymentField.scss";
import { getPaymentMethods } from "../utils/get-payment-methods";
import { getFields } from "../utils/get-fields";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import { DateField } from "components/fields/DateField/DateField";
import { SelectModal } from "components/modals/SelectModal/SelectModal";
import { formatNumber } from "../../../../utils/formatNumber";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import AxiosInterceptor from "AxiosInterceptor";
import { UnpaidDocumentsPicker } from "../UnpaidDocumentsPicker/UnpaidDocumentsPicker";
import urls from "config/urls";
import Icon from "components/Icon/Icon";
const EditablePaymentField = ({ transactionType, onChange }: any) => {
  const paymentMethodsInitialData = getPaymentMethods();
  const withholdingFields = getFields().withholding;
  const customerFields = getFields().customer;
  const supplierFields = getFields().supplier;
  const [paymentMethods, setPaymentMethods] = useState(
    paymentMethodsInitialData
  );
  let initialValues: any = {
    // withholdings: [],
    // total: 0,
    // balance: 0,
    // unpaidDocuments: [],
  };
  const [data, setData] = useState((initialValues as any) || ({} as any));
  const [selectPartyModalIsOpen, setSelectPartyModalIsOpen] = useState(false);
  const [selectWithholdingModalIsOpen, setSelectWithholdingModalIsOpen] =
    useState(false);
  const [partyType, setPartyType] = useState("customer"); // party ademas de fiesta se usa también como "parte" -> customer or supplier
  const [visiblePartyType, setVisiblePartyType] = useState("cliente"); // cliente or proveedor
  const [withholdings, setWithholdings] = useState([]);
  const [unpaidDocuments, setUnpaidDocuments] = useState([]);
  const [allocatedDocuments, setAllocatedDocuments] = useState([]);
  const [totalToPay, setTotalToPay] = useState(0);
  //const [totalSelected, setTotalSelected] = useState(100);
  const getDebt = async (customerId: number) => {
    try {
      let url = `${urls.server}/api/customers/${customerId}/debt`;
      const res = await AxiosInterceptor.get(url);
      const resData = await res?.data;
      //setTotalToPay(resData.total_due);
      setData((prevData: any) => ({
        ...prevData,
        totalDue: resData.total_due,
        //unpaidDocuments: resData.unpaid_documents,
      }));
      setUnpaidDocuments(resData.unpaid_documents);

      console.log(resData);
    } catch (error: any) { }
  };
  useEffect(() => {
    setPartyType(transactionType === "purchase" ? "supplier" : "customer");
    setVisiblePartyType(
      transactionType === "purchase" ? "proveedor" : "cliente"
    );
  }, [transactionType]);
  const calculateTotalToPay = () => {
    if (Array.isArray(paymentMethods) && paymentMethods.length > 0) {
      // Calcular totalPaid sumando los amount de data.paymentMethods
      const totalToPay = paymentMethods.reduce(
        (total: number, current: any) => {
          // Asegurarse de que current.amount es un número
          const amount = parseFloat(current.amount) || 0;
          return total + amount;
        },
        0
      );

      // Actualizar data con el nuevo valor de totalPaid
      // setData((prevData: any) => ({
      //   ...prevData,
      //   totalToPay: totalToPay,
      // }));
      setTotalToPay(totalToPay);
    }
  };
  useEffect(() => {
    const newData = {
      ...data,
      unpaidDocuments: unpaidDocuments,
      withholdings: withholdings,
      totalToPay: totalToPay,
      paymentMethods: paymentMethods,
      allocatedDocuments: allocatedDocuments,
      // Cualquier otra lógica que necesites
    };

    onChange(newData);
    // onChange({
    //   unpaidDocuments: unpaidDocuments,
    //   withholdings: withholdings,
    //   totalToPay: totalToPay,
    //   paymentMethods: paymentMethods,
    // });
  }, [
    allocatedDocuments,
    unpaidDocuments,
    withholdings,
    totalToPay,
    paymentMethods,
    data,
  ]);
  return (
    <div className="editable-payment-field">
      <div className="grid-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DateField
              label="Fecha"
              defaultValue="today"
              onChange={(data: any) => {
                console.log(data);
                setData((prev: any) => {
                  const dataCopy: any = { ...prev };
                  dataCopy.date = data;
                  return dataCopy;
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {!data[`${partyType}_id`] ? (
              <div className="select-party-btn-wrapper">
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectPartyModalIsOpen(true);
                  }}
                >
                  Seleccionar{" "}
                  {partyType == "customer" ? "cliente" : "proveedor"}
                </Button>
              </div>
            ) : (
              <div className="selected-party">
                <div>
                  <strong>{data[partyType]?.name}</strong>
                  <span>CUIT: {data[partyType]?.tin || "-"} </span>
                  <span>Email: {data[partyType]?.email || "-"} </span>
                </div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setData((prev: any) => {
                      const dataCopy: any = { ...prev };
                      dataCopy[partyType] = null;
                      dataCopy[`${partyType}_id`] = null;
                      return dataCopy;
                    });
                  }}
                >
                  <Icon name="delete" />
                </IconButton>
              </div>
            )}
          </Grid>
          {data[partyType] && (
            <>
              <Grid item xs={12}>
                <div className="unpaid-documents-picker">
                  <UnpaidDocumentsPicker
                    data={unpaidDocuments}
                    totalToPay={totalToPay}
                    onChange={(data: any) => {
                      setAllocatedDocuments(data);
                    }}
                  />
                </div>
                <div className="total-due-wrapper">
                  <span>Total adeudado</span>
                  <strong>$ {formatNumber(data.totalDue || 0)}</strong>
                </div>
                <div className="payment-methods-wrapper">
                  {paymentMethods?.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <span>{item.displayName}</span>
                        <div>
                          <IconButton
                            aria-label="pay"
                            onClick={() => {
                              const paymentMethodsCopy: any = [
                                ...paymentMethods,
                              ];
                              paymentMethodsCopy.forEach((item: any) => {
                                item.amount = 0;
                              });
                              const amount =
                                Math.round(data.totalDue * 100) / 100;
                              paymentMethodsCopy[index].amount = amount;
                              setPaymentMethods(paymentMethodsCopy);
                              calculateTotalToPay();
                            }}
                          >
                            <ArrowCircleRightIcon />
                          </IconButton>
                          <div className="input-group">
                            <span>$</span>
                            <input
                              className="ta-r"
                              value={item.amount}
                              onChange={(e) => {
                                const paymentMethodsCopy: any = [
                                  ...paymentMethods,
                                ];
                                const value: any = e.target.value;

                                paymentMethodsCopy[index].amount = value;
                                setPaymentMethods(paymentMethodsCopy);
                                calculateTotalToPay();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="total-paid-wrapper">
                  <span>
                    Total {transactionType == "sale" ? "cobrado" : "pagado"}
                  </span>{" "}
                  <strong>$ {formatNumber(totalToPay || 0)}</strong>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <SelectModal
        isOpen={selectPartyModalIsOpen}
        title={"Seleccionar " + visiblePartyType}
        listPath={partyType == "customer" ? "customers" : "suppliers"}
        formTitle={"Ficha de " + visiblePartyType}
        formFields={partyType == "customer" ? customerFields : supplierFields}
        primaryKey="name"
        secondaryKey="tin"
        filters={[]}
        onClose={(data: any) => {
          setSelectPartyModalIsOpen(false)
          console.log("data", data);
          if (data) {
            getDebt(data.id);
            setData((prev: any) => {
              const dataCopy = { ...prev };
              dataCopy[partyType] = data;
              dataCopy[`${partyType}_id`] = data.id;
              return dataCopy;
            });
          }
        }}
      />

      <SelectModal
        isOpen={selectWithholdingModalIsOpen}
        title="Seleccionar withholding"
        listPath="withholdings"
        formTitle="Ficha de retención"
        formFields={withholdingFields}
        primaryKey="name"
        filters={[]}
        //secondaryKey="tin"
        onClose={(data: any) => {
          console.log("data", data);
          setSelectWithholdingModalIsOpen(false);
          if (data) {
            const withholdingsCopy: any = JSON.parse(
              JSON.stringify(withholdings)
            );
            const searchIndex = withholdingsCopy.findIndex(
              (item: any) => item.id == data.id
            );
            if (searchIndex == -1) {
              //data.taxable_base = 0;
              data.amount = 0;
              withholdingsCopy.push(data);
            }

            setWithholdings(withholdingsCopy);
          }
        }}
      />
    </div>
  );
};

export { EditablePaymentField };

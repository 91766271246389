import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";

import "./ChildRecordsModal.scss";
import Modal from "components/Modal/Modal";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
import DynamicList from "components/DynamicList/DynamicList";
import interpolateString from "utils/interpolateString";
import Icon from "components/Icon/Icon";

interface ModalProps {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  listPath: string;
  deletePath?: string;
  deleteConfirmationMessage?: string;
  sortPath: string;
  //itemCells: any[];
  itemLeftCells: any[];
  itemRightCells: any[];
  listActions: any[];
  headerActions: any[];
  itemActions: any[];
  noContentText: string;
  noContentIcon: string;
  isSortable: boolean;
  createFormTitle: string;
  editFormTitle: string;
  formFields: any[];
  createPath: string;
  editPath?: string;
  extraData?: any;
  onClose: (refreshOnClose?: boolean) => void;
}

const ChildRecordsModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  listPath,
  deletePath,
  deleteConfirmationMessage,
  sortPath,
  listActions,
  headerActions,
  itemActions: propItemActions,
  //itemCells,
  itemLeftCells,
  itemRightCells,
  noContentText,
  noContentIcon,
  isSortable,
  createFormTitle,
  editFormTitle,
  formFields,
  createPath,
  editPath,
  extraData,
  onClose,
}) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editFormDialogIsOpen, setEditFormDialogIsOpen] = useState(false);
  const [createFormDialogIsOpen, setCreateFormDialogIsOpen] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null as any);
  const [interpolatedEditPath, setInterpolatedEditPath] = useState(null as any);
  const itemActions = [
    ...(editPath
      ? [{
        name: "edit",
        icon: "pencil",
      }]
      : []),
    ...(deletePath
      ? [{
        name: "delete",
        icon: "delete",
        deletePath: deletePath,
        deleteConfirmationMessage: deleteConfirmationMessage
      }]
      : []),
    ...propItemActions
  ];

  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };

  useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);
    } else {
      setShouldRefresh(false);
    }
  }, [isOpen])
  return (
    <>
      <Modal
        title={title}
        subtitle={subtitle}
        className={'child-records-modal-container'}
        isOpen={isOpen}
        onClose={() => { onClose(refreshOnClose) }}
        hasSearchBar={true}
        onSearchChange={(searchTerm: string) => {
          setSearchTerm(searchTerm);
        }}
        headerActions={headerActions}
      >
        {isOpen && (
          <>
            <DynamicList
              searchTerm={searchTerm}
              listPath={listPath}
              sortPath={sortPath}
              forceRefresh={shouldRefresh}
              onRefreshComplete={handleRefreshComplete}
              onSelectItemAction={(res: any) => {
                if (res?.actionName === "edit" && editPath) {
                  setSelectedItem(res?.currentItem);
                  setInterpolatedEditPath(interpolateString(res?.currentItem, editPath))
                  setEditFormDialogIsOpen(true);

                }
              }}

              leftCells={itemLeftCells}
              rightCells={itemRightCells}
              itemActions={itemActions}
              listActions={listActions}
              noContentText={noContentText}
              noContentIcon={noContentIcon}
              isSortable={isSortable}
              extraData={extraData}
              onEditItem={() => {
                setRefreshOnClose(true);

              }}
            />
            <FormDialog
              isOpen={createFormDialogIsOpen}
              onClose={(data: any) => {
                setCreateFormDialogIsOpen(false);
                if (data) {
                  console.log(data);
                  setShouldRefresh(true);
                }
              }}
              title={createFormTitle}
              size="sm"
              fields={formFields}
              createPath={createPath}
              mode="create"
            />
            <FormDialog
              isOpen={editFormDialogIsOpen}
              onClose={(data: any) => {
                setEditFormDialogIsOpen(false);
                if (data) {
                  console.log(data);
                  setShouldRefresh(true);
                }
              }}
              title={editFormTitle}
              size="sm"
              fields={formFields}
              editPath={interpolatedEditPath}
              defaultData={selectedItem}
              mode="globalEdit"
            />
            <Fab
              className="fab"
              color="primary"
              aria-label="add"
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
              }}
              onClick={() => {
                setCreateFormDialogIsOpen(true);
              }}
            >
              <Icon name="add" />
            </Fab>
          </>
        )}
      </Modal>
    </>
  );
};

export { ChildRecordsModal };

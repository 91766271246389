import { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import "./SearchPlace.scss";

import markerIcon from "./marker.svg";
import InfoIcon from "@mui/icons-material/Info";
import keys from "config/keys";
import styles from "config/styles";

export default function SearchPlace({ onChange, coordinates }: any) {
  const [libraries] = useState(['places'] as any);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: keys.googleMaps || "",
    //[libraries]: libraries,
    //libraries: ["places"],
    libraries: libraries
  });
  useEffect(() => {
    console.log(coordinates);
  }, []);
  if (!isLoaded) return <div>Loading...</div>;
  return <Map onChange={onChange} coordinates={coordinates} />;
}
const mapStyles = styles.googleMap;
function Map({ onChange, coordinates }: any) {
  //const defaultCoords = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  //const defaultCoords = { lat: 43.45, lng: -80.49 };
  const [center, setCenter] = useState(coordinates);
  const [selected, setSelected] = useState(coordinates);

  useEffect(() => {
    if (!coordinates && navigator.geolocation) {
      // De lo contrario, tratamos de obtener las coordenadas del navegador
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          setCenter({ lat, lng });
          setSelected({ lat, lng });
        },
        (error) => {
          console.error("Error obteniendo la geolocalización: ", error);
          // En caso de error, las coordenadas permanecen en el valor por defecto.
        }
      );
    } else {
      console.log("Geolocalización no soportada por este navegador.");
      // Si la geolocalización no es soportada, las coordenadas permanecen en el valor por defecto.
    }
  }, [coordinates]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);
  const onDragMarker = (coord: any) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelected({ lat: lat, lng: lng });
    console.log(lat, lng);
  };

  return (
    <div className="search-place-container">
      <div className="info-wrapper">
        <div>
          <InfoIcon />{" "}
          <span>
            Busca un domicilio o mueve el marcador hasta el lugar deseado
          </span>
        </div>
      </div>
      <div className="places-autocomplete-wrapper">
        <PlacesAutocomplete
          setSelected={setSelected}
          setCenter={setCenter}
        // placeholder={"Buscar domicilio"}
        />
      </div>

      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
        options={{
          styles: mapStyles,
        }}
      >
        {selected && (
          <Marker
            position={selected}
            draggable={true}
            onDragEnd={onDragMarker}
            //icon={iconPin}
            icon={{
              url: markerIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
}

const PlacesAutocomplete = ({ setSelected, setCenter }: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setCenter({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => {
          console.log(e.target.value, status, data);
          setValue(e.target.value);
        }}
        disabled={!ready}
        className="combobox-input"
        placeholder="Buscar domicilio"
      />
      {/* <InputField
        placeholder="Buscar domicilio"
      /> */}
      {/* <input type="text" onChange={(e) => {
        console.log(e.target.value, status, data);
        setValue(e.target.value);
      }} /> */}
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              // <Fragment key={place_id}>
              //   <ComboboxOption value={description} />
              // </Fragment>

              <ComboboxOption key={place_id} value={description} />

            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

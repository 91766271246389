import React, { useEffect, useState } from "react";
import "./SimpleIndicatorCardBody.scss";
import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import { MiniLineChart } from "components/miniCharts/MiniLineChart/MiniLineChart";
import { MiniColumnChart } from "components/miniCharts/MiniColumnChart/MiniColumnChart";
import colors from "config/colors";
const SimpleIndicatorCardBody = (props: any) => {
  const { data, config } = props;
  const [selectedItem, setSelectedItem] = useState({} as any);
  //const [newData, setNewData] = useState([] as any);

  const handleSelectItem = (data: any) => {
    //console.log(data);
    setSelectedItem(data);
  };
  useEffect(() => {
    const newData = data?.length > 10 ? data.slice(-10) : data;
    //setNewData(newData);
  }, []);
  useEffect(() => {
    if (data?.length > 0) {
      const newData = data?.length > 10 ? data.slice(-10) : data;
      //setNewData(newData);
      setSelectedItem(newData[newData?.length - 1]);
    }
  }, [data]);
  return (

    <div className="simple-indicator-card-body-container">


      <div className="current-data">

        <strong>{selectedItem?.display_period}</strong>
        <div>
          <AnimatedNumber
            value={selectedItem?.value}
            duration="500"
            color={config?.color}
            fontWeight={900}
            type={"decimal"}
            fontSize={selectedItem?.value > 9999 ? "16px" : "22px"}
            decimalPrecision={config?.decimalPrecision}
          />
          <span style={{
            color: colors.textTint
          }}>({config?.measurementUnit})</span>
        </div>


      </div>
      <div className="chart-wrapper">
        {config?.chartType == "lineChart" ? (
          <>
            <MiniLineChart
              data={data?.slice(-10)}
              height="75"
              color={config?.color}
              onPointSelect={handleSelectItem}
              decimalPrecision={config?.decimalPrecision}
            />
          </>
        ) : (
          <MiniColumnChart
            data={data?.slice(-10)}
            color={config?.color}
            onColumnSelect={handleSelectItem}
            decimalPrecision={config?.decimalPrecision}
          />
        )}
      </div>
    </div>
  );
};

export { SimpleIndicatorCardBody };

// components/Configuration/LottieConfiguration.tsx
import React from 'react';
import { CheckboxField } from 'components/fields/CheckboxField/CheckboxField';
import { LottieField } from 'components/fields/LottieField/LottieField';
import SizeField from '../../SizeField/SizeField';

interface LottieConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const LottieConfiguration: React.FC<LottieConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <SizeField
                label="Ancho"
                field="width"
                isEnabled={getFieldValues('width').value !== null}
                value={getFieldValues('width').value}
                unit={getFieldValues('width').unit}
                handleChange={handleChange}
                minValue={0}
            />
            <div className="action-wrapper">
                <LottieField
                    id="lottie"
                    defaultValue={selectedTab === 'default' ? localConfig?.animationData || null : localConfig?.responsive?.[selectedTab]?.animationData || localConfig?.animationData || null}
                    label={"Lottie"}
                    onChange={(animationData: any) => handleChange('animationData', animationData)}
                />
            </div>
            <div className="action-wrapper">
                <CheckboxField
                    defaultValue={selectedTab === 'default' ? localConfig?.loop || null : localConfig?.responsive?.[selectedTab]?.loop || localConfig?.loop || null}
                    label={"Repetir"}
                    onChange={(loop: any) => handleChange('loop', loop)}
                />
            </div>
        </>
    );
};

export default LottieConfiguration;

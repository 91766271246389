import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./PillTabs.scss";
const PillTabs = ({ options, onTabChange, initialIndex = 0 }: any) => {
  const [value, setValue] = React.useState(initialIndex);
  const handleTabChange = (event: any, newValue: any) => {
    // Aquí puedes realizar acciones adicionales si lo deseas
    // antes de llamar a la función proporcionada por el usuario.
    setValue(newValue);
  };
  useEffect(() => {
    if (onTabChange) {
      onTabChange(value);
    }
  }, [value]);
  return (
    <div className="pill-tabs-container">
      <Box
        className="pill-tabs"
        sx={{
          width: "100%",
          maxWidth: { xs: 300, sm: 480 },
          margin: "0 auto",
        }}
      >
        <Tabs
          component="a"
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {options.map((option: any, index: number) => (
            <Tab key={index} label={option} />
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default PillTabs;

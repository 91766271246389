import { useState, useEffect, Fragment } from "react";
import { Button, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "./DynamicListField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

import { DynamicListInput } from "./DynamicListInput";
import * as ev from "expr-eval";
import Icon from "components/Icon/Icon";

const DynamicListField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    cells,
    extraData,
    canAddItems = true,
    canRemoveItems = true,
    onEdit,
    mode
  }: any = props;
  const [data, setData] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const addItem = () => {
    const output: any = {};
    cells.forEach((cell: any) => {
      if (cell.defaultValue !== undefined) {
        output[cell.name] = cell.defaultValue;
      }
    });
    setData((prevData: any) => [...(prevData || []), output]);
  };

  const handleInputChange = (res: any, itemIndex: number, cellName: string) => {
    const value = res;
    setData((prevData: any) => {
      const newData = [...prevData];
      newData[itemIndex][cellName] = value;
      return newData;
    });
  };

  const handleDeleteItem = (itemIndex: number) => {
    setData((prevData: any) => {
      const newData = [...prevData];
      newData.splice(itemIndex, 1);
      return newData;
    });
  };

  const moveItemUp = (itemIndex: number) => {
    if (itemIndex === 0) return;
    setData((prevData: any) => {
      const newData = [...prevData];
      [newData[itemIndex - 1], newData[itemIndex]] = [newData[itemIndex], newData[itemIndex - 1]];
      return newData;
    });
  };

  const moveItemDown = (itemIndex: number) => {
    if (itemIndex === data.length - 1) return;
    setData((prevData: any) => {
      const newData = [...prevData];
      [newData[itemIndex + 1], newData[itemIndex]] = [newData[itemIndex], newData[itemIndex + 1]];
      return newData;
    });
  };

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  const shouldShowCell: any = (exp: any, context: any) => {
    if (!exp) return true;
    try {
      return exp ? ev.Parser.evaluate(exp, context) : false;
    } catch (error) {
      return false;
    }
  };

  const containerClasses = `field-container dynamic-list-field-container`;

  return (
    <div className={containerClasses}>
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode === 'edit' && editable) && (
              <FieldEditControls
                value={data}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setData(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setData(originalValue);
                  } else {
                    onEdit();
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode === 'readonly' ||
            (mode === 'edit' && editable && !isEditEnabled) ||
            (mode === 'edit' && !editable) ? (
            <div className="readonly-content">
              {data && data.length > 0 ? (
                <div>{JSON.stringify(data)}</div>
              ) : (
                <span>-</span>
              )}
            </div>
          ) : (
            <div className="editable-content">
              {canAddItems && (
                <div className="add-btn-wrapper">
                  <Button onClick={addItem} startIcon={<Icon name="add" />}>
                    Agregar
                  </Button>
                </div>
              )}

              <div className="list-wrapper">
                {data?.length > 0 && (
                  <>
                    {data.map((item: any, itemIndex: number) => (
                      <div key={itemIndex}>
                        <Grid container spacing={2}>
                          {cells.map((cell: any, cellIndex: number) => (
                            <Fragment key={cellIndex}>
                              {((cell.showIf && shouldShowCell(cell.showIf, item)) ||
                                !cell.showIf) && (
                                  <Grid item xs={12} sm={cell.size || 12}>
                                    <span key={cellIndex}>
                                      <DynamicListInput
                                        itemIndex={itemIndex}
                                        cell={cell}
                                        item={item}
                                        handleInputChange={handleInputChange}
                                        extraData={extraData}
                                      />
                                    </span>
                                  </Grid>
                                )}
                            </Fragment>
                          ))}
                        </Grid>
                        <div className="footer-btns">
                          {canRemoveItems && (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteItem(itemIndex)}
                              color="error"
                            >
                              <Icon name="delete" />
                            </IconButton>
                          )}
                          {itemIndex > 0 && (
                            <IconButton
                              aria-label="subir"
                              onClick={() => moveItemUp(itemIndex)}
                              color="primary"
                            >
                              <Icon name="upArrow" />
                            </IconButton>
                          )}
                          {itemIndex < data.length - 1 && (
                            <IconButton
                              aria-label="bajar"
                              onClick={() => moveItemDown(itemIndex)}
                              color="primary"
                            >
                              <Icon name="downArrow" />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DynamicListField };

import React, { useState, useEffect } from "react";
import "./LineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const LineChart = (props: any) => {
  const { data, color, decimalPrecision = 2 } = props;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  } as any);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      // legend: {
      //   position: "top" as const,
      // },
      title: {
        display: false,
        //text: "Chart.js Line Chart",
      },
    },
    pointRadius: 4,
    pointHoverRadius: 4,
    pointBorderWidth: 0,
  };
  function adaptSimpleIndicatorData(indicatorData: any) {
    const labels = indicatorData.map((item: any) => item.display_period);
    const data = indicatorData.map((item: any) => item.value.toFixed(decimalPrecision));

    return {
      labels: labels,
      datasets: [
        {
          //label: "Indicador Simple",
          data: data,
          fill: false,
          borderColor: color,
          backgroundColor: color,
          tension: 0.3,
        },
      ],
    };
  }

  useEffect(() => {
    const chartData = adaptSimpleIndicatorData(data);
    setChartData(chartData);
  }, []);
  return (
    // <div className="line-chart-container" style={{ height: height + "px" }}>
    //   <Line options={options} data={chartData} />;
    // </div>
    <div className="line-chart-container">
      <div className="chart-wrapper">
        <Line options={options} data={chartData} />;
      </div>
    </div>
  );
};

export { LineChart };

import React, { useRef, useState } from "react";
import { IconButton } from "@mui/material";
import Icon from "components/Icon/Icon";
import TypingText from "components/TypingText/TypingText";
import "./MySnapsEmbedPage.scss";

const MySnapsEmbedPage = () => {
  const [inputValue, setInputValue] = useState("");
  const [processing, setProcessing] = useState(false);

  const textareaRef: any = useRef();
  return (
    <div className="my-snaps-embed-page-container page-container">
      <div className="page-body">
        <div className="main-content">
          <div>
            <TypingText
              speed={20}
              content="<strong>Escribe por ej:</strong> Tasa de desempleo <strong>o puedes preguntar:</strong> Cuantos escuelas publicas tenemos?"
              showCursor={true}
            />
          </div>
          <div className="query-wrapper">
            <div className="query-container">
              <textarea
                ref={textareaRef}
                placeholder="Escribe aquí..."
                value={inputValue || ""}
                onChange={(e) => setInputValue(e.target.value)}
              ></textarea>
              <div>
                <div>
                  <IconButton
                    disabled={processing || inputValue == "" ? true : false}
                    className={processing || inputValue == "" ? "disabled" : ""}
                    onClick={() => {
                      //send();
                    }}
                    aria-label="close"
                  >
                    <Icon name="snap" />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    disabled={inputValue == "" ? true : false}
                    className={inputValue == "" ? "disabled" : ""}
                    size="small"
                    onClick={() => {
                      setInputValue("");
                    }}
                    aria-label="close"
                  >
                    <Icon name="eraser" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export { MySnapsEmbedPage };

import React from "react";
import { IconButton } from "@mui/material";
import "./ActiveFilterItem.scss";
import Icon from "components/Icon/Icon";
const ActiveFilterItem = ({ filter, onDelete }: any) => {
  const conditionTranslations: any = {
    equals: "igual a",
    greater: "mayor que",
    greater_equals: "mayor o igual a",
    less: "menor que",
    less_equals: "menor o igual a",
    between: "entre",
    contains: "contiene",
  };

  const renderContent = () => {
    switch (filter.type) {
      case "text":
        return (
          <>
            <b>{filter?.label}</b>{" "}
            {conditionTranslations[filter.value.condition]} {filter.value.data}
          </>
        );
      case "date":
        switch (filter.value.condition) {
          case "between":
            return (
              <>
                <b>{filter?.label}</b> desde {filter.value.data.from} hasta{" "}
                {filter.value.data.to}
              </>
            );
          default:
            return (
              <>
                <b>{filter?.label}</b>{" "}
                {conditionTranslations[filter.value.condition]}{" "}
                {filter.value.data}
              </>
            );
        }

      case "number":
        switch (filter.value.condition) {
          case "between":
            return (
              <>
                <b>{filter?.label}</b> desde {filter.value.data.from} hasta{" "}
                {filter.value.data.to}
              </>
            );
          default:
            return (
              <>
                <b>{filter?.label}</b>{" "}
                {conditionTranslations[filter.value.condition]}{" "}
                {filter.value.data}
              </>
            );
        }

      case "multiSelect":
        return (
          <>
            <b>{filter?.label}:</b>{" "}
            {filter.value.data
              .map((item: any) => item[filter.primaryKey])
              .join(", ")}
          </>
        );

      case "tags":
        return (
          <>
            <b>{filter?.label}:</b>{" "}
            {filter.value.data.map((tag: any) => tag.name).join(", ")}
          </>
        );
      case "statuses":
        return (
          <>
            <b>{filter?.label}:</b>{" "}
            {filter.value.data.map((item: any) => item.displayName).join(", ")}
          </>
        );
      case "boolean":
        return (
          <>
            <b>{filter?.label}</b> {filter.value.data == "yes" ? "Si" : "No"}
          </>
        );
      default:
        return filter?.label;
    }
  };
  const handleDelete = () => {
    console.log("true borro");
    onDelete();
  };
  return (
    <div className="active-filter-item-container">
      <Icon name="filters" />
      <span>{renderContent()}</span>

      <IconButton size={"small"} color="primary" onClick={handleDelete}>
        <Icon name="close" />
      </IconButton>
    </div>
  );
};

export { ActiveFilterItem };

import React, { Fragment } from "react";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from "config/urls";
import "./NotificationsPage.scss";
import { Spinner } from "../../components/Spinner/Spinner";
import PillTabs from "components/tabs/PillTabs/PillTabs";
import NotificationItem from "components/items/NotificationItem/NotificationItem";
import { Header } from "components/Header/Header";
import { LoadingButton } from "@mui/lab";
import { WorkflowEntryDetailModal } from "cards/workflowEntries/components/WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import Icon from "components/Icon/Icon";

const NotificationsPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([] as any);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [offset, setOffset] = React.useState(0);
  const limit = 5;
  const [tabIndex, setTabIndex] = React.useState(0); // 0 for unread, 1 for all
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showMoreButton, setShowMoreButton] = React.useState(false);
  const [workflowEntryDetailModalIsOpen, setWorkflowEntryDetailModalIsOpen] = React.useState(false);
  const [currentWorkflowEntryId, setCurrentWorkflowEntryId] = React.useState(null);
  const [currentWorkflowStepOrder, setCurrentWorkflowStepOrder] = React.useState(0 as any);

  const loadData = async () => {
    if (offset > 0) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const endpoint = tabIndex === 0 ? '/notifications/unread' : '/notifications';
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api${endpoint}?offset=${offset}&limit=${limit}&search_term=${searchTerm}`
      );
      const newData = res?.data;
      if (offset > 0) {
        setData((prevData: any) => [...prevData, ...newData]); // Concatenamos si es carga de "más registros"
      } else {
        setData(newData); // Reemplazamos los datos si es una nueva búsqueda o cambio de tab
      }
      setShowMoreButton(newData.length === limit);
    } catch (error) {
      console.error('Error loading notifications', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };
  // Cargamos datos en respuesta a cambios en offset, tabIndex, o searchTerm
  React.useEffect(() => {


    loadData();
  }, [offset, tabIndex, searchTerm]);

  const handleNotificationClick = async (notification: any) => {
    if (notification?.read_at == null) {
      try {
        await AxiosInterceptor.post(`${urls.server}/api/notifications/${notification?.id}/mark-as-read`);
        if (offset != 0) {
          setOffset(0); // Reset to load from the beginning
        } else {
          loadData();
        }

      } catch (error) {
        console.error('Error marking notification as read', error);
      }
    }
    if (notification?.data?.workflowStepEntryId) {
      setCurrentWorkflowEntryId(notification?.data?.workflowStepEntryId)
    }
    console.log(notification?.data?.workflowStepEntryId);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
    setOffset(0);
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    setOffset(0);
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    setOffset(prevOffset => prevOffset + limit);
  };

  const tabOptions = ['No leídas', 'Todas'];
  React.useEffect(() => {
    if (currentWorkflowEntryId != null &&
      currentWorkflowStepOrder != null) {
      setWorkflowEntryDetailModalIsOpen(true);
    }
  }, [currentWorkflowEntryId, currentWorkflowStepOrder])
  return (
    <div className="notifications-page-container page-container">
      <Header
        title="Notificaciones"
        hasSearchBar
        onSearchChange={handleSearchChange}
      />
      <div className="page-body">
        <div className="tabs-wrapper">
          <PillTabs
            options={tabOptions}
            onTabChange={handleTabChange}
          />
        </div>
        {loading && (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        )}
        {!loading && data.length === 0 && (
          <div className="no-content">
            <Icon name="notifications" />
            <p>No hay notificaciones</p>
          </div>
        )}
        {!loading && data.length > 0 && (
          <>
            <div className="notifications-list">
              {data.map((item: any, index: number) => (
                <Fragment key={index}>
                  <NotificationItem
                    data={item?.data}
                    className={item?.read_at == null ? "notification-unread" : ""}
                    onClick={() => handleNotificationClick(item)}
                  />
                </Fragment>
              ))}
            </div>
            {showMoreButton && (
              <div className="loading-btn-wrapper">
                <LoadingButton
                  onClick={handleLoadMore}
                  loading={loadingMore}
                  loadingPosition="start"
                  startIcon={<Icon name="plus" />}
                >
                  Más registros
                </LoadingButton>
              </div>
            )}
          </>
        )}
      </div>
      <WorkflowEntryDetailModal
        isOpen={workflowEntryDetailModalIsOpen}
        onClose={(res) => {
          console.log(res)

          setWorkflowEntryDetailModalIsOpen(false)
          setCurrentWorkflowEntryId(null)
          //setCurrentWorkflowStepOrder(null)
        }}
        workflowEntryId={currentWorkflowEntryId}
        goToStep={currentWorkflowStepOrder}
      />
    </div>
  );
};

export { NotificationsPage };


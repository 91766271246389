import React, { useState } from "react";
import { RingBinder } from "components/RingBinder/RingBinder";
import { SvgIcon } from "@mui/material";
import './Shelf.scss';
import { RingBinderDetailModal } from "components/modals/RingBinderDetailModal/RingBinderDetailModal";

// Define the props interface
interface ShelfProps {
    binders: {
        color: string,
        display_name: string,
        name: string,
        id: number,
        can_delete_files: boolean
        can_upload_files: boolean
    }[];
    ringBinderType: string;
    mode: 'all' | 'my'
}

const Shelf: React.FC<ShelfProps> = ({ binders, ringBinderType, mode }) => {
    //const [ringBinderDetailModalIsOpen, setRingBinderDetailModalIsOpen] = useState(false);
    return (
        <>
            <div className="shelf-container">
                <div className="binders">
                    {binders.map((binder, index) => (
                        <div className="ring-binder-wrapper" style={{
                            right: ringBinderType == 'viewedFromSide' ? -6 : 0
                        }} key={index}>
                            <RingBinder
                                color={binder.color}
                                type={ringBinderType}
                                label={binder?.display_name}
                                name={binder?.name}
                                id={binder?.id}
                                mode={mode}
                                //canDeleteFiles={mode === 'all' ? true : binder?.can_delete_files}
                                canUploadFiles={mode === 'all' ? true : binder?.can_upload_files}
                            // onClick={() => {
                            //     setRingBinderDetailModalIsOpen(true)
                            // }}
                            />
                        </div>
                    ))}
                </div>
                {
                    ringBinderType == 'viewedFromSide' || ringBinderType == 'viewedFromTop' ?
                        <div className="shelf">
                            <div className="shelf-start">

                                {
                                    ringBinderType == 'viewedFromSide' ?

                                        <SvgIcon style={{ width: 64, height: 75 }} viewBox={`0 0 64 75`}>
                                            <path fill={"#68818e"} d={"M 64,0 5.9552923e-6,51 H 64 Z"} />
                                            <path fill={"#4d6472"} d={"M 0,51 H 64 V 75 H 0 Z"} />
                                        </SvgIcon>

                                        :

                                        <SvgIcon style={{ width: 50, height: 75 }} viewBox={`0 0 50 75`}>
                                            <path fill={"#68818e"} d={"M 50,0 4.6525721e-6,51 H 50 Z"} />
                                            <path fill={"#4d6472"} d={"M 0,51 H 50 V 75 H 0 Z"} />
                                        </SvgIcon>


                                }
                            </div>
                            <div className="shelf-middle"></div>
                            <div className="shelf-end">
                                {
                                    ringBinderType == 'viewedFromSide' ?

                                        <SvgIcon style={{ width: 36, height: 75 }} viewBox={`0 0 36 75`}>
                                            <path fill={"#68818e"} d={"M -1e-6,0 V 52.30079 L 35.999999,0 Z"} />
                                            <path fill={"#4d6472"} d={"M 35.999998,19.911938 0,75 3e-6,50.834483 35.999998,0 Z"} />
                                        </SvgIcon>

                                        :

                                        <SvgIcon style={{ width: 50, height: 75 }} viewBox={`0 0 50 75`}>
                                            <path fill={"#68818e"} d={"M 0,0 49.999995,51 H 0 Z"} />
                                            <path fill={"#4d6472"} d={"M 50,51 H 0 v 24 h 50 z"} />
                                        </SvgIcon>


                                }
                            </div>
                        </div> :
                        <div className="shelf-2d"></div>
                }

            </div>

        </>

    );
};

export default Shelf;

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./WebsiteBuilderModal.scss";
import Modal from "components/Modal/Modal";
import { Fab, IconButton } from "@mui/material";
import Icon from "components/Icon/Icon";
import DividerTabs from "components/tabs/DividerTabs/DividerTabs";
import ConfigurationItemSelector from "./components/ConfigurationItemSelector/ConfigurationItemSelector";
import recordService from 'services/recordService';
import DevicePreview from "../DevicePreview/DevicePreview";
import colors from "config/colors";
import ConfigurationItemEditor from "./components/ConfigurationItemEditor/ConfigurationItemEditor";
import AutoSaveButton from "components/buttons/AutoSaveButton/AutoSaveButton";
import slugify from 'slugify';
//import EditableValueButton from "components/buttons/EditableValueButton/EditableValueButton";
import { Toast } from "components/Toast/Toast";

interface ModalProps {
  title: string;
  isOpen: boolean;
  pages: any[];
  versionId: number;
  onClose: (refreshOnClose?: boolean) => void;
}

const WebsiteBuilderModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  pages = [],
  versionId,
  onClose
}) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [tabOptions, setTabOptions] = useState([] as any);
  const [configurationItemSelectorWrapperIsVisible, setConfigurationItemSelectorWrapperIsVisible] = useState(true);
  const [configurationItemEditorWrapperIsVisible, setConfigurationItemEditorWrapperIsVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('desktop');
  const [selectedItem, setSelectedItem] = useState(null as any);

  const [currentPage, setCurrentPage] = useState(null as any);
  const [globalPages, setGlobalPages] = useState({ pages: [] as any[] });
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const modes = ['desktop', 'tablet', 'mobile'];

  const getNextMode = (currentMode: string) => {
    const currentIndex = modes.indexOf(currentMode);
    return modes[(currentIndex + 1) % modes.length];
  };

  useEffect(() => {
    if (isOpen) {
      setRefreshOnClose(false);
      console.log('Pages on open:', pages);
    } else {
      setRefreshOnClose(true);
    }

  }, [isOpen]);

  useEffect(() => {
    console.log('currentPage:', currentPage);

  }, [currentPage]);

  useEffect(() => {
    if (pages && pages.length > 0) {
      const pagesWithIds = pages.map(page => ({
        ...page,
        content: {
          ...page.content,
          id: page.content.id || uuidv4(),
          children: page.content.children?.map((child: any) => ({
            ...child,
            id: child.id || uuidv4()
          })) || []
        }
      }));
      setTabOptions(pagesWithIds);
      setGlobalPages({ pages: pagesWithIds });
      console.log('Pages with IDs:', pagesWithIds);
    }
  }, [pages]);

  const addItem = (newItem: any) => {
    if (selectedItem) {
      newItem.id = uuidv4();
      // Verifica si el tipo del nuevo ítem es 'dynamicList'
      if (newItem.type === 'dynamicList') {
        // Asignar un ID único al propio dynamicList
        //newItem.id = uuidv4();

        // Asignar un ID único al 'child' dentro de 'item'
        if (newItem.item && newItem.item.child) {
          newItem.item.child.id = uuidv4();
        }
      }

      // Verifica si el tipo del nuevo ítem es 'header'
      if (newItem.type === 'header') {
        //newItem.id = uuidv4();
        newItem.children = newItem.children.map((child: any) => {
          // Asignar un ID único al bloque hijo
          const newChild = {
            ...child,
            id: uuidv4(),
            children: child.children.map((grandchild: any) => ({
              ...grandchild,
              id: uuidv4()
            }))
          };
          return newChild;
        });
      }

      const newItemCopy = JSON.parse(JSON.stringify(newItem));
      const selectedItemId = selectedItem.id;

      // Función recursiva para buscar el item por ID y agregar el nuevo item a sus hijos
      const addItemToTree = (node: any, itemId: string): boolean => {
        // Si el nodo actual tiene el ID buscado
        if (node.id === itemId) {
          // Verifica si el nodo tiene la propiedad children
          if (Array.isArray(node.children)) {
            node.children.push(newItemCopy);
            return true; // Indica que el item fue añadido
          } else {
            throw new Error(`El nodo con id ${itemId} no tiene la propiedad 'children'`);
          }
        }

        // Si el nodo tiene hijos, recorre cada hijo y llama recursivamente
        if (Array.isArray(node.children)) {
          for (let child of node.children) {
            const added = addItemToTree(child, itemId);
            if (added) return true; // Si el item fue añadido, termina la búsqueda
          }
        }

        if (node.item?.child) {
          const added = addItemToTree(node.item.child, itemId);
          if (added) return true; // Si el item fue añadido, termina la búsqueda
        }

        return false; // Indica que el item no fue añadido en esta rama
      };

      const updatedContent = { ...currentPage.content };

      // Intentar añadir el nuevo item al árbol
      try {
        const itemAdded = addItemToTree(updatedContent, selectedItemId);
        if (!itemAdded) {
          throw new Error(`No se encontró un nodo con id ${selectedItemId}`);
        }

        console.log('updatedContent', updatedContent);
        console.log('newItemCopy', newItemCopy);

        const updatedPage = {
          ...currentPage,
          content: updatedContent
        };

        setCurrentPage(updatedPage);
        updateGlobalPages(updatedPage);
      } catch (error: any) {
        console.error(error.message);
      }
    }
  };


  const handleSelectComponent = (component: any) => {
    console.log('component', component);
    setSelectedItem(component);
  };
  const findAndUpdateComponentById = (components: any[], id: string, config: any): any[] | null => {
    let found = false;

    // Función recursiva para buscar y actualizar el componente
    const updateComponent = (componentArray: any[]): boolean => {
      for (let i = 0; i < componentArray.length; i++) {
        const component = componentArray[i];

        // Si encontramos el componente con el ID buscado
        if (component.id === id) {
          // Reemplazar las propiedades del componente en lugar del objeto entero
          Object.assign(component, config);
          console.log(config)
          console.log(component)
          found = true;
          return true; // Indicar que se hizo la actualización
        }

        // Si el componente tiene hijos, buscar recursivamente en ellos
        if (component.children) {
          const foundAndUpdated = updateComponent(component.children);
          if (foundAndUpdated) {
            return true; // Si se encontró y actualizó en los hijos, terminamos la búsqueda
          }
        }

        // Si el componente tiene un 'item.child', buscar recursivamente en él
        if (component.item?.child) {
          const foundAndUpdated = updateComponent([component.item.child]);
          if (foundAndUpdated) {
            return true; // Si se encontró y actualizó en el 'item.child', terminamos la búsqueda
          }
        }
      }

      return false; // No se encontró el componente en este nivel
    };

    // Llamar a la función recursiva para iniciar la búsqueda y actualización
    updateComponent(components);

    // Devolver los componentes actualizados si se encontró el componente, de lo contrario null
    return found ? components : null;
  };

  const handleComponentChange = (id: string, changes: any) => {

    let updatedPage;

    if (currentPage.content.id === id) {
      // Si el componente seleccionado es el bloque padre, aplicamos los cambios directamente
      updatedPage = {
        ...currentPage,
        content: { ...currentPage.content, ...changes }
      };
    } else {
      const updatedComponents = findAndUpdateComponentById(currentPage.content.children, id, changes);
      if (updatedComponents) {
        console.log('El componente fue actualizado correctamente.', updatedComponents);
        updatedPage = {
          ...currentPage,
          content: {
            ...currentPage.content,
            children: updatedComponents
          }
        };
      }

    }
    setCurrentPage(updatedPage);
    updateGlobalPages(updatedPage);
    setSelectedItem(changes);

  };

  const updateGlobalPages = (updatedPage: any) => {
    setGlobalPages((prevGlobalPages) => ({
      pages: prevGlobalPages.pages.map((page: any) =>
        page.id === updatedPage.id ? updatedPage : page
      )
    }));
    console.log('Global pages updated:', globalPages);
  };

  const onCloseBtnPress = () => {
    onClose(refreshOnClose);
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const res = await recordService.fetchAll({
        listPath: `configuration-items/ui-components`,
      });
      setItems(res);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) fetchItems();
  }, [isOpen]);

  useEffect(() => {
    if (currentPage) {
      //updateGlobalPages(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (selectedItem) setConfigurationItemEditorWrapperIsVisible(true);
  }, [selectedItem]);

  const findComponentById = (components: any[], id: string): any => {
    for (let component of components) {
      if (component.id === id) {
        return component;
      }
      if (component.children) {
        const found = findComponentById(component.children, id);
        if (found) {
          return found;
        }
      }
      if (component.item?.child?.children) {
        const found = findComponentById(component.item.child.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };




  const generateNewPageTitle = (pages: any[]) => {
    const baseTitle = 'Página';
    let newTitle = `${baseTitle} 1`;
    let count = 2;
    while (pages.some(page => page.title === newTitle)) {
      newTitle = `${baseTitle} ${count}`;
      count++;
    }
    return newTitle;
  };

  const handleAddPage = () => {
    const newTitle = generateNewPageTitle(globalPages.pages);
    const newPage = {
      id: uuidv4(),
      title: newTitle,
      slug: slugify(newTitle, { lower: true }),
      content: {
        id: 'main-container',
        type: 'block',
        displayType: 'Bloque',
        className: 'main-container',
        width: {
          value: '100',
          unit: '%'
        },
        height: {
          value: null,
          unit: 'px'
        },
        margin: {
          value: '5',
          unit: 'px'
        },
        padding: {
          value: '5',
          unit: 'px'
        },
        style: [],
        children: []
      }
    };

    setGlobalPages((prevGlobalPages) => ({
      pages: [...prevGlobalPages.pages, newPage]
    }));
    setTabOptions((prevTabOptions: any) => [...prevTabOptions, newPage]);
    //setCurrentPage(newPage);
  };

  const moveComponentUp = (id: string) => {
    const updatedPage = { ...currentPage };
    const parent = findParentComponent(updatedPage.content, id);

    if (parent) {
      const index = parent.children.findIndex((child: any) => child.id === id);
      if (index > 0) {
        const temp = parent.children[index - 1];
        parent.children[index - 1] = parent.children[index];
        parent.children[index] = temp;
        setCurrentPage(updatedPage);
        updateGlobalPages(updatedPage);
      }
    }
  };

  const moveComponentDown = (id: string) => {
    const updatedPage = { ...currentPage };
    const parent = findParentComponent(updatedPage.content, id);

    if (parent) {
      const index = parent.children.findIndex((child: any) => child.id === id);
      if (index < parent.children.length - 1) {
        const temp = parent.children[index + 1];
        parent.children[index + 1] = parent.children[index];
        parent.children[index] = temp;
        setCurrentPage(updatedPage);
        updateGlobalPages(updatedPage);
      }
    }
  };

  const deleteComponent = (id: string) => {
    const updatedPage = { ...currentPage };
    const parent = findParentComponent(updatedPage.content, id);
    if (parent) {
      parent.children = parent.children.filter((child: any) => child.id !== id);
      setSelectedItem(null);
      setConfigurationItemEditorWrapperIsVisible(false);
      setTimeout(() => {
        setCurrentPage(updatedPage);
        updateGlobalPages(updatedPage);
      }, 500)


    }
  };


  const findParentComponent = (component: any, id: string): any => {
    if (!component.children && !component.item?.child?.children) {
      return null;
    }
    if (component.children) {
      for (let child of component.children) {
        if (child.id === id) {
          return component;
        }
        const found = findParentComponent(child, id);
        if (found) {
          return found;
        }
      }
    }
    if (component.item?.child?.children) {
      for (let child of component.item?.child?.children) {
        if (child.id === id) {
          return component.item.child;
        }
        const found = findParentComponent(child, id);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };
  const hasSiblings = (id: string): boolean => {
    const parent = findParentComponent(currentPage.content, id);
    console.log(id, parent);
    return parent ? parent?.children?.length > 1 : false;
  };

  const handleTabEdit = (newValue: string, option: any) => {
    // Verificar si el título nuevo ya existe en otra página
    const isTitleDuplicate = globalPages.pages.some(
      (page) => page.title === newValue && page.id !== option.id
    );

    if (isTitleDuplicate) {
      setToastMessage(`El título "${newValue}" ya existe. Por favor elija otro.`);
      setToastSeverity("error");
      setToastIsOpen(true);
      return;
    }

    // Actualizar currentPage si coincide con el id de la página editada
    if (currentPage && currentPage.id === option.id) {
      setCurrentPage({ ...currentPage, title: newValue });
    }

    // Actualizar el título en globalPages
    setGlobalPages((prevState) => ({
      pages: prevState.pages.map((page) =>
        page.id === option.id ? { ...page, title: newValue } : page
      ),
    }));
    setTabOptions((prevState: any) =>
      prevState.map((page: any) =>
        page.id === option.id ? { ...page, title: newValue } : page
      )
    );
    setToastMessage("El título de la página ha sido actualizado con éxito.");
    setToastSeverity("success");
    setToastIsOpen(true);
  };
  return (
    <>
      <Modal
        className={'website-builder-modal-container'}
        isOpen={isOpen}
        onClose={() => {
          onClose(refreshOnClose);
        }}
        hideHeader={true}
      >
        {isOpen && (
          <>
            <div className="container-header">
              <div>
                <h2>{title}</h2>
                <AutoSaveButton
                  savePath={`website-versions/${versionId}/config`}
                  data={globalPages}
                  interval={900000}
                  onSaveSuccess={() => {
                    console.log('se guardó');
                    setRefreshOnClose(true);
                  }}
                />
              </div>
              <IconButton
                color="inherit"
                onClick={onCloseBtnPress}
                aria-label="close"
              >
                <Icon name="close" />
              </IconButton>
            </div>
            <div className="container-body">
              <div className="pages-wrapper">
                <DividerTabs
                  options={tabOptions}
                  labelKey="title"
                  showAddButton={true}
                  onAddButtonClick={handleAddPage}
                  onTabChange={(tabIndex: any) => {
                    setCurrentPage(globalPages.pages[tabIndex]);
                  }}
                  deleteConfirmationMessage="Desea eliminar el la página <b>{{title}}</b>"
                  showDeleteButton={true}
                  onDeleteButtonClick={(option) => {

                  }}
                  onTabDelete={(option) => {
                    // Filtrar las páginas para excluir la que tiene la id de 'option'
                    const updatedPages = globalPages.pages.filter(page => page.id !== option.id);

                    // Actualizar el estado de globalPages y tabOptions con las páginas filtradas
                    setGlobalPages({ pages: updatedPages });
                    setTabOptions(updatedPages);

                    // Verificar si el id de currentPage coincide con el id de la opción eliminada
                    if (currentPage?.id === option.id) {
                      // Si coincide, actualizar currentPage a la primera página de updatedPages o null si no hay páginas
                      setCurrentPage(updatedPages.length > 0 ? updatedPages[0] : null);
                    }
                  }}
                  showDeleteConfirmationDialog={true}
                  showEditButton
                  onTabEdit={handleTabEdit}
                />
              </div>
              <div className="main-content">
                <div className={`configuration-item-selector-wrapper ${configurationItemSelectorWrapperIsVisible ? "visible" : "hidden"}`}>
                  <a className="toggle-button" onClick={() => {
                    setConfigurationItemSelectorWrapperIsVisible(!configurationItemSelectorWrapperIsVisible);
                  }}>
                    {configurationItemSelectorWrapperIsVisible ? <Icon name="close" /> : <Icon name="add" />}
                  </a>
                  <ConfigurationItemSelector items={items} onSelectItem={addItem} disabled={selectedItem?.type !== 'block'} />
                </div>
                <div className="device-preview-wrapper">
                  <DevicePreview
                    mode={mode}
                    structure={currentPage?.content}
                    selectable
                    onSelectItem={handleSelectComponent}
                    onChange={(updatedContent: any) => {
                      const updatedComponent = findComponentById([updatedContent], selectedItem?.id);
                      if (updatedComponent) {
                        handleComponentChange(selectedItem?.id, updatedComponent);
                      }
                    }}
                  />
                </div>
                <ConfigurationItemEditor
                  config={selectedItem}
                  onHide={() => {
                    setConfigurationItemEditorWrapperIsVisible(false);
                    setSelectedItem(null);
                  }}
                  onChange={handleComponentChange}
                  isVisible={configurationItemEditorWrapperIsVisible}
                  onMoveUp={moveComponentUp}
                  onMoveDown={moveComponentDown}
                  onDelete={deleteComponent}
                  hasSiblings={selectedItem ? hasSiblings(selectedItem.id) : false}
                />
              </div>
            </div>
            <Fab
              className="secondary-fab"
              size="small"
              aria-label="mode"
              onClick={() => {
                setMode((prev) => getNextMode(prev));
              }}
              sx={{
                position: "fixed",
                bottom: 10,
                right: 65,
                zIndex: 0,
                backgroundColor: colors.medium,
                color: "#fff"
              }}
            >
              <Icon name={mode === 'desktop' ? 'tablet' : (mode === 'tablet' ? 'mobile' : 'desktop')} />
            </Fab>
            <Fab
              color="primary"
              size="small"
              aria-label="preview"
              onClick={() => { }}
              sx={{
                position: "fixed",
                bottom: 10,
                right: 16,
                zIndex: 0
              }}
            >
              <Icon name="eye" />
            </Fab>
            <Toast
              isOpen={toastIsOpen}
              onClose={() => setToastIsOpen(false)}
              message={toastMessage}
              severity={toastSeverity}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export { WebsiteBuilderModal };

import { AnimatedNumber } from "components/AnimatedNumber/AnimatedNumber";
import Icon from "components/Icon/Icon";
import colors from 'config/colors';
import './CardPreview.scss';

interface PreviewValueDefinition {
    name: string;
    displayName: string;
    decimalPrecision: number;
    prepend?: string;
    append?: string;
    color?: string;
}

interface CardPreviewProps {
    type: 'icon' | 'values';
    icon?: string;
    valueDefinitions?: PreviewValueDefinition[];
    data: {
        [key: string]: number;
    };
}

const CardPreview: React.FC<CardPreviewProps> = ({ type, icon, valueDefinitions, data }) => {
    const getClassByItemCount = (count: number) => {
        switch (count) {
            case 1:
                return 'lg';
            case 2:
                return 'md';
            case 3:
            default:
                return 'sm';
        }
    };

    const getPrimaryColor = () => {
        return localStorage.getItem("primaryColor") || colors.primary;
    };

    if (type === 'icon' && icon) {
        return <div className="card-preview-container"><Icon size={110} name={icon} /></div>;
    }

    if (type === 'values' && valueDefinitions) {
        const items = valueDefinitions.slice(0, 3);
        const className = getClassByItemCount(items.length);

        return (
            <div className="card-preview-container">
                <div className={`preview-values ${className}`}>
                    {items.map((item: any, index) => {
                        const value = data ? data[item.name] : 0;
                        const color = item.color === "primary" ? getPrimaryColor() : (colors[item.color] || item.color);
                        return (
                            <div key={index} className="preview-value-item" style={{ color: color }}>
                                <AnimatedNumber
                                    value={value}
                                    duration={500}
                                    color={color}
                                    decimalPrecision={item.decimalPrecision}
                                    prepend={item.prepend}
                                    append={item.append}
                                />
                                <span>{item.displayName}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};

export default CardPreview;

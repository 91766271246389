import React, { useEffect } from 'react'
import './SegmentTabs.scss';
import Icon from 'components/Icon/Icon';

const SegmentTabs = ({ options, onTabChange, initialIndex = 0 }: any) => {
    const [value, setValue] = React.useState(options[initialIndex]?.name);
    useEffect(() => {
        if (onTabChange) {
            onTabChange(value);
        }
    }, [value]);
    return (
        <div className='segment-tabs-container'>
            {options.map((option: any, index: number) => (
                <a
                    className={value == option.name ? "actived" : ""}
                    key={index}
                    onClick={() => {
                        setValue(options[index].name);
                    }}
                    style={{
                        width: option?.icon ? 50 : "auto",
                    }}
                >
                    {
                        option?.icon ? <Icon name={option?.icon} /> : <span>{option?.displayName}</span>
                    }
                </a>
            ))}
        </div>
    )
}

export default SegmentTabs;
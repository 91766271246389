import React, { useState, useEffect } from 'react'
import './ConfigurationItemSelector.scss';
import CardButton from 'components/buttons/CardButton/CardButton';


interface ConfigurationItemSelectorProps {
    items: Array<[]>;
    onSelectItem: (item: any) => void;
    disabled: boolean;
}
const ConfigurationItemSelector: React.FC<ConfigurationItemSelectorProps> = ({ items, disabled = false, onSelectItem }) => {
    const handleClickItem = (item: any) => {
        if (!disabled) onSelectItem(item?.configurable_attributes);
    };
    return (
        <div className={`configuration-item-selector-container ${disabled ? 'disabled' : ''}`}>

            <div className='items-wrapper'>
                {
                    items.map((item: any, index: number) => {
                        return <CardButton
                            size={'md'}
                            key={index}
                            title={item.display_type}
                            icon={item.icon}
                            footerIcon={'add'}
                            onPress={() => {
                                handleClickItem(item)
                            }} />
                    })
                }
            </div>

        </div>
    )
}

export default ConfigurationItemSelector
import React, { useState, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DynamicForm } from "../../DynamicForm/DynamicForm";
import "./PreviewFormModal.scss";
import { Fab } from "@mui/material";
import Icon from "components/Icon/Icon";
interface ModalProps {
  isOpen: boolean;
  fields: any[];
  extraData?: any;
  onClose: (refreshOnClose?: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PreviewFormModal: React.FC<ModalProps> = ({
  isOpen,
  fields = [],
  extraData = {},
  onClose,
}) => {
  const [values, setValues] = useState({});
  const onSave = async () => {
    console.log(values)
  };

  const handleFieldEdit = () => {

  };
  useEffect(() => {
    if (isOpen) {

    }
  }, [isOpen]);
  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => {
          onClose();

        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
      >
        <div className="preview-form-modal-body">
          <div>
            <div className="paper">
              <a
                onClick={() => {

                  onClose();

                }}
              >
                <Icon name="cancel" />
              </a>
              <span></span>
              <span></span>
              <div>
                <DynamicForm
                  fields={fields}
                  onChange={(data: any) => {
                    setValues(data);
                  }}
                  title={'Vista previa del formulario'}
                  onFieldEdit={handleFieldEdit}
                  extraData={extraData}
                  mode={'create'}
                />
              </div>
            </div>
          </div>
        </div>

        <Fab
          color="primary"
          variant="extended"
          aria-label="save"

          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={onSave}
        >
          <Icon name="check" style={{ marginRight: 5 }} />
          {"Ver data"}
        </Fab>



      </Dialog>

    </>

  );
};

export { PreviewFormModal };

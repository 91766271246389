import { useState, useEffect, useRef } from "react";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./DueDateTabs.scss";
const DueDateTabs = ({
  forceRefresh,
  onSelectItem,
  listPath,
  onRefreshComplete,
}: any) => {
  //const tabsRef = useRef(null as any);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [value, setValue] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0 as any);
  const [isEnd, setIsEnd] = useState(false);
  //const [scrollPosition, setScrollPosition] = useState(0);
  const [tabsRef, setTabsRef] = useState(null as any);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const selectTab = (index: number) => {
    setSelectedTabIndex(index);
    console.log(index);
  };
  const loadRecords = async () => {
    setLoading(true);
    try {
      const hasQueryParams = listPath.includes('?');
      const prefix = hasQueryParams ? '&' : '?';
      const url = `${urls.server}/api/${listPath}${prefix}offset=${offset}&limit=${limit}`
      const res = await AxiosInterceptor.get(url);
      const records = await res?.data;
      setRecords(records);
      setLoading(false);
      if (onRefreshComplete) {
        onRefreshComplete();
      }
    } catch (error) {

      setLoading(false);
      if (onRefreshComplete) {
        onRefreshComplete();
      }
    }
  };
  useEffect(() => {
    loadRecords();
  }, [listPath]);
  useEffect(() => {
    onSelectItem(records[selectedTabIndex]);
  }, [selectedTabIndex]);
  useEffect(() => {
    if (forceRefresh) {
      loadRecords();
      setSelectedTabIndex(0);
    }
  }, [forceRefresh]);
  const handleTabsScroll = (event: any) => {
    console.log("Final de los tabs alcanzado...");
    const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;
    const currentScrollLeft = event.target.scrollLeft;

    if (currentScrollLeft >= maxScrollLeft) {
      console.log("Final de los tabs alcanzado");
    }

    //setScrollPosition(currentScrollLeft);
  };
  return (
    <Box
      className="due-date-tabs-container"
      sx={{ maxWidth: { xs: 320, sm: 480, lg: 1100 } }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        aria-label="Proximos dias"
        onScroll={handleTabsScroll}
      >
        {records.map((item: any, index: number) => (
          <Tab
            component="a"
            key={index}
            label={
              <div className={selectedTabIndex === index ? "selected" : ""}>
                <div>
                  {index != 0 && index != 1 && index != 2 ? (
                    item.abbreviatedDay
                  ) : (
                    <div
                      style={{
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <strong>{item.label}</strong>
                    </div>
                  )}
                </div>
                {index != 0 && index != 1 && index != 2 && (
                  <>
                    <div>{item.dayOfMonth}</div>
                    <div>{item.abbreviatedMonth}</div>
                  </>
                )}

                <span className="pending-count">
                  <span
                    style={{
                      fontSize: item.pendingCount > 99 ? "11px" : "12px",
                    }}
                  >
                    {item.pendingCount}
                  </span>
                </span>

                <svg width="70" height="70" viewBox="0 0 70 70">
                  <path d="M 8.9738429,-4e-5 C 4.0020289,-4e-5 0,4.00269 0,8.97444 L 0,61.0255 C 0,65.9972 4.0020289,70 8.9738429,70 L 61.025415,70 C 65.997229,70 70,65.9972 70,61.0255 L 70,22.3101 A 16.463256,16.463077 0 0 1 62.55726,24.0897 16.463256,16.463077 0 0 1 46.094549,7.62638 16.463256,16.463077 0 0 1 47.981275,-4e-5 l -39.0074321,0 z" />
                </svg>
              </div>
            }
            style={{}}
            //value={value}
            onClick={() => selectTab(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export { DueDateTabs };

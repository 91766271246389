import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { addDays, formatDate, subtractDays } from "../../../utils";
import { useSessionStorage } from "hooks/useSessionStorage";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import "dayjs/locale/es";
import "./DateField.scss";

const DateField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    editPath,
    onChange,
    name,
    //readonly,
    format = 'dateTime', //fullDate, fullDateTime, monthYear, yearOnly, monthOnly
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [views, setViews] = useState(['year', 'month', 'day'] as any);


  useEffect(() => {
    switch (format) {
      case "fullDate":
        setViews(['year', 'month', 'day']);
        break;
      case "monthYear":
        setViews(['year', 'month']);
        break;
      case "yearOnly":
        setViews(['year']);
        break;
      case "monthOnly":
        setViews(['month']);
        break;
      default:
        break;
    }
  }, [format])
  useEffect(() => {
    if (defaultValue) {
      if (defaultValue == "today") {
        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          ("0" + (today.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + today.getDate()).slice(-2);
        setValue(date);
      } else if (
        defaultValue.includes("today") &&
        (defaultValue.includes("+") || defaultValue.includes("-"))
      ) {
        let arr;
        if (defaultValue.includes("+")) {
          arr = defaultValue.split("+");
        } else if (defaultValue.includes("-")) {
          arr = defaultValue.split("-");
        }
        let n = arr[1].trim();
        n = parseInt(n);
        const today = new Date();
        let date: any;
        if (defaultValue.includes("+")) {
          date = addDays(today, n);
        } else if (defaultValue.includes("-")) {
          date = subtractDays(today, n);
        }
        const dateStr =
          date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2);
        setValue(dateStr);
      } else {
        setValue(defaultValue);
      }
    }
  }, []);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  useEffect(() => {
    console.log(defaultValue)
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="date-field-container">
      {label && <label>{label}</label>}
      <div>
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <span>{formatDate(value) || '-'}</span>
          ) : (
            <div className="datepicker-wrapper">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
                localeText={{
                  cancelButtonLabel: "Cancelar",
                  clearButtonLabel: "Limpiar",
                  okButtonLabel: "Ok",
                  todayButtonLabel: "Hoy",
                }}
              >
                {/* {JSON.stringify(type)} */}
                <DatePicker
                  value={value ? dayjs(value) : null}
                  views={views}
                  //views={['year', 'month']}
                  onChange={(newValue: any) => {
                    if (newValue) {
                      const date = new Date(newValue);

                      // Get year, month, and day part from the date
                      const year = date.toLocaleString("default", {
                        year: "numeric",
                      });
                      const month = date.toLocaleString("default", {
                        month: "2-digit",
                      });
                      const day = date.toLocaleString("default", {
                        day: "2-digit",
                      });

                      // Generate yyyy-mm-dd date string
                      const formattedDate = year + "-" + month + "-" + day;
                      setValue(formattedDate);
                    } else {
                      setValue(null);
                    }
                  }}
                  slotProps={{
                    actionBar: { actions: ["clear", "today"] },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>

        {editable && (
          <div>
            {(mode == 'edit' && editable) && (

              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DateField };

import React, { useState, useEffect } from "react";
import "./MultiActionExpandedCard.scss";
import Icon from "components/Icon/Icon";
import { FormModal } from "components/modals/FormModal/FormModal";
import { DataTableModal } from "components/modals/DataTableModal/DataTableModal";
import { TagsModal } from "components/modals/TagsModal/TagsModal";
import { DeletedRecordsModal } from "components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { DataListModal } from "components/modals/DataListModal/DataListModal";
import { SelectableListModal } from "components/modals/SelectableListModal/SelectableListModal";

const MultiActionExpandedCard = (props: any) => {
  const { title, config, setRefreshOnClose } = props;
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [dataTableModalIsOpen, setDataTableModalIsOpen] = useState(false);
  const [dataListModalIsOpen, setDataListModalIsOpen] = useState(false);
  const [selectableListModalIsOpen, setSelectableListModalIsOpen] = useState(false);

  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);
  const [tagsModalIsOpen, setTagsModalIsOpen] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [viewPath, setViewPath] = useState(null as any);
  const [createPath, setCreatePath] = useState(null as any);
  const [listPath, setListPath] = useState(null as any);
  const [filters, setFilters] = useState([] as any);
  const [formTitle, setFormTitle] = useState("" as string);
  const [fields, setFields] = useState([] as any);
  const [cols, setCols] = useState([] as any);
  const [rightCells, setRightCells] = useState([] as any);
  const [leftCells, setLeftCells] = useState([] as any);
  const [dividerGroups, setDividerGroups] = useState([] as any);
  const [rowActions, setRowActions] = useState([] as any);
  const [moreRowActions, setMoreRowActions] = useState([] as any);
  const [itemActions, setItemActions] = useState([] as any);
  const [headerActions, setHeaderActions] = useState([] as any);
  const [moreItemActions, setMoreItemActions] = useState([] as any);
  const [moreHeaderActions, setMoreHeaderActions] = useState([] as any);
  const [tagGroupDisplayName, setTagGroupDisplayName] = useState(null as any);
  const [tagGroupName, setTagGroupName] = useState(null as any);
  const [currentAction, setCurrentAction] = useState(null as any);
  const [modalTitle, setModalTitle] = useState(null as any);
  const [modalSubtitle, setModalSubtitle] = useState(null as any);
  const [selectedItemActions, setSelectedItemActions] = useState([] as any);
  const [sortPath, setSortPath] = useState(null as any);
  const [isSortable, setIsSortable] = useState(null as any);

  useEffect(() => {
    console.log(currentAction);
    setModalTitle(currentAction?.displayName);
    setModalSubtitle(currentAction?.subtitle);
    if (currentAction?.type == 'viewDataTable') {
      setViewPath(currentAction.viewPath);
      setCreatePath(currentAction.createPath);
      setListPath(currentAction.listPath);
      setFilters(currentAction.filters);
      setFormTitle(currentAction.formTitle);
      setFields(currentAction.fields);
      setCols(currentAction.cols);
      setRowActions(currentAction.rowActions);
      setMoreRowActions(currentAction.moreRowActions);
      setDividerGroups(currentAction.dividerGroups);
      setDataTableModalIsOpen(true)
    } else if (currentAction?.type == 'viewDataList') {
      setViewPath(currentAction.viewPath);
      setCreatePath(currentAction.createPath);
      setListPath(currentAction.listPath);
      setFilters(currentAction.filters);
      setFormTitle(currentAction.formTitle);
      setFields(currentAction.fields);
      setRightCells(currentAction.rightCells);
      setLeftCells(currentAction.leftCells);
      setItemActions(currentAction.itemActions);
      setMoreItemActions(currentAction.moreItemActions);
      setHeaderActions(currentAction.headerActions);
      setMoreHeaderActions(currentAction.moreHeaderActions);
      setMoreItemActions(currentAction.moreItemActions);
      setDividerGroups(currentAction.dividerGroups);
      setSortPath(currentAction.sortPath);
      setIsSortable(currentAction.isSortable);
      setDataListModalIsOpen(true)
    } else if (currentAction?.type == 'viewDeletedRecords') {
      setDeletedRecordsModalIsOpen(true)
    } else if (currentAction?.type == 'viewTags') {
      setTagsModalIsOpen(true);
      setTagGroupName(currentAction.tagGroupName);
      setTagGroupDisplayName(currentAction.tagGroupDisplayName);
    } else if (currentAction?.type == 'importRecords') {

    } else if (currentAction?.type == 'viewForm') {
      setFormModalIsOpen(true)
      setFormTitle(currentAction.formTitle);
      setFields(currentAction.fields);
      setViewPath(currentAction.viewPath);
    } else if (currentAction?.type == 'downloadFile') {

    } else if (currentAction?.type == 'viewSelectableList') {
      setListPath(currentAction.listPath);
      setFields(currentAction.fields);
      setRightCells(currentAction.rightCells);
      setLeftCells(currentAction.leftCells);
      setSelectedItemActions(currentAction.selectedItemActions);
      setSelectableListModalIsOpen(true);
    } else if (currentAction?.type == 'customAction') {

    }
  }, [currentAction]);
  return (
    <>
      <div className="multi-action-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {
            config.actions.map((item: any, index: number) => {
              const iconName = item?.icon;
              const wrapperIconName = `${item?.icon}Wrapper`;
              return <a
                key={index}
                className="card-btn lg"
                onClick={() => {
                  setCurrentAction(item)
                }}
              >
                <strong>{item.displayName}</strong>
                <div>
                  <Icon name={wrapperIconName} />
                  <Icon name={iconName} />
                </div>
              </a>
            })
          }
        </div>
      </div>
      <FormModal
        isOpen={formModalIsOpen}
        onClose={(res: any) => {
          setFormModalIsOpen(false)
          setCurrentAction(null);
          if (res) {
            setShouldRefresh(true)
            setRefreshOnClose(true);
          } else {
            setShouldRefresh(false)
          }
        }}
        title={formTitle}
        fields={fields}
        viewPath={viewPath}
        //createPath={createPath}
        mode="edit"
      />

      <DataTableModal
        isOpen={dataTableModalIsOpen}
        onClose={(res) => {
          setCurrentAction(null);
          if (res) {
            setRefreshOnClose(true);
          }
          setDataTableModalIsOpen(false);
        }}
        title={modalTitle}
        cols={cols}
        dividerGroups={dividerGroups}
        formTitle={formTitle}
        fields={fields}
        rowActions={rowActions}
        moreRowActions={moreRowActions}
        listPath={listPath}
        createPath={createPath}
        filters={filters}
      />

      <DataListModal
        isOpen={dataListModalIsOpen}
        onClose={(res) => {
          setCurrentAction(null);
          if (res) {
            setRefreshOnClose(true);
          }
          setDataListModalIsOpen(false);
        }}
        title={modalTitle}
        leftCells={leftCells}
        rightCells={rightCells}
        dividerGroups={dividerGroups}
        formTitle={formTitle}
        fields={fields}
        itemActions={itemActions}
        moreItemActions={moreItemActions}
        listPath={listPath}
        createPath={createPath}
        filters={filters}
        sortPath={sortPath}
        isSortable={isSortable}
        moreHeaderActions={moreHeaderActions}
        headerActions={headerActions}

      />

      <TagsModal
        isOpen={tagsModalIsOpen}
        groupDisplayName={tagGroupDisplayName}
        groupName={tagGroupName}
        onClose={() => {
          setCurrentAction(null);
          setTagsModalIsOpen(false);
        }}
      />

      <DeletedRecordsModal
        isOpen={deletedRecordsModalIsOpen}
        onClose={() => {
          setCurrentAction(null);
          setDeletedRecordsModalIsOpen(false)
        }}
        subtitle={modalSubtitle}
      />

      <SelectableListModal
        isOpen={selectableListModalIsOpen}
        onClose={(res) => {
          setCurrentAction(null);
          if (res) {
            setRefreshOnClose(true);
          }
          setSelectableListModalIsOpen(false);
        }}
        title={modalTitle}
        subtitle=""
        leftCells={leftCells}
        rightCells={rightCells}
        listPath={listPath}
        selectedItemActions={selectedItemActions}
      />
    </>
  );
};

export { MultiActionExpandedCard };

import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Grid from "@mui/material/Grid";
import { ImageCropperField } from "../../components/fields/ImageCropperField/ImageCropperField";
import { InputField } from "../../components/fields/InputField/InputField";
import "./ProfilePage.scss";
const ProfilePage = () => {
  const { deleteToken, deleteUser } = useAuth();
  const signOut = () => {
    deleteToken();
    deleteUser();
  };
  return (
    <div className="profile-page-container page-container">
      <div className="page-header">
        <h1>Perfíl</h1>
        <Button onClick={() => signOut()} startIcon={<LogoutIcon />}>
          Despedirse
        </Button>
      </div>
      <div className="page-body">
        <div className="paper">
          <span></span>
          <span></span>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <ImageCropperField
                  id={"profile-picture"}
                  label={"Imagen de perfíl"}
                  onChange={(value: any) => console.log("holis")}
                  defaultValue={null}
                  readonly={false}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Nombre"}
                  onChange={(value: any) => console.log("holis")}
                  defaultValue={null}
                  readonly={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  label={"Email"}
                  onChange={(value: any) => console.log("holis")}
                  defaultValue={null}
                  readonly={false}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProfilePage };

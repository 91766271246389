import { Button } from "@mui/material";
import './AddressData.scss';
const AddressData = (props: any) => {
  let {
    data
  } = props;

  return (
    <>
      <div className="address-data-container">
        <div>
          <b>Nombre:</b>
          <span>{data?.name || "-"}</span>
        </div>
        <div>
          <b>Domicilio:</b>
          <span>{data?.full_address || "-"}</span>
        </div>
        <div>
          <b>Lat:</b>
          <span>{data?.lat || "-"}</span>
        </div>
        <div>
          <b>Long:</b>
          <span>{data?.lng || "-"}</span>
        </div>
        <div>
          <b>Observaciones:</b>
          <span>{data?.observations || "-"}</span>
        </div>

      </div>

    </>

  );
};

export { AddressData };

import "./MiniColumnLineChart.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MiniColumnLineChart = (props: any) => {
    const { data, height = 75, decimalPrecision = 2, onElementSelect } = props;
    const chartRef = useRef<any>(null);
    const [chartData, setChartData] = useState<any>(null);
    const [selectedElement, setSelectedElement] = useState<any>({ datasetIndex: null, index: null });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
        onClick: (event: any, elements: any) => {
            if (elements.length > 0) {
                const element = elements[0];
                const datasetIndex = element.datasetIndex;
                const index = element.index;
                const period = chartData.labels[index];
                const value = chartData.datasets[datasetIndex].data[index];
                const label = chartData.datasets[datasetIndex].label;
                const color = chartData.datasets[datasetIndex].backgroundColor;
                const type = chartData.datasets[datasetIndex].type;

                if (onElementSelect) {
                    onElementSelect({ period, value, label, color, type });
                }

                // Agrandar el punto o columna seleccionado
                setSelectedElement({ datasetIndex, index });
            }
        },
    };

    function adaptColumnLineData(indicatorData: any) {
        const datasetsMap = new Map();
        const labels = indicatorData.map((item: any) => item.display_period);

        indicatorData.forEach((period: any) => {
            period.data.forEach((item: any) => {
                if (!datasetsMap.has(item.label)) {
                    const isLine = item.chart_type === "line";
                    datasetsMap.set(item.label, {
                        label: item.label,
                        data: new Array(indicatorData.length).fill(0),
                        borderColor: isLine ? item.color : undefined,
                        backgroundColor: item.color,
                        pointRadius: new Array(indicatorData.length).fill(isLine ? 3 : 0), // Solo para las líneas
                        hidden: !item.is_visible, // Ocultar si is_visible es false
                        type: isLine ? "line" : "bar",
                    });
                }
                const dataset = datasetsMap.get(item.label);
                const periodIndex = labels.indexOf(period.display_period);
                dataset.data[periodIndex] = item.value.toFixed(decimalPrecision);
            });
        });

        const datasets = Array.from(datasetsMap.values());
        return {
            labels: labels,
            datasets: datasets,
        };
    }

    useEffect(() => {
        const adaptedData = adaptColumnLineData(data);
        setChartData(adaptedData);
    }, [data]);

    useEffect(() => {
        if (chartRef.current && chartData) {
            const lastDataIndex = chartData.labels.length - 1;
            const lastDatasetIndex = chartData.datasets.length - 1;

            chartRef.current.data.datasets.forEach((dataset: any, i: number) => {
                dataset.pointRadius = dataset.data.map((_: any, dataIndex: number) =>
                    dataIndex === lastDataIndex && i === lastDatasetIndex ? 6 : 3
                );
            });

            chartRef.current.update("none");
        }
    }, [chartData]);

    useEffect(() => {
        setTimeout(() => {
            if (chartRef.current && chartData && selectedElement.index !== null) {
                const { datasetIndex, index } = selectedElement;

                chartRef.current.data.datasets.forEach((dataset: any, i: number) => {
                    dataset.pointRadius = dataset.data.map((_: any, dataIndex: number) =>
                        dataIndex === index && i === datasetIndex && dataset.type === "line" ? 6 : 3
                    );
                });

                chartRef.current.update("none");
            }
        }, 100);
    }, [selectedElement]);

    return (
        <div
            className="mini-column-line-chart-container"
            style={{
                height: height + "px",
            }}
        >
            {chartData && <Bar ref={chartRef} options={options} data={chartData} />}
        </div>
    );
};

export { MiniColumnLineChart };

import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./DividerTabs.scss";
import { IconButton } from "@mui/material";
import Icon from "components/Icon/Icon";
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";
import interpolateString from "utils/interpolateString";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

interface DividerTabsProps {
  options: any[];
  onTabChange?: (index: number) => void;
  initialIndex?: number;
  labelKey?: string;
  showAddButton?: boolean;
  onAddButtonClick?: () => void;
  deleteConfirmationMessage?: any;
  showDeleteButton?: boolean;
  onDeleteButtonClick?: (option: any) => void;
  showDeleteConfirmationDialog?: boolean;
  showEditButton?: boolean;
  onTabEdit?: (newValue: string, option: any) => void;
  onTabDelete?: (option: any) => void;
}

const DividerTabs = ({
  options,
  onTabChange,
  initialIndex = 0,
  labelKey = "name",
  showAddButton = true,
  onAddButtonClick,
  deleteConfirmationMessage,
  showDeleteButton = true,
  onDeleteButtonClick,
  showDeleteConfirmationDialog = true,
  showEditButton = false,
  onTabEdit,
  onTabDelete
}: DividerTabsProps) => {
  const [value, setValue] = React.useState(initialIndex);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [interpolatedDeleteConfirmationMessage, setInterpolatedDeleteConfirmationMessage] = useState(null as any);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [editOption, setEditOption] = useState<any>(null);
  const [currentOption, setCurrentOption] = useState(null);
  const handleTabChange = (index: number) => {
    setValue(index);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  const handleEditClick = (option: any) => {
    setEditValue(option[labelKey]); // Obtener el valor actual del tab
    setEditOption(option); // Guardar la opción actual para la edición
    setDialogIsOpen(true); // Abrir el diálogo
  };

  const handleEditConfirm = () => {
    if (onTabEdit && editOption) {
      onTabEdit(editValue, editOption); // Llamar a la función de callback con el nuevo valor y la opción
    }
    setDialogIsOpen(false); // Cerrar el diálogo después de confirmar
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value); // Actualizar el valor del input en el diálogo
  };

  useEffect(() => {
    if (onTabChange) {
      onTabChange(value);
    }
  }, [value]);
  useEffect(() => {
    if (options?.length > 0) setValue(0);
  }, [options]);
  return (
    <div className="divider-tabs-container" style={{ paddingLeft: showAddButton ? 40 : 0 }}>
      {showAddButton && (
        <div style={{ width: 40, height: 40, display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: -8, left: 0 }}>
          <IconButton
            aria-label="add"
            onClick={onAddButtonClick}
            className="add-btn"
            size="small"
            style={{ borderRadius: 10, color: "#fff" }}
          >
            <Icon name="add" />
          </IconButton>
        </div>
      )}
      <Box className="divider-tabs" sx={{ width: "100%", boxSizing: "border-box" }}>
        <Tabs
          component="a"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {options.map((option: any, index: number) => (
            <Tab
              key={index}
              label={
                <div>
                  <svg
                    height="20"
                    width="20"
                    style={{
                      fill: selectedTabIndex === index && option.color ? option.color : null,
                    }}
                  >
                    <path d="M 20,20 20,0 c 0,0.6925 -0.03481,1.3766 -0.10286,2.0506 -0.06803,0.6739 -0.169363,1.3379 -0.30214,1.99 -0.132776,0.6522 -0.297106,1.2924 -0.491325,1.9195 -0.194206,0.627 -0.418287,1.2406 -0.670634,1.8392 -0.252348,0.5986 -0.53292,1.1821 -0.840094,1.7489 -0.307174,0.5669 -0.640977,1.1172 -0.99969,1.6491 -0.358712,0.5318 -0.742226,1.0452 -1.149163,1.5387 -0.406951,0.4935 -0.837174,0.9672 -1.289061,1.4191 -0.451873,0.4518 -0.925301,0.882 -1.418795,1.289 -0.493508,0.4069 -1.007138,0.7904 -1.53898,1.1492 -0.531842,0.3587 -1.081882,0.6925 -1.648742,0.9997 -0.566874,0.3071 -1.15061,0.5877 -1.749201,0.84 -0.598592,0.2524 -1.21223,0.4765 -1.839251,0.6707 C 5.333042,19.2979 4.692761,19.4622 4.040612,19.595 3.388462,19.7278 2.724526,19.8291 2.05055,19.8972 1.376574,19.9652 0.692502,20 0,20 l 20,0 z" />
                  </svg>
                  <div>
                    <span>{option[labelKey]}</span>
                    {showEditButton && (
                      <div onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        handleEditClick(option)
                      }}>
                        <Icon name="pencil" />
                      </div>
                    )}
                    {showDeleteButton && (
                      <div onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        setCurrentOption(option)
                        if (showDeleteConfirmationDialog) {
                          setInterpolatedDeleteConfirmationMessage(interpolateString(option, deleteConfirmationMessage));
                          setConfirmDialogIsOpen(true);

                        } else {
                          if (onDeleteButtonClick) onDeleteButtonClick(option);
                          if (onTabDelete) onTabDelete(option);
                        }

                      }}>
                        <Icon name="close" />
                      </div>
                    )}
                  </div>
                  <svg
                    height="20"
                    width="20"
                    style={{
                      fill: selectedTabIndex === index && option.color ? option.color : null,
                    }}
                  >
                    <path d="M 0,20 0,0 c 0,0.6925 0.03481,1.3766 0.10286,2.0506 0.06803,0.6739 0.169363,1.3379 0.30214,1.99 0.132776,0.6522 0.297106,1.2924 0.491325,1.9195 0.194206,0.627 0.418287,1.2406 0.670634,1.8392 0.252348,0.5986 0.53292,1.1821 0.840094,1.7489 0.307174,0.5669 0.640977,1.1172 0.99969,1.6491 0.358712,0.5318 0.742226,1.0452 1.149163,1.5387 0.406951,0.4935 0.837174,0.9672 1.289061,1.4191 0.451873,0.4518 0.925301,0.882 1.418795,1.289 0.493508,0.4069 1.007138,0.7904 1.53898,1.1492 0.531842,0.3587 1.081882,0.6925 1.648742,0.9997 0.566874,0.3071 1.15061,0.5877 1.749201,0.84 0.598592,0.2524 1.21223,0.4765 1.839251,0.6707 0.627022,0.1942 1.267303,0.3585 1.919452,0.4913 0.65215,0.1328 1.316086,0.2341 1.990062,0.3022 C 18.623426,19.9652 19.307498,20 20,20 L 0,20 Z" />
                  </svg>
                </div>
              }
              style={{
                backgroundColor: selectedTabIndex === index && option.color ? option.color : null,
                zIndex: selectedTabIndex === index ? options.length : options.length - index,
                paddingTop: showDeleteButton ? 6 : 12,
                paddingBottom: showDeleteButton ? 6 : 12,
              }}
              onClick={() => handleTabChange(index)}
            />
          ))}
        </Tabs>
      </Box>
      <DeleteConfirmationDialog
        isOpen={confirmDialogIsOpen}
        onClose={() => {
          setConfirmDialogIsOpen(false)
          setCurrentOption(null);
          console.log("cerrar")
        }}
        onConfirm={() => {
          //if (onDeleteButtonClick) onDeleteButtonClick();
          if (onTabDelete) onTabDelete(currentOption);
        }}
        confirmationMessage={interpolatedDeleteConfirmationMessage}
      />
      <Dialog open={dialogIsOpen} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Editar</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="value"
            type="text"
            fullWidth
            value={editValue}
            onChange={handleEditChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditConfirm} color="primary">
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DividerTabs;

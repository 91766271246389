import { useState, useEffect, useRef } from "react";
import { Spinner } from "components/Spinner/Spinner";
import LoadingButton from "@mui/lab/LoadingButton";
import "./DynamicTable.scss";
import { DynamicTableRow } from "./DynamicTableRow";
import { useDebounce } from 'hooks/useDebounce';
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from 'config/urls';
import { formatFilters } from 'utils/formatFilters';
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Icon from "components/Icon/Icon";
import interpolateString from "utils/interpolateString";
const DynamicTable = ({
  searchTerm,
  listPath,
  activeFilters = [],
  hiddenActiveFilters = [],
  activeTabFilters = [],
  cols = [],
  forceRefresh = false,
  onRefreshComplete,
  rowActions,
  moreRowActions,
  formFields = [],
  formTitle = [],
  onSelectRowAction,
  onSelectMoreRowAction,
  onDeleteRow,
  onDeleteRowSuccess,
}: any) => {
  const [offset, setOffset] = useState<number>(0);
  const limit = 15;
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [records, setRecords] = useState([] as any);
  const [hasMore, setHasMore] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null as any);
  const [deleteConfirmationDialogIsOpen, setDeleteConfirmationDialogIsOpen] = useState(false);
  const [interpolatedDeletePath, setInterpolatedDeletePath] = useState(null as any);
  const [interpolatedDeleteConfirmationMessage, setInterpolatedDeleteConfirmationMessage] = useState(null as any);
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const prevActiveFiltersRef = useRef(activeFilters);
  const prevHiddenActiveFiltersRef = useRef(hiddenActiveFilters);
  const prevActiveTabFiltersRef = useRef(activeTabFilters);
  const prevOrderByRef = useRef(orderBy);
  const prevOrderDirectionRef = useRef(orderDirection);
  const prevDebouncedValueRef = useRef(debouncedValue);
  const loadRecords = async (reset: boolean = false) => {
    if (reset) {
      setLoading(true);
      setOffset(0);
    } else {
      setLoadingMore(true);
    }

    const combinedFilters = [...activeFilters, ...hiddenActiveFilters, ...activeTabFilters];

    try {
      const hasQueryParams = listPath?.includes('?');
      const prefix = hasQueryParams ? '&' : '?';
      let url = `${urls.server
        }/api/${listPath}${prefix}offset=${reset ? 0 : offset}&limit=${limit}&order_by=${orderBy}&order_direction=${orderDirection}&search_term=${searchTerm}&filters=${encodeURIComponent(
          JSON.stringify(combinedFilters)
        )}`
      const response = await AxiosInterceptor.get(
        url
      );
      const data = response.data;
      if (reset) {
        setRecords(data);
        setOffset(data.length);
        //setOffset(limit);
        //if (onRefreshComplete) onRefreshComplete()
      } else {
        setRecords((prevRecords: any) => [...prevRecords, ...data]);

        setOffset((prevOffset: number) => {
          if (data.length == 0) return prevOffset;
          return prevOffset + limit
        });
      }

    } catch (error) {
      console.error("Error fetching files", error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
      if (onRefreshComplete) onRefreshComplete()
    }
  };

  useEffect(() => {
    const prevActiveFilters = prevActiveFiltersRef.current;
    const prevHiddenActiveFilters = prevHiddenActiveFiltersRef.current;
    const prevActiveTabFilters = prevActiveTabFiltersRef.current;
    const prevOrderBy = prevOrderByRef.current;
    const prevOrderDirection = prevOrderDirectionRef.current;
    const prevDebouncedValue = prevDebouncedValueRef.current;

    if (
      JSON.stringify(prevActiveFilters) !== JSON.stringify(activeFilters) ||
      JSON.stringify(prevHiddenActiveFilters) !== JSON.stringify(hiddenActiveFilters) ||
      JSON.stringify(prevActiveTabFilters) !== JSON.stringify(activeTabFilters) ||
      prevOrderBy !== orderBy ||
      prevOrderDirection !== orderDirection ||
      prevDebouncedValue !== debouncedValue
    ) {
      prevActiveFiltersRef.current = activeFilters;
      prevActiveTabFiltersRef.current = activeTabFilters;
      prevHiddenActiveFiltersRef.current = hiddenActiveFilters;
      prevOrderByRef.current = orderBy;
      prevOrderDirectionRef.current = orderDirection;
      prevDebouncedValueRef.current = debouncedValue;

      const delayDebounceFn = setTimeout(() => {
        loadRecords(true);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    debouncedValue,
    activeFilters,
    hiddenActiveFilters,
    activeTabFilters,
    orderBy,
    orderDirection,
  ]);

  useEffect(() => {
    if (forceRefresh) {
      loadRecords(true);
    }
  }, [forceRefresh]);

  useEffect(() => {
    return () => {
      console.log("cierro dynamictable");
    };
  }, []);

  const order = (col: any) => {
    if (col.ordeable != false && col.ordeable != null) {
      const orderBy = col.type === 'displayId' ? 'id' : col.name;
      setOrderDirection((prev: string) => {
        return prev == "asc" ? "desc" : "asc";
      });
      setOrderBy(orderBy);
      // setHasMore(true);
      // setOffset(0);
      // loadRecords(0);
    }
  };


  if (loading)
    return (
      <div className="spinner-wrapper">
        <Spinner visible={loading} />
      </div>
    );
  return (
    <div className="dynamic-table-container">
      {!loading && records.length === 0 && (
        <div className="empty-container">
          <Icon name="documents" />
          <p>No hay registros</p>
        </div>
      )}

      {!loading && records.length > 0 && cols.length > 0 && (
        <>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  {cols.map((col: any, index: number) => (
                    <th key={index}>
                      <a
                        href="#/"
                        onClick={() => order(col)}
                        style={{
                          display: "block",
                          textAlign:
                            col.type == "money" || col.type == "decimal"
                              ? "right"
                              : "left",
                        }}
                      >
                        {orderDirection == "asc" && (orderBy == col.name || (col.type == 'displayId' && orderBy == 'id')) && (
                          <Icon name="upArrow" />
                        )}
                        {/* {orderDirection == "asc" && orderBy == col.name  || (col.type == 'displayId' && orderBy == 'id') && (
                          <Icon name="upArrow" />
                        )} */}
                        {orderDirection == "desc" && (orderBy == col.name || (col.type == 'displayId' && orderBy == 'id')) && (
                          <Icon name="downArrow" />
                        )}
                        {col.type == 'displayId' ? 'ID' : col.displayName}
                      </a>
                    </th>
                  ))}

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records.map((item: any, index: number) => (
                  <DynamicTableRow
                    key={index}
                    data={item}
                    cols={cols}
                    actions={rowActions}
                    moreActions={moreRowActions}
                    id={index}
                    //tableName={tableName}
                    //recordId={item.id}
                    formTitle={formTitle}
                    formFields={formFields}
                    //resetState={resetState}
                    onSelectRowAction={onSelectRowAction}
                    onDeleteRecord={() => {
                      //resetState()
                    }}
                    onEditRecord={() => {
                      //resetState()
                    }}
                    //deletePath={deletePath}
                    onSelectMoreAction={(res: any) => {
                      console.log(res)
                      if (onSelectMoreRowAction) {
                        onSelectMoreRowAction({
                          actionName: res?.actionName,
                          actionDisplayName: res?.actionDisplayName,
                          currentRow: res?.currentRow,
                          config: res?.config
                        });
                      }
                      if (res?.actionName == 'delete') {
                        if (onDeleteRow) onDeleteRow(item, index);
                        // if (deletePath) {
                        //   setSelectedRow(res?.currentRow);
                        //   setDeleteConfirmationDialogIsOpen(true);
                        // }
                        if (res?.config?.deleteConfirmationMessage) {
                          setInterpolatedDeleteConfirmationMessage(interpolateString(res?.currentRow, res?.config?.deleteConfirmationMessage));
                        }
                        if (res?.config?.deletePath) {
                          setSelectedRow(res?.currentRow);
                          setInterpolatedDeletePath(interpolateString(res?.currentRow, res?.config?.deletePath))
                          setDeleteConfirmationDialogIsOpen(true);
                        }

                      }
                    }}
                  />
                ))}
              </tbody>
            </table>

            {limit && hasMore && (
              <div className="loading-button-wrapper">
                <LoadingButton
                  onClick={() => loadRecords(false)}
                  loading={loadingMore}
                  loadingPosition="start"
                  startIcon={<Icon name="plus" />}
                >
                  Más registros
                </LoadingButton>
              </div>
            )}
          </div>
          <DeleteConfirmationDialog
            isOpen={deleteConfirmationDialogIsOpen}
            onClose={() => setDeleteConfirmationDialogIsOpen(false)}
            onDeleteSuccess={() => {
              if (onDeleteRowSuccess) onDeleteRowSuccess(selectedRow);

              loadRecords(true);

            }}
            data={selectedRow}
            deletePath={interpolatedDeletePath}
            confirmationMessage={interpolatedDeleteConfirmationMessage}
          />
        </>
      )}
    </div>
  );
};

export { DynamicTable };
import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";
import "./TasksCalendarModal.scss";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import { DueDateTabs } from "components/tabs/DueDateTabs/DueDateTabs";
import { UserTabs } from "components/tabs/UserTabs/UserTabs";
import Modal from "components/Modal/Modal";
import configurationService from "services/configurationService";
import DynamicList from "components/DynamicList/DynamicList";
import Icon from "components/Icon/Icon";

interface ReportModalProps {
  mode?: 'all' | 'user';
  isOpen: boolean;
  onClose: (refreshOnClose?: boolean) => void;
}
const TasksCalendarModal: React.FC<ReportModalProps> = ({ isOpen, onClose, mode }) => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [refreshTabs, setRefreshTabs] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userIds, setUserIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState([]);

  const currentDate: any = new Date().toJSON().slice(0, 10);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [editFormModalIsOpen, setEditFormModalIsOpen] = useState(false);
  const [createFormModalIsOpen, setCreateFormModalIsOpen] = useState(false);

  const [fields, setFields] = React.useState([] as any);
  const [leftCells, setLeftCells] = React.useState([] as any);
  const [rightCells, setRightCells] = React.useState([] as any);
  const [moreItemActions, setMoreItemActions] = React.useState([] as any);
  const [selectedItemId, setSelectedItemId] = React.useState(null as any);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [shouldUserTabsRefresh, setShouldUserTabsRefresh] = useState(true);
  const [shouldDueDateTabsRefresh, setShouldDueDateTabsRefresh] = useState(true);
  const [hiddenActiveFilters, setHiddenActiveFilters] = useState([
    ["status", "!=", "done"]
  ] as any);

  const handleRefreshComplete = () => {
    setShouldRefresh(false);
    // setHiddenActiveFilters([
    //   ["status", "!=", "done"]
    // ]);
  };
  useEffect(() => {
    setLoadingConfig(true);
    const configurationNames = [
      "taskLeftCells",
      "taskRightCells",
      "taskFields",
      "taskMoreItemActions",
      "taskMoreHeaderActions",
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {

        setLoadingConfig(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case "taskLeftCells":
              setLeftCells(configuration.json_schema);
              break;
            case "taskRightCells":

              setRightCells(configuration.json_schema);
              break;
            case "taskFields":
              setFields(configuration.json_schema);
              break;
            case "taskMoreItemActions":
              setMoreItemActions(configuration.json_schema);
              break;

            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        setLoadingConfig(false);
        console.log(error);
      });
  }, []);
  const handleChangeItem = (val: any, item: any, index: number) => {
    setShouldRefresh(true);
    setShouldUserTabsRefresh(true)
    setShouldDueDateTabsRefresh(true)
    console.log("refresh")
  }
  useEffect(() => {
    if (mode === "all") {
      // Llamar al endpoint que trae todas las tareas
    } else if (mode === "user") {
      // Llamar al endpoint que trae solo las tareas del usuario
    }
  }, [mode]);
  useEffect(() => {
    setHiddenActiveFilters([["status", "!=", "done"]]);
  }, []);
  useEffect(() => {
    if (formModalIsOpen) setRefreshData(false);
  }, [formModalIsOpen]);
  useEffect(() => {
    if (refreshData) {
      setRefreshTabs(true);
      setRefreshOnClose(true);
    }
  }, [refreshData]);
  useEffect(() => {
    if (isOpen) {
      setRefreshTabs(true);
    } else {
      setRefreshTabs(false);
      setHiddenActiveFilters([["status", "!=", "done"]]);
    }
  }, [isOpen]);




  useEffect(() => {
    // Incluimos el filtro de fecha actual junto con el nuevo filtro de usuario
    const userFilter = selectedUserId ? [["users", [selectedUserId]]] : [];
    updateFilters([...dateFilter, ...userFilter]);
  }, [selectedUserId, dateFilter]);

  const updateFilters = (newFilters: any) => {
    setHiddenActiveFilters((prev: any) => {
      // Filtramos para mantener solo los filtros que no son de 'users' ni 'due_date'.
      const filteredPrev = prev.filter(
        (filter: any) => filter[0] !== "users" && filter[0] !== "due_date"
      );
      return [...filteredPrev, ...newFilters];
    });
  };


  const handleDateFilterChange = (val: any) => {
    setShouldRefresh(true);
    let newDateFilter: any = [];
    if (val?.label === "Todas") {
      newDateFilter = [];
    } else if (val?.label === "Venc.") {
      newDateFilter = [["due_date", "<", currentDate]];
    } else if (val?.label === "S/V") {
      newDateFilter = [["due_date", null]];
    } else if (val?.date) {
      newDateFilter = [["due_date", val?.date]];
    }
    // Actualizamos el estado del filtro de fecha
    setDateFilter(newDateFilter);
    // Actualizamos el filtro global, incluyendo el filtro de usuario si existe
    const userFilter = selectedUserId ? [["users", [selectedUserId]]] : [];
    updateFilters([...newDateFilter, ...userFilter]);
  };
  useEffect(() => {
    const userIds = selectedUserId ? [selectedUserId] : []
    setUserIds(userIds);
  }, [selectedUserId]);
  return (

    <Modal
      title={"Calendario de tareas"}
      className={'tasks-calendar-modal-container'}
      isOpen={isOpen}
      onClose={onClose}
      hasSearchBar={true}
      //filters={filters}
      onSearchChange={(searchTerm: string) => {
        setSearchTerm(searchTerm);
      }}
    // onActiveFiltersChange={(filters: any) => {
    //   setHiddenActiveFilters(filters);
    // }}

    >

      {mode == "all" && (
        <UserTabs
          listPath={`tasks/user-tabs?filters=${encodeURIComponent(JSON.stringify(hiddenActiveFilters))}`}
          onChange={(val: any) => {
            setSelectedUserId(val?.id);
            setShouldRefresh(true);
          }}
          forceRefresh={shouldUserTabsRefresh}
          onRefreshComplete={() => {
            setShouldUserTabsRefresh(false)
          }}
        />
      )}

      <DueDateTabs
        listPath={`tasks/due-date-tabs?user_ids=${encodeURIComponent(JSON.stringify(userIds))}`}
        onSelectItem={handleDateFilterChange}
        forceRefresh={shouldDueDateTabsRefresh}
        onRefreshComplete={() => {
          setShouldDueDateTabsRefresh(false)
        }}
      />

      <DynamicList
        leftCells={leftCells}
        rightCells={rightCells}
        limit={10}
        listPath={mode == 'all' ? 'tasks' : 'my/tasks'}
        noContentText="No hay tareas cargadas"
        noContentIcon="tasks"
        moreItemActions={moreItemActions}
        onSelectItemAction={(res: any) => {
          console.log(res);
          if (res.actionName == 'view') {
            setSelectedItemId(res.currentItem?.id);
            setEditFormModalIsOpen(true);
          }
        }}
        forceRefresh={shouldRefresh}
        onRefreshComplete={handleRefreshComplete}
        activeFilters={[]}
        hiddenActiveFilters={hiddenActiveFilters}
        searchTerm={searchTerm}
        onChangeItem={handleChangeItem}
      />
      <Fab
        className="fab-btn"
        color="primary"
        aria-label="add"
        onClick={() => {
          //setFormModalIsOpen(true);
          setCreateFormModalIsOpen(true)
        }}
      >
        <Icon name="add" />
      </Fab>

      <FormModal
        isOpen={createFormModalIsOpen}
        onClose={(res: any) => {
          setCreateFormModalIsOpen(false);
          if (res) {
            //setForceRefresh
            setShouldRefresh(true);
          }
        }}

        title="Form. de tarea"
        fields={fields}
        createPath={mode == 'all' ? 'tasks' : 'my/tasks'}
        mode="create"
      />
      <FormModal
        isOpen={editFormModalIsOpen}
        onClose={(res: any) => {
          setEditFormModalIsOpen(false)
          if (res) {
            setShouldRefresh(true);
          }
        }}

        title="Editar tarea"
        fields={fields}
        mode="edit"

      />
    </Modal>
  );
};

export { TasksCalendarModal };

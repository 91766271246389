import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material';
import './Header.scss';
import SearchBar from 'components/SearchBar/SearchBar';
import { FiltersDrawer } from 'components/drawers/FiltersDrawer/FiltersDrawer';
import { ActiveFilters } from 'components/ActiveFilters/ActiveFilters';
import { MoreActionsButton } from 'components/buttons/MoreActionsButton/MoreActionsButton';
import DoneIcon from "@mui/icons-material/Done";
import Icon from 'components/Icon/Icon';
interface HeaderProps {
    title: string;
    subtitle?: string;
    hasSearchBar?: boolean;
    hasCloseBtn?: boolean;
    hasDoneBtn?: boolean;
    filters?: Array<[]>;
    moreActions?: Array<[]>;
    actions?: Array<[]>;
    searchPlaceholder?: string;
    canGoBack?: boolean;
    modalIsOpen?: boolean;
    onDoneBtnPress?: () => void;
    onCloseBtnPress?: () => void;
    onSearchChange?: (text: string) => void;
    onActiveFiltersChange?: (activeFilters: Array<any>) => void;
    onSelectMoreAction?: (response: any) => void;
}
const Header = ({
    title,
    subtitle = "",
    hasSearchBar,
    hasCloseBtn,
    hasDoneBtn,
    filters = [],
    moreActions = [],
    actions = [],
    //canGoBack = true,
    searchPlaceholder,
    onDoneBtnPress,
    onCloseBtnPress,
    onSearchChange,
    onActiveFiltersChange,
    onSelectMoreAction

}: HeaderProps) => {
    const [searchBarVisible, setSearchBarVisible] = useState(false);
    const [filtersDrawerIsOpen, setFiltersDrawerIsOpen] = useState(false);
    const [filtersCopy, setFiltersCopy] = useState(
        JSON.parse(JSON.stringify(filters))
    );
    const [hasActiveFilters, setHasActiveFilters] = useState(false);
    // const handleBackPress = () => {

    // };

    const handleFiltersBtnPress = () => {
        setFiltersDrawerIsOpen(true);
    };

    // const handleMoreActionsBtnPress = () => {

    // };

    const toggleSearchBar = () => {
        setSearchBarVisible(!searchBarVisible);
    };

    const handleRemoveFilter = (index: number, updatedFilter: any) => {
        const updatedFilters = [...filtersCopy];
        updatedFilters[index] = updatedFilter;
        setFiltersCopy(updatedFilters);
    };

    useEffect(() => {
        // Comprobar si hay algún filtro activo
        const isActive = filtersCopy.some((filter: any) => {
            return filter.selected;
        });
        setHasActiveFilters(isActive);
        if (onActiveFiltersChange) onActiveFiltersChange(filtersCopy);
        return () => {

        };
    }, [filtersCopy]);

    useEffect(() => {
        if (filters && filters.length > 0) {

            setFiltersCopy(JSON.parse(JSON.stringify(filters)))
        }
    }, [filters])
    return (
        <>
            <div className="header-container">
                <div className="header-title">
                    <h2>{title}</h2>
                    {/* <span>{subtitle}</span> */}
                    <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                </div>
                {
                    hasSearchBar &&
                    <div className='search-bar-wrapper'>
                        <SearchBar
                            placeholder={searchPlaceholder}
                            onChange={(searchTerm) => {
                                if (onSearchChange) onSearchChange(searchTerm)
                            }}
                        />
                    </div>
                }
                <div className="header-end-btns">
                    {
                        filters.length > 0 && <IconButton
                            color="inherit"
                            onClick={handleFiltersBtnPress}
                            aria-label="filters"
                        >
                            <Icon name="filters" />
                        </IconButton>
                    }
                    {
                        moreActions.length > 0 && <MoreActionsButton
                            id="header-more-actions-button"
                            options={moreActions}
                            onSelectItem={(res: any) => {
                                if (onSelectMoreAction) {
                                    onSelectMoreAction({
                                        actionName: res?.actionName,
                                        actionDisplayName: res?.actionDisplayName,
                                        config: res?.config
                                    });
                                }
                            }}
                        />
                    }
                    {
                        hasDoneBtn && <IconButton
                            color="inherit"
                            onClick={onDoneBtnPress}
                            aria-label="done"
                        >
                            <DoneIcon />
                        </IconButton>
                    }
                    {
                        hasCloseBtn && <IconButton
                            color="inherit"
                            onClick={onCloseBtnPress}
                            aria-label="close"
                        >
                            <Icon name="close" />
                        </IconButton>
                    }

                </div>
            </div>
            {hasActiveFilters && (
                <div className='header-active-filters-wrapper'>
                    <ActiveFilters
                        filters={filtersCopy}
                        onRemoveFilter={handleRemoveFilter}
                    />
                </div>

            )}
            <FiltersDrawer
                isOpen={filtersDrawerIsOpen}
                onClose={(res: any) => {
                    setFiltersDrawerIsOpen(false);
                    if (res) {
                        setFiltersCopy(res);
                    }
                }}
                //filters={filters}
                filters={filtersCopy}
            />
        </>


    )
}

export { Header }
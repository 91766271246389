import React, { useState, useEffect } from 'react';
import './ConfigurationItemEditor.scss';
import Icon from 'components/Icon/Icon';
import colors from 'config/colors';
import IconButtonGroup from 'components/IconButtonGroup/IconButtonGroup';
import { ColorField } from 'components/fields/ColorField/ColorField';
import { IconButton } from '@mui/material';
import SegmentTabs from 'components/tabs/SegmentTabs/SegmentTabs';
import SizeField from '../SizeField/SizeField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import { ImportConfigDialog } from 'components/dialogs/ImportConfigDialog/ImportConfigDialog';
import { ExportConfigDialog } from 'components/dialogs/ExportConfigDialog/ExportConfigDialog';
import { v4 as uuidv4 } from 'uuid';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteConfirmationDialog from 'components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import interpolateString from "utils/interpolateString";
import { ImageField } from 'components/fields/ImageField/ImageField';
import { VideoField } from 'components/fields/VideoField/VideoField';
import { RadioField } from 'components/fields/RadioField/RadioField';
import LottieConfiguration from './configurations/LottieConfiguration';
import HeaderConfiguration from './configurations/HeaderConfiguration';
import T1Configuration from './configurations/T1Configuration';
import T2Configuration from './configurations/T2Configuration';
import T3Configuration from './configurations/T3Configuration';
import TextConfiguration from './configurations/TextConfiguration';
import ParagraphConfiguration from './configurations/ParagraphConfiguration';
import ImageConfiguration from './configurations/ImageConfiguration';
import VideoConfiguration from './configurations/VideoConfiguration';
import ProfileButtonConfiguration from './configurations/ProfileButtonConfiguration';
import NavConfiguration from './configurations/NavConfiguration';
import BlockConfiguration from './configurations/BlockConfiguration';
import LinkConfiguration from './configurations/LinkConfiguration';
import GridConfiguration from './configurations/GridConfiguration';
import DynamicListConfiguration from './configurations/DynamicListConfiguration';
import CodeConfiguration from './configurations/CodeConfiguration';

interface ConfigurationItemEditorProps {
    config: any;
    isVisible: boolean;
    onHide: () => void;
    onChange: (id: string, changes: any) => void;
    onMoveUp: (id: string) => void;
    onMoveDown: (id: string) => void;
    onDelete: (id: string) => void;
    hasSiblings: boolean;
}

const ConfigurationItemEditor: React.FC<ConfigurationItemEditorProps> = ({
    config = {},
    isVisible = false,
    onHide,
    onChange,
    onMoveUp,
    onMoveDown,
    onDelete,
    hasSiblings
}) => {
    const [selectedTab, setSelectedTab] = useState<string>('default');
    const [localConfig, setLocalConfig] = useState<any>(config);
    const [importConfigDialogIsOpen, setImportConfigDialogIsOpen] = useState<boolean>(false);
    const [exportConfigDialogIsOpen, setExportConfigDialogIsOpen] = useState<boolean>(false);
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
    const deleteConfirmationMessage = `Desea eliminar el componente <b>{{id}}</b>?`
    const [interpolatedDeleteConfirmationMessage, setInterpolatedDeleteConfirmationMessage] = useState(null as any);
    //const interpolatedDeleteConfirmationMessage = interpolateString(localConfig, deleteConfirmationMessage);

    useEffect(() => {
        setLocalConfig(config);
        if (config) {
            setInterpolatedDeleteConfirmationMessage(interpolateString(config, deleteConfirmationMessage))
        }
    }, [config]);

    useEffect(() => {
        if (!isVisible) setSelectedTab('default');
    }, [isVisible]);


    const handleChange = (field: string, value: any) => {
        const updateNestedConfig = (obj: any, path: string[], value: any, isResponsive: boolean = false) => {
            const lastKey = path.pop() as string;
            const lastObj = path.reduce((acc, key) => acc[key] = acc[key] || {}, obj);

            if (isResponsive) {
                if (!lastObj.responsive) {
                    lastObj.responsive = {};
                }
                if (!lastObj.responsive[selectedTab]) {
                    lastObj.responsive[selectedTab] = {};
                }
                lastObj.responsive[selectedTab][lastKey] = value;
            } else {
                lastObj[lastKey] = value;
            }
        };

        const path = field.split('.');
        const updatedConfig = { ...localConfig };

        if (selectedTab === 'default') {
            updateNestedConfig(updatedConfig, path, value);
        } else {
            updateNestedConfig(updatedConfig, path, value, true);
        }

        setLocalConfig(updatedConfig);
        onChange(localConfig.id, updatedConfig);
    };

    const getFieldValues = (field: string) => {
        const getNestedProperty = (obj: any, path: string[]) => {
            return path.reduce((acc, part) => acc && acc[part], obj);
        };

        const path = field.split('.');

        // Obtén los valores por defecto y los valores responsivos utilizando el path
        const defaultValues = getNestedProperty(localConfig, path) || { value: null, unit: 'px' };
        const responsiveValues = getNestedProperty(localConfig?.responsive?.[selectedTab], path) || { value: null, unit: 'px' };

        // Retorna los valores correspondientes según la tab seleccionada
        return selectedTab === 'default' ? defaultValues : responsiveValues;
    };

    const handleImportSubmit = (data: any) => {
        if (localConfig.children) {
            const newData = { ...data, id: uuidv4() };
            const updatedConfig = {
                ...localConfig,
                children: [...localConfig.children, newData],
            };
            setLocalConfig(updatedConfig);
            onChange(localConfig.id, updatedConfig);
            setImportConfigDialogIsOpen(false);
        } else {
            alert("No se puede importar. El objeto padre no tiene la llave 'children'.");
        }
    };

    return (
        <div className={`configuration-item-editor-container isVisible ${isVisible ? 'visible' : 'hidden'} `}>
            {isVisible && (
                <>

                    <a onClick={() => { onHide(); }}>
                        <Icon name="close" />
                    </a>
                    <div>
                        {
                            //JSON.stringify(localConfig)
                        }
                        {localConfig && (
                            <>
                                <h2>{localConfig?.displayType}</h2>
                                <div className="other-actions">
                                    <IconButton
                                        color="primary"
                                        onClick={() => onMoveUp(localConfig.id)}
                                        aria-label="move-up"
                                        size="large"
                                        disabled={!hasSiblings}
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={() => onMoveDown(localConfig.id)}
                                        aria-label="move-down"
                                        size="large"
                                        disabled={!hasSiblings}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                    {(localConfig.type === 'block') && (
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                setImportConfigDialogIsOpen(true);
                                            }}
                                            aria-label="import"
                                            size="large"
                                        >
                                            <Icon name="import" />
                                        </IconButton>
                                    )}

                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setExportConfigDialogIsOpen(true);
                                        }}
                                        aria-label="export"
                                        size="large"
                                    >
                                        <Icon name="export" />
                                    </IconButton>
                                    {
                                        localConfig.id !== 'main-container' && localConfig.deletable !== false && (
                                            <IconButton color="warning" onClick={() => {
                                                setConfirmDialogIsOpen(true);
                                            }} aria-label="delete" size="large">
                                                <Icon name="delete" />
                                            </IconButton>
                                        )
                                    }

                                </div>
                                {isVisible && (
                                    <div className='tabs-wrapper'>
                                        <SegmentTabs
                                            options={[
                                                { icon: 'all', name: 'default' },
                                                { icon: 'desktop', name: 'lg' },
                                                { icon: 'tablet', name: 'md' },
                                                { icon: 'mobile', name: 'xs' },
                                            ]}
                                            onTabChange={(tab: string) => setSelectedTab(tab)}
                                        />
                                    </div>
                                )}

                                <div className="main-actions">
                                    {localConfig?.type === 't1' && (
                                        <T1Configuration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 't2' && (
                                        <T2Configuration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 't3' && (
                                        <T3Configuration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'text' && (
                                        <TextConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'paragraph' && (
                                        <ParagraphConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'image' && (
                                        <ImageConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'video' && (
                                        <VideoConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'profileButton' && (
                                        <ProfileButtonConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'header' && (
                                        <HeaderConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'lottie' && (
                                        <LottieConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'nav' && (
                                        <NavConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'block' && (
                                        <BlockConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'link' && (
                                        <LinkConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'grid' && (
                                        <GridConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'dynamicList' && (
                                        <DynamicListConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                    {localConfig?.type === 'code' && (
                                        <CodeConfiguration
                                            localConfig={localConfig}
                                            selectedTab={selectedTab}
                                            handleChange={handleChange}
                                            getFieldValues={getFieldValues}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )
            }

            <ImportConfigDialog
                isOpen={importConfigDialogIsOpen}
                subtitle={`Importar dentro de ${localConfig?.id}`}
                onClose={() => {
                    setImportConfigDialogIsOpen(false)
                }}
                onSubmit={handleImportSubmit}
            />
            <ExportConfigDialog
                isOpen={exportConfigDialogIsOpen}
                subtitle={`Exportar ${localConfig?.id}`}
                data={localConfig}
                onClose={() => {
                    setExportConfigDialogIsOpen(false)
                }}
            />
            <DeleteConfirmationDialog
                isOpen={confirmDialogIsOpen}
                onClose={() => setConfirmDialogIsOpen(false)}
                onConfirm={() => {
                    onDelete(localConfig.id);
                    setConfirmDialogIsOpen(false);
                }}
                confirmationMessage={interpolatedDeleteConfirmationMessage}
            />
        </div >
    );
};

export default ConfigurationItemEditor;

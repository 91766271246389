import "./ColumnLineChart.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ColumnLineChart = (props: any) => {
    const { data, decimalPrecision = 2 } = props;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    function adaptColumnLineData(indicatorData: any) {
        // Creamos un mapa para rastrear los datos para cada etiqueta
        const datasetsMap = new Map();

        // Extraemos las etiquetas de los períodos
        const labels = indicatorData.map((item: any) => item.display_period);

        // Llenamos el mapa con los datos para cada etiqueta
        indicatorData.forEach((period: any) => {
            period.data.forEach((item: any) => {
                if (!datasetsMap.has(item.label)) {
                    const isLine = item.chart_type === "line";
                    datasetsMap.set(item.label, {
                        label: item.label,
                        data: new Array(indicatorData.length).fill(0),
                        borderColor: isLine ? item.color : undefined,
                        backgroundColor: item.color,
                        type: isLine ? "line" : "bar",
                        borderWidth: isLine ? 2 : undefined,
                        hidden: !item.is_visible,
                    });
                }
                const dataset = datasetsMap.get(item.label);
                const periodIndex = labels.indexOf(period.display_period);
                dataset.data[periodIndex] = item.value.toFixed(decimalPrecision);
            });
        });

        // Convertimos el mapa en un array para los datasets
        const datasets = Array.from(datasetsMap.values());

        return {
            labels: labels,
            datasets: datasets,
        };
    }

    const chartData = adaptColumnLineData(data);

    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <div className="column-line-chart-container">
            <div className="chart-wrapper">
                <Bar options={options} data={chartData} />
            </div>
        </div>
    );
};

export { ColumnLineChart };

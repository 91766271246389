import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Header } from 'components/Header/Header';

import './Modal.scss';

interface ModalProps {
    title?: string;
    subtitle?: string;
    isOpen: boolean;
    children: React.ReactNode;
    className?: string;
    hasSearchBar?: boolean;
    hasDoneBtn?: boolean;
    filters?: Array<[]>;
    style?: any;
    headerActions?: Array<any>;
    moreHeaderActions?: Array<any>;
    hideHeader?: boolean;
    onDone?: () => void;
    onClose: () => void;
    onSearchChange?: (text: string) => void;
    onActiveFiltersChange?: (activeFilters: Array<any>) => void;
    onSelectHeaderAction?: (action: any) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modal: React.FC<ModalProps> = ({
    isOpen,
    children,
    title = "",
    subtitle,
    className,
    hasSearchBar,
    hasDoneBtn,
    filters,
    style = {},
    headerActions = [],
    moreHeaderActions = [],
    hideHeader = false,
    onDone,
    onClose,
    onSearchChange,
    onActiveFiltersChange,
    onSelectHeaderAction
}) => {

    return (
        <>
            <Dialog
                fullScreen
                open={isOpen}
                onClose={onClose}
                TransitionComponent={Transition}
                className={`modal-container ${className}`}
                PaperProps={{
                    //style: modalStyle,
                    style: {
                        backgroundColor: "#f2f2f4", ...style
                    }
                }}
            >
                {!hideHeader &&
                    <Header
                        title={title}
                        subtitle={subtitle}
                        hasCloseBtn={true}
                        onCloseBtnPress={onClose}
                        onDoneBtnPress={onDone}
                        hasSearchBar={hasSearchBar}
                        hasDoneBtn={hasDoneBtn}
                        actions={headerActions}
                        moreActions={moreHeaderActions}
                        onSearchChange={onSearchChange}
                        filters={filters}
                        onActiveFiltersChange={onActiveFiltersChange}
                        onSelectMoreAction={onSelectHeaderAction}

                    />
                }
                <div className='modal-body'>{children}</div>

            </Dialog>

        </>

    );
};

export default Modal;

import React, { useState, useEffect } from "react";
import "./MultiCheckboxFilter.scss";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const MultiCheckboxFilter = ({ label, options, initialValue, onChange }: any) => {
    const [selectedOptions, setSelectedOptions] = useState(initialValue || []);

    useEffect(() => {
        const isActive = selectedOptions.length > 0;

        if (onChange) {
            onChange({
                operator: "in",
                value: selectedOptions,
                active: isActive
            });
        }
    }, [selectedOptions]);

    const handleOptionChange = (optionName: string) => {
        setSelectedOptions((prevSelected: any) => {
            const newSelected = prevSelected.includes(optionName)
                ? prevSelected.filter((name: string) => name !== optionName)
                : [...prevSelected, optionName];
            return newSelected;
        });
    };

    return (
        <div className="multi-checkbox-filter-container">
            <label className="filter-label">{label}</label>
            <div className="checkbox-wrapper">
                {options.map((option: any, index: number) => (
                    <div key={index} className="checkbox-item" onClick={() => handleOptionChange(option.name)}>
                        {selectedOptions.includes(option.name) ? (
                            <CheckBoxIcon color="primary" />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                        <span className="checkbox-label">{option.displayName}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { MultiCheckboxFilter };

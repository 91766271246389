import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "components/Icon/Icon";
const ListCol = ({ defaultValue, onChange }: any) => {
  const [items, setItems] = useState((defaultValue as any) || ([] as any));
  const addItem = () => {
    const itemsCopy = [...items];
    itemsCopy.push("");
    setItems(itemsCopy);
  };
  const handleChangeItemInput = (index: number, value: string) => {
    const itemsCopy = [...items];
    itemsCopy[index] = value;
    setItems(itemsCopy);
  };
  const handleDeleteItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  };
  useEffect(() => {
    onChange(items);
  }, [items]);
  return (
    <div className="list-wrapper">
      <div className="items-wrapper">
        {items?.map((item: any, index: number) => {
          return (
            <div key={index}>
              <input
                type="text"
                value={item || ""}
                onChange={(e) => {
                  handleChangeItemInput(index, e.target.value);
                }}
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteItem(index)}
                color="error"
                size="small"
              >
                <Icon name="delete" />
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className="add-item-btn-wrapper">
        <Button onClick={addItem} startIcon={<Icon name="add" />}>
          Agregar
        </Button>
      </div>
    </div>
  );
};

export { ListCol };

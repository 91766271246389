import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { TextEditorFieldItemBtns } from "./TextEditorFieldItemBtns";
import { TextEditorFieldItemContent } from "./TextEditorFieldItemContent";

const TextEditorFieldItem = SortableElement<any>(({ item, i }: any) => {
  return (
    <div className="text-editor-field-item" style={{ zIndex: 99999999 }}>
      <TextEditorFieldItemBtns item={item} i={i}></TextEditorFieldItemBtns>
      <TextEditorFieldItemContent
        item={item}
        i={i}
      ></TextEditorFieldItemContent>
    </div>
  );
});

export { TextEditorFieldItem };

import React, { useCallback, useState, useEffect } from "react";
import { RadioField } from "components/fields/RadioField/RadioField";
import { LongTextField } from "components/fields/LongTextField/LongTextField";

interface ContentTreeNodeEditorProps {
    data: any;
    onChange: (data: { [key: string]: string }) => void;
}

const ContentTreeNodeEditor: React.FC<ContentTreeNodeEditorProps> = React.memo(({ data, onChange }) => {
    const [formData, setFormData] = useState(data);

    useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleFieldChange = useCallback((field: string, value: string) => {
        setFormData((prevState: any) => {
            const newState = { ...prevState, [field]: value };
            onChange(newState);
            return newState;
        });
    }, [onChange]);

    return (
        <div>
            <RadioField
                defaultValue={formData.type}
                label={"tipo"}
                description=""
                options={
                    [
                        {
                            name: "tag",
                            displayName: "Etiqueta",
                        },
                        {
                            name: "text",
                            displayName: "texto",
                        },
                    ]
                }
                onChange={(type: string) => handleFieldChange('type', type)}
            />
            {formData.type === "tag" && (
                <>
                    <LongTextField
                        defaultValue={formData.tagName}
                        label={"tagName"}
                        description=""
                        onChange={(val: string) => handleFieldChange('tagName', val)}
                    />
                    <LongTextField
                        //defaultValue={JSON.stringify(formData.attributes)}
                        defaultValue={formData.attributes}
                        label={"attributes"}
                        description=""
                        onChange={(val: string) => handleFieldChange('attributes', val)}
                    />
                    <LongTextField
                        defaultValue={formData.showIf}
                        label={"showIf"}
                        description=""
                        onChange={(val: string) => handleFieldChange('showIf', val)}
                    />
                </>
            )}
            {formData.type === "text" && (
                <>
                    <LongTextField
                        defaultValue={formData.attributes}
                        //defaultValue={JSON.stringify(formData.attributes)}
                        label={"attributes"}
                        description=""
                        onChange={(val: string) => handleFieldChange('attributes', val)}
                    />
                    <LongTextField
                        defaultValue={formData.showIf}
                        label={"showIf"}
                        description=""
                        onChange={(val: string) => handleFieldChange('showIf', val)}
                    />
                    <LongTextField
                        defaultValue={formData.content}
                        label={"content"}
                        description=""
                        onChange={(val: string) => handleFieldChange('content', val)}
                    />
                </>
            )}
        </div>
    );
});

export default ContentTreeNodeEditor;

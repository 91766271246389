import { useState, useEffect } from "react";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import "./DataListExpandedCard.scss";
import { Header } from "components/Header/Header";
import DynamicList from "components/DynamicList/DynamicList";
import colors from "config/colors";
import { Fab } from "@mui/material";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import { prepareFilterConfigurations } from "utils/prepareFilterConfigurations";
import Icon from "components/Icon/Icon";
import interpolateString from "utils/interpolateString";
import { ImportModal } from "components/modals/ImportModal/ImportModal";

const DataListExpandedCard = (props: any) => {
  const { title, config, setRefreshOnClose } = props;

  const [searchTerm, setSearchTerm] = useState('' as any);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [filters, setFilters] = useState([]);
  const [editFormModalIsOpen, setEditFormModalIsOpen] = useState(false);
  const [createFormModalIsOpen, setCreateFormModalIsOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [importPath, setImportPath] = useState(null);
  const [importerSubtitle, setImporterSubtitle] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [viewPath, setViewPath] = useState(null as any);
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };

  const handleChangeItem = (val: any, item: any, index: number) => {
    setRefreshOnClose(true);
    setShouldRefresh(true);
  }

  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }

  const handleSelectHeaderAction = (res: any) => {
    if (res.actionName === 'importSingle') {
      setImportModalIsOpen(true)
      setImportPath(res?.config?.importPath)
      setImporterSubtitle(res?.config?.importerSubtitle)
    } else if (res.actionName === 'export') {
      exportRecords(res?.config?.exportPath, res?.config?.fileName);
    }
  }

  const exportRecords = async (exportPath: string, fileName?: string) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/${exportPath}`
      );

      // Crear un Blob con el contenido JSON
      const blob = new Blob([JSON.stringify(res?.data)], {
        type: "application/json",
      });

      // Crear una URL Blob para el Blob
      const url = URL.createObjectURL(blob);

      // Crear un enlace para descargar
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fileName || 'recods'}.json`;
      link.click();

      // Liberar la URL Blob
      URL.revokeObjectURL(url);
    } catch (error) {

    }
  };

  const handleSelectItemAction = (res: any) => {
    if (res.actionName === 'export') {
      // Handle export action if necessary
    } else if (res.actionName === 'view') {
      setEditFormModalIsOpen(true);
      setSelectedItemId(res.currentItem?.id);
      setViewPath(interpolateString(res?.currentItem, res?.config?.viewPath));
    }
  };

  useEffect(() => {
    const enhancedFilters = prepareFilterConfigurations(config.filters);
    setFilters(enhancedFilters)
  }, []);

  return (
    <>
      <div className="data-list-expanded-card-container">
        <Header
          title={title}
          hasSearchBar
          filters={filters}
          onSearchChange={handleSearchChange}
          moreActions={config?.moreHeaderActions}
          onSelectMoreAction={handleSelectHeaderAction}
        />
        <DynamicList
          className="data-list-container"
          leftCells={config?.leftCells}
          rightCells={config?.rightCells}
          limit={10}
          listPath={config?.listPath}
          noContentText={config?.noContentText}
          noContentIcon={config?.noContentIcon}
          moreItemActions={config?.moreItemActions}
          isSortable={config?.isSortable}
          sortPath={config?.sortPath}
          onSelectItemAction={handleSelectItemAction}
          itemActions={config?.itemActions}
          forceRefresh={shouldRefresh}
          onRefreshComplete={handleRefreshComplete}
          searchTerm={searchTerm || ""}
          onChangeItem={handleChangeItem}
          itemStyle={{
            filter: "none",
            backgroundColor: colors.light
          }}
          hiddenActiveFilters={config?.hiddenActiveFilters || []}
          activeFilters={[]}

        />
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={() => {
            setCreateFormModalIsOpen(true);
          }}

        >
          <Icon name="add" sx={{ mr: 0 }} />
        </Fab>

        <FormModal
          isOpen={createFormModalIsOpen}
          onClose={(res: any) => {
            setCreateFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
              setRefreshOnClose(true);
            } else {
              setShouldRefresh(false)
            }
          }}
          title={config?.formTitle}
          fields={config?.fields}
          createPath={config?.createPath}
          mode="create"
        />
        <FormModal
          isOpen={editFormModalIsOpen}
          onClose={(res: any) => {
            setEditFormModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
            } else {
              setShouldRefresh(false)
            }
          }}
          title={config?.formTitle}
          fields={config?.fields}
          viewPath={viewPath}
          mode="edit"
        />
        <ImportModal
          isOpen={importModalIsOpen}
          subtitle={importerSubtitle || ''}
          importPath={importPath}
          importType={'singleRecord'}
          onClose={(res: any) => {
            setImportModalIsOpen(false)
            if (res) {
              setShouldRefresh(true)
              setRefreshOnClose(true);
            }
          }}
        />

      </div>

    </>
  );
};

export { DataListExpandedCard };

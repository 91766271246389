import React, { useState, useEffect } from "react";

import { Chart as MiniChart, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./MiniPieChart.scss";
MiniChart.register(ArcElement);
const MiniPieChart = ({ data = [], height = 60, onSelectItem }: any) => {
  const [chartData, setChartData] = useState(null as any);
  // Suponiendo que 'originalData' es tu array de datos original.
  const convertToChartData = (originalData: any) => {
    // Extrae los labels, valores y colores en arrays separados.
    const labels = originalData.map((item: any) => item.label);
    const data = originalData.map((item: any) => item.value);
    const backgroundColor = originalData.map((item: any) => item.color);

    // Crea el objeto de datos en el formato que Chart.js espera.
    const chartData = {
      labels: labels,
      datasets: [
        {
          //label: "Dataset 1", // Puedes cambiar esta etiqueta por algo más descriptivo si lo deseas
          data: data,
          backgroundColor: backgroundColor,
        },
      ],
    };

    return chartData;
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const firstElementIndex = elements[0].index;
        onSelectItem(firstElementIndex);
      }
    },
    //maintainAspectRatio: false, // Para mantener el aspecto del gráfico según la altura proporcionada
  };
  useEffect(() => {
    const sortedData = [...data].sort((a: any, b: any) => b.value - a.value);

    setChartData(convertToChartData(sortedData));
  }, []);
  return (
    <div className="mini-pie-chart-container">
      <div className="graph" style={{ height: height + "px" }}>
        {chartData && (
          <Pie
            data={chartData}
            options={options}
          // options={{
          //   plugins: {
          //     legend: {
          //       display: false,
          //     },
          //     tooltip: {
          //       enabled: false,
          //     },
          //   },
          // }}
          />
        )}
      </div>
    </div>
  );
};

export { MiniPieChart };

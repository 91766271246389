import Slider from "@mui/material/Slider";
import { useState, useEffect } from "react";
import "./SliderField.scss";
import Icon from "components/Icon/Icon";
const SliderField = (props: any) => {
  const {
    defaultValue = 1,
    editable,
    label,
    editPath,
    id,
    onChange,
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleSave = () => {
    setEnabledEdition(false);
  };
  const enableEdition = () => {
    setEnabledEdition(true);
    setTimeout(() => {
      const elem: any = document.getElementById(id);
      elem.focus();
    }, 200);
  };
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  /*   useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]); */
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };
  return (
    <div className="slider-field-container">
      {label && (
        <label className="label">
          <span>{label}</span>
        </label>
      )}
      <div>
        <div>
          {editable && !enabledEdition ? (
            <span>{value}</span>
          ) : (
            <div className="editable-content">
              <b>{value}</b>
              <Slider
                aria-label={label}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="off"
                step={1}
                marks
                min={1}
                max={12}
              />
            </div>
          )}
        </div>

        {editable && (
          <div>
            {enabledEdition ? (
              <>
                <button onClick={handleSave}>
                  <Icon name="check" />
                </button>
                <button onClick={() => setEnabledEdition(false)}>
                  <Icon name="close" />
                </button>
              </>
            ) : (
              <>
                <button onClick={enableEdition}>
                  <Icon name="pencil" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { SliderField };

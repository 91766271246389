// components/configurations/BlockConfiguration.tsx
import React from 'react';
import { ColorField } from 'components/fields/ColorField/ColorField';

import { RadioField } from 'components/fields/RadioField/RadioField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import SizeField from '../../SizeField/SizeField';

interface BlockConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const BlockConfiguration: React.FC<BlockConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <SizeField
                label="Ancho"
                field="width"
                isEnabled={getFieldValues('width').value !== null}
                value={getFieldValues('width').value}
                unit={getFieldValues('width').unit}
                handleChange={handleChange}
                minValue={0}
            />
            <SizeField
                label="Alto"
                field="height"
                isEnabled={getFieldValues('height').value !== null}
                value={getFieldValues('height').value}
                unit={getFieldValues('height').unit}
                handleChange={handleChange}
                minValue={0}
            />
            <div className="action-wrapper">
                <ColorField
                    defaultValue={selectedTab === 'default' ? localConfig?.backgroundColor || null : localConfig?.responsive?.[selectedTab]?.backgroundColor || localConfig?.backgroundColor || null}
                    label={"Color de fondo"}
                    onChange={(color: any) => handleChange('backgroundColor', color)}
                />
            </div>
            <ExpandableSection title="Márgenes">

                <SizeField
                    label="Margen arriba"
                    field="marginTop"
                    isEnabled={getFieldValues('marginTop').value !== null}
                    value={getFieldValues('marginTop').value}
                    unit={getFieldValues('marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo"
                    field="marginBottom"
                    isEnabled={getFieldValues('marginBottom').value !== null}
                    value={getFieldValues('marginBottom').value}
                    unit={getFieldValues('marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda"
                    field="marginLeft"
                    isEnabled={getFieldValues('marginLeft').value !== null}
                    value={getFieldValues('marginLeft').value}
                    unit={getFieldValues('marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha"
                    field="marginRight"
                    isEnabled={getFieldValues('marginRight').value !== null}
                    value={getFieldValues('marginRight').value}
                    unit={getFieldValues('marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
            <ExpandableSection title="Márgenes internos">
                <SizeField
                    label="Margen interno arriba"
                    field="paddingTop"
                    isEnabled={getFieldValues('paddingTop').value !== null}
                    value={getFieldValues('paddingTop').value}
                    unit={getFieldValues('paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo"
                    field="paddingBottom"
                    isEnabled={getFieldValues('paddingBottom').value !== null}
                    value={getFieldValues('paddingBottom').value}
                    unit={getFieldValues('paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha"
                    field="paddingRight"
                    isEnabled={getFieldValues('paddingRight').value !== null}
                    value={getFieldValues('paddingRight').value}
                    unit={getFieldValues('paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda"
                    field="paddingLeft"
                    isEnabled={getFieldValues('paddingLeft').value !== null}
                    value={getFieldValues('paddingLeft').value}
                    unit={getFieldValues('paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
            <ExpandableSection title="Flex">
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.flexDirection || 'row' : localConfig?.responsive?.[selectedTab]?.flexDirection || localConfig?.flexDirection || 'row'}
                        label={"Dirección del Contenido"}
                        options={[
                            { name: "row", displayName: "Horizontal" },
                            { name: "row-reverse", displayName: "Horizontal Invertido" },
                            { name: "column", displayName: "Vertical" },
                            { name: "column-reverse", displayName: "Vertical Invertido" },
                        ]}
                        onChange={(value: string) => handleChange('flexDirection', value)}
                    />
                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.justifyContent || 'flex-start' : localConfig?.responsive?.[selectedTab]?.justifyContent || localConfig?.justifyContent || 'flex-start'}
                        label={"Justificación del contenido"}
                        options={[
                            { name: "flex-start", displayName: "Alinear al Inicio" },
                            { name: "flex-end", displayName: "Alinear al Final" },
                            { name: "center", displayName: "Centrar" },
                            { name: "space-between", displayName: "Espacio Entre Elementos" },
                            { name: "space-around", displayName: "Espacio Alrededor de Elementos" },
                        ]}
                        onChange={(value: string) => handleChange('justifyContent', value)}
                    />
                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.alignItems || 'flex-start' : localConfig?.responsive?.[selectedTab]?.alignItems || localConfig?.alignItems || 'flex-start'}
                        label={"Alineación de los items"}
                        options={[
                            { name: "flex-start", displayName: "Alineación al Inicio" },
                            { name: "flex-end", displayName: "Alineación al Final" },
                            { name: "center", displayName: "Centrar" },
                        ]}
                        onChange={(value: string) => handleChange('alignItems', value)}
                    />
                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.flexWrap || 'wrap' : localConfig?.responsive?.[selectedTab]?.flexWrap || localConfig?.flexWrap || 'wrap'}
                        label={"Comportamiento de Envoltura"}
                        options={[
                            { name: "wrap", displayName: "Permitir Envoltura" },
                            { name: "nowrap", displayName: "Sin Envoltura" },
                        ]}
                        onChange={(value: string) => handleChange('flexWrap', value)}
                    />
                </div>
            </ExpandableSection>

            <ExpandableSection title="Borde">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.borderColor || null : localConfig?.responsive?.[selectedTab]?.borderColor || localConfig?.borderColor || null}
                        label={"Color del borde"}
                        onChange={(color: any) => handleChange('borderColor', color)}
                    />
                </div>
                <div className="action-wrapper">
                    <SizeField
                        label="Grosor del borde"
                        field="borderWidth"
                        isEnabled={getFieldValues('borderWidth').value !== null}
                        value={getFieldValues('borderWidth').value}
                        unit={getFieldValues('borderWidth').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.borderStyle || 'none' : localConfig?.responsive?.[selectedTab]?.borderStyle || localConfig?.borderStyle || 'none'}
                        label={"Estilo de borde"}
                        options={[
                            { name: "none", displayName: "Sin borde" },
                            { name: "solid", displayName: "Sólido" },
                            { name: "dotted", displayName: "Puntos" },
                            { name: "dashed", displayName: "Líneas" },
                        ]}
                        onChange={(type: string) => handleChange('borderStyle', type)}
                    />
                </div>
            </ExpandableSection>

            <ExpandableSection title="Bordes redondeados">
                <SizeField
                    label="Borde arriba izquierda"
                    field="borderTopLeftRadius"
                    isEnabled={getFieldValues('borderTopLeftRadius').value !== null}
                    value={getFieldValues('borderTopLeftRadius').value}
                    unit={getFieldValues('borderTopLeftRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde arriba derecha"
                    field="borderTopRightRadius"
                    isEnabled={getFieldValues('borderTopRightRadius').value !== null}
                    value={getFieldValues('borderTopRightRadius').value}
                    unit={getFieldValues('borderTopRightRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde abajo izquierda"
                    field="borderBottomLeftRadius"
                    isEnabled={getFieldValues('borderBottomLeftRadius').value !== null}
                    value={getFieldValues('borderBottomLeftRadius').value}
                    unit={getFieldValues('borderBottomLeftRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Borde abajo derecha"
                    field="borderBottomRightRadius"
                    isEnabled={getFieldValues('borderBottomRightRadius').value !== null}
                    value={getFieldValues('borderBottomRightRadius').value}
                    unit={getFieldValues('borderBottomRightRadius').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
        </>
    );
};

export default BlockConfiguration;

import React, { useState, useEffect } from "react";
import "./IconPickerModal.scss";
import Icon from "components/Icon/Icon";
import Modal from "components/Modal/Modal";
import { useIcons } from "contexts/IconContext";
import { Spinner } from "components/Spinner/Spinner";


interface ModalProps {
  isOpen: boolean;
  onClose: (item?: any) => void;
  onlyTypes?: string[];
  onlyWith?: string[];
}

const IconPickerModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onlyTypes = [],
  onlyWith = [],
}) => {
  const { icons, loading } = useIcons();
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let filtered: any = icons;

    if (onlyTypes.length > 0) {
      filtered = filtered.filter((icon: any) => onlyTypes.includes(icon.type));
    }

    if (onlyWith.length > 0) {
      const filterByVersion = (icon: any, version: string) => {
        if (version === 'wrapper') return icon.hasWrapper;
        if (version === 'inverted') return icon.hasInverted;
        return false;
      };

      filtered = filtered.filter((icon: any) => onlyWith.every(version => filterByVersion(icon, version)));
    }
    if (searchTerm) {
      filtered = filtered.filter((icon: any) =>
        icon.displayName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredIcons(filtered);
  }, [icons, searchTerm]);

  if (loading) {
    return <div className="spinner-wrapper"><Spinner visible={loading} /></div>;
  }

  return (
    <Modal
      title="Selecionar icono"
      className={'icon-picker-modal-container'}
      isOpen={isOpen}
      onClose={onClose}
      hasSearchBar={true}
      onSearchChange={(searchTerm: string) => {
        setSearchTerm(searchTerm);
      }}
    >
      <div>
        {filteredIcons.map((icon: any) => (
          <a key={icon.name} className="icon-item" onClick={() => onClose(icon)}>
            <Icon name={icon.name} />
            <span>{icon.displayName}</span>
          </a>
        ))}
        {filteredIcons.length == 0 && (
          <div className="no-content">
            <Icon name="icon" />
            <p>{searchTerm ? "Sin resultado" : "No hay iconos"}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export { IconPickerModal };

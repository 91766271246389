import { AnimatedNumber } from "../AnimatedNumber/AnimatedNumber";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./BasicIndicator.scss";
const BasicIndicator = (props: any) => {
  const { value, label, delta, color, duration = 500, size = "md", decimalPrecision = 2 } = props;
  const className =
    size == "sm" ? "basic-indicator-container sm" : "basic-indicator-container";
  return (
    <div className={className}>
      <div>
        <AnimatedNumber value={value} duration={duration} color={color} decimalPrecision={decimalPrecision} />
        {delta && (
          <div className="delta">
            {delta > 0 && <ArrowUpwardIcon />}
            {delta < 0 && <ArrowDownwardIcon />}
            <span>{delta}</span>
          </div>
        )}
      </div>

      <span>{label}</span>
    </div>
  );
};

export { BasicIndicator };

import { arrayMoveImmutable } from "array-move";
import React, { useEffect, useState } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
const TextEditorFieldContext = React.createContext({} as any);
const useTextEditorField = () => {
  return React.useContext(TextEditorFieldContext);
};

const TextEditorFieldProvider = ({ children }: any) => {
  const minFontSize: number = 12;
  const maxFontSize: number = 20;

  const [items, setItems] = useState([] as any);

  const handleAddItem: any = (type: string) => {
    const itemsCopy = [...items];

    const template = type == "table" ? generateTable(2, 2) : getTemplate(type);

    itemsCopy.push(template);
    setItems(itemsCopy);
  };
  const handleDeleteItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  };

  const handleChangeFontStyle = (index: number) => {
    const itemsCopy = [...items];
    let fontStyle = itemsCopy[index]["fontStyle"];
    if (fontStyle === "regular") {
      fontStyle = "regular_italic";
    } else if (fontStyle === "regular_italic") {
      fontStyle = "bold";
    } else if (fontStyle === "bold") {
      fontStyle = "bold_italic";
    } else {
      fontStyle = "regular";
    }
    itemsCopy[index]["fontStyle"] = fontStyle;
    setItems(itemsCopy);
  };
  const handleChangeImageDescription = (index: number, value: string) => {
    const itemsCopy = [...items];

    itemsCopy[index]["content"]["description"] = value;
    setItems(itemsCopy);
  };
  const handleChangeFontSize = (index: number, type: string) => {
    const itemsCopy = [...items];
    if (type === "enlarge") {
      if (itemsCopy[index]["fontSize"] >= maxFontSize) {
        return;
      }
      itemsCopy[index]["fontSize"] += 2;
    }
    if (type === "reduce") {
      if (itemsCopy[index]["fontSize"] <= minFontSize) {
        return;
      }
      itemsCopy[index]["fontSize"] -= 2;
    }

    setItems(itemsCopy);
  };
  const handleChangeTextAlign = (index: number) => {
    const itemsCopy = [...items];
    let textAlign = itemsCopy[index]["textAlign"];
    if (textAlign === "left") {
      textAlign = "center";
    } else if (textAlign === "center") {
      textAlign = "right";
    } else if (textAlign === "right") {
      textAlign = "justify";
    } else {
      textAlign = "left";
    }
    itemsCopy[index]["textAlign"] = textAlign;
    setItems(itemsCopy);
  };
  const handleChangeTextColor = (value: string, index: number) => {
    const itemsCopy = [...items];
    itemsCopy[index]["color"] = value;
    setItems(itemsCopy);
  };
  const handleChangeContent = (index: number, value: string) => {
    const itemsCopy = [...items];

    itemsCopy[index]["content"] = value;
    setItems(itemsCopy);
  };
  const handleSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    let data = arrayMoveImmutable(items, oldIndex, newIndex);
    data.forEach((item: any, index: number) => {
      item.order = ++index;
    });
    setItems(data);
  };
  const getTemplate = (type: string) => {
    const templates: any = {
      text: {
        content: "",
        label: "Texto",
        fontSize: 16,
        fontStyle: "regular",
        fontWeight: 600,
        textAlign: "left",
        type: "text",
        color: "#333333",
      },
      paragraph: {
        content: "",
        label: "Párrafo",
        fontSize: 14,
        fontStyle: "regular",
        fontWeight: 300,
        textAlign: "left",
        type: "paragraph",
        color: "#333333",
      },
      h1: {
        content: "",
        fontSize: 26,
        fontWeight: 900,
        label: "Título 1",
        textAlign: "left",
        type: "h1",
      },
      h2: {
        content: "",
        fontSize: 22,
        fontWeight: 600,
        label: "Título 2",
        textAlign: "left",
        type: "h2",
      },
      h3: {
        content: "",
        fontSize: 18,
        fontWeight: 600,
        label: "Título 3",
        textAlign: "left",
        type: "h3",
      },
      list: {
        content: [],
        fontSize: 14,
        fontWeight: 300,
        label: "Lista",
        type: "list",
        markerType: "circle",
      },
      table: {
        content: [],
        fontSize: 14,
        fontWeight: 300,
        label: "Tabla",
        type: "table",
        withHeader: true,
      },
      image: {
        content: {
          path: null,
          description: null,
        },
        label: "Imagen",
        type: "image",
      },
      code: {
        content: "",
        label: "Código",
        type: "code",
      },
    };
    console.log(templates[type]);
    return templates[type];
  };
  /**
   * List
   */
  useEffect(() => { }, []);
  const handleAddListItem = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy[index]["content"].push("");
    setItems(itemsCopy);
  };
  const handleDeleteListItem = (itemIndex: number, listItemIndex: number) => {
    const itemsCopy = [...items];
    itemsCopy[itemIndex]["content"].splice(listItemIndex, 1);
    setItems(itemsCopy);
  };
  const handleChangeListItem = (
    itemIndex: number,
    listItemIndex: number,
    value: any
  ) => {
    const itemsCopy = [...items];
    itemsCopy[itemIndex]["content"][listItemIndex] = value;
    setItems(itemsCopy);
  };
  /**
   * Table
   */
  const generateTable = (rows: number, columns: number) => {
    const tableTemplate = getTemplate("table");
    for (let x = 0; x < columns; x++) {
      const newColumns = [];
      for (let y = 0; y < rows; y++) {
        const newRows = { text: "" };
        newColumns.push(newRows);
      }
      tableTemplate["content"].push(newColumns);
    }
    return tableTemplate;
  };

  const handleAddColumn = (index: number, col: number) => {
    const itemsCopy = [...items];
    for (const row of itemsCopy[index]["content"]) {
      row.splice(col, 0, { text: "" });
    }
    setItems(itemsCopy);
  };

  const handleRemoveColumn = (index: number, col: number) => {
    const itemsCopy = [...items];
    for (const row of itemsCopy[index]["content"]) {
      if (row.length === 1) {
        return;
      }
      row.splice(col, 1);
    }
    setItems(itemsCopy);
  };

  const handleAddRow = (index: number, row: number) => {
    const itemsCopy = [...items];
    const cols = [];
    for (const x of itemsCopy[index]["content"][0]) {
      cols.push({ text: "" });
    }
    itemsCopy[index]["content"].splice(row, 0, cols);
    setItems(itemsCopy);
  };

  const handleRemoveRow = (index: number, row: number) => {
    const itemsCopy = [...items];
    const element = itemsCopy[index]["content"];
    if (element.length === 1) {
      return;
    }
    element.splice(row, 1);
    setItems(itemsCopy);
  };

  const handleToggleWithHeader = (index: number) => {
    const itemsCopy = [...items];

    itemsCopy[index]["withHeader"] = !itemsCopy[index]["withHeader"];

    setItems(itemsCopy);
  };

  const handleChangeTableCell = (
    itemIndex: number,
    rowIndex: number,
    colIndex: number,
    value: any
  ) => {
    const itemsCopy = [...items];
    itemsCopy[itemIndex]["content"][rowIndex][colIndex]["text"] = value;
    setItems(itemsCopy);
  };
  /**
   * Image
   */
  const handleImageUpload = async (e: any, index: number) => {
    //console.log(URL.createObjectURL(e.target.files[0]));
    console.log(index);
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      console.log(reader.result);
      const data = {
        base64Image: reader.result,
        extension,
        folder: "files",
        index,
      };
      saveImage(data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleRemoveImage = (index: number) => {
    const itemsCopy = [...items];
    itemsCopy[index]["content"] = {
      path: null,
      description: null,
    };
    setItems(itemsCopy);
  };
  const saveImage = async ({ base64Image, extension, folder, index }: any) => {
    //setProcessing(true);
    const itemsCopy = [...items];
    try {
      // const res = await AxiosInterceptor.post(
      //   `${urls.server}/api/files/store-publicly`,
      //   {
      //     content,
      //     extension,
      //     folder,
      //   }
      // );
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        file_content: base64Image,
        extension,
        folder,
      });
      const resData = await res?.data;
      console.log(resData.path);
      console.log(index);
      itemsCopy[index]["content"]["path"] = resData.path;
      //itemsCopy[index]["content"]['description'] = resData.description;

      setItems(itemsCopy);
      //setProcessing(false);
    } catch (error: any) {
      //setProcessing(false);
    }
  };
  /**
   * List
   */
  const handleChangeMarkerType = (index: number) => {
    const itemsCopy = [...items];
    let markerType = itemsCopy[index]["markerType"];
    if (markerType === "circle") {
      markerType = "circleOutline";
    } else if (markerType === "circleOutline") {
      markerType = "square";
    } else if (markerType === "square") {
      markerType = "squareOutline";
    } else if (markerType === "squareOutline") {
      markerType = "checkMark";
    } else if (markerType === "checkMark") {
      markerType = "circle";
    }
    itemsCopy[index]["markerType"] = markerType;
    setItems(itemsCopy);
  };
  const valueAttr = {
    handleAddItem,
    handleDeleteItem,
    handleChangeFontStyle,
    handleChangeFontSize,
    handleChangeTextAlign,
    handleChangeContent,
    handleAddListItem,
    handleDeleteListItem,
    handleChangeListItem,
    handleAddColumn,
    handleRemoveColumn,
    handleAddRow,
    handleRemoveRow,
    handleChangeTableCell,
    handleToggleWithHeader,
    handleImageUpload,
    handleRemoveImage,
    handleChangeMarkerType,
    handleChangeTextColor,
    handleChangeImageDescription,
    handleSortEnd,
    items,
    setItems,
  };

  return (
    <TextEditorFieldContext.Provider value={valueAttr}>
      {children}
    </TextEditorFieldContext.Provider>
  );
};
export { TextEditorFieldProvider, useTextEditorField };

import { useState, useEffect } from "react";
import "./MiniLineChart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import colors from "config/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MiniLineChart = (props: any) => {
  const { data, height = 73, onPointSelect, color = "#666", decimalPrecision = 2 } = props;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  } as any);
  const [currentIndex, setCurrentIndex] = useState(data.length - 1);
  const options = {
    responsive: true,
    scaleShowLabels: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,

      },

    },
    pointRadius: 4,
    pointHoverRadius: 4,
    pointBorderWidth: 0,

    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },

    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const firstElementIndex = elements[0].index;
        console.log(firstElementIndex);
        onPointSelect(data[firstElementIndex]);
        setCurrentIndex(firstElementIndex);
      }
    },
  };
  function adaptSimpleIndicatorData(indicatorData: any) {
    const labels = indicatorData.map((item: any) => item.display_period);
    const data = indicatorData.map((item: any) => item.value.toFixed(decimalPrecision));

    return {
      labels: labels,
      datasets: [
        {
          data: data,
          fill: false,
          tension: 0.3,
          borderColor: colors.medium
        },
      ],
    };
  }
  const makeDotColors = (currentIndex: number) => {
    const colorArr: any = [];
    data.forEach((item: any, index: number) => {
      colorArr.push(index == currentIndex ? color : colors?.medium);
    });
    return colorArr;
  };
  useEffect(() => {
    const chartData: any = adaptSimpleIndicatorData(data);
    chartData.datasets[0].pointBackgroundColor = makeDotColors(currentIndex);
    setChartData(chartData);
  }, [currentIndex]);
  return (
    <div
      className="mini-line-chart-container"
      style={{
        height: height + "px",
      }}
    >
      <Line options={options} data={chartData} />
    </div>
  );
};

export { MiniLineChart };

// components/configurations/ProfileButtonConfiguration.tsx
import React from 'react';
import { ColorField } from 'components/fields/ColorField/ColorField';

import { RadioField } from 'components/fields/RadioField/RadioField';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import IconButtonGroup from 'components/IconButtonGroup/IconButtonGroup';
import SizeField from '../../SizeField/SizeField';

interface ProfileButtonConfigurationProps {
    localConfig: any;
    selectedTab: string;
    handleChange: (field: string, value: any) => void;
    getFieldValues: (field: string) => any;
}

const ProfileButtonConfiguration: React.FC<ProfileButtonConfigurationProps> = ({
    localConfig,
    selectedTab,
    handleChange,
    getFieldValues
}) => {
    return (
        <>
            <div className="action-wrapper">
                <RadioField
                    defaultValue={selectedTab === 'default' ? localConfig?.displayMode || 'popover' : localConfig?.responsive?.[selectedTab]?.displayMode || localConfig?.displayMode || 'popover'}
                    label={"Modo de visualización"}
                    description=""
                    options={
                        [
                            {
                                name: "popover",
                                displayName: "Popover",
                            },
                            {
                                name: "expanded",
                                displayName: "Expandido",
                            },
                        ]
                    }
                    onChange={(mode: string) => handleChange('displayMode', mode)}
                />
            </div>
            <SizeField
                label="Ancho"
                field="width"
                isEnabled={getFieldValues('width').value !== null}
                value={getFieldValues('width').value}
                unit={getFieldValues('width').unit}
                handleChange={handleChange}
                minValue={0}
            />
            <ExpandableSection title="Márgenes">

                <SizeField
                    label="Margen arriba"
                    field="marginTop"
                    isEnabled={getFieldValues('marginTop').value !== null}
                    value={getFieldValues('marginTop').value}
                    unit={getFieldValues('marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo"
                    field="marginBottom"
                    isEnabled={getFieldValues('marginBottom').value !== null}
                    value={getFieldValues('marginBottom').value}
                    unit={getFieldValues('marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda"
                    field="marginLeft"
                    isEnabled={getFieldValues('marginLeft').value !== null}
                    value={getFieldValues('marginLeft').value}
                    unit={getFieldValues('marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha"
                    field="marginRight"
                    isEnabled={getFieldValues('marginRight').value !== null}
                    value={getFieldValues('marginRight').value}
                    unit={getFieldValues('marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
            <ExpandableSection title="Márgenes internos">
                <SizeField
                    label="Margen interno arriba"
                    field="paddingTop"
                    isEnabled={getFieldValues('paddingTop').value !== null}
                    value={getFieldValues('paddingTop').value}
                    unit={getFieldValues('paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo"
                    field="paddingBottom"
                    isEnabled={getFieldValues('paddingBottom').value !== null}
                    value={getFieldValues('paddingBottom').value}
                    unit={getFieldValues('paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha"
                    field="paddingRight"
                    isEnabled={getFieldValues('paddingRight').value !== null}
                    value={getFieldValues('paddingRight').value}
                    unit={getFieldValues('paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda"
                    field="paddingLeft"
                    isEnabled={getFieldValues('paddingLeft').value !== null}
                    value={getFieldValues('paddingLeft').value}
                    unit={getFieldValues('paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
            </ExpandableSection>
            <ExpandableSection title="Miniatura">
                <div className="action-wrapper">
                    <SizeField
                        label="Ancho (Miniatura)"
                        field="profileThumbnail.width"
                        isEnabled={getFieldValues('profileThumbnail.width').value !== null}
                        value={getFieldValues('profileThumbnail.width').value}
                        unit={getFieldValues('profileThumbnail.width').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <SizeField
                        label="Borde redondeado (Miniatura)"
                        field="profileThumbnail.borderRadius"
                        isEnabled={getFieldValues('profileThumbnail.borderRadius').value !== null}
                        value={getFieldValues('profileThumbnail.borderRadius').value}
                        unit={getFieldValues('profileThumbnail.borderRadius').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
            </ExpandableSection>
            <ExpandableSection title="Imagen de perfil">
                <div className="action-wrapper">
                    <SizeField
                        label="Ancho (Imagen de perfil)"
                        field="profileImage.width"
                        isEnabled={getFieldValues('profileImage.width').value !== null}
                        value={getFieldValues('profileImage.width').value}
                        unit={getFieldValues('profileImage.width').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
                <div className="action-wrapper">
                    <SizeField
                        label="Borde redondeado (Imagen de perfil)"
                        field="profileImage.borderRadius"
                        isEnabled={getFieldValues('profileImage.borderRadius').value !== null}
                        value={getFieldValues('profileImage.borderRadius').value}
                        unit={getFieldValues('profileImage.borderRadius').unit}
                        handleChange={handleChange}
                        minValue={0}
                    />
                </div>
            </ExpandableSection>
            <ExpandableSection title="Popover">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.popover?.backgroundColor || null : localConfig?.popover?.responsive?.[selectedTab]?.backgroundColor || localConfig?.popover?.backgroundColor || null}
                        label={"Color de fondo (Popover)"}
                        onChange={(color: any) => handleChange('popover.backgroundColor', color)}
                    />
                </div>
                <SizeField
                    label="Margen interno arriba (Popover)"
                    field="popover.paddingTop"
                    isEnabled={getFieldValues('popover.paddingTop').value !== null}
                    value={getFieldValues('popover.paddingTop').value}
                    unit={getFieldValues('popover.paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo (Popover)"
                    field="popover.paddingBottom"
                    isEnabled={getFieldValues('popover.paddingBottom').value !== null}
                    value={getFieldValues('popover.paddingBottom').value}
                    unit={getFieldValues('popover.paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha (Popover)"
                    field="popover.paddingRight"
                    isEnabled={getFieldValues('popover.paddingRight').value !== null}
                    value={getFieldValues('popover.paddingRight').value}
                    unit={getFieldValues('popover.paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda (Popover)"
                    field="popover.paddingLeft"
                    isEnabled={getFieldValues('popover.paddingLeft').value !== null}
                    value={getFieldValues('popover.paddingLeft').value}
                    unit={getFieldValues('popover.paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />

            </ExpandableSection>
            <ExpandableSection title="Nombre del perfil">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileName?.backgroundColor || null : localConfig?.profileName?.responsive?.[selectedTab]?.backgroundColor || localConfig?.profileName?.backgroundColor || null}
                        label={"Color de fondo (Nombre del perfil)"}
                        onChange={(color: any) => handleChange('profileName.backgroundColor', color)}
                    />
                </div>
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileName?.color || null : localConfig?.profileName?.responsive?.[selectedTab]?.color || localConfig?.profileName?.color || null}
                        label={"Color (Nombre del perfil)"}
                        onChange={(color: any) => handleChange('profileName.color', color)}
                    />
                </div>
                <SizeField
                    label="Margen interno arriba (Nombre del perfil)"
                    field="profileName.paddingTop"
                    isEnabled={getFieldValues('profileName.paddingTop').value !== null}
                    value={getFieldValues('profileName.paddingTop').value}
                    unit={getFieldValues('profileName.paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo (Nombre del perfil)"
                    field="profileName.paddingBottom"
                    isEnabled={getFieldValues('profileName.paddingBottom').value !== null}
                    value={getFieldValues('profileName.paddingBottom').value}
                    unit={getFieldValues('profileName.paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha (Nombre del perfil)"
                    field="profileName.paddingRight"
                    isEnabled={getFieldValues('profileName.paddingRight').value !== null}
                    value={getFieldValues('profileName.paddingRight').value}
                    unit={getFieldValues('profileName.paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda (Nombre del perfil)"
                    field="profileName.paddingLeft"
                    isEnabled={getFieldValues('profileName.paddingLeft').value !== null}
                    value={getFieldValues('profileName.paddingLeft').value}
                    unit={getFieldValues('profileName.paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen arriba (Nombre del perfil)"
                    field="profileName.marginTop"
                    isEnabled={getFieldValues('profileName.marginTop').value !== null}
                    value={getFieldValues('profileName.marginTop').value}
                    unit={getFieldValues('profileName.marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo (Nombre del perfil)"
                    field="profileName.marginBottom"
                    isEnabled={getFieldValues('profileName.marginBottom').value !== null}
                    value={getFieldValues('profileName.marginBottom').value}
                    unit={getFieldValues('profileName.marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda (Nombre del perfil)"
                    field="profileName.marginLeft"
                    isEnabled={getFieldValues('profileName.marginLeft').value !== null}
                    value={getFieldValues('profileName.marginLeft').value}
                    unit={getFieldValues('profileName.marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha (Nombre del perfil)"
                    field="profileName.marginRight"
                    isEnabled={getFieldValues('profileName.marginRight').value !== null}
                    value={getFieldValues('profileName.marginRight').value}
                    unit={getFieldValues('profileName.marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <div className="action-wrapper">
                    <label>Alineación (Nombre del perfil)</label>
                    <IconButtonGroup
                        options={[
                            { name: 'left', icon: 'alignLeft' },
                            { name: 'center', icon: 'alignCenter' },
                            { name: 'right', icon: 'alignRight' },
                            { name: 'justify', icon: 'alignJustify' }
                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileName?.textAlign : localConfig?.profileName?.responsive?.[selectedTab]?.textAlign || localConfig?.profileName?.textAlign]}
                        onSelect={(selectedOptions: any) => handleChange('profileName.textAlign', selectedOptions[0]?.name)}
                    />
                </div>
                <div className="action-wrapper">
                    <label>Estilo de la fuente (Nombre del perfil)</label>

                    <IconButtonGroup
                        options={[
                            { name: 'regular', icon: 'regular' },
                            { name: 'italic', icon: 'regularItalic' },
                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileName?.fontStyle : localConfig?.profileName?.responsive?.[selectedTab]?.fontStyle || localConfig?.profileName?.fontStyle]}
                        onSelect={(selectedOptions: any) => handleChange('profileName.fontStyle', selectedOptions[0]?.name)}
                    />

                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileName?.fontWeight || '400' : localConfig?.profileName?.responsive?.[selectedTab]?.fontWeight || localConfig?.profileName?.fontWeight || '400'}
                        label={"Grosor de la fuente"}
                        description=""
                        options={
                            [
                                {
                                    name: "200",
                                    displayName: "Extra light",
                                },
                                {
                                    name: "300",
                                    displayName: "Light",
                                },
                                {
                                    name: "400",
                                    displayName: "Regular",
                                },
                                {
                                    name: "500",
                                    displayName: "Medium",
                                },
                                {
                                    name: "600",
                                    displayName: "Semi bold",
                                },
                                {
                                    name: "700",
                                    displayName: "Bold",
                                },
                                {
                                    name: "800",
                                    displayName: "Extra bold",
                                },
                                {
                                    name: "900",
                                    displayName: "Black",
                                },
                            ]
                        }
                        onChange={(type: string) => handleChange('profileName.fontWeight', type)}
                    />
                </div>

                <div className="action-wrapper">
                    <label>Decoración del texto (Nombre del perfil)</label>

                    <IconButtonGroup
                        options={[
                            { name: 'none', icon: 'textDecorationNone' },
                            { name: 'line-through', icon: 'textDecorationLineThrough' },
                            { name: 'overline', icon: 'textDecorationOverline' },
                            { name: 'underline', icon: 'textDecorationUnderline' },
                            { name: 'underline overline', icon: 'textDecorationOverlineUnderline' },

                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileName?.textDecoration : localConfig?.profileName?.responsive?.[selectedTab]?.textDecoration || localConfig?.profileName?.textDecoration]}
                        onSelect={(selectedOptions: any) => handleChange('profileName.textDecoration', selectedOptions[0]?.name)}
                    />

                </div>
                <SizeField
                    label="Tamaño fuente (Nombre del perfil)"
                    field="profileName.fontSize"
                    isEnabled={getFieldValues('profileName.fontSize').value !== null}
                    value={getFieldValues('profileName.fontSize').value}
                    unit={getFieldValues('profileName.fontSize').unit}
                    handleChange={handleChange}
                    minValue={1}
                />
            </ExpandableSection>

            <ExpandableSection title="Email del perfil">
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileEmail?.backgroundColor || null : localConfig?.profileEmail?.responsive?.[selectedTab]?.backgroundColor || localConfig?.profileEmail?.backgroundColor || null}
                        label={"Color de fondo (Email del perfil)"}
                        onChange={(color: any) => handleChange('profileEmail.backgroundColor', color)}
                    />
                </div>
                <div className="action-wrapper">
                    <ColorField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileEmail?.color || null : localConfig?.profileEmail?.responsive?.[selectedTab]?.color || localConfig?.profileEmail?.color || null}
                        label={"Color (Email del perfil)"}
                        onChange={(color: any) => handleChange('profileEmail.color', color)}
                    />
                </div>
                <SizeField
                    label="Margen interno arriba (Email del perfil)"
                    field="profileEmail.paddingTop"
                    isEnabled={getFieldValues('profileEmail.paddingTop').value !== null}
                    value={getFieldValues('profileEmail.paddingTop').value}
                    unit={getFieldValues('profileEmail.paddingTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno abajo (Email del perfil)"
                    field="profileEmail.paddingBottom"
                    isEnabled={getFieldValues('profileEmail.paddingBottom').value !== null}
                    value={getFieldValues('profileEmail.paddingBottom').value}
                    unit={getFieldValues('profileEmail.paddingBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno derecha (Email del perfil)"
                    field="profileEmail.paddingRight"
                    isEnabled={getFieldValues('profileEmail.paddingRight').value !== null}
                    value={getFieldValues('profileEmail.paddingRight').value}
                    unit={getFieldValues('profileEmail.paddingRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen interno izquierda (Email del perfil)"
                    field="profileEmail.paddingLeft"
                    isEnabled={getFieldValues('profileEmail.paddingLeft').value !== null}
                    value={getFieldValues('profileEmail.paddingLeft').value}
                    unit={getFieldValues('profileEmail.paddingLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen arriba (Email del perfil)"
                    field="profileEmail.marginTop"
                    isEnabled={getFieldValues('profileEmail.marginTop').value !== null}
                    value={getFieldValues('profileEmail.marginTop').value}
                    unit={getFieldValues('profileEmail.marginTop').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen abajo (Email del perfil)"
                    field="profileEmail.marginBottom"
                    isEnabled={getFieldValues('profileEmail.marginBottom').value !== null}
                    value={getFieldValues('profileEmail.marginBottom').value}
                    unit={getFieldValues('profileEmail.marginBottom').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen izquierda (Email del perfil)"
                    field="profileEmail.marginLeft"
                    isEnabled={getFieldValues('profileEmail.marginLeft').value !== null}
                    value={getFieldValues('profileEmail.marginLeft').value}
                    unit={getFieldValues('profileEmail.marginLeft').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <SizeField
                    label="Margen derecha (Email del perfil)"
                    field="profileEmail.marginRight"
                    isEnabled={getFieldValues('profileEmail.marginRight').value !== null}
                    value={getFieldValues('profileEmail.marginRight').value}
                    unit={getFieldValues('profileEmail.marginRight').unit}
                    handleChange={handleChange}
                    minValue={0}
                />
                <div className="action-wrapper">
                    <label>Alineación (Email del perfil)</label>
                    <IconButtonGroup
                        options={[
                            { name: 'left', icon: 'alignLeft' },
                            { name: 'center', icon: 'alignCenter' },
                            { name: 'right', icon: 'alignRight' },
                            { name: 'justify', icon: 'alignJustify' }
                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileEmail?.textAlign : localConfig?.profileEmail?.responsive?.[selectedTab]?.textAlign || localConfig?.profileEmail?.textAlign]}
                        onSelect={(selectedOptions: any) => handleChange('profileEmail.textAlign', selectedOptions[0]?.name)}
                    />
                </div>
                <div className="action-wrapper">
                    <label>Estilo de la fuente (Email del perfil)</label>

                    <IconButtonGroup
                        options={[
                            { name: 'regular', icon: 'regular' },
                            { name: 'italic', icon: 'regularItalic' },
                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileEmail?.fontStyle : localConfig?.profileEmail?.responsive?.[selectedTab]?.fontStyle || localConfig?.profileEmail?.fontStyle]}
                        onSelect={(selectedOptions: any) => handleChange('profileEmail.fontStyle', selectedOptions[0]?.name)}
                    />

                </div>
                <div className="action-wrapper">
                    <RadioField
                        defaultValue={selectedTab === 'default' ? localConfig?.profileEmail?.fontWeight || '400' : localConfig?.profileEmail?.responsive?.[selectedTab]?.fontWeight || localConfig?.profileEmail?.fontWeight || '400'}
                        label={"Grosor de la fuente"}
                        description=""
                        options={
                            [
                                {
                                    name: "200",
                                    displayName: "Extra light",
                                },
                                {
                                    name: "300",
                                    displayName: "Light",
                                },
                                {
                                    name: "400",
                                    displayName: "Regular",
                                },
                                {
                                    name: "500",
                                    displayName: "Medium",
                                },
                                {
                                    name: "600",
                                    displayName: "Semi bold",
                                },
                                {
                                    name: "700",
                                    displayName: "Bold",
                                },
                                {
                                    name: "800",
                                    displayName: "Extra bold",
                                },
                                {
                                    name: "900",
                                    displayName: "Black",
                                },
                            ]
                        }
                        onChange={(type: string) => handleChange('profileEmail.fontWeight', type)}
                    />
                </div>

                <div className="action-wrapper">
                    <label>Decoración del texto (Email del perfil)</label>

                    <IconButtonGroup
                        options={[
                            { name: 'none', icon: 'textDecorationNone' },
                            { name: 'line-through', icon: 'textDecorationLineThrough' },
                            { name: 'overline', icon: 'textDecorationOverline' },
                            { name: 'underline', icon: 'textDecorationUnderline' },
                            { name: 'underline overline', icon: 'textDecorationOverlineUnderline' },

                        ]}
                        multiple={false}
                        defaultValues={[selectedTab === 'default' ? localConfig?.profileEmail?.textDecoration : localConfig?.profileEmail?.responsive?.[selectedTab]?.textDecoration || localConfig?.profileEmail?.textDecoration]}
                        onSelect={(selectedOptions: any) => handleChange('profileEmail.textDecoration', selectedOptions[0]?.name)}
                    />

                </div>
                <SizeField
                    label="Tamaño fuente (Email del perfil)"
                    field="profileEmail.fontSize"
                    isEnabled={getFieldValues('profileEmail.fontSize').value !== null}
                    value={getFieldValues('profileEmail.fontSize').value}
                    unit={getFieldValues('profileEmail.fontSize').unit}
                    handleChange={handleChange}
                    minValue={1}
                />
            </ExpandableSection>

        </>
    );
};

export default ProfileButtonConfiguration;

import React, { useEffect, useState } from "react";
import "./BoardPage.scss";
import BoardCard from "components/BoardCard/BoardCard";
import { Spinner } from "components/Spinner/Spinner";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import FixUnfixBoardCardsModal from "components/modals/FixUnfixBoardCardsModal/FixUnfixBoardCardsModal";
import { useSortItems } from "hooks/useSortItems";
import { Header } from "components/Header/Header";
import Icon from "components/Icon/Icon";
import { useDebounce } from "hooks/useDebounce";
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from 'config/urls';
import LoadingButton from '@mui/lab/LoadingButton';

const BoardPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cards, setCards] = useState([] as any);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [fixUnfixCardsModalIsOpen, setFixUnfixCardsModalIsOpen] = useState(false);
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const limit = 20;
  const { sortItems, openAlert, processing, setOpenAlert, alertMsg, severity } =
    useSortItems({ sortedData: [], sortPath: `my/cards/sort` });

  const loadData = async (newOffset = 0, append = false) => {
    if (append) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    try {
      let url = `${urls.server}/api/my/fixed-cards?offset=${newOffset}&limit=${limit}&search_term=${debouncedValue}`
      const res: any = await AxiosInterceptor.get(url);
      const resData = res.data;

      if (append) {
        setCards((prev: any) => [...prev, ...resData.cards]);
      } else {
        setCards(resData.cards);
      }
      setTotal(resData.total);
    } catch (error) {
      console.error(error);
    } finally {
      if (append) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [debouncedValue]);

  const handleLoadMore = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    loadData(newOffset, true);
  };

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    const oldIndexInFullArray = cards.findIndex((item: any) => item === cards[oldIndex]);
    const newIndexInFullArray = cards.findIndex((item: any, idx: number) => {
      if (newIndex === 0) {
        return idx === newIndex;
      } else {
        return item === cards[newIndex] || cards[idx - 1] === cards[newIndex - 1];
      }
    });

    if (oldIndexInFullArray < 0 || newIndexInFullArray < 0) {
      console.error('No se pudo encontrar índices válidos para la reordenación.');
      return;
    }

    const newCards = arrayMoveImmutable(cards, oldIndexInFullArray, newIndexInFullArray);
    setCards(newCards);
    sortItems(newCards);
  };

  const handleSearchChange = (res: string) => {
    setSearchTerm(res);
    setOffset(0);
  };

  return (
    <>
      <div className="board-page-container page-container">
        <Header title="Tablero" hasSearchBar onSearchChange={handleSearchChange} />

        {loading ? (
          <div className="spinner-wrapper">
            <Spinner visible={loading} />
          </div>
        ) : (
          <SortableList
            onSortEnd={handleSortEnd}
            className="board-cards"
            draggedItemClassName="dragged"
          >
            <a onClick={() => setFixUnfixCardsModalIsOpen(true)}>
              <span>Agregar/Quitar tarjetas al tablero</span>
              <Icon name="fixUnfixCards" />
            </a>
            {!loading && cards.map((item: any, index: number) => {
              const cardData = item.type === "myIndicators" || item.type === "myTasks" ? item.data : item.card.data;
              return (
                <SortableItem key={item.id}>
                  <div>
                    <BoardCard
                      title={item.display_name}
                      data={cardData}
                      config={item.config}
                      type={item.type}
                      isSortable={searchTerm === ""}
                      isExpandable={item.is_expandable}
                      childCards={item.child_cards}
                      childCardsCount={item.child_cards_count}
                      id={item.card_id}
                      onChange={() => loadData()}
                    />
                  </div>
                </SortableItem>
              );
            })}
          </SortableList>
        )}

        {!loading && cards.length < total && (
          <div className="loading-button-wrapper">
            <LoadingButton
              onClick={handleLoadMore}
              loading={loadingMore}
              startIcon={<Icon name="plus" />}
            >
              Más tarjetas
            </LoadingButton>
          </div>
        )}
      </div>

      <FixUnfixBoardCardsModal
        isOpen={fixUnfixCardsModalIsOpen}
        onClose={() => setFixUnfixCardsModalIsOpen(false)}
        onChange={() => loadData()}
      />
    </>
  );
};

export { BoardPage };

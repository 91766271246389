import React, { useEffect, useState } from "react";
import "./MyTasksPage.scss";
import { Header } from "components/Header/Header";
import { Fab } from "@mui/material";

import { FormModal } from "components/modals/FormModal/FormModal";
import configurationService from "services/configurationService";
import DynamicList from "components/DynamicList/DynamicList";
import { Spinner } from "components/Spinner/Spinner";
import { DueDateTabs } from "components/tabs/DueDateTabs/DueDateTabs";
import { CompletedTasksModal } from "cards/tasks/components/CompletedTasksModal/CompletedTasksModal";
import { TasksMapModal } from "cards/tasks/components/TasksMapModal/TasksMapModal";
import { WorkflowEntryDetailModal } from "cards/workflowEntries/components/WorkflowEntryDetailModal/WorkflowEntryDetailModal";
import Icon from "components/Icon/Icon";


const MyTasksPage = () => {
  //const [loading, setLoading] = React.useState(false);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [editFormModalIsOpen, setEditFormModalIsOpen] = useState(false);
  const [createFormModalIsOpen, setCreateFormModalIsOpen] = useState(false);
  const [completedTasksModalIsOpen, setCompletedTasksModalIsOpen] =
    useState(false);
  const [mapModalIsOpen, setMapModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState('' as any);
  const [fields, setFields] = React.useState([] as any);
  const [leftCells, setLeftCells] = React.useState([] as any);
  const [rightCells, setRightCells] = React.useState([] as any);
  const [moreItemActions, setMoreItemActions] = React.useState([] as any);
  const [selectedItemId, setSelectedItemId] = React.useState(null as any);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [filters, setFilters] = useState([
    ["status", "!=", "done"]
  ] as any);
  const [workflowEntryDetailModalIsOpen, setWorkflowEntryDetailModalIsOpen] =
    useState(false);
  const [currentWorkflowEntryId, setCurrentWorkflowEntryId] = useState(null);
  const [currentWorkflowStepOrder, setCurrentWorkflowStepOrder] = useState(null);
  const [moreHeaderActions, setMoreHeaderActions] = useState([] as any);
  const currentDate: any = new Date().toJSON().slice(0, 10);

  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }

  const handleChangeItem = (val: any, item: any, index: number) => {
    setShouldRefresh(true);
  }
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
    setFilters([
      ["status", "!=", "done"]
    ]);
  };
  useEffect(() => {
    if (currentWorkflowEntryId != null &&
      currentWorkflowStepOrder != null) {
      setWorkflowEntryDetailModalIsOpen(true);
    }
  }, [currentWorkflowEntryId, currentWorkflowStepOrder])
  useEffect(() => {
    setLoadingConfig(true);
    const configurationNames = [
      "myTaskLeftCells",
      "myTaskRightCells",
      "myTaskFields",
      "myTaskMoreItemActions",
      "myTaskMoreHeaderActions",
    ];
    configurationService
      .findByNames(configurationNames)
      .then((configurations) => {

        setLoadingConfig(false);
        configurations.forEach((configuration: any) => {
          switch (configuration.name) {
            case "myTaskLeftCells":
              setLeftCells(configuration.json_schema);
              break;
            case "myTaskRightCells":

              setRightCells(configuration.json_schema);
              break;
            case "myTaskFields":
              setFields(configuration.json_schema);
              break;
            case "myTaskMoreItemActions":
              setMoreItemActions(configuration.json_schema);
              break;
            case "myTaskMoreHeaderActions":
              setMoreHeaderActions(configuration.json_schema);
              break;
            default:
            // Manejar cualquier otro caso
          }
        });
      })
      .catch((error) => {
        setLoadingConfig(false);
        console.log(error);
      });
  }, []);
  return (
    <div className="my-tasks-page-container page-container">
      <Header
        title="Mis tareas"
        hasSearchBar
        onSearchChange={handleSearchChange}
        moreActions={moreHeaderActions}
        onSelectMoreAction={(res: any) => {
          console.log(res);
          if (res.actionName == "viewCompletedTasks") {
            setCompletedTasksModalIsOpen(true);
          } else if (res.actionName == "viewTaskMap") {
            setMapModalIsOpen(true);
          }
        }}
      />
      {!loadingConfig ?
        <>
          <div className="page-body">
            <DueDateTabs
              listPath={'my/tasks/due-date-tabs'}
              onSelectItem={(val: any) => {
                console.log(val);
                if (val?.label === "Todas") {
                  setFilters([
                    ["status", "!=", "done"],
                  ]);
                } else if (val?.label === "Venc.") {
                  setFilters([
                    ["due_date", "<", currentDate],
                    ["status", "!=", "done"],

                  ]);
                } else if (val?.label === "S/F") {
                  setFilters([
                    ["due_date", null],
                    ["status", "!=", "done"],

                  ]);
                } else {
                  if (val?.date) {
                    setFilters([
                      ["due_date", val?.date],
                      ["status", "!=", "done"],

                    ]);
                  }
                }
              }}
              forceRefresh={shouldRefresh}
            />
            <DynamicList
              leftCells={leftCells}
              rightCells={rightCells}
              limit={10}
              listPath="my/tasks"
              noContentText="No hay tareas cargadas"
              noContentIcon="tasks"
              moreItemActions={moreItemActions}
              onSelectItemAction={(res: any) => {
                console.log(res);
                if (res.actionName == 'view') {
                  setSelectedItemId(res.currentItem?.id);
                  setEditFormModalIsOpen(true);
                }
                if (res.actionName == 'viewWorkflowEntryDetail') {
                  setCurrentWorkflowEntryId(res.currentItem?.workflow_step_entry?.workflow_entry_id);
                  const stepIndex: any = res.currentItem?.workflow_step_entry?.workflow_step_order - 1;
                  console.log(stepIndex)
                  setCurrentWorkflowStepOrder(stepIndex);
                }
              }}
              // deletePath="my/tasks/{{id}}"
              // deleteConfirmationMessage={"Esta seguro que desea eliminar la tarea <b>{{display_id}}</b>?"}
              forceRefresh={shouldRefresh}
              onRefreshComplete={handleRefreshComplete}
              hiddenActiveFilters={filters}
              searchTerm={searchTerm}
              onChangeItem={handleChangeItem}
            />
          </div>
          <Fab

            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 76,
              right: 16,
            }}
            onClick={() => {
              setCreateFormModalIsOpen(true)
            }}

          >
            <Icon name="add" sx={{ mr: 0 }} />
          </Fab>
          <FormModal
            isOpen={createFormModalIsOpen}
            onClose={(res: any) => {
              setCreateFormModalIsOpen(false);
              if (res) {
                //setForceRefresh
                setShouldRefresh(true);
              }
            }}

            title="Form. de tarea"
            fields={fields}
            createPath="my/tasks"
            mode="create"
          />
          <FormModal
            isOpen={editFormModalIsOpen}
            onClose={(res: any) => {
              setEditFormModalIsOpen(false)
              if (res) {
                setShouldRefresh(true);
              }
            }}

            title="Editar tarea"
            fields={fields}
            mode="edit"
          //tableName="tasks"
          //recordId={selectedItemId}
          />
          <CompletedTasksModal
            isOpen={completedTasksModalIsOpen}
            onClose={(res: any) => {
              setCompletedTasksModalIsOpen(false)
              if (res) {
                //setRefreshOnClose(true);
              }
            }}
            mode="user"
          />
          <TasksMapModal
            isOpen={mapModalIsOpen}
            onClose={(res: any) => {
              setMapModalIsOpen(false)
              if (res) {
                //setRefreshOnClose(true);
              }
            }}
            mode="user"
          />
          <WorkflowEntryDetailModal
            isOpen={workflowEntryDetailModalIsOpen}
            onClose={(res) => {
              console.log(res)
              if (res) {
                setShouldRefresh(true)
              }
              setWorkflowEntryDetailModalIsOpen(false)
              setCurrentWorkflowEntryId(null)
              setCurrentWorkflowStepOrder(null)
            }}
            workflowEntryId={currentWorkflowEntryId}
            goToStep={currentWorkflowStepOrder}
          />
        </>

        : <div className="spinner-wrapper">
          <Spinner visible={loadingConfig} />
        </div>}

    </div>
  );
};

export { MyTasksPage };

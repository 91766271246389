import React, { useState, useEffect } from 'react';
import './Counter.scss';

type CounterProps = {
    value: number;
    onChange: (newValue: number) => void;
    minValue?: number;
    maxValue?: number;
};

const Counter: React.FC<CounterProps> = ({ value, onChange, minValue, maxValue }) => {
    const [inputValue, setInputValue] = useState<number | string>(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleDecrement = () => {
        const newValue = Number(inputValue) - 1;
        if (minValue !== undefined && newValue < minValue) return;
        onChange(newValue);
        setInputValue(newValue);
    };

    const handleIncrement = () => {
        const newValue = Number(inputValue) + 1;
        if (maxValue !== undefined && newValue > maxValue) return;
        onChange(newValue);
        setInputValue(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(e.target.value, 10);
        if (isNaN(newValue)) {
            setInputValue('');
            return;
        }
        if ((minValue !== undefined && newValue < minValue) || (maxValue !== undefined && newValue > maxValue)) {
            setInputValue(value);
            return;
        }
        onChange(newValue);
        setInputValue(newValue);
    };

    return (
        <div className="counter-container" style={{ display: 'flex', alignItems: 'center' }}>
            <button
                onClick={handleDecrement}
                style={{
                    border: 'none',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    opacity: minValue !== undefined && Number(inputValue) <= minValue ? 0.5 : 1
                }}
                disabled={minValue !== undefined && Number(inputValue) <= minValue}
            >
                <span>-</span>
            </button>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                style={{
                    textAlign: 'center',
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    height: '30px',
                }}
            />
            <button
                onClick={handleIncrement}
                style={{
                    border: 'none',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    opacity: maxValue !== undefined && Number(inputValue) >= maxValue ? 0.5 : 1
                }}
                disabled={maxValue !== undefined && Number(inputValue) >= maxValue}
            >
                <span>+</span>
            </button>
        </div>
    );
};

export default Counter;

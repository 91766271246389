import React, { useEffect, useState } from 'react';
import './SelectableContentViewer.scss';
import urls from "config/urls";
import T1 from './uiComponents/T1/T1';
import Text from './uiComponents/Text/Text';
import Image from './uiComponents/Image/Image';
import Video from './uiComponents/Video/Video';
import T2 from './uiComponents/T2/T2';
import Nav from './uiComponents/Nav/Nav';
import ProfileButton from './uiComponents/ProfileButton/ProfileButton';
import CollapsedHeader from './uiComponents/CollapsedHeader/CollapsedHeader';
import Lottie from './uiComponents/Lottie/Lottie';
interface Style {
    value: string | number | null;
    unit: string;
}

interface ResponsiveStyle {
    [key: string]: any;
}
interface NavItem {
    label: string;
    link: string;
}
interface JsonComponent {
    id?: string;
    type: string;
    displayType?: string;
    className?: string | null;
    width?: Style;
    height?: Style;
    margin?: Style;
    padding?: Style;
    style?: React.CSSProperties;
    children?: JsonComponent[];
    textContent?: string;
    fontSize?: Style;
    fontFamily?: string;
    fontWeight?: string;
    fontStyle?: string;
    color?: string;
    src?: string;
    items?: NavItem[];
    itemsWrapper?: any;
    item?: any;
    hamburgerButton?: any;
    drawer?: any;
    popover?: any;
    profileThumbnail?: any;
    profileImage?: any;
    profileName?: any;
    profileEmail?: any;
    authButton?: any;
    isCollapsed?: boolean;
    responsive?: ResponsiveStyle;
    loop?: boolean;
    animationData?: string;
    displayMode?: string;
    [key: string]: any; // To accommodate other possible props
}

interface SelectableContentViewerProps {
    structure: JsonComponent;

    onSelectItem?: (item: JsonComponent | null) => void;
    screenWidth?: number;
    screenHeight?: number;
    onChange?: (updatedStructure: JsonComponent) => void; // Añadimos onChange aquí
}

const findComponentById = (component: JsonComponent, id: string): JsonComponent | null => {
    //console.log(component);
    if (component.id === id) {
        return component;
    }
    if (component.children) {
        for (let child of component.children) {
            const found = findComponentById(child, id);
            if (found) {
                return found;
            }
        }
    }
    if (component.item?.child) {
        //console.log(component.item?.child);
        const found = findComponentById(component.item?.child, id);
        console.log(found);
        if (found) {
            return found;
        }
    }
    return null;
};

const SelectableContentViewer: React.FC<SelectableContentViewerProps> = ({
    structure,
    onSelectItem,
    screenWidth = 1000,
    screenHeight = 390,
    onChange
}) => {
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [structureState, setStructureState] = useState<JsonComponent>(structure);

    useEffect(() => {
        setStructureState(structure);
    }, [structure]);

    const applyResponsiveProps = (component: any, size: string): any => {
        const responsiveProps = component?.responsive?.[size] || {};
        const combinedProps = { ...component };

        // Aplicar los estilos responsivos en la raíz del componente
        Object.keys(responsiveProps).forEach(key => {
            if (responsiveProps[key] !== undefined) {
                combinedProps[key] = responsiveProps[key];
            }
        });

        // Buscar propiedades que puedan tener subobjetos con responsive
        Object.keys(combinedProps).forEach(key => {
            if (combinedProps[key] && typeof combinedProps[key] === 'object' && !Array.isArray(combinedProps[key])) {
                combinedProps[key] = applyResponsiveProps(combinedProps[key], size);
            }
        });

        return combinedProps;
    };

    const getResponsiveProps = (component: JsonComponent) => {
        let size = 'lg';
        if (screenWidth < 1200 && screenWidth >= 768) {
            size = 'md';
        } else if (screenWidth < 768) {
            size = 'xs';
        }

        const combinedProps = applyResponsiveProps(component, size);
        return combinedProps;
    };

    const mapCustomToCSS = (customProps: any) => {
        const cssProps: any = { ...customProps };
        // if (customProps?.textAlign) {
        //     switch (customProps.textAlign) {
        //         case 'alignLeft':
        //             cssProps.textAlign = 'left';
        //             break;
        //         case 'alignCenter':
        //             cssProps.textAlign = 'center';
        //             break;
        //         case 'alignRight':
        //             cssProps.textAlign = 'right';
        //             break;
        //         case 'alignJustify':
        //             cssProps.textAlign = 'justify';
        //             break;
        //         default:
        //             break;
        //     }
        // }
        return cssProps;
    };

    const handleSelect = (event: React.MouseEvent, id: string | undefined, component: JsonComponent | null) => {

        event.stopPropagation();
        //const newSelectedId: any = id === selectedId ? null : id;
        const newSelectedId: any = id;
        setSelectedId(newSelectedId);
        if (onSelectItem) {
            const selectedComponent = newSelectedId ? findComponentById(structure, newSelectedId) : null;
            console.log('Selected component:', selectedComponent);
            onSelectItem(selectedComponent);
        }
        //}
    };

    const handleChange = (id: string, newText: string) => {
        const updateTextContent = (component: JsonComponent): JsonComponent => {
            if (component.id === id) {
                return { ...component, textContent: newText };
            }
            if (component.children) {
                return { ...component, children: component.children.map(updateTextContent) };
            }
            if (component.item?.child?.children) {
                return { ...component, children: component.item.child.children.map(updateTextContent) };
            }
            return component;
        };

        const updatedStructure = updateTextContent(structureState);
        setStructureState(updatedStructure);

        // Llamamos a onChange si está definido
        if (onChange) {
            onChange(updatedStructure);
        }
    };

    const renderComponent = (component: JsonComponent) => {
        const combinedProps = getResponsiveProps(component);

        const mappedResponsiveProps = mapCustomToCSS(combinedProps);
        const finalProps = { ...component, ...mappedResponsiveProps };

        const commonProps = {
            className: finalProps.className || undefined,
            src: finalProps.src || undefined,
            style: {
                width: finalProps.width ? `${finalProps.width.value}${finalProps.width.unit}` : undefined,
                height: finalProps.height ? `${finalProps.height.value}${finalProps.height.unit}` : undefined,
                marginTop: finalProps.marginTop ? `${finalProps.marginTop.value}${finalProps.marginTop.unit}` : 'default',
                marginBottom: finalProps.marginBottom ? `${finalProps.marginBottom.value}${finalProps.marginBottom.unit}` : 'default',
                marginLeft: finalProps.marginLeft ? `${finalProps.marginLeft.value}${finalProps.marginLeft.unit}` : 'default',
                marginRight: finalProps.marginRight ? `${finalProps.marginRight.value}${finalProps.marginRight.unit}` : 'default',
                paddingLeft: finalProps.paddingLeft ? `${finalProps.paddingLeft.value}${finalProps.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.paddingRight ? `${finalProps.paddingRight.value}${finalProps.paddingRight.unit}` : 'default',
                paddingTop: finalProps.paddingTop ? `${finalProps.paddingTop.value}${finalProps.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.paddingBottom ? `${finalProps.paddingBottom?.value}${finalProps.paddingBottom?.unit}` : 'default',
                color: finalProps.color,
                backgroundColor: finalProps.backgroundColor,
                borderColor: finalProps.borderColor,
                borderWidth: finalProps.borderWidth ? `${finalProps.borderWidth.value}${finalProps.borderWidth.unit}` : undefined,
                borderStyle: finalProps.borderStyle || 'none',
                borderTopLeftRadius: finalProps.borderTopLeftRadius ? `${finalProps.borderTopLeftRadius.value}${finalProps.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.borderTopRightRadius ? `${finalProps.borderTopRightRadius.value}${finalProps.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.borderBottomLeftRadius ? `${finalProps.borderBottomLeftRadius.value}${finalProps.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.borderBottomRightRadius ? `${finalProps.borderBottomRightRadius.value}${finalProps.borderBottomRightRadius.unit}` : undefined,
                fontSize: finalProps.fontSize ? `${finalProps.fontSize.value}${finalProps.fontSize.unit}` : undefined,
                fontWeight: finalProps.fontWeight,
                fontStyle: finalProps.fontStyle,
                textAlign: finalProps.textAlign,
                textDecoration: finalProps.textDecoration,
                display: 'flex',
                flexDirection: finalProps.flexDirection || 'row',
                flexWrap: finalProps.flexWrap || 'nowrap',
                justifyContent: finalProps.justifyContent || 'flex-start',
                alignItems: finalProps.alignItems || 'flex-start',
                ...finalProps.style,
            },
            titleStyle: {
                width: finalProps.width ? `${finalProps.width.value}${finalProps.width.unit}` : undefined,
                height: finalProps.height ? `${finalProps.height.value}${finalProps.height.unit}` : undefined,
                marginTop: finalProps.marginTop ? `${finalProps.marginTop.value}${finalProps.marginTop.unit}` : 'default',
                marginBottom: finalProps.marginBottom ? `${finalProps.marginBottom.value}${finalProps.marginBottom.unit}` : 'default',
                marginLeft: finalProps.marginLeft ? `${finalProps.marginLeft.value}${finalProps.marginLeft.unit}` : 'default',
                marginRight: finalProps.marginRight ? `${finalProps.marginRight.value}${finalProps.marginRight.unit}` : 'default',
                paddingLeft: finalProps.paddingLeft ? `${finalProps.paddingLeft.value}${finalProps.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.paddingRight ? `${finalProps.paddingRight.value}${finalProps.paddingRight.unit}` : 'default',
                paddingTop: finalProps.paddingTop ? `${finalProps.paddingTop.value}${finalProps.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.paddingBottom ? `${finalProps.paddingBottom?.value}${finalProps.paddingBottom?.unit}` : 'default',
                color: finalProps.color,
                backgroundColor: finalProps.backgroundColor,
                borderColor: finalProps.borderColor,
                borderWidth: finalProps.borderWidth ? `${finalProps.borderWidth.value}${finalProps.borderWidth.unit}` : undefined,
                borderStyle: finalProps.borderStyle || 'none',
                borderTopLeftRadius: finalProps.borderTopLeftRadius ? `${finalProps.borderTopLeftRadius.value}${finalProps.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.borderTopRightRadius ? `${finalProps.borderTopRightRadius.value}${finalProps.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.borderBottomLeftRadius ? `${finalProps.borderBottomLeftRadius.value}${finalProps.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.borderBottomRightRadius ? `${finalProps.borderBottomRightRadius.value}${finalProps.borderBottomRightRadius.unit}` : undefined,
                fontSize: finalProps.fontSize ? `${finalProps.fontSize.value}${finalProps.fontSize.unit}` : undefined,
                fontWeight: finalProps.fontWeight,
                fontStyle: finalProps.fontStyle,
                textAlign: finalProps.textAlign,
                textDecoration: finalProps.textDecoration,
            },
            navItems: finalProps.items || [],
            navItemStyle: {
                color: finalProps.item?.color,
                backgroundColor: finalProps.item?.backgroundColor,
                borderColor: finalProps.item?.borderColor,
                borderWidth: finalProps.item?.borderWidth ? `${finalProps.item?.borderWidth.value}${finalProps.item?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.item?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.item?.borderTopLeftRadius ? `${finalProps.item?.borderTopLeftRadius.value}${finalProps.item?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.item?.borderTopRightRadius ? `${finalProps.item?.borderTopRightRadius.value}${finalProps.item?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.item?.borderBottomLeftRadius ? `${finalProps.item?.borderBottomLeftRadius.value}${finalProps.item?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.item?.borderBottomRightRadius ? `${finalProps.item?.borderBottomRightRadius.value}${finalProps.item?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.item?.width ? `${finalProps.item?.width.value}${finalProps.item?.width.unit}` : undefined,
                height: finalProps.item?.height ? `${finalProps.item?.height.value}${finalProps.item?.height.unit}` : undefined,
                marginTop: finalProps.item?.marginTop ? `${finalProps.item?.marginTop.value}${finalProps.item?.marginTop.unit}` : 'default',
                marginBottom: finalProps.item?.marginBottom ? `${finalProps.item?.marginBottom.value}${finalProps.item?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.item?.marginLeft ? `${finalProps.item?.marginLeft.value}${finalProps.item?.marginLeft.unit}` : 'default',
                marginRight: finalProps.item?.marginRight ? `${finalProps.item?.marginRight.value}${finalProps.item?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.item?.paddingLeft ? `${finalProps.item?.paddingLeft.value}${finalProps.item?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.item?.paddingRight ? `${finalProps.item?.paddingRight.value}${finalProps.item?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.item?.paddingTop ? `${finalProps.item?.paddingTop.value}${finalProps.item?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.item?.paddingBottom ? `${finalProps.item?.paddingBottom?.value}${finalProps.item?.paddingBottom?.unit}` : 'default',
            },
            listItemStyle: {
                color: finalProps.item?.color,
                backgroundColor: finalProps.item?.backgroundColor,
                borderColor: finalProps.item?.borderColor,
                borderWidth: finalProps.item?.borderWidth ? `${finalProps.item?.borderWidth.value}${finalProps.item?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.item?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.item?.borderTopLeftRadius ? `${finalProps.item?.borderTopLeftRadius.value}${finalProps.item?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.item?.borderTopRightRadius ? `${finalProps.item?.borderTopRightRadius.value}${finalProps.item?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.item?.borderBottomLeftRadius ? `${finalProps.item?.borderBottomLeftRadius.value}${finalProps.item?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.item?.borderBottomRightRadius ? `${finalProps.item?.borderBottomRightRadius.value}${finalProps.item?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.item?.width ? `${finalProps.item?.width.value}${finalProps.item?.width.unit}` : undefined,
                height: finalProps.item?.height ? `${finalProps.item?.height.value}${finalProps.item?.height.unit}` : undefined,
                marginTop: finalProps.item?.marginTop ? `${finalProps.item?.marginTop.value}${finalProps.item?.marginTop.unit}` : 'default',
                marginBottom: finalProps.item?.marginBottom ? `${finalProps.item?.marginBottom.value}${finalProps.item?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.item?.marginLeft ? `${finalProps.item?.marginLeft.value}${finalProps.item?.marginLeft.unit}` : 'default',
                marginRight: finalProps.item?.marginRight ? `${finalProps.item?.marginRight.value}${finalProps.item?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.item?.paddingLeft ? `${finalProps.item?.paddingLeft.value}${finalProps.item?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.item?.paddingRight ? `${finalProps.item?.paddingRight.value}${finalProps.item?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.item?.paddingTop ? `${finalProps.item?.paddingTop.value}${finalProps.item?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.item?.paddingBottom ? `${finalProps.item?.paddingBottom?.value}${finalProps.item?.paddingBottom?.unit}` : 'default',
            },
            listItemsWrapperStyle: {
                display: 'flex',
                flexDirection: finalProps.itemsWrapper?.flexDirection || 'row',
                flexWrap: finalProps.itemsWrapper?.flexWrap || 'nowrap',
                justifyContent: finalProps.itemsWrapper?.justifyContent || 'flex-start',
                alignItems: finalProps.itemsWrapper?.alignItems || 'flex-start',
                backgroundColor: finalProps.itemsWrapper?.backgroundColor,
                borderColor: finalProps.itemsWrapper?.borderColor,
                borderWidth: finalProps.itemsWrapper?.borderWidth ? `${finalProps.itemsWrapper?.borderWidth.value}${finalProps.itemsWrapper?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.itemsWrapper?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.itemsWrapper?.borderTopLeftRadius ? `${finalProps.itemsWrapper?.borderTopLeftRadius.value}${finalProps.itemsWrapper?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.itemsWrapper?.borderTopRightRadius ? `${finalProps.itemsWrapper?.borderTopRightRadius.value}${finalProps.itemsWrapper?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.itemsWrapper?.borderBottomLeftRadius ? `${finalProps.itemsWrapper?.borderBottomLeftRadius.value}${finalProps.itemsWrapper?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.itemsWrapper?.borderBottomRightRadius ? `${finalProps.itemsWrapper?.borderBottomRightRadius.value}${finalProps.itemsWrapper?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.itemsWrapper?.width ? `${finalProps.itemsWrapper?.width.value}${finalProps.itemsWrapper?.width.unit}` : undefined,
                height: finalProps.itemsWrapper?.height ? `${finalProps.itemsWrapper?.height.value}${finalProps.itemsWrapper?.height.unit}` : undefined,
                marginTop: finalProps.itemsWrapper?.marginTop ? `${finalProps.itemsWrapper?.marginTop.value}${finalProps.itemsWrapper?.marginTop.unit}` : 'default',
                marginBottom: finalProps.itemsWrapper?.marginBottom ? `${finalProps.itemsWrapper?.marginBottom.value}${finalProps.itemsWrapper?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.itemsWrapper?.marginLeft ? `${finalProps.itemsWrapper?.marginLeft.value}${finalProps.itemsWrapper?.marginLeft.unit}` : 'default',
                marginRight: finalProps.itemsWrapper?.marginRight ? `${finalProps.itemsWrapper?.marginRight.value}${finalProps.itemsWrapper?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.itemsWrapper?.paddingLeft ? `${finalProps.itemsWrapper?.paddingLeft.value}${finalProps.itemsWrapper?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.itemsWrapper?.paddingRight ? `${finalProps.itemsWrapper?.paddingRight.value}${finalProps.itemsWrapper?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.itemsWrapper?.paddingTop ? `${finalProps.itemsWrapper?.paddingTop.value}${finalProps.itemsWrapper?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.itemsWrapper?.paddingBottom ? `${finalProps.itemsWrapper?.paddingBottom?.value}${finalProps.itemsWrapper?.paddingBottom?.unit}` : 'default',

            },
            dynamicListItemsWrapperStyle: {
                display: 'flex',
                flexDirection: finalProps.itemsWrapper?.flexDirection || 'row',
                flexWrap: finalProps.itemsWrapper?.flexWrap || 'nowrap',
                justifyContent: finalProps.itemsWrapper?.justifyContent || 'flex-start',
                alignItems: finalProps.itemsWrapper?.alignItems || 'flex-start',
                backgroundColor: finalProps.itemsWrapper?.backgroundColor,
                borderColor: finalProps.itemsWrapper?.borderColor,
                borderWidth: finalProps.itemsWrapper?.borderWidth ? `${finalProps.itemsWrapper?.borderWidth.value}${finalProps.itemsWrapper?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.itemsWrapper?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.itemsWrapper?.borderTopLeftRadius ? `${finalProps.itemsWrapper?.borderTopLeftRadius.value}${finalProps.itemsWrapper?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.itemsWrapper?.borderTopRightRadius ? `${finalProps.itemsWrapper?.borderTopRightRadius.value}${finalProps.itemsWrapper?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.itemsWrapper?.borderBottomLeftRadius ? `${finalProps.itemsWrapper?.borderBottomLeftRadius.value}${finalProps.itemsWrapper?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.itemsWrapper?.borderBottomRightRadius ? `${finalProps.itemsWrapper?.borderBottomRightRadius.value}${finalProps.itemsWrapper?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.itemsWrapper?.width ? `${finalProps.itemsWrapper?.width.value}${finalProps.itemsWrapper?.width.unit}` : undefined,
                height: finalProps.itemsWrapper?.height ? `${finalProps.itemsWrapper?.height.value}${finalProps.itemsWrapper?.height.unit}` : undefined,
                marginTop: finalProps.itemsWrapper?.marginTop ? `${finalProps.itemsWrapper?.marginTop.value}${finalProps.itemsWrapper?.marginTop.unit}` : 'default',
                marginBottom: finalProps.itemsWrapper?.marginBottom ? `${finalProps.itemsWrapper?.marginBottom.value}${finalProps.itemsWrapper?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.itemsWrapper?.marginLeft ? `${finalProps.itemsWrapper?.marginLeft.value}${finalProps.itemsWrapper?.marginLeft.unit}` : 'default',
                marginRight: finalProps.itemsWrapper?.marginRight ? `${finalProps.itemsWrapper?.marginRight.value}${finalProps.itemsWrapper?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.itemsWrapper?.paddingLeft ? `${finalProps.itemsWrapper?.paddingLeft.value}${finalProps.itemsWrapper?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.itemsWrapper?.paddingRight ? `${finalProps.itemsWrapper?.paddingRight.value}${finalProps.itemsWrapper?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.itemsWrapper?.paddingTop ? `${finalProps.itemsWrapper?.paddingTop.value}${finalProps.itemsWrapper?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.itemsWrapper?.paddingBottom ? `${finalProps.itemsWrapper?.paddingBottom?.value}${finalProps.itemsWrapper?.paddingBottom?.unit}` : 'default',

            },
            dynamicListItemStyle: {
                color: finalProps.item?.color,
                backgroundColor: finalProps.item?.backgroundColor,
                borderColor: finalProps.item?.borderColor,
                borderWidth: finalProps.item?.borderWidth ? `${finalProps.item?.borderWidth.value}${finalProps.item?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.item?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.item?.borderTopLeftRadius ? `${finalProps.item?.borderTopLeftRadius.value}${finalProps.item?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.item?.borderTopRightRadius ? `${finalProps.item?.borderTopRightRadius.value}${finalProps.item?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.item?.borderBottomLeftRadius ? `${finalProps.item?.borderBottomLeftRadius.value}${finalProps.item?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.item?.borderBottomRightRadius ? `${finalProps.item?.borderBottomRightRadius.value}${finalProps.item?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.item?.width ? `${finalProps.item?.width.value}${finalProps.item?.width.unit}` : undefined,
                height: finalProps.item?.height ? `${finalProps.item?.height.value}${finalProps.item?.height.unit}` : undefined,
                marginTop: finalProps.item?.marginTop ? `${finalProps.item?.marginTop.value}${finalProps.item?.marginTop.unit}` : 'default',
                marginBottom: finalProps.item?.marginBottom ? `${finalProps.item?.marginBottom.value}${finalProps.item?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.item?.marginLeft ? `${finalProps.item?.marginLeft.value}${finalProps.item?.marginLeft.unit}` : 'default',
                marginRight: finalProps.item?.marginRight ? `${finalProps.item?.marginRight.value}${finalProps.item?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.item?.paddingLeft ? `${finalProps.item?.paddingLeft.value}${finalProps.item?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.item?.paddingRight ? `${finalProps.item?.paddingRight.value}${finalProps.item?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.item?.paddingTop ? `${finalProps.item?.paddingTop.value}${finalProps.item?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.item?.paddingBottom ? `${finalProps.item?.paddingBottom?.value}${finalProps.item?.paddingBottom?.unit}` : 'default',
            },
            hamburgerButtonStyle: {
                color: finalProps.hamburgerButton?.color,
                backgroundColor: finalProps.hamburgerButton?.backgroundColor,
                borderColor: finalProps.hamburgerButton?.borderColor,
                borderWidth: finalProps.hamburgerButton?.borderWidth ? `${finalProps.hamburgerButton?.borderWidth.value}${finalProps.hamburgerButton?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.hamburgerButton?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.hamburgerButton?.borderTopLeftRadius ? `${finalProps.hamburgerButton?.borderTopLeftRadius.value}${finalProps.hamburgerButton?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.hamburgerButton?.borderTopRightRadius ? `${finalProps.hamburgerButton?.borderTopRightRadius.value}${finalProps.hamburgerButton?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.hamburgerButton?.borderBottomLeftRadius ? `${finalProps.hamburgerButton?.borderBottomLeftRadius.value}${finalProps.hamburgerButton?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.hamburgerButton?.borderBottomRightRadius ? `${finalProps.hamburgerButton?.borderBottomRightRadius.value}${finalProps.hamburgerButton?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.hamburgerButton?.width ? `${finalProps.hamburgerButton?.width.value}${finalProps.hamburgerButton?.width.unit}` : undefined,
                height: finalProps.hamburgerButton?.height ? `${finalProps.hamburgerButton?.height.value}${finalProps.hamburgerButton?.height.unit}` : undefined,
                marginTop: finalProps.hamburgerButton?.marginTop ? `${finalProps.hamburgerButton?.marginTop.value}${finalProps.hamburgerButton?.marginTop.unit}` : 'default',
                marginBottom: finalProps.hamburgerButton?.marginBottom ? `${finalProps.hamburgerButton?.marginBottom.value}${finalProps.hamburgerButton?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.hamburgerButton?.marginLeft ? `${finalProps.hamburgerButton?.marginLeft.value}${finalProps.hamburgerButton?.marginLeft.unit}` : 'default',
                marginRight: finalProps.hamburgerButton?.marginRight ? `${finalProps.hamburgerButton?.marginRight.value}${finalProps.hamburgerButton?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.hamburgerButton?.paddingLeft ? `${finalProps.hamburgerButton?.paddingLeft.value}${finalProps.hamburgerButton?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.hamburgerButton?.paddingRight ? `${finalProps.hamburgerButton?.paddingRight.value}${finalProps.hamburgerButton?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.hamburgerButton?.paddingTop ? `${finalProps.hamburgerButton?.paddingTop.value}${finalProps.hamburgerButton?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.hamburgerButton?.paddingBottom ? `${finalProps.hamburgerButton?.paddingBottom?.value}${finalProps.hamburgerButton?.paddingBottom?.unit}` : 'default',
            },
            drawerStyle: {
                color: finalProps.drawer?.color,
                backgroundColor: finalProps.drawer?.backgroundColor,
                borderColor: finalProps.drawer?.borderColor,
                borderWidth: finalProps.drawer?.borderWidth ? `${finalProps.drawer?.borderWidth.value}${finalProps.drawer?.borderWidth.unit}` : undefined,
                borderStyle: finalProps.drawer?.borderStyle || 'none',
                borderTopLeftRadius: finalProps.drawer?.borderTopLeftRadius ? `${finalProps.drawer?.borderTopLeftRadius.value}${finalProps.drawer?.borderTopLeftRadius.unit}` : undefined,
                borderTopRightRadius: finalProps.drawer?.borderTopRightRadius ? `${finalProps.drawer?.borderTopRightRadius.value}${finalProps.drawer?.borderTopRightRadius.unit}` : undefined,
                borderBottomLeftRadius: finalProps.drawer?.borderBottomLeftRadius ? `${finalProps.drawer?.borderBottomLeftRadius.value}${finalProps.drawer?.borderBottomLeftRadius.unit}` : undefined,
                borderBottomRightRadius: finalProps.drawer?.borderBottomRightRadius ? `${finalProps.drawer?.borderBottomRightRadius.value}${finalProps.drawer?.borderBottomRightRadius.unit}` : undefined,
                width: finalProps.drawer?.width ? `${finalProps.drawer?.width.value}${finalProps.drawer?.width.unit}` : undefined,
                maxWidth: finalProps.drawer?.maxWidth ? `${finalProps.drawer?.maxWidth.value}${finalProps.drawer?.maxWidth.unit}` : undefined,
                height: finalProps.drawer?.height ? `${finalProps.drawer?.height.value}${finalProps.drawer?.height.unit}` : undefined,
                marginTop: finalProps.drawer?.marginTop ? `${finalProps.drawer?.marginTop.value}${finalProps.drawer?.marginTop.unit}` : 'default',
                marginBottom: finalProps.drawer?.marginBottom ? `${finalProps.drawer?.marginBottom.value}${finalProps.drawer?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.drawer?.marginLeft ? `${finalProps.drawer?.marginLeft.value}${finalProps.drawer?.marginLeft.unit}` : 'default',
                marginRight: finalProps.drawer?.marginRight ? `${finalProps.drawer?.marginRight.value}${finalProps.drawer?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.drawer?.paddingLeft ? `${finalProps.drawer?.paddingLeft.value}${finalProps.drawer?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.drawer?.paddingRight ? `${finalProps.drawer?.paddingRight.value}${finalProps.drawer?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.drawer?.paddingTop ? `${finalProps.drawer?.paddingTop.value}${finalProps.drawer?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.drawer?.paddingBottom ? `${finalProps.drawer?.paddingBottom?.value}${finalProps.drawer?.paddingBottom?.unit}` : 'default',
            },
            profileThumbnailStyle: {
                width: finalProps.profileThumbnail?.width ? `${finalProps.profileThumbnail?.width.value}${finalProps.profileThumbnail?.width.unit}` : undefined,
                borderRadius: finalProps.profileThumbnail?.borderRadius ? `${finalProps.profileThumbnail?.borderRadius.value}${finalProps.profileThumbnail?.borderRadius.unit}` : undefined,
            },
            popoverStyle: {
                backgroundColor: finalProps.popover?.backgroundColor,
                marginRight: finalProps.popover?.marginRight ? `${finalProps.popover?.marginRight.value}${finalProps.popover?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.popover?.paddingLeft ? `${finalProps.popover?.paddingLeft.value}${finalProps.popover?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.popover?.paddingRight ? `${finalProps.popover?.paddingRight.value}${finalProps.popover?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.popover?.paddingTop ? `${finalProps.popover?.paddingTop.value}${finalProps.popover?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.popover?.paddingBottom ? `${finalProps.popover?.paddingBottom?.value}${finalProps.popover?.paddingBottom?.unit}` : 'default',
            },
            profileImageStyle: {
                width: finalProps.profileImage?.width ? `${finalProps.profileImage?.width.value}${finalProps.profileImage?.width.unit}` : undefined,
                borderRadius: finalProps.profileImage?.borderRadius ? `${finalProps.profileImage?.borderRadius.value}${finalProps.profileImage?.borderRadius.unit}` : undefined,
            },
            profileNameStyle: {
                fontSize: finalProps.profileName?.fontSize ? `${finalProps.profileName?.fontSize.value}${finalProps.profileName?.fontSize.unit}` : undefined,
                fontWeight: finalProps.profileName?.fontWeight,
                fontStyle: finalProps.profileName?.fontStyle,
                textAlign: finalProps.profileName?.textAlign,
                textDecoration: finalProps.profileName?.textDecoration,
                color: finalProps.profileName?.color,
                backgroundColor: finalProps.profileName?.backgroundColor,
                marginTop: finalProps.profileName?.marginTop ? `${finalProps.profileName?.marginTop.value}${finalProps.profileName?.marginTop.unit}` : 'default',
                marginBottom: finalProps.profileName?.marginBottom ? `${finalProps.profileName?.marginBottom.value}${finalProps.profileName?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.profileName?.marginLeft ? `${finalProps.profileName?.marginLeft.value}${finalProps.profileName?.marginLeft.unit}` : 'default',
                marginRight: finalProps.profileName?.marginRight ? `${finalProps.profileName?.marginRight.value}${finalProps.profileName?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.profileName?.paddingLeft ? `${finalProps.profileName?.paddingLeft.value}${finalProps.profileName?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.profileName?.paddingRight ? `${finalProps.profileName?.paddingRight.value}${finalProps.profileName?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.profileName?.paddingTop ? `${finalProps.profileName?.paddingTop.value}${finalProps.profileName?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.profileName?.paddingBottom ? `${finalProps.profileName?.paddingBottom?.value}${finalProps.profileName?.paddingBottom?.unit}` : 'default',
            },
            profileEmailStyle: {
                fontSize: finalProps.profileEmail?.fontSize ? `${finalProps.profileEmail?.fontSize.value}${finalProps.profileEmail?.fontSize.unit}` : undefined,
                fontWeight: finalProps.profileEmail?.fontWeight,
                fontStyle: finalProps.profileEmail?.fontStyle,
                textAlign: finalProps.profileEmail?.textAlign,
                textDecoration: finalProps.profileEmail?.textDecoration,
                color: finalProps.profileEmail?.color,
                backgroundColor: finalProps.profileEmail?.backgroundColor,
                marginTop: finalProps.profileEmail?.marginTop ? `${finalProps.profileEmail?.marginTop.value}${finalProps.profileEmail?.marginTop.unit}` : 'default',
                marginBottom: finalProps.profileEmail?.marginBottom ? `${finalProps.profileEmail?.marginBottom.value}${finalProps.profileEmail?.marginBottom.unit}` : 'default',
                marginLeft: finalProps.profileEmail?.marginLeft ? `${finalProps.profileEmail?.marginLeft.value}${finalProps.profileEmail?.marginLeft.unit}` : 'default',
                marginRight: finalProps.profileEmail?.marginRight ? `${finalProps.profileEmail?.marginRight.value}${finalProps.profileEmail?.marginRight.unit}` : 'default',
                paddingLeft: finalProps.profileEmail?.paddingLeft ? `${finalProps.profileEmail?.paddingLeft.value}${finalProps.profileEmail?.paddingLeft.unit}` : 'default',
                paddingRight: finalProps.profileEmail?.paddingRight ? `${finalProps.profileEmail?.paddingRight.value}${finalProps.profileEmail?.paddingRight.unit}` : 'default',
                paddingTop: finalProps.profileEmail?.paddingTop ? `${finalProps.profileEmail?.paddingTop.value}${finalProps.profileEmail?.paddingTop.unit}` : 'default',
                paddingBottom: finalProps.profileEmail?.paddingBottom ? `${finalProps.profileEmail?.paddingBottom?.value}${finalProps.profileEmail?.paddingBottom?.unit}` : 'default',
            },
            authButtonStyle: {},
            onClick: (event: React.MouseEvent) => handleSelect(event, finalProps.id, finalProps),
        };

        const isSelected = selectedId === finalProps.id;
        const wrapWithSelection = (children: React.ReactNode) => (
            <div
                style={{
                    border: isSelected ? '2px dashed #000' : '1px dashed #ccc',
                    width: commonProps.style.width,
                    height: commonProps.style.height,
                    display: 'inline-flex'
                }}
                onClick={(event: React.MouseEvent) => handleSelect(event, finalProps.id, finalProps)}
            >
                {
                    //JSON.stringify(finalProps?.id)
                }
                {children}
            </div>
        );
        const src = commonProps.src ? `${urls?.server}${commonProps.src}` : '';
        switch (finalProps.type) {
            case 'block':
                return wrapWithSelection(
                    <div style={{ ...commonProps.style, width: '100%', height: '100%' }}>
                        {finalProps.children && finalProps.children.map((child: any, index: number) => (
                            <React.Fragment key={index}>
                                {renderComponent(child)}
                            </React.Fragment>
                        ))}
                    </div>
                );
            case 'dynamicList':
                return wrapWithSelection(
                    <div style={{ ...commonProps.style, width: '100%', height: '100%' }}>
                        <div style={commonProps.dynamicListItemsWrapperStyle}>
                            <div style={commonProps.dynamicListItemStyle}>
                                {renderComponent(finalProps?.item?.child)}
                                {/* {finalProps?.item?.child?.children && finalProps?.item?.child?.children.map((child: any, index: number) => (
                                <React.Fragment key={index}>
                                    {renderComponent(child)}
                                </React.Fragment>
                            ))} */}
                            </div>
                        </div>

                    </div>
                );

            case 'header':
                return wrapWithSelection(
                    finalProps.isCollapsed ? (
                        <CollapsedHeader
                            style={commonProps.style}
                            hamburgerButtonStyle={commonProps.hamburgerButtonStyle}
                            drawerStyle={commonProps.drawerStyle}
                            openIcon={finalProps?.hamburgerButton?.openIcon?.name}
                            closeIcon={finalProps?.hamburgerButton?.closeIcon?.name}
                            screenHeight={screenHeight}
                        >
                            {finalProps.children && finalProps.children.map((child: any, index: number) => (
                                <React.Fragment key={index}>
                                    {renderComponent(child)}
                                </React.Fragment>
                            ))}
                        </CollapsedHeader>
                    ) : (
                        <div style={{ ...commonProps.style, width: '100%', height: '100%' }}>
                            {finalProps.children && finalProps.children.map((child: any, index: number) => (
                                <React.Fragment key={index}>
                                    {renderComponent(child)}
                                </React.Fragment>
                            ))}
                        </div>
                    )
                );

            case 't1':
                return wrapWithSelection(
                    <T1
                        textContent={finalProps.textContent || ''}
                        selected={isSelected}
                        onChange={(newText: any) => handleChange(finalProps.id!, newText)}
                        //commonProps={{ ...commonProps, onClick: undefined }}
                        style={commonProps.titleStyle}
                    />
                );
            case 't2':
                return wrapWithSelection(
                    <T2
                        textContent={finalProps.textContent || ''}
                        selected={isSelected}
                        onChange={(newText: any) => handleChange(finalProps.id!, newText)}
                        //commonProps={{ ...commonProps, onClick: undefined }}
                        style={commonProps.titleStyle}
                    />
                );
            case 'text':
                return wrapWithSelection(
                    <Text
                        textContent={finalProps.textContent || ''}
                        selected={isSelected}
                        onChange={(newText: any) => handleChange(finalProps.id!, newText)}
                        style={commonProps.style}
                    //commonProps={{ ...commonProps, onClick: undefined }}
                    />
                );
            case 'image':
                return wrapWithSelection(
                    <Image
                        style={commonProps.style}
                        //commonProps={{ ...commonProps }}
                        src={src}
                    />
                );


            case 'video':

                return wrapWithSelection(
                    <Video
                        style={commonProps.style}
                        //commonProps={{ ...commonProps }}
                        src={src}
                    />
                );
            case 'lottie':
                return wrapWithSelection(
                    <>
                        <Lottie
                            style={commonProps.style}
                            //commonProps={{ ...commonProps }}
                            loop={finalProps.loop}
                            animationData={finalProps.animationData}
                        />
                    </>

                );
            case 'nav':
                return wrapWithSelection(
                    <Nav
                        style={commonProps.style}
                        //commonProps={{ ...commonProps }}
                        items={commonProps?.navItems}
                        itemStyle={commonProps?.navItemStyle}
                    />
                );
            case 'profileButton':
                return wrapWithSelection(
                    <ProfileButton
                        style={commonProps.style}
                        //commonProps={{ ...commonProps }}
                        profileThumbnailStyle={commonProps.profileThumbnailStyle}
                        popoverStyle={commonProps.popoverStyle}
                        profileImageStyle={commonProps.profileImageStyle}
                        profileNameStyle={commonProps.profileNameStyle}
                        profileEmailStyle={commonProps.profileEmailStyle}
                        authButtonStyle={commonProps.authButtonStyle}
                        displayMode={finalProps.displayMode}
                    />
                );
            default:
                return null;
        }
    };

    return <div className="selectable-content-viewer-container">{renderComponent(structureState)}</div>;
};

export default SelectableContentViewer;

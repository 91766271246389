import React, { useState, useEffect } from "react";
import "./MiniStackedColumnChart.scss";

const MiniStackedColumnChart = (props: any) => {
  const { data, height = 60, onSubColumnSelect } = props;
  const [items, setItems] = useState([] as any);
  const [selectedSubColumn, setSelectedSubColumn] = useState<any>({ itemIndex: null, subItemIndex: null });

  const addHeightToStackedItems = (items: any, height: number) => {
    const maxTotalValue = Math.max(
      ...items.map((item: any) =>
        item.data.reduce((sum: number, subItem: any) => sum + subItem.value, 0)
      )
    );

    return items.map((item: any) => {
      const periodTotal = item.data.reduce(
        (sum: number, subItem: any) => sum + subItem.value,
        0
      );
      const periodHeight = (periodTotal / maxTotalValue) * height;

      let accumulatedHeight = 0;

      const dataWithHeight = item.data.map((subItem: any) => {
        const subItemHeight = (subItem.value / periodTotal) * periodHeight;
        const posY = accumulatedHeight;
        accumulatedHeight += subItemHeight;

        return {
          ...subItem,
          height: Math.round(subItemHeight),
        };
      });

      return {
        ...item,
        data: dataWithHeight,
        totalHeight: Math.round(periodHeight),
      };
    });
  };

  const handleClick = (itemIndex: number, subItemIndex: number, item: any, subItem: any) => {
    const period = item?.display_period;
    const value = subItem?.value;
    const label = subItem?.label;
    const color = subItem?.color;
    if (onSubColumnSelect) {
      onSubColumnSelect({ period, value, label, color });
    }
    setTimeout(() => {
      setSelectedSubColumn({ itemIndex, subItemIndex });
    }, 100)

  };

  useEffect(() => {
    const newData = addHeightToStackedItems(data, height);
    setItems(newData);

    // Seleccionar la última subcolumna del gráfico por defecto
    if (newData.length > 0) {
      const lastItemIndex = newData.length - 1;
      const lastSubItemIndex = newData[lastItemIndex].data.length - 1;
      setSelectedSubColumn({ itemIndex: lastItemIndex, subItemIndex: lastSubItemIndex });
    }
  }, [data, height]);

  return (
    <div className="mini-stacked-column-chart-container">
      <div className="main-content" style={{ height: height + "px" }}>
        {items?.map((item: any, itemIndex: number) => (
          <div
            key={itemIndex}
            className="column-wrapper"
            style={{ height: height + "px" }}
          >
            {item.data.map((subItem: any, subItemIndex: number) => (
              <a
                key={subItemIndex}
                style={{
                  height: subItem.height + "px",
                  background: subItem.color || "#ccc",
                  borderRight: selectedSubColumn.itemIndex === itemIndex && selectedSubColumn.subItemIndex === subItemIndex ? `2px solid ${subItem.color}` : "none",
                  borderLeft: selectedSubColumn.itemIndex === itemIndex && selectedSubColumn.subItemIndex === subItemIndex ? `2px solid ${subItem.color}` : "none",
                  //borderRadius: selectedSubColumn.itemIndex === itemIndex && selectedSubColumn.subItemIndex === subItemIndex ? `3px` : "0px",
                }}
                onClick={() => handleClick(itemIndex, subItemIndex, item, subItem)}
              ></a>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export { MiniStackedColumnChart };

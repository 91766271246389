import React, { useState, useEffect, useRef } from "react";
import "./RecordLinkingModal.scss";
import Modal from "components/Modal/Modal";
import { Autocomplete } from "components/Autocomplete/Autocomplete";
import Icon from "components/Icon/Icon";
import { Fab, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import ItemFields from "components/ItemFields/ItemFields";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
interface ModalProps {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  searchPath: string;
  linkPath: string;
  readonly?: boolean;
  searchPlaceholder?: string;
  primaryKey: string;
  secondaryKey?: string;
  thumbnailKey?: string;
  data?: any[];
  extraData?: any;
  noContentIcon?: string;
  noContentText?: string;
  itemFields?: any[];
  onClose: (items: any, refreshOnClose?: boolean) => void;
}
const RecordLinkingModal: React.FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  searchPath,
  linkPath,
  readonly = false,
  noContentIcon = 'documents',
  noContentText = 'No hay registros vinculados',
  data = [],
  extraData,
  searchPlaceholder,
  primaryKey,
  secondaryKey,
  thumbnailKey,
  itemFields = [],
  onClose,

}) => {
  const [items, setItems] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  const itemsRef = useRef(items);
  const deleteRecord = (idToDelete: number) => {
    const newItems = items.filter((record: any) => record.id !== idToDelete);
    setItems(newItems);
  };
  const handleSelect = (data: any) => {
    if (data) {
      // Search for a record in the array that matches the id of the record to add
      const existingRecord = items.find((record: any) => record.id === data.id);
      // If the record doesn't exist, add it to the array
      if (!existingRecord) {
        const newItems = [...items, data];
        setItems(newItems);
      }
    }
  };
  const send = async () => {
    try {
      const data = {
        data: items,
      };
      //console.log(data)
      let url = `${urls.server}/api/${linkPath}`;
      const res = await AxiosInterceptor.put(url, data);
      const resData = await res?.data;
      onClose(items, true);
    } catch (error) {

    }

  };
  useEffect(() => {
    if (JSON.stringify(itemsRef.current) !== JSON.stringify(items)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [items]);
  useEffect(() => {
    if (isOpen) {
      setItems(data);
      //setRefreshOnClose(false);
    }
  }, [isOpen]);

  const handleFieldChange = (index: number, res: any) => {
    // Crea una copia del array items
    const newItems = [...items];

    // Elimina la llave extraData si está presente en res
    const { extraData, ...rest } = res;

    // Actualiza el item correspondiente con los nuevos valores de res (sin extraData)
    newItems[index] = {
      ...newItems[index],
      extraData: {
        ...newItems[index].extraData,
        ...rest
      }
    };

    // Actualiza el estado con el nuevo array de items
    setItems(newItems);
  };

  return (

    <>
      <Modal
        title={title}
        subtitle={subtitle}
        className={'record-linking-modal-container'}
        isOpen={isOpen}
        onClose={() => {
          onClose(items, false);
        }}

      >
        {!readonly ?
          <div className="autocomplete-wrapper">
            <div>
              <Autocomplete
                onSelect={handleSelect}
                placeholder={searchPlaceholder}
                primaryKey={primaryKey}
                secondaryKey={secondaryKey}
                thumbnailKey={thumbnailKey}
                searchPath={searchPath}
              />
            </div>
          </div> : null
        }

        {items.length == 0 && (
          <div className="no-content">
            <Icon name={noContentIcon} />
            <p>{noContentText}</p>
          </div>
        )}
        <div className="list-wrapper">
          <div
            style={{
              paddingLeft: thumbnailKey ? "10px" : "0px",
              maxWidth: itemFields.length > 0 ? "700px" : "600px"
            }}
          >
            {items.map((item: any, index: number) => {
              return (
                <div
                  style={{
                    paddingLeft: thumbnailKey ? "70px" : "15px",
                    marginBottom: thumbnailKey ? "25px" : "15px"
                  }}
                  key={index}>
                  {thumbnailKey && (
                    <div className={'thumbnail-wrapper'}>
                      {thumbnailKey && (
                        <img src={urls.server + item[thumbnailKey]} alt="" />
                      )}
                    </div>
                  )}

                  <div className="main-content-wrapper">
                    <strong>{item[primaryKey]}</strong>
                    {secondaryKey ? <span>{item[secondaryKey]}</span> : null}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexWrap: "nowrap",
                    alignItems: "center"
                  }}>
                    {
                      itemFields.length > 0 &&
                      <div className="item-fields-wrapper">
                        <ItemFields extraData={extraData} fields={itemFields} defaultData={item?.extraData} onChange={(res: any) => handleFieldChange(index, res)} />
                      </div>
                      // !readonly && itemFields.length > 0 &&
                      // <div>
                      //   <IconButton
                      //     color="inherit"
                      //     onClick={() => {
                      //       setCurrentItem(item);
                      //       setFormDialogIsOpen(true);
                      //     }}
                      //     aria-label="open form"
                      //   >
                      //     <Icon name="pencil" />
                      //   </IconButton>
                      // </div>
                    }
                    {
                      !readonly ?
                        <div className="remove-btn-wrapper">
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              //setCurrentUser(item);
                              deleteRecord(item?.id);
                            }}
                            aria-label="remove"
                          >
                            <ClearIcon />
                          </IconButton>
                        </div> : null
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          !readonly ?
            <Fab
              className="fab-btn"
              color="primary"
              aria-label="send"
              onClick={() => {
                send();
              }}
              disabled={isDisabled}
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
              }}
            >
              <Icon name="check" />
            </Fab> : null
        }
        {
          // !readonly ?
          //   <FormDialog
          //     title=""
          //     isOpen={formDialogIsOpen}
          //     onClose={() => {
          //       setFormDialogIsOpen(false);
          //     }}
          //     fields={itemFields}
          //   /> : null
        }

      </Modal>

    </>
  );
};

export { RecordLinkingModal };

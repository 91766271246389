export const transformData = (input: any) => {
  // Inicializar estructuras para almacenar el resultado
  let result: any = {
    datasets: [],
    periods: [],
  };

  // Extraer períodos
  result.periods = input.map((entry: any) => entry.display_period);

  // Inicializar datasets
  input[0].data.forEach((datum: any) => {
    result.datasets.push({
      color: datum.color,
      label: datum.label,
      data: [],
    });
  });

  // Llenar los datasets con los datos correspondientes
  input.forEach((entry: any) => {
    entry.data.forEach((datum: any, index: number) => {
      result.datasets[index].data.push(datum.value);
    });
  });

  return result;
};

import { SvgIcon } from "@mui/material";
const DefaultThumbnail = (props: any) => (
  <div
    style={{
      backgroundColor: "#f2f2f4",
      color: "#9cb0be",

      borderRadius: "10px",
      display: "flex",
      width: "50px",
      height: "50px",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <SvgIcon {...props} viewBox="0 0 1000 1000">
      <path d="m 508.58707,37.371154 c -2.29355,0 -4.57817,0.324455 -6.83823,0.616811 C 269.5015,38.833431 76.722491,226.641 70.113841,461.66588 c -0.08031,3.7016 0.289183,7.41288 1.099799,11.03306 v 448.42718 c 0.0025,25.25231 20.306238,45.72146 45.35311,45.72443 h 136.76809 c 25.04688,0 45.35073,-20.47212 45.35312,-45.72443 V 461.87929 c -0.0154,-0.49164 -0.0264,-1.01141 -0.0794,-1.49748 7.28269,-111.04255 96.07853,-195.34239 207.31364,-193.86598 114.09403,1.65759 202.92011,92.41801 202.92011,207.40397 0.0181,3.54422 0.44935,7.06315 1.27632,10.50623 V 921.1317 c 0.002,25.25243 20.30652,45.72443 45.35339,45.73137 h 136.80349 c 25.04706,-0.0149 45.3509,-20.47894 45.35334,-45.73137 V 461.88771 c -0.0145,-6.32847 -1.33022,-12.57974 -3.8621,-18.36068 C 918.26528,222.30585 738.56278,45.247668 516.6235,38.210423 c -2.30125,-0.455203 -4.63595,-0.714965 -6.98002,-0.81188 h -0.35337 -0.0796 -0.0265 c -0.21293,0 -0.39613,0 -0.6022,0 v 0 z" />
    </SvgIcon>
  </div>
);

export { DefaultThumbnail };

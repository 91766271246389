import React, { useEffect } from 'react';
import './IconCell.scss';
import Icon from 'components/Icon/Icon';
import colors from 'config/colors';
import { DynamicIcon } from 'components/DynamicIcon/DynamicIcon';

// Función auxiliar para resolver el color
const resolveColor = (color: string | undefined, colorKey: string | undefined, extraData: any) => {
    if (colorKey && extraData && extraData[colorKey]) {
        return extraData[colorKey]; // Retorna el valor correspondiente de extraData
    }
    if (color === "primary") {
        return localStorage.getItem("primaryColor") || colors.primary;
    }
    return color || localStorage.getItem("primaryColor") || colors.primary;
};

const resolveName = (name: any, extraData: any) => {
    if (name.includes('{{') && name.includes('}}')) {
        const propertyName = name.match(/\{\{(.+?)\}\}/)[1];
        return extraData[propertyName];
    }
    return name;
};

const resolvePaths = (paths: any, pathsKey: string | undefined, extraData: any) => {
    if (pathsKey && extraData && extraData[pathsKey]) {
        return extraData[pathsKey];
    }
    return paths;
};

interface Props {
    name: string;
    color?: string;
    colorKey?: string;
    paths?: any[];
    pathsKey?: string;
    size: number;
    extraData: any;
}

const IconCell: React.FC<Props> = ({ name, color, colorKey, size, paths, pathsKey, extraData }) => {
    useEffect(() => {
        console.log(name);
    }, [name]);

    // Resuelve el color real antes de pasarlo al componente Icon
    const resolvedColor = resolveColor(color, colorKey, extraData);
    const resolvedName = resolveName(name, extraData);
    const resolvedPaths = resolvePaths(paths, pathsKey, extraData);

    return (
        <div className='icon-cell-container'>
            {
                (paths || pathsKey) ? <DynamicIcon paths={resolvedPaths} size={size} /> :
                    <Icon name={resolvedName} color={resolvedColor} size={size} />
            }
        </div>
    );
};

export default IconCell;

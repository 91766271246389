import React, { useState, useEffect } from "react";
import "./WebsiteBuilderExpandedCard.scss";
import { Fab } from "@mui/material";
import Icon from "components/Icon/Icon";
import { Header } from "components/Header/Header";
import DynamicList from "components/DynamicList/DynamicList";
import colors from "config/colors";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import { WebsiteBuilderModal } from "../WebsiteBuilderModal/WebsiteBuilderModal";
const WebsiteBuilderExpandedCard = (props: any) => {
  const { title, config, setRefreshOnClose } = props;
  const [searchTerm, setSearchTerm] = useState('' as any);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [filters, setFilters] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [websiteBuilderModalIsOpen, setWebsiteBuilderModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null as any);
  const handleRefreshComplete = () => {
    setShouldRefresh(false);
  };
  const handleSearchChange = (res: string) => {
    setSearchTerm(res)
  }
  const handleSelectItemAction = (res: any) => {
    console.log(res);
    if (res?.actionName == 'edit') {
      setCurrentItem(res?.currentItem)
    }
  };
  const handleChangeItem = (val: any, item: any, index: number) => {
    setShouldRefresh(true);
    setRefreshOnClose(true);
  }
  const createVersion = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/website-versions`
      );
      const resData = res.data;
      setCurrentItem(resData?.data);

    } catch (error) {

    } finally {
      setProcessing(false);
    }
  };
  useEffect(() => {
    if (currentItem) {
      setWebsiteBuilderModalIsOpen(true)
    }

  }, [currentItem])

  return (
    <>
      <div className="website-builder-expanded-card-container">
        <Header
          title={title}
          hasSearchBar
          filters={filters}
          onSearchChange={handleSearchChange}

        />
        <DynamicList
          className="data-list-container"
          leftCells={[
            {
              type: 'text',
              name: 'name'
            }
          ]}
          limit={10}
          listPath={'website-versions'}
          noContentText={'No hay versiones creadas'}
          noContentIcon={'websiteBuilder'}
          onSelectItemAction={handleSelectItemAction}
          itemActions={[
            {
              type: "customAction",
              name: "edit",
              displayName: "Editar",
              icon: "pencil",
            }
          ]}
          rightCells={[
            {
              type: "playPause",
              sendPath: "website-versions/{{id}}/is-published",
              name: "is_published",
            }
          ]}
          forceRefresh={shouldRefresh}
          onRefreshComplete={handleRefreshComplete}
          searchTerm={searchTerm || ""}
          onChangeItem={handleChangeItem}
          itemStyle={{
            filter: "none",
            backgroundColor: colors.light
          }}
          hiddenActiveFilters={config?.hiddenActiveFilters || []}
          activeFilters={[]}

        />

        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          disabled={processing}
          onClick={() => {
            createVersion();
          }}

        >
          <Icon name="add" sx={{ mr: 0 }} />
        </Fab>
        <WebsiteBuilderModal
          isOpen={websiteBuilderModalIsOpen}
          onClose={(refresh) => {
            setWebsiteBuilderModalIsOpen(false)
            setCurrentItem(null);
            console.log(refresh)
            if (refresh == true) {
              setShouldRefresh(true);
            }
            //setShouldRefresh(true);
          }}
          versionId={currentItem?.id}
          title={currentItem?.name}
          pages={currentItem?.config?.pages}
        />
      </div>
    </>
  );
};

export { WebsiteBuilderExpandedCard };

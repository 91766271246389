import React from 'react';
import './ActionButtons.scss';
import { IconButton, Button } from '@mui/material';
import Icon from 'components/Icon/Icon';

interface Action {
    name: string;
    displayName: string;
    icon: string;

}

interface ActionButtonsProps {
    actions: Action[];
    onSelectAction: (action: Action) => void;
    showDisplayName?: boolean;
    disabled?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ actions, onSelectAction, showDisplayName = true, disabled = false }) => {
    return (
        <div className='action-buttons-container'>
            {actions.map((action, index) => {
                return showDisplayName ? (
                    <Button
                        key={index}
                        size="small"
                        startIcon={<Icon name={action.icon} />}
                        onClick={() => {
                            onSelectAction(action);
                        }}
                        disabled={disabled}
                    >
                        {action.displayName || action.name}
                    </Button>
                ) : (
                    <IconButton
                        key={index}
                        color="inherit"
                        onClick={() => {
                            onSelectAction(action);
                        }}
                        aria-label={action.name}
                        disabled={disabled}
                    >
                        <Icon name={action.icon} />
                    </IconButton>
                );
            })}
        </div>
    );
};

export default ActionButtons;


import React, { useState, useEffect } from 'react';
import AxiosInterceptor from "../../AxiosInterceptor";
import urls from 'config/urls';
import './NotificationBadge.scss';
const NotificationBadge = () => {
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await AxiosInterceptor.get(`${urls.server}/api/notifications/count-unread`);
                setUnreadCount(response.data.unread_count);
            } catch (error) {
                console.error('Error fetching unread notifications count:', error);
                // Manejo de errores o establecer el contador en un estado de error si es necesario
            }
        };

        // Llamada inmediata para obtener la cuenta al cargar
        fetchUnreadCount();

        // Establecer el intervalo para llamar a la función cada 10 segundos
        const intervalId = setInterval(fetchUnreadCount, 10000);

        // Limpiar intervalo al desmontar componente
        return () => clearInterval(intervalId);
    }, []);

    // Si no hay notificaciones no leídas, no mostrar el badge
    if (unreadCount === 0) {
        return null;
    }

    return (
        <div className="notification-badge-container">
            <span>{unreadCount}</span>
        </div>
    );
};

export default NotificationBadge;

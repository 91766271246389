import { useState, useEffect, useRef } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { IconButton } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ImagesField.scss";
import { Spinner } from "components/Spinner/Spinner";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import Icon from "components/Icon/Icon";
const ImagesField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    name,
    accept = "image/*",
    id,
    maxItems,
    defaultValue,
    folder = "images",
    //readonly,
    mode
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [queuedFiles, setQueuedFiles] = useState([] as any);
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = async (e: any, value: any) => {
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file?.name.split(".").pop();
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      const data = {
        content: reader.result,
        extension,
        folder: folder,
      };
      saveImage(value, data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    // Resetear el valor del input de archivo
    e.target.value = null;
  };
  const removeImg = (index: number) => {
    const valueCopy = [...value];
    valueCopy.splice(index, 1);
    setValue(valueCopy);
  };

  const saveImage = async (value: any, { content, extension, folder }: any) => {
    const queuedFilesCopy = [...queuedFiles];
    queuedFilesCopy.push("");
    setQueuedFiles(queuedFilesCopy);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        file_content: content,
        extension,
        folder,
      });
      const resData = await res?.data;
      const valueCopy = value !== null ? [...value] : [];
      valueCopy.push(resData?.path);

      setValue(valueCopy);
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    } catch (error: any) {
      const queuedFilesCopy = [...queuedFiles];
      queuedFilesCopy.pop();
      setQueuedFiles(queuedFilesCopy);
    }
  };

  return (
    <div className="images-field-container field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}</div>
        )}
      </div>
      <div className="field-body">
        {mode == 'readonly' ||
          (mode == 'edit' && editable && !isEditEnabled) ||
          (mode == 'edit' && !editable) ? (
          <div className="readonly-content">
            {value && value.length > 0 ? (
              value.map((item: any, index: number) => {
                return (
                  <div key={index} className="img-preview-wrapper">
                    <img src={`${urls.server}${item}`} />
                  </div>
                );
              })
            ) : (
              <div className="no-value">
                <InsertPhotoIcon />
                <span>Sin imagenes</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {((maxItems && value?.length < maxItems) || maxItems == null) && (
              <div className="add-image-btn">
                <input
                  id={id}
                  accept={accept}
                  type="file"
                  onChange={(e) => handleChange(e, value)}
                  ref={fileInputRef}
                />
                <div>
                  <div>
                    <InsertPhotoIcon />
                    <span>Seleccionar imagen</span>
                  </div>
                  <label htmlFor={id}></label>
                </div>
              </div>
            )}

            {queuedFiles.length > 0 && (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            )}

            {value?.length > 0 && (
              <>
                {value.map((item: any, index: number) => {
                  return (
                    <div key={index} className="img-preview-wrapper">
                      <img src={`${urls.server}${item}`} />
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeImg(index)}
                      >
                        <Icon name="delete" />
                      </IconButton>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ImagesField };

import React from "react";
import "./CommentItem.scss";
import urls from "config/urls";
const CommentItem = ({ data }: any) => {
  /**
   * data.is_from_authenticated_user
   *
   */
  const commentClass = data.is_from_authenticated_user
    ? "my-comment"
    : "other-comment";
  return (
    <div className={`comment-item-container ${commentClass}`}>
      <img src={urls.server + data?.user?.profile_picture} alt="" style={{}} />
      <div className="speech-bubble">
        <strong>{data?.user?.name}</strong>
        {data.content}
        <span>{data?.updated_at_diff}</span>
      </div>
    </div>
  );
};

export { CommentItem };

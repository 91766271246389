import React from "react";
import "./ActiveFilters.scss";
import { ActiveFilterItem } from "./ActiveFilterItem";
import "./ActiveFilterItem.scss";
const ActiveFilters = ({ filters, onRemoveFilter }: any) => {
  const handleRemove = (index: number) => {
    const updatedFilter = { ...filters[index], selected: false, value: null };
    onRemoveFilter(index, updatedFilter);
  };

  return (
    <div className="active-filters-container">
      {filters.map((filter: any, index: number) => {
        if (filter.selected) {
          return (
            <ActiveFilterItem
              key={index}
              filter={filter}
              onDelete={() => handleRemove(index)}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export { ActiveFilters };

import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText, DialogTitle } from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Toast } from "components/Toast/Toast";
import PillTabs from "components/tabs/PillTabs/PillTabs";
import ReactJson from "react-json-view";
import "./ImportConfigDialog.scss";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LongTextField } from "components/fields/LongTextField/LongTextField";

interface DialogProps {
  isOpen: boolean;
  subtitle: string;
  description?: string;
  sendPath?: string;
  defaultData?: any;
  size?: 'lg' | 'md' | 'sm';
  onClose: () => void;
  onSubmit?: (data: any) => void;
}
const ImportConfigDialog: React.FC<DialogProps> = ({
  isOpen,
  subtitle,
  description,
  onClose,
  onSubmit,
  defaultData,
  size = "md",
  sendPath,
}) => {
  const [data, setData] = useState({} as any);
  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const [currentTab, setCurrentTab] = useState(0);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target!.result as string;
        setData(JSON.parse(data));
        setDisabledSaveBtn(false);
      };
      reader.readAsText(file);
    }
  };
  const handleSend = async () => {
    setProcessing(true);
    const dataToSend = data;
    try {
      const url = `${urls.server}/api/${sendPath}`;
      const res = await AxiosInterceptor.put(url, dataToSend);
      const data = await res?.data;
      const { message } = data;
      setToastMessage(message.toString());
      onClose();
      setToastIsOpen(true);
      setToastSeverity("success");
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        className="import-config-dialog-container"
        fullWidth
        open={isOpen}
        maxWidth={size}
        onClose={handleClose}
      >
        <DialogTitle className="import-config-dialog-title">{'Importar'}</DialogTitle>
        <div className="import-config-dialog-subtitle">{subtitle}</div>
        <DialogContent>
          {
            description ? <DialogContentText className="import-config-dialog-description">
              {description}
            </DialogContentText> : null
          }

          <div className='tabs-wrapper'>
            <PillTabs
              options={['Pegar', 'Subir archivo']}
              onTabChange={(tabIndex: any) => {
                console.log(tabIndex);
                setCurrentTab(tabIndex)
              }}
            />
          </div>
          <div>
            {
              currentTab == 0 ?
                <div>
                  <LongTextField
                    placeholder={'Pegar JSON'}
                    onChange={(res: any) => {
                      console.log(res);
                      setData(res ? JSON.parse(res) : null)
                    }}
                  />
                </div> : <div>
                  <div className="upload-button-wrapper">
                    {!data ? (
                      <>
                        <input
                          ref={uploadInputRef}
                          type="file"
                          accept=".json"
                          className="upload-input"
                          onChange={onPreviewUpload}
                        />
                        <Button
                          className="upload-button"
                          onClick={() =>
                            uploadInputRef.current && uploadInputRef.current.click()
                          }
                          variant="text"
                        >
                          <strong>Seleccionar</strong>
                          <UploadFileIcon />
                        </Button>
                      </>
                    ) : (
                      <div className="json-preview">
                        <Button
                          onClick={() => {
                            setData(null);
                            setDisabledSaveBtn(true);
                          }}
                        >
                          Volver a cargar
                        </Button>
                        <React.Fragment>
                          <ReactJson src={data} />
                        </React.Fragment>
                      </div>
                    )}
                  </div>
                </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={processing}
            onClick={() => {
              if (sendPath) {
                handleSend();
              } else {
                onClose();
                if (onSubmit) onSubmit(data);
              }
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />

    </>
  );
};

export { ImportConfigDialog };

import React, { useState } from "react";

import "./RankingExpandedCard.scss";
const RankingExpandedCard = (props: any) => {
  const { title, data } = props;

  return (
    <>
      <div className="ranking-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body"></div>
      </div>
    </>
  );
};

export { RankingExpandedCard };

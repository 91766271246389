import React, { useEffect, useState } from "react";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./UserTabs.scss";
import Icon from "components/Icon/Icon";

const UserTabs = ({
  onChange,
  listPath,
  forceRefresh,
  onRefreshComplete
}: any) => {
  const [records, setRecords] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(null as any);

  const loadRecords = async () => {
    try {
      setLoading(true);
      const res = await AxiosInterceptor.get(`${urls.server}/api/${listPath}`);
      const data = await res?.data;
      setRecords(data?.tabs);
      setLoading(false);
      if (onRefreshComplete) {
        onRefreshComplete();
      }
    } catch (error) {
      setLoading(false);
      if (onRefreshComplete) {
        onRefreshComplete();
      }
    }

  };
  useEffect(() => {
    loadRecords();
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const selectTab = (index: number) => {
    setSelectedTabIndex(index);
    console.log(index);
  };
  useEffect(() => {
    onChange(selectedTabIndex != null ? records[selectedTabIndex] : null);
  }, [selectedTabIndex]);
  useEffect(() => {
    if (forceRefresh) {
      loadRecords();
      setSelectedTabIndex(null);
    }
  }, [forceRefresh]);
  return (
    <Box
      className="user-tabs-container"
      sx={{ maxWidth: { xs: 320, sm: 480, lg: 1100 } }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Usuarios"
      >
        <Tab
          component="a"
          label={
            <div className={selectedTabIndex == null ? "all selected" : "all"}>
              <div
                className="icon-wrapper"
                style={{
                  background: selectedTabIndex == null ? "transparent" : "#fff",
                }}
              >
                <Icon name="users" />
              </div>

              <span>Todos</span>
            </div>
          }
          style={{}}
          //value={value}
          onClick={() => {
            setSelectedTabIndex(null);
          }}
        />
        {records.map((item: any, index: number) => (
          <Tab
            component="a"
            key={index}
            label={
              <div className={selectedTabIndex === index ? "selected" : ""}>
                {item.count && <div className="badge">{item.count}</div>}
                <img src={`${urls.server}${item?.profile_picture}`} />
                {selectedTabIndex === index && <span>{item.name}</span>}
              </div>
            }
            style={{}}
            //value={value}
            onClick={() => selectTab(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export { UserTabs };

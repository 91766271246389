import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText, DialogTitle } from "@mui/material";
import ReactJson from "react-json-view";
import "./ExportConfigDialog.scss";
import { Toast } from "components/Toast/Toast";

interface DialogProps {
  isOpen: boolean;
  subtitle: string;
  description?: string;
  data?: any;
  size?: 'lg' | 'md' | 'sm';
  onClose: () => void;
}
const ExportConfigDialog: React.FC<DialogProps> = ({
  isOpen,
  subtitle,
  description,
  onClose,
  data,
  size = "md",
}) => {

  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const handleCopy = () => {
    if (data) {
      const jsonString = JSON.stringify(data, null, 2);
      navigator.clipboard.writeText(jsonString).then(() => {
        setToastIsOpen(true)
        //alert("JSON copiado al portapapeles");
      }).catch(err => {
        alert("Error al copiar JSON: " + err);
      });
    }
  };

  const handleDownload = () => {
    if (data) {
      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "config.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        className="export-config-dialog-container"
        fullWidth
        open={isOpen}
        maxWidth={size}
        onClose={handleClose}
      >
        <DialogTitle className="export-config-dialog-title">{'Exportar'}</DialogTitle>
        <div className="export-config-dialog-subtitle">{subtitle}</div>
        <DialogContent>
          {description && (
            <DialogContentText className="export-config-dialog-description">
              {description}
            </DialogContentText>
          )}
          <div className="json-preview">
            <React.Fragment>
              <ReactJson src={data} />
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={processing}
            onClick={handleCopy}
          >
            Copiar
          </Button>
          <Button
            disabled={processing}
            onClick={handleDownload}
          >
            Descargar
          </Button>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={'JSON copiado al portapapeles'}
        severity={'success'}
      />
    </>
  );
};

export { ExportConfigDialog };

import React from "react";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "@mui/material";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { useAuth } from "../../../contexts/AuthContext";
const ConfirmWhatsappNumberStep = ({ title, completed, onComplete }: any) => {
  // const handleCompletion = () => {
  //   // Do whatever needs to be done to complete the step,
  //   // then call the onComplete prop.
  //   onComplete();
  // };
  const [processing, setProcessing] = React.useState(false);
  const { setUser } = useAuth();
  const handleCompletion = async () => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/verify-whatsapp`
      );
      const data = await res?.data;
      setUser(data.user);
      setProcessing(false);
      onComplete();
    } catch (error: any) {
      setProcessing(false);
      // setAlertMsg(error.toString());
      // setAlertIsOpen(true);
    }
  };
  return (
    <div className="step">
      <div className="step-status">
        {completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
      </div>
      <div className="step-content">
        <h2>{title}</h2>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum magnam
          error dolor voluptatem eveniet voluptatibus totam maxime ipsum
          consectetur, recusandae ipsa aspernatur amet quibusdam fugiat sunt
          saepe architecto quaerat porro?
          {!completed && <Button onClick={handleCompletion}>Completar</Button>}
        </div>
      </div>
    </div>
  );
};

export { ConfirmWhatsappNumberStep };

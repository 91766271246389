import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomePage = ({ startupMode, homePage }: { startupMode: string; homePage: string }) => {
    const navigate = useNavigate();

    useEffect(() => {
        //alert(startupMode)
        console.log(startupMode)
        if (startupMode === "publicPages") {
            navigate(homePage);
        } else {
            navigate(homePage);
            //navigate("/profile");
        }
    }, [startupMode, homePage, navigate]);

    return <div>Loading...</div>; // Puede mostrar un spinner o algo visual mientras redirige.
};

export default HomePage;

import React, { useState, useEffect } from "react";
import SearchPlace from "../../SearchPlace/SearchPlace";
import "./CoordinatesField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";

const CoordinatesField = (props: any) => {
  let {
    defaultValue,
    editable,
    label,
    onChange,
    //readonly,
    recordId,
    editPath,
    name,
    mode
  } = props;

  // Asegurándonos de que defaultValue es un objeto con las propiedades lat y lng
  defaultValue = defaultValue || { lat: 43.45, lng: -80.49 };

  const [enabledEdition, setEnabledEdition] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const handleLatChange = (e: any) => {
    const newValue = { ...value, lat: Number(e.target.value) };
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleLongChange = (e: any) => {
    const newValue = { ...value, lng: Number(e.target.value) };
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  // useEffect(() => {
  //   onChange(value);
  // }, [value]);

  return (
    <div className="field-container coordinates-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {editable && (
          <div>
            {(mode == 'edit' && editable) && (
              <FieldEditControls
                value={value}
                fieldName={name}
                editPath={editPath}
                onEditStart={() => {
                  setIsEditEnabled(true);
                }}
                onEditCancel={(originalValue) => {
                  setIsEditEnabled(false);
                  setValue(originalValue);
                }}
                onEditEnd={(success: boolean, originalValue) => {
                  setIsEditEnabled(false);
                  if (!success) {
                    setValue(originalValue);
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-content">
              <span>{`Latitud: ${value?.lat}, Longitud: ${value?.lng}`}</span>

            </div>
          ) : (
            <div className="editable-content">
              <div>
                <div>
                  <div className="input-wrapper">
                    <label>Latitud</label>
                    <div>
                      <input
                        type="number"
                        value={value?.lat}
                        onChange={handleLatChange}
                      />
                    </div>

                    {/* <InputField
                  label={"Latitud:"}
                  size="sm"
                  defaultValue={value.lat}
                  onChange={(val: any) => {
                    console.log(val);
                    const newValue = { ...value, lat: val };
                    setValue(newValue);
                    if (onChange) onChange(newValue);
                  }}
                /> */}
                  </div>
                </div>
                <div>
                  <div className="input-wrapper">
                    <label>Longitud</label>
                    <div>
                      <input
                        type="number"
                        value={value?.lng}
                        onChange={handleLongChange}
                      />
                    </div>
                    {/* <InputField
                  label={"Longitud:"}
                  size="sm"
                  defaultValue={value.lng}
                  onChange={(val: any) => {
                    console.log(val);
                    const newValue = { ...value, lng: val };
                    setValue(newValue);
                    if (onChange) onChange(newValue);
                  }}
                /> */}
                  </div>
                </div>
              </div>
              <div>
                <SearchPlace
                  onChange={(value: any) => {
                    console.log(value);

                    const newValue = {
                      ...value,
                      lat: Number(value.lat),
                      lng: Number(value.lng),
                    };
                    setValue(newValue);
                    if (onChange) onChange(newValue);
                  }}
                  coordinates={defaultValue}
                />
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export { CoordinatesField };

import { useState, useEffect } from "react";
import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import { Snackbar, Alert, IconButton } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import "./CameraImageField.scss";
import { Spinner } from "../../Spinner/Spinner";
import Icon from "components/Icon/Icon";
const CameraImageField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    tableName,
    name,
    recordId,
    accept = "image/*",
    id,
    defaultValue,
    folder = "images",
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [enabledEdition, setEnabledEdition] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const enableEdition = () => {
    setEnabledEdition(true);
  };
  useEffect(() => {
    if (onChange) {
      console.log("value", value);
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);
  const editValue = async () => {
    if (tableName) tableName = tableName.replace(/_/g, "-");
    const url = tableName
      ? `${urls.server}/api/${tableName}/${recordId}/update-${name}`
      : `${urls.server}/api/${editPath}`;
    setProcessing(true);
    try {
      const data = {
        data: value,
      };
      const res = await AxiosInterceptor.post(`${url}`, data);
      const resData = await res?.data;

      setEnabledEdition(false);
      setProcessing(false);
    } catch (error: any) {
      setEnabledEdition(false);
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
    }
  };
  const handleCloseAlert = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleChange = async (e: any) => {
    var reader = new FileReader();
    const file: File = e.target.files[0];
    const extension = file.name.split(".").pop();
    console.log(extension);
    reader.readAsDataURL(file);
    reader.onload = await function () {
      console.log(reader.result);
      const data = {
        content: reader.result,
        extension,
        folder: folder,
      };
      saveImage(data);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const removeImg = () => {
    setValue(null);
  };

  const saveImage = async ({ content, extension, folder }: any) => {
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.post(`${urls.server}/api/images`, {
        file_content: content,
        extension,
        folder,
      });
      const resData = await res?.data;
      setValue(resData?.path);
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
    }
  };
  return (
    <div className="images-field-container">
      {label && (
        <label>
          <span>{label}</span>
        </label>
      )}

      <div className="content">
        {(recordId || editPath) && editable && !enabledEdition ? (
          <div className="readonly-content">
            {value ? (
              <div className="img-preview-wrapper">
                <img src={`${urls.server}${value}`} />
              </div>
            ) : (
              <div className="no-value">
                <InsertPhotoIcon />
                <span>Sin imagen</span>
              </div>
            )}
          </div>
        ) : (
          <div className="editable-content">
            {processing ? (
              <div className="spinner-wrapper">
                <Spinner size="sm" />
              </div>
            ) : (
              <>
                {!value && (
                  <div className="add-image-btn">
                    <input
                      id={id}
                      accept={accept}
                      type="file"
                      onChange={handleChange}
                    />
                    <div>
                      <div>
                        <InsertPhotoIcon />
                        <span>Seleccionar imagen</span>
                      </div>
                      <label htmlFor={id}></label>
                    </div>
                  </div>
                )}
                {value && (
                  <div className="img-preview-wrapper">
                    <img src={`${urls.server}${value}`} />
                    <IconButton aria-label="delete" onClick={removeImg}>
                      <Icon name="delete" />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {(recordId || editPath) && editable && (
        <div className="btns">
          {enabledEdition ? (
            <>
              <button onClick={editValue}>
                <Icon name="check" />
              </button>
              <button
                onClick={() => {
                  setEnabledEdition(false);
                  setValue(null);
                }}
              >
                <Icon name="close" />
              </button>
            </>
          ) : (
            <>
              <button onClick={enableEdition}>
                <Icon name="pencil" />
              </button>
            </>
          )}
        </div>
      )}

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { CameraImageField };

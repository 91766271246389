import React, { useState, useEffect } from "react";
import "./TextFilter.scss";

const TextFilter = ({ label, availableOperators, initialOperator, initialValue, onChange }: any) => {
  const [selectedOperator, setSelectedOperator] = useState(initialOperator || "equal");
  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    const isActive = () => {
      if (selectedOperator === "isNull" || selectedOperator === "isNotNull") {
        return true;
      }
      return value !== null && value !== "";
    };

    if (onChange) {
      onChange({
        operator: selectedOperator,
        value: value,
        active: isActive()
      });
    }
  }, [selectedOperator, value]);

  const handleOperatorChange = (operator: string) => {
    setSelectedOperator(operator);
    if (operator === "isNull" || operator === "isNotNull") {
      setValue("");
    }
  };

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="text-filter-container">
      <label className="filter-label">{label}</label>
      <div className="radio-wrapper">
        {availableOperators.map((item: any, index: number) => (
          <a
            key={index}
            className={selectedOperator === item.name ? "selected" : ""}
            onClick={() => handleOperatorChange(item.name)}
          >
            {item.displayName}
          </a>
        ))}
      </div>
      {selectedOperator !== "isNull" && selectedOperator !== "isNotNull" && (
        <div className="value-wrapper">
          <input
            type="text"
            onChange={(e) => handleValueChange(e.target.value)}
            value={value || ""}
          />
        </div>
      )}
    </div>
  );
};

export { TextFilter };

import { useState, useEffect, Fragment } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import {
  IconButton,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import "./WorkflowListItem.scss";
import { WorkflowStepperCell } from "../WorkflowStepperCell/WorkflowStepperCell";
import { StepBuilderModal } from "../StepBuilderModal/StepBuilderModal";
import { PlayPauseCell } from "../../../../components/cells/PlayPauseCell/PlayPauseCell";
import { FormModal } from "../../../../components/modals/FormModal/FormModal";
import DeleteConfirmationDialog from "components/dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Icon from "components/Icon/Icon";


const WorkflowListItem = ({
  data,
  id,
  onDelete,
  onChange,
  //onSelectPopoverItem,
  resetState,
  workflowFields,
  workflowUserFields,
  workflowStepFields,
  workflowStepUserFields
}: any) => {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [userFormIsOpen, setUserFormIsOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [stepBuilderModalIsOpen, setStepBuilderModalIsOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const closeConfirmDialog = () => {
    setConfirmDialogIsOpen(false);
  };
  const presentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const closeAlert = () => {
    setOpenAlert(false);
  };
  const removeRecord = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.delete(
      `${urls.server}/api/workflows/${data?.id}`
    );
    console.log(res);
    onDelete(true);
    setProcessing(false);
    closeAlert();
    closePopover();
    //setRefreshOnClose(true);
  };
  const exportWorkflow = async () => {
    setProcessing(true);
    const res = await AxiosInterceptor.get(
      `${urls.server}/api/workflows/${data?.id}/export`
    );

    // Crear un Blob con el contenido JSON
    const blob = new Blob([JSON.stringify(res?.data)], {
      type: "application/json",
    });

    // Crear una URL Blob para el Blob
    const url = URL.createObjectURL(blob);

    // Crear un enlace para descargar
    const link = document.createElement("a");
    link.href = url;
    link.download = `${res?.data?.file_name}.json`; // Nombre del archivo
    link.click();

    // Liberar la URL Blob
    URL.revokeObjectURL(url);

    closePopover();
  };
  // const fields = getFields().workflow;
  // const userfields = getFields().workflowUsers;



  return (
    <div className="workflow-list-item-container">
      <div>
        {/* <Icon name="workflow"/> */}
        <div>
          <span>{data?.display_id}</span>
          <strong>{data?.display_name}</strong>
          {data?.startable_by_any_user && (
            <div>* Lo inicia cualquier usuario</div>
          )}
        </div>
      </div>
      <div>
        <div style={{ marginRight: "10px" }}>
          {data?.steps?.length > 0 ? (
            <WorkflowStepperCell steps={data?.steps} />
          ) : (
            <div className="no-content">
              <Icon name="steps" />
              <span>No hay pasos cargados</span>
            </div>
          )}
        </div>
        <div style={{ marginRight: "10px" }}>
          <PlayPauseCell
            editPath={`workflows/${data?.id}/is-active`}
            value={data?.is_active}
          />
        </div>
        <IconButton aria-label="delete" onClick={presentPopover}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={"workflow-popover-" + id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popover-items">
            <a
              onClick={() => {
                // onSelectPopoverItem({
                //   action: "edit",
                //   data: data,
                // });
                setFormIsOpen(true);
                setAnchorEl(null);
              }}
            >
              Editar
            </a>
            <a
              onClick={() => {
                setUserFormIsOpen(true);
                setAnchorEl(null);
              }}
            >
              Usuarios
            </a>
            <a
              onClick={() => {
                setStepBuilderModalIsOpen(true);
                setAnchorEl(null);
              }}
            >
              {/* {data?.steps?.length > 0 ? "Editar" : "Crear"} pasos */}
              Pasos
            </a>
            <a
              onClick={() => {
                exportWorkflow();
              }}
            >
              Exportar config.
            </a>
            <a
              onClick={() => {
                {
                  setConfirmDialogIsOpen(true);
                  setAnchorEl(null);
                  /** deletePath -> workflows/{data.id} */
                }
              }}
            >
              Eliminar
            </a>
          </div>
        </Popover>

        <Dialog
          open={openAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Quitar"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Desea eliminar el grupo <b>{data?.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus>
              Cancelar
            </Button>
            <Button onClick={removeRecord}>Aceptar</Button>
          </DialogActions>
        </Dialog>

        <StepBuilderModal
          open={stepBuilderModalIsOpen}
          setOpen={setStepBuilderModalIsOpen}
          workflowId={data?.id}
          workflowDisplayName={data?.display_name}
          workflowStepFields={workflowStepFields}
          workflowStepUserFields={workflowStepUserFields}
          onClose={(data: any) => {
            if (data?.refresh) {
              console.log("refrescar");
              onChange(true);
            }
          }}
        />

        <FormModal
          isOpen={formIsOpen}
          fields={workflowFields}
          title={"Workflow"}
          //tableName={"workflows"}
          //recordId={data?.id}
          mode={'edit'}
          onClose={(res: any) => {
            setFormIsOpen(false)
            console.log(res);
            if (res) {
              onChange(true);
              // setHasMore(true);
              // setOffset(0);
              // loadRecords({ offset: 0 });
            }
          }}
        />
        <FormModal
          isOpen={userFormIsOpen}
          fields={workflowUserFields}
          title={"Usuarios"}
          //tableName={"workflows"}
          //recordId={data?.id}
          mode={'edit'}
          //extraData={{ workflow_id: value?.workflow_id }}
          onClose={(res: any) => {
            console.log(res);
            setUserFormIsOpen(false)
            if (res) {
              onChange(true);
              // setHasMore(true);
              // setOffset(0);
              // loadRecords({ offset: 0 });
            }
          }}
        />
        <DeleteConfirmationDialog
          open={confirmDialogIsOpen}
          onClose={() => setConfirmDialogIsOpen(false)}
          onConfirm={closeConfirmDialog}
          data={data}
          resetState={resetState}
          deletePath={`workflows/${data.id}`}
          confirmationText={
            <>
              Desea eliminar el registro <b>{data?.display_id}</b>
            </>
          }
        />
      </div>
    </div>
  );
};

export { WorkflowListItem };

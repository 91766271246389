import React, { useState, useEffect } from 'react';
import Icon from 'components/Icon/Icon';
import { Fab } from "@mui/material";
import axios from 'axios';
import urls from 'config/urls';
import './FileItem.scss';
import Paper from 'components/Paper/Paper';
import { ImageViewerModal } from 'components/modals/ImageViewerModal/ImageViewerModal';
import { PDFViewerModal } from 'components/modals/PDFViewerModal/PDFViewerModal';
import TextLines from 'components/TextLines/TextLines';

interface FileItemProps {
    file: {
        id: number;
        name: string;
        type: string;
        path: string;
    };
    isPrivate: boolean;
    canDelete: boolean;
    canLinkToUser: boolean;
    onDelete: (fileId: number) => void;
    onClick?: () => void;
    mode?: 'all' | 'my'; // Agregado para definir el modo
}

const FileItem: React.FC<FileItemProps> = ({
    file,
    isPrivate,
    canLinkToUser,
    canDelete,
    mode = 'my',
    onDelete,
    onClick,
}) => {
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [imageViewerModalIsOpen, setImageViewerModalIsOpen] = useState(false);
    const [pdfViewerModalIsOpen, setPdfViewerModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Estado para manejar la precarga
    const fileTypesWithPreview = [
        'pdf',
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg'
    ];
    const fileExtension = file.type.toLowerCase();

    useEffect(() => {
        const fetchFileUrl = async () => {
            setIsLoading(true); // Iniciar la precarga
            if (isPrivate && fileTypesWithPreview.includes(fileExtension)) {
                try {
                    const response = await axios.get(`${urls.server}/api/private-files/${file.id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'X-Mode': mode
                        },
                        responseType: 'blob'
                    });
                    const mimeType = response.headers['content-type'];
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
                    setFileUrl(url);
                } catch (error) {
                    console.error('Error fetching the file:', error);
                } finally {
                    setIsLoading(false); // Finalizar la precarga
                }
            } else if (fileTypesWithPreview.includes(fileExtension)) {
                setFileUrl(`${urls.server}${file.path}`);
                setIsLoading(false); // Finalizar la precarga
            }
        };

        fetchFileUrl();
    }, [file.id, isPrivate, mode, fileExtension]);

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${urls.server}/api/private-files/${file.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'X-Mode': mode
                },
                responseType: 'blob'
            });
            const mimeType = response.headers['content-type'];
            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;

            // Asegurarse de que el nombre del archivo tenga la extensión
            const fileName = file.name.includes(`.${fileExtension}`) ? file.name : `${file.name}.${fileExtension}`;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleFileClick = () => {
        if (fileExtension == 'pdf') {
            setPdfViewerModalIsOpen(true);
        } else {
            setImageViewerModalIsOpen(true);
        }
        // if (fileTypesWithPreview.includes(fileExtension)) {
        //     setImageViewerModalIsOpen(true);
        // }
    };

    return (
        <div className="file-item-container">
            <div className="file-info">
                <Paper size='sm'>
                    <a className='file-content' onClick={handleFileClick}>
                        {fileTypesWithPreview.includes(fileExtension) ? (
                            <div className="file-preview">
                                {isLoading ? (
                                    <img src={"assets/placeholder-image.svg"} alt="loading" width="100%" />
                                ) : (
                                    fileUrl &&
                                    <>
                                        {
                                            fileExtension == 'pdf' ? <div className='file-type'>
                                                {file?.type}</div> : <img src={fileUrl} alt={file.name} width="100%" />
                                        }

                                    </>
                                )}
                            </div>
                        ) : <div className='file-type'>
                            {file?.type}</div>}
                        <div className='file-name'>

                            <TextLines
                                //className="ring-binder-label vertical"
                                //style={{ top: labelTop, left: labelLeft }}
                                text={file?.name}
                                maxCharsPerLine={14}
                                maxLines={3}
                            />
                        </div>
                    </a>

                    <div className="file-actions">
                        <Fab color="default" aria-label="download" size="small" onClick={handleDownload}>
                            <Icon name="download" />
                        </Fab>
                        {canDelete ? (
                            <Fab color="warning" aria-label="delete" size="small" onClick={() => onDelete(file.id)}>
                                <Icon name="delete" />
                            </Fab>
                        ) : ''}
                        {canLinkToUser && (
                            // <Fab color="primary" aria-label="link" size="small">
                            //     <Icon name="link" />
                            // </Fab>
                            <></>
                        )}
                    </div>
                </Paper>
            </div>
            <ImageViewerModal
                isOpen={imageViewerModalIsOpen}
                onClose={() => {
                    setImageViewerModalIsOpen(false);
                }}
                title={file?.name}
                fileUrl={fileUrl}
            />
            <PDFViewerModal
                isOpen={pdfViewerModalIsOpen}
                onClose={() => {
                    setPdfViewerModalIsOpen(false);
                }}
                title={file?.name}
                fileUrl={fileUrl}
            />
        </div>
    );
}

export default FileItem;

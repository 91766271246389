import React, { useState, useEffect, useRef } from "react";
import { Fab, Button } from "@mui/material";
import "./ImportModal.scss";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReactJson from "react-json-view";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import Modal from "components/Modal/Modal";
import { Toast } from "components/Toast/Toast";
import Icon from "components/Icon/Icon";

interface ModalProps {
  isOpen: boolean;
  importPath: string | null;
  importType: 'singleRecord' | 'multipleRecords';
  subtitle?: string;
  onClose: (refreshOnClose?: boolean) => void;
}

const ImportModal: React.FC<ModalProps> = ({
  isOpen,
  subtitle,
  importPath,
  importType,
  onClose
}) => {
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [data, setData] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const [processing, setProcessing] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const validateData = (data: any) => {
    if (importType === 'singleRecord') {
      if (typeof data !== 'object' || Array.isArray(data)) {
        setToastMessage("Error: El archivo JSON debe contener un solo objeto.");
        setToastIsOpen(true);
        setToastSeverity("error");
        return false;
      }
    } else if (importType === 'multipleRecords') {
      if (!Array.isArray(data)) {
        setToastMessage("Error: El archivo JSON debe contener un array de objetos.");
        setToastIsOpen(true);
        setToastSeverity("error");
        return false;
      }
    }
    return true;
  };

  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target!.result as string;
        try {
          if (file.type === "application/json") {
            const parsedData = JSON.parse(content);
            if (validateData(parsedData)) {
              setData(parsedData);
              setDisabledSaveBtn(false);
              setFile(file);
            }
          } else if (file.type === "text/csv" && importType === 'multipleRecords') {
            setData(content);
            setDisabledSaveBtn(false);
            setFile(file);
          } else {
            setToastMessage("Tipo de archivo no soportado.");
            setToastIsOpen(true);
            setToastSeverity("error");
          }
        } catch (error) {
          setToastMessage("Error al leer el archivo. Asegúrese de que sea un archivo JSON o CSV válido.");
          setToastIsOpen(true);
          setToastSeverity("error");
        }
      };
      reader.readAsText(file);
    }
  };

  const onSave = async () => {
    setProcessing(true);
    try {
      const formData = new FormData();
      formData.append("file", file as Blob);
      const url = `${urls.server}/api/${importPath}`;
      let resData;
      if (importType === 'singleRecord') {
        const res: any = await AxiosInterceptor.post(url, { data: data });
        resData = await res?.data;
      } else {
        const res: any = await AxiosInterceptor.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        resData = await res?.data;
      }


      const { message } = resData;
      onClose(true);
      setProcessing(false);
      setToastSeverity("success");
      setToastIsOpen(true);
      setToastMessage(message.toString());
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setData(null);
      setFile(null);
      setProcessing(false);
      setDisabledSaveBtn(true);
    }
  }, [isOpen]);

  const handleOnClose = () => {
    onClose();
  };

  const renderDataPreview = () => {
    if (importType === 'singleRecord') {
      return <ReactJson src={data} />;
    }
    if (importType === 'multipleRecords' && typeof data === 'string') {
      return (
        <pre>{data}</pre>
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        title={"Importador"}
        subtitle={subtitle || "Importar datos"}
        className={'import-modal-container'}
        isOpen={isOpen}
        onClose={handleOnClose}
      >
        <div className="upload-button-wrapper">
          {!data ? (
            <>
              <input
                ref={uploadInputRef}
                type="file"
                accept={importType === 'multipleRecords' ? ".json, .csv" : ".json"}
                className="upload-input"
                onChange={onPreviewUpload}
              />
              <Button
                className="upload-button"
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current.click()
                }
                variant="contained"
              >
                <strong>Seleccionar</strong>
                <UploadFileIcon />
              </Button>
            </>
          ) : (
            <div className="data-preview">
              <Button
                onClick={() => {
                  setData(null);
                  setDisabledSaveBtn(true);
                  setFile(null);
                }}
              >
                Volver a cargar
              </Button>
              {renderDataPreview()}
            </div>
          )}
        </div>

        <Fab
          className="fab-btn"
          color="primary"
          aria-label="add"
          disabled={disabledSaveBtn || processing}
          onClick={onSave}
        >
          {<Icon name="upload" />}
        </Fab>
      </Modal>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export { ImportModal };

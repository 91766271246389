import { useState, useEffect } from "react";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
import { ColorField } from "../ColorField/ColorField";
import { DynamicIcon } from "components/DynamicIcon/DynamicIcon";
import "./IconMakerField.scss";

import { Button, IconButton } from "@mui/material";
import { LongTextField } from "../LongTextField/LongTextField";
import Icon from "components/Icon/Icon";

const IconMakerField = (props: any) => {
    let {
        defaultValue,
        editable,
        label,
        editPath,
        onChange,
        name,
        mode,
        //readonly,
        onEdit
    }: any = props;

    const [value, setValue] = useState(defaultValue || []);
    const [isEditEnabled, setIsEditEnabled] = useState(false);

    useEffect(() => {
        if (onChange) onChange(value);
        console.log(value);
    }, [value]);

    // useEffect(() => {
    //     setValue(defaultValue);
    // }, [defaultValue]);

    const handleAddPath = () => {
        setValue([...value, { d: "", color: "#000000" }]);
    };

    const handlePathChange = (index: number, field: string, newValue: string) => {

        const updatedPaths = value.map((path: any, i: number) =>
            i === index ? { ...path, [field]: newValue } : path
        );
        setValue(updatedPaths);
    };

    const handleRemovePath = (index: number) => {
        setValue(value.filter((_: any, i: number) => i !== index));
    };

    return (
        <div className="icon-maker-field-container">
            {/* {label && (
                <label>
                    <span>{label}</span>
                </label>
            )} */}
            <div className="field-header">
                {label && (
                    <label>
                        <span>{label}</span>
                    </label>
                )}
                {editable && (
                    <div>
                        {(mode == 'edit' && editable) && (
                            <FieldEditControls
                                value={value}
                                fieldName={name}
                                editPath={editPath}
                                onEditStart={() => {
                                    setIsEditEnabled(true);
                                }}
                                onEditCancel={(originalValue) => {
                                    setIsEditEnabled(false);
                                    setValue(originalValue);
                                }}
                                onEditEnd={(success: boolean, originalValue) => {
                                    setIsEditEnabled(false);
                                    onEdit();
                                    if (!success) {
                                        setValue(originalValue);
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className="field-body">
                {mode == 'readonly' ||
                    (mode == 'edit' && editable && !isEditEnabled) ||
                    (mode == 'edit' && !editable) ? (
                    <div className="readonly-content">
                        {value.length > 0 ? (
                            <DynamicIcon size={100} paths={value} />
                        ) : (
                            <div className="no-value">
                                <span>Sin icono</span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="editable-content">
                        {value.length > 0 && (
                            <div className="icon-preview">
                                <span>
                                    <DynamicIcon size={100} paths={value} />
                                </span>

                            </div>
                        )}
                        {/* <button type="button" onClick={handleAddPath}>
                            Agregar Path
                        </button> */}
                        <div className="add-path-btn-wrapper">
                            <Button onClick={handleAddPath} startIcon={<Icon name="add" />}>
                                Agregar path
                            </Button>
                        </div>
                        <div className="path-items">
                            {value.map((path: any, index: number) => (
                                <div key={index} className="path-item">
                                    <div>
                                        <ColorField
                                            defaultValue={path.color}
                                            label={"Color"}
                                            onChange={(color: any) => handlePathChange(index, "color", color)}
                                        />
                                    </div>
                                    <div>
                                        <LongTextField
                                            defaultValue={path.d}
                                            label={"Path data"}
                                            description=""
                                            height={88}
                                            onChange={(value: any) => handlePathChange(index, "d", value)}
                                        />
                                    </div>
                                    <div>
                                        <IconButton
                                            color="warning"
                                            onClick={() => {
                                                handleRemovePath(index)
                                            }}
                                            aria-label="delete"
                                        >
                                            <Icon name="delete" />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                )}
            </div>

            {/* {(recordId || editPath) && editable && (
                <div className="btns">
                    <FieldEditControls
                        value={value}
                        fieldName={name}
                        editPath={editPath}
                        onEditStart={() => {
                            setIsEditEnabled(true);
                        }}
                        onEditCancel={(originalValue) => {
                            setIsEditEnabled(false);
                            setValue(originalValue);
                        }}
                        onEditEnd={(success: boolean, originalValue) => {
                            setIsEditEnabled(false);
                            if (!success) {
                                setValue(originalValue);
                            }
                        }}
                    />
                </div>
            )} */}
        </div>
    );
};

export { IconMakerField };

import React from 'react'

interface VideoProps {
    style: React.CSSProperties;
    src?: string;
}
const Video: React.FC<VideoProps> = ({ style, src }) => {
    return (
        <div style={style}>
            {
                src ? <video src={src} controls width="100%">
                    El navegador no admite el elemento <code>video</code>.
                </video> : <img src={"../assets/placeholder-image.svg"} alt="loading" width="100%" />
            }
        </div>

    )
}

export default Video
import { useState, useEffect, Fragment } from "react";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import urls from "config/urls";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import "./SelectItemField.scss";

const SelectItemField = (props: any) => {
  const {
    defaultValue,
    label,
    onChange,
    primaryKey,
    secondaryKey,
    thumbnailKey,
    filters = [],
    searchPath,
    searchFields = [],
  }: any = props;
  const [value, setValue] = useState(defaultValue as any);
  const [noOptionsText, setNoOptionsText] = useState("Buscar...");
  const [options, setOptions] = useState<readonly []>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {

  }, []);
  const handleChange = async (event: any) => {
    if (event.target.value != "") {
      setNoOptionsText("Sin resultado");
    } else {
      setNoOptionsText("Buscar...");
    }
    setLoading(true);
    try {
      const hasQueryParams = searchPath.includes('?');
      const prefix = hasQueryParams ? '&' : '?';

      let url = `${urls.server}/api/${searchPath}${prefix}search_term=${event.target.value
        }&offset=0&limit=20&filters=${encodeURIComponent(JSON.stringify(filters))}&search_fields=${encodeURIComponent(JSON.stringify(searchFields))}`;

      const res = await AxiosInterceptor.get(`${url}`);
      const data = await res?.data;
      setOptions(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="select-item-field-container">
      <div className="item-field-header">
        {label && (
          <span>{label}</span>
        )}
      </div>


      <div className="item-field-body">
        <Autocomplete
          noOptionsText={noOptionsText}
          loadingText="Buscando..."
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: any, value) =>
            option[primaryKey] === value[primaryKey]
          }
          getOptionLabel={(option) => option[primaryKey]}
          options={options || []}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option?.id}>
              {thumbnailKey && <img src={`${urls.server}${option[thumbnailKey]}`} alt="" style={{ marginRight: 8, width: 20, height: 20 }} />}
              <span>{option[primaryKey]}</span>
              {secondaryKey && <span style={{ marginLeft: 8, color: '#888' }}>{option[secondaryKey]}</span>}
            </Box>
          )}
          loading={loading}
          value={value || null}
          onChange={(event: any, newValue: any | null) => {
            //onChange(newValue);
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              hiddenLabel
              onChange={handleChange}
              onFocus={handleChange}
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export { SelectItemField };

import { useState, useEffect } from "react";
import Icon from "../../Icon/Icon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./MultiIconCheckboxField.scss";
import { FieldEditControls } from "components/FieldEditControls/FieldEditControls";
const MultiIconCheckboxField = (props: any) => {
  let {
    editable,
    label,
    editPath,
    onChange,
    options = [],
    defaultValue,
    //readonly,
    name,
    mode
  }: any = props;

  const [value, setValue] = useState(defaultValue as any);
  const [isEditEnabled, setIsEditEnabled] = useState(false);


  const selectItem = (index: number) => {
    setValue((prev: any) => {
      let arr = [...prev];
      if (arr.includes(options[index].name)) {
        arr = arr.filter((e) => e !== options[index].name);
      } else {
        arr.push(options[index].name);
      }
      return arr;
    });
  };
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="multi-icon-checkbox-field-container">
      <div className="field-header">
        {label && (
          <label>
            <span>{label}</span>
          </label>
        )}
        {(mode == 'edit' && editable) && (
          <FieldEditControls
            value={value}
            fieldName={name}
            editPath={editPath}
            onEditStart={() => {
              setIsEditEnabled(true);
            }}
            onEditCancel={(originalValue) => {
              setIsEditEnabled(false);
              setValue(originalValue);
            }}
            onEditEnd={(success: boolean, originalValue) => {
              setIsEditEnabled(false);
              if (!success) {
                setValue(originalValue);
              }
            }}
          />
        )}
      </div>
      <div className="field-body">
        <div>
          {mode == 'readonly' ||
            (mode == 'edit' && editable && !isEditEnabled) ||
            (mode == 'edit' && !editable) ? (
            <div className="readonly-items">
              {options.filter((item: any) => value.includes(item.name)).map((item: any, index: number) => (
                <div key={index} style={{ marginTop: item.icon ? "20px" : "10px" }}>
                  {item.icon && (
                    <div>
                      <Icon name={`${item.icon}Wrapper`} />
                      <Icon name={item.icon} />
                    </div>
                  )}
                  <span style={{ marginTop: item.icon ? "20px" : "0px" }}>
                    {item.displayName}
                  </span>
                </div>
              ))}
            </div>

          ) : (
            <div className="editable-items">
              {options.map((item: any, index: number) => {
                return (
                  <a
                    key={index}
                    style={{ marginTop: item.icon ? "20px" : "10px" }}
                    onClick={() => selectItem(index)}
                  >
                    {item.icon && (
                      <div>
                        <Icon name={`${item.icon}Wrapper`} />
                        <Icon name={item.icon} />
                      </div>
                    )}
                    <span style={{ marginTop: item.icon ? "20px" : "0px" }}>
                      {item.displayName}
                    </span>


                    <span style={{ borderRadius: "10px" }}>
                      {value.includes(item.name) ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </span>

                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { MultiIconCheckboxField };

import React, { useState } from "react";
import "./WorkflowEntriesExpandedCard.scss";
import { DeletedRecordsModal } from "../../../../components/modals/DeletedRecordsModal/DeletedRecordsModal";
import { WorkflowEntryListModal } from "../WorkflowEntryListModal/WorkflowEntryListModal";
import Icon from "components/Icon/Icon";
const WorkflowEntriesExpandedCard = (props: any) => {
  const { title, config } = props;
  const [newWorkflowEntryModalIsOpen, setNewWorkflowEntryModalIsOpen] =
    useState(false);
  const [workflowEntryListModalIsOpen, setWorkflowEntryListModalIsOpen] =
    useState(false);
  const [deletedRecordsModalIsOpen, setDeletedRecordsModalIsOpen] =
    useState(false);

  return (
    <>
      <div className="workflow-entries-expanded-card-container">
        <div className="expanded-card-header">
          <h2>{title}</h2>
        </div>
        <div className="expanded-card-body">
          {/* <a
            className="card-btn lg"
            onClick={() => {
              setNewWorkflowEntryModalIsOpen(true);
            }}
          >
            <strong>Nueva entrada</strong>
            <div>
              <Icon name="addWrapper" />
              <Icon name="add" />
            </div>
          </a> */}
          <a
            className="card-btn lg"
            onClick={() => {
              setWorkflowEntryListModalIsOpen(true);
            }}
          >
            <strong>Registros</strong>
            <div>
              <Icon name="gridWrapper" />
              <Icon name="grid" />
            </div>
          </a>

          <a
            className="card-btn lg"
            onClick={() => {
              setDeletedRecordsModalIsOpen(true);
            }}
          >
            <strong>Entradas eliminadas</strong>
            <div>
              <Icon name="deleteWrapper" />
              <Icon name="delete" />
            </div>
          </a>
        </div>
      </div>

      {/* <NewWorkflowEntryModal
        isOpen={newWorkflowEntryModalIsOpen}
        onClose={() => {
          setNewWorkflowEntryModalIsOpen(false);
        }}
      /> */}

      <WorkflowEntryListModal
        open={workflowEntryListModalIsOpen}
        setOpen={setWorkflowEntryListModalIsOpen}
        onClose={() => { }}
      />
      <DeletedRecordsModal
        isOpen={deletedRecordsModalIsOpen}
        subtitle={''}
        onClose={() => {
          setDeletedRecordsModalIsOpen(false)
        }}
      />
    </>
  );
};

export { WorkflowEntriesExpandedCard };

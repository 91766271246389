import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import { useTextEditorField } from "./TextEditorFieldContext";
import { ColorPickerButton } from "components/buttons/ColorPickerButton/ColorPickerButton";
import Icon from "components/Icon/Icon";

const DragHandle = SortableHandle(() => {
  return (
    <IconButton>
      <DragIndicatorIcon />
    </IconButton>
  );
});
const TextEditorFieldItemBtns = ({ i, item }: any) => {
  const {
    handleDeleteItem,
    handleChangeFontStyle,
    handleChangeFontSize,
    handleChangeTextAlign,
    handleToggleWithHeader,
    handleChangeMarkerType,
    handleChangeTextColor,
  } = useTextEditorField();

  // const handleChangeTextColor = (slectedColor: string, id: any) => {
  //   console.log(slectedColor);
  //   console.log(id);

  // };
  return (
    <div className="text-editor-field-item-btns">
      <span>{item.label}</span>
      <div>
        {item?.type == "text" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeFontStyle(i)}
          >
            {item?.fontStyle === "regular" && <Icon name="regular" />}
            {item?.fontStyle === "regular_italic" && <Icon name="regularItalic" />}
            {item?.fontStyle === "bold" && <Icon name="bold" />}
            {item?.fontStyle === "bold_italic" && <Icon name="boldItalic" />}
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeTextAlign(i)}
          >
            {item?.textAlign === "left" && <Icon name="alignLeft" />}
            {item?.textAlign === "center" && <Icon name="alignCenter" />}
            {item?.textAlign === "right" && <Icon name="alignRight" />}
            {item?.textAlign === "justify" && <Icon name="alignJustify" />}
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleChangeFontSize(i, "enlarge")}
          >
            <Icon name="enlarge" />
          </IconButton>
        )}
        {item?.type == "text" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleChangeFontSize(i, "reduce")}
          >
            <Icon name="reduce" />
          </IconButton>
        )}
        {item?.type == "text" && (
          <ColorPickerButton
            id={i}
            handleChangeColor={handleChangeTextColor}
            defaultColor="#333333" />
        )}
        {item?.type == "table" && (
          <IconButton
            aria-label="change font size"
            onClick={() => handleToggleWithHeader(i)}
          >
            {!item?.withHeader && <Icon name="table1" />}
            {item?.withHeader && <Icon name="table2" />}
          </IconButton>
        )}
        {item?.type == "list" && (
          <IconButton
            aria-label="change font style"
            onClick={() => handleChangeMarkerType(i)}
          >
            {item?.markerType === "square" && <Icon name="square" />}
            {item?.markerType === "squareOutline" && <Icon name="squareOutline" />}
            {item?.markerType === "circle" && <Icon name="circle" />}
            {item?.markerType === "circleOutline" && <Icon name="circleOutline" />}
            {item?.markerType === "checkMark" && <Icon name="checkmark" />}
          </IconButton>
        )}
        <IconButton aria-label="delete" onClick={() => handleDeleteItem(i)}>
          <Icon name="delete" />
        </IconButton>
        <DragHandle />
      </div>
    </div>
  );
};

export { TextEditorFieldItemBtns };

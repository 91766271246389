import React, { useState } from "react";
import "./InteractiveActionItem.scss";
import { FormDialog } from "components/dialogs/FormDialog/FormDialog";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import Icon from "components/Icon/Icon";

const InteractiveActionItem = ({
  name,
  params,
  displayName,
  icon,
  status,
  formFields,
  formTitle,
  stepEntryId,
  id,
  isDisabled,
  extraData,
  onChange,
}: any) => {
  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  //const [formData, setFormData] = useState({});
  const [processing, setProcessing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const executeInteractiveAction = async (data?: any) => {
    try {
      setProcessing(true);
      const res = await AxiosInterceptor.post(
        `${urls.server}/api/workflow-step-entries/${stepEntryId}/execute-interactive-action`,
        {
          data: data,
          name: name,
          params: params,
          id: id,
        }
      );
      setProcessing(false);
      if (res) {
        const xdata = await res?.data;
        const { message } = xdata;
        setAlertMsg(message.toString());
        onChange(true);
        setFormDialogIsOpen(false);
        setOpenAlert(true);
        setSeverity("success");
      }
    } catch (error: any) {
      setProcessing(false);
      setAlertMsg(error.toString());
      setOpenAlert(true);
      setSeverity("error");
    }
  };
  const handleClick = () => {
    if (formFields) {
      if (!isDisabled) setFormDialogIsOpen(true);
    } else {
      executeInteractiveAction();
    }
  };
  const handleSubmit = (data: any) => {
    executeInteractiveAction(data);
  };
  return (
    <div className="interactive-action-item-container">
      <a
        onClick={handleClick}
        className={isDisabled || processing ? "disabled" : ""}
      >
        <Icon name={icon} />
        <span>{displayName}</span>
      </a>
      <FormDialog
        title={formTitle}
        fields={formFields}
        isOpen={formDialogIsOpen}
        onSubmit={handleSubmit}
        extraData={extraData}
        mode="save"
        onClose={(data: any) => {
          setFormDialogIsOpen(false);
          if (data) {
            //executeInteractiveAction(data);
            //setFormData(data);
          }
        }}
      />
      {/* <InformationMessage
        open={openAlert}
        message={alertMsg}
        severity={severity}
        onClose={setOpenAlert}
      /> */}
    </div>
  );
};

export { InteractiveActionItem };

import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as ev from "expr-eval";
import "./MoreActionsButton.scss";

type Option = {
  name: string;
  displayName: string;
  showIf?: string;
};

interface MoreActionsButtonProps {
  //options: Option[] | Array<[]>;
  options: any;
  id: string;
  onSelectItem: (option: { actionName: string, actionDisplayName: string, config?: any }) => void;
  context?: { [key: string]: any };
}

const MoreActionsButton = ({ options, id, onSelectItem, context = {} }: MoreActionsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePresentPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const shouldShowItem: any = (option: Option) => {
    if (!option.showIf) return true;

    try {
      return option.showIf ? ev.Parser.evaluate(option.showIf, context) : false;
    } catch (error) {
      console.error("Error evaluating expression: ", error);
      return false; // En caso de error en la expresión, ocultar el item
    }
  };

  return (
    <div className="more-actions-button-container">
      <IconButton aria-label="actions" onClick={handlePresentPopover}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="more-actions-button-popover"
      >
        <div className="popover-items">
          {options.filter(shouldShowItem).map((item: any, index: number) => (
            <a
              key={index}
              onClick={() => {
                onSelectItem({
                  actionName: item.name,
                  actionDisplayName: item.displayName,
                  config: item
                });
                handleClosePopover();
              }}
            >
              {item.displayName}
            </a>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export { MoreActionsButton };

import React, { useState, useEffect } from "react";
import { Dialog, IconButton, Slide, Fab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import urls from "config/urls";
import AxiosInterceptor from "../../../../AxiosInterceptor";
import "./WorkflowEntryListModal.scss";
import { Spinner } from "components/Spinner/Spinner";
import { WorkflowEntryListItem } from "../WorkflowEntryListItem/WorkflowEntryListItem";
import { FiltersDrawer } from "components/drawers/FiltersDrawer/FiltersDrawer";
import Icon from "components/Icon/Icon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const WorkflowEntryListModal = ({ open, setOpen, onClose }: any) => {
  //const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(15);
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersDrawerIsOpen, setFiltersDrawerIsOpen] = useState(false);
  const loadData = async () => {
    try {
      setLoading(true);
      const res = await AxiosInterceptor.get(
        `${urls.server}/api/my/workflow-entries?offset=${offset}&limit=${limit}`
      );
      setRecords(res?.data);
      setLoading(false);
    } catch (error) {
      setRecords([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) loadData();
  }, [open]);
  const resetState = () => {
    loadData();
  };
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
        className="workflow-entry-list-modal-container"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h2>Entradas de workflows</h2>
          </div>
          <div className="header-searchbar-wrapper">

          </div>

          <div className="modal-end-btns">
            <IconButton
              color="inherit"
              onClick={() => {
                setFiltersDrawerIsOpen(true);
              }}
              aria-label="filter"
            >
              <Icon name="filters" />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
              aria-label="close"
            >
              <Icon name="close" />
            </IconButton>
          </div>
        </div>
        <div className="modal-body">
          {loading && (
            <div className="spinner-wrapper">
              <Spinner visible={loading} />
            </div>
          )}
          {!loading && records.length == 0 && (
            <div className="no-content">
              <Icon name="workflowEntries" />
              <p>No hay entradas workflows</p>
            </div>
          )}
          {!loading && records.length > 0 && (
            <>
              <div className="list-container">
                {records.map((item: any, index: number) => {
                  return (
                    <WorkflowEntryListItem
                      key={index}
                      data={item}
                      resetState={resetState}
                      onChange={(res: boolean) => {
                        if (res) loadData();
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Dialog>
      <FiltersDrawer
        isOpen={filtersDrawerIsOpen}
        onClose={(res: boolean) => {
          setFiltersDrawerIsOpen(false)
        }}
        filters={[]}
      />
    </>
  );
};

export { WorkflowEntryListModal };

import React from "react";
import "./RankingCard.scss";
const RankingCard = ({ title }: any) => {
  return (
    <div className="ranking-card-container">
      <h2 className="card-title">{title}</h2>
      <div></div>
    </div>
  );
};

export { RankingCard };

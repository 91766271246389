import { useState, useEffect, Fragment } from "react";
import urls from "config/urls";
import AxiosInterceptor from "../../AxiosInterceptor";

import { Grid } from "@mui/material";
import { RadioField } from "../fields/RadioField/RadioField";
import "./DynamicForm.scss";
import { InputField } from "../fields/InputField/InputField";
import { PasswordField } from "../fields/PasswordField/PasswordField";
import { CheckboxField } from "../fields/CheckboxField/CheckboxField";
import { MultiCheckboxField } from "../fields/MultiCheckboxField/MultiCheckboxField";
import { ImageCropperField } from "../fields/ImageCropperField/ImageCropperField";
import { SelectField } from "../fields/SelectField/SelectField";
import * as ev from "expr-eval";
import { LongTextField } from "../fields/LongTextField/LongTextField";
import { TagsField } from "../fields/TagsField/TagsField";
import { DateField } from "../fields/DateField/DateField";
import { MoneyField } from "../fields/MoneyField/MoneyField";
import { MultiSelectField } from "../fields/MultiSelectField/MultiSelectField";
import { Spinner } from "../Spinner/Spinner";
import { PeriodicityField } from "../fields/PeriodicityField/PeriodicityField";
import ListField from "../fields/ListField/ListField";
import { GridField } from "../fields/GridField/GridField";
import { AddressField } from "../fields/AddressField/AddressField";
import { CoordinatesField } from "../fields/CoordinatesField/CoordinatesField";
import { ImagesField } from "../fields/ImagesField/ImagesField";
import { ImageField } from "../fields/ImageField/ImageField";
import { TextEditorField } from "../fields/TextEditorField/TextEditorField";
import { TextEditorFieldProvider } from "../fields/TextEditorField/TextEditorFieldContext";
import { ColorField } from "../fields/ColorField/ColorField";
import { PastelColorField } from "../fields/PastelColorField/PastelColorField";
import FormBuilderField from "../fields/FormBuilderField/FormBuilderField";
import { UsersField } from "../fields/UsersField/UsersField";
import { UserGroupsField } from "../fields/UserGroupsField/UserGroupsField";
import { PaymentField } from "../fields/PaymentField/PaymentField";
import { ChecklistField } from "components/fields/ChecklistField/ChecklistField";
import SimplePaymentField from "components/fields/SimplePaymentField/SimplePaymentField";
import { CameraImageField } from "components/fields/CameraImageField/CameraImageField";
import { BillingField } from "components/fields/BillingField/BillingField";
import ConfigurationField from "components/fields/ConfigurationField/ConfigurationField";
import { VideoField } from "components/fields/VideoField/VideoField";
import { VideosField } from "components/fields/VideosField/VideosField";
import { MultiIconCheckboxField } from "components/fields/MultiIconCheckboxField/MultiIconCheckboxField";
import { IconRadioField } from "components/fields/IconRadioField/IconRadioField";

import interpolateString from "utils/interpolateString";
import { AddressPickerField } from "components/fields/AddressPickerField/AddressPickerField";
import { FilesField } from "components/fields/FilesField/FilesField";
import { FileField } from "components/fields/FileField/FileField";
import { IconPickerField } from "components/fields/IconPickerField/IconPickerField";
import { CalculatedField } from "components/fields/CalculatedField/CalculatedField";
import { DynamicListField } from "components/fields/DynamicListField/DynamicListField";
import { IconMakerField } from "components/fields/IconMakerField/IconMakerField";
import { ContentBuilderField } from "components/fields/ContentBuilderField/ContentBuilderField";
import { MarkdownField } from "components/fields/MarkdownField/MarkdownField";
const DynamicForm = (props: any) => {
  let {
    title,
    fields,
    onChange,
    onFieldEdit,
    // tableName,
    // recordId,
    //readonly,
    viewPath,
    extraData,
    defaultData,
    mode, // edit or create
    //globalEdit,
  } = props;
  const [loading, setLoading] = useState(false);

  let initialValues: any = {};

  fields.map((field: any) => {
    switch (field.type) {
      case "text":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "longText":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "radio":
        initialValues[field.name] = field.defaultValue || field.options.length > 0 ? field.options[0].name : null;
        break;
      case "date":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "checkbox":
        initialValues[field.name] = field.defaultValue || false;
        break;
      case "color":
        initialValues[field.name] = "#666";
        break;
      case "pastelColor":
        initialValues[field.name] = "#A597CC";
        break;
      case "list":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "dynamicList":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "iconMaker":
        initialValues[field.name] = field.defaultValue || [];
        break;

      case "checklist":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "multiCheckbox":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "images":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "file":
        initialValues[field.name] = field.defaultValue || {};
        break;
      case "files":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "videos":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "integer":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "grid":

        initialValues[field.name] = field.defaultValue || [];
        break;
      case "configuration":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "money":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "calculated":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "iconRadio":
        initialValues[field.name] = field.defaultValue || field.options[0]?.name;
        break;
      case "multiIconCheckbox":
        initialValues[field.name] = field.defaultValue || [];
        break;
      case "text":
        break;
      case "decimal":
        initialValues[field.name] = field.defaultValue || null;
        break;
      case "simplePayment":
        initialValues[field.name] = {
          paymentMethods: [
            {
              name: "cash",
              displayName: "Efectivo",
              amount: 0,
            },
            {
              name: "credit_card",
              displayName: "Tarjeta de crédito",
              amount: 0,
            },
            {
              name: "debit_card",
              displayName: "Tarjeta de débito",
              amount: 0,
            },
            {
              name: "mercado_pago",
              displayName: "Mercado Pago",
              amount: 0,
            },
            {
              name: "bank_payment",
              displayName: "Pago bancario",
              amount: 0,
            },
          ],
          totalPaid: 0,
        };
        break;

      default:
        break;
    }
  });

  const [values, setValues] = useState({
    ...initialValues,
    ...defaultData,
    ...extraData,
  } as any);
  useEffect(() => {
    setValues({
      ...initialValues,
      ...defaultData,
      ...extraData,
    });
  }, [defaultData]);
  const loadData = async () => {
    // if (tableName) tableName = tableName?.replace(/_/g, "-");
    // let url = `${urls.server}/api/${tableName}/${id}`;
    try {
      const url = `${urls.server}/api/${viewPath}`;
      setLoading(true);
      const res = await AxiosInterceptor.get(`${url}`);
      const data = await res?.data;
      setValues({
        ...data,
        ...extraData,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };
  useEffect(() => {
    if (viewPath) {
      loadData();
    }
  }, [viewPath]);

  const handleChangeField = (
    fieldName: any,
    value: any,
    fieldType?: string
  ) => {
    setValues((prev: any) => {
      const data = { ...prev };
      data[fieldName] = value;
      if (fieldType == "select") data[`${fieldName}_id`] = value?.id;
      if (fieldType == "addressPicker") data[`${fieldName}_id`] = value?.id;

      return data;
    });
  };
  useEffect(() => {
    onChange(values);
  }, [values]);
  const evaluateShow = (exp: any) => {
    try {
      return exp ? ev.Parser.evaluate(exp, values) : true;
    } catch (error) {
      console.error("Error evaluating expression: ", error);
      return false; // Retorna false en caso de error
    }
  };

  const shouldShowField = (field: any) => {
    const showInModes = field.showInModes || [];
    const modeMatch = showInModes.includes(mode);
    const conditionMatch = field.showIf ? evaluateShow(field.showIf) : true;
    return modeMatch && conditionMatch;
  };

  return (
    <div className="dynamic-form-container">
      {loading && (
        <div className="spinner-wrapper">
          <Spinner visible={loading} />
        </div>
      )}
      {!loading && (
        <form style={{ paddingTop: title ? "70px" : "0px" }}>
          {title ? <h2>{title}</h2> : ""}

          <div>
            <Grid container spacing={2}>
              {fields.map((field: any, index: number) => {
                //const fieldName = field.name.replace(/_/g, "-");
                //const shouldShowField = (!field.showOnEdit || (field.showOnEdit && mode === 'edit')) && ((field.showIf && evaluateShow(field.showIf)) || !field.showIf);
                return (
                  <Fragment key={index}>
                    {shouldShowField(field) && (
                      <Grid item xs={12} sm={field.size}>
                        {(() => {
                          switch (field.type) {
                            case "text":
                              return (
                                <InputField
                                  type="text"
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  startIcon={field.startIcon}
                                  endIcon={field.endIcon}
                                  name={field.name}
                                  prepend={field.prepend}
                                  append={field.append}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  onEdit={onFieldEdit}
                                  description={field.description}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                />
                              );
                            case "money":
                              return (
                                <MoneyField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  description={field.description}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "periodicity":
                              return (
                                <PeriodicityField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "decimal":
                              return (
                                <InputField
                                  type="decimal"
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  startIcon={field.startIcon}
                                  endIcon={field.endIcon}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  prepend={field.prepend}
                                  append={field.append}
                                  description={field.description}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "integer":
                              return (
                                <InputField
                                  type="integer"
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}

                                  startIcon={field.startIcon}
                                  endIcon={field.endIcon}

                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  prepend={field.prepend}
                                  append={field.append}
                                  description={field.description}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "longText":
                              return (
                                <LongTextField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}


                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  id={field.name}
                                  height={field.height}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "date":
                              return (
                                <DateField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}


                                  format={field.format}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                />
                              );
                            case "email":
                              return (
                                <InputField
                                  type="email"
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}


                                  startIcon={field.startIcon}
                                  endIcon={field.endIcon}
                                  prepend={field.prepend}
                                  append={field.append}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "password":
                              return (
                                <PasswordField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "radio":
                              return (
                                <>
                                  <RadioField
                                    label={field.displayName}
                                    onChange={(value: any) =>
                                      handleChangeField(field.name, value)
                                    }
                                    defaultValue={values[field.name]}
                                    options={field.options}
                                    //readonly={mode == 'readonly' ? 'true' : 'false'}
                                    mode={mode}
                                    name={field.name}
                                    editable={field.editable}


                                    editPath={
                                      values?.id ?
                                        interpolateString(values, field.editPath) : field.editPath
                                    }
                                    onEdit={onFieldEdit}
                                    description={field.description}

                                  />
                                </>
                              );
                            case "configuration":
                              return (
                                <ConfigurationField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  configurationType={field.configurationType}


                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );

                            case "checkbox":
                              return (
                                <CheckboxField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  description={field.description}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  onEdit={onFieldEdit}
                                //globalEdit={globalEdit}
                                />
                              );
                            case "multiCheckbox":
                              return (
                                <MultiCheckboxField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  description={field.description}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  loadPath={field.loadPath}
                                  options={field.options}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );

                            case "iconRadio":
                              return (
                                <IconRadioField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  options={field.options}
                                  //validations={field.validations}
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  editable={field.editable}
                                />
                              );
                            case "multiIconCheckbox":
                              return (
                                <MultiIconCheckboxField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  options={field.options}
                                  //validations={field.validations}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  editable={field.editable}
                                />
                              );
                            case "list":
                              return (
                                <ListField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  //validations={field.validations}
                                  defaultValue={values[field.name]}
                                  itemPlaceholder={field.itemPlaceholder}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}
                                  options={field.options}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  editable={field.editable}
                                //defaultValue={field.defaultValue}
                                />
                              );
                            case "checklist":
                              return (
                                <ChecklistField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  description={field.description}
                                  //validations={field.validations}
                                  defaultValue={values[field.name]}
                                  //defaultValue={field.defaultValue}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "imageCropper":
                              return (
                                <ImageCropperField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  aspect={field.aspect}
                                  editable={field.editable}
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "images":
                              return (
                                <ImagesField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  folder={field.folder}

                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "image":
                              return (
                                <ImageField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "files":
                              return (
                                <FilesField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  folder={field.folder}

                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "file":
                              return (
                                <FileField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "video":
                              return (
                                <VideoField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}


                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "videos":
                              return (
                                <VideosField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  editable={field.editable}
                                  name={field.name}
                                  placeholder={field.placeholder}


                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  id={field.name}
                                  onEdit={onFieldEdit}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "cameraImage":
                              return (
                                <CameraImageField
                                  id={field.name}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  folder={field.folder}
                                  name={field.name}


                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                />
                              );
                            case "grid":
                              return (
                                <GridField
                                  label={field.displayName}
                                  cols={field.cols}
                                  onChange={(value: any) => {
                                    console.log(value);
                                    handleChangeField(field.name, value);
                                  }}
                                  defaultValue={values[field.name]}
                                  description={field.description}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }


                                  name={field.name}
                                  formData={values}
                                  extraData={extraData}
                                  forceMobileView={field.forceMobileView}
                                  canRemoveRows={field.canRemoveRows}
                                  canAddRows={field.canAddRows}
                                />
                              );
                            case "dynamicList":
                              return (
                                <DynamicListField
                                  label={field.displayName}
                                  cells={field.cells}
                                  onChange={(value: any) => {
                                    console.log(value);
                                    handleChangeField(field.name, value);
                                  }}
                                  defaultValue={values[field.name]}
                                  description={field.description}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  onEdit={onFieldEdit}


                                  name={field.name}
                                  formData={values}
                                  extraData={extraData}
                                  canRemoveItems={field.canRemoveItems}
                                  canAddItems={field.canAddItems}
                                />
                              );
                            case "select":
                              return (
                                <SelectField
                                  //id={field.name}
                                  defaultValue={values[field.name]}
                                  label={field.displayName}

                                  searchPath={field.searchPath}
                                  searchFields={field.searchFields}
                                  primaryKey={field.primaryKey}
                                  secondaryKey={field.secondaryKey}
                                  thumbnailKey={field.thumbnailKey}

                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  formFields={field.formFields}
                                  formTitle={field.formTitle}
                                  createPath={field.createPath}
                                  filters={field.filters}
                                  onChange={(value: any) =>
                                    handleChangeField(
                                      field.name,
                                      value,
                                      field.type
                                    )
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}
                                />
                              );
                            case "addressPicker":
                              return (
                                <AddressPickerField
                                  //id={field.name}
                                  defaultValue={values[field.name]}
                                  label={field.displayName}

                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  editable={field.editable}
                                  filters={field.filters}
                                  onChange={(value: any) =>
                                    handleChangeField(
                                      field.name,
                                      value,
                                      field.type
                                    )
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  name={field.name}
                                />
                              );
                            case "multiSelect":
                              return (
                                <MultiSelectField
                                  label={field.displayName}
                                  searchPath={field.searchPath}
                                  primaryKey={field.primaryKey}
                                  secondaryKey={field.secondaryKey}
                                  thumbnailKey={field.thumbnailKey}
                                  maxItems={field.maxItems}
                                  minItems={field.minItems}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                />
                              );
                            case "tags":
                              return (
                                <TagsField
                                  label={field.displayName}
                                  groupName={field.groupName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  readonlyIf={field.readonlyIf}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "address":
                              return (
                                <AddressField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}

                                />
                              );

                            case "coordinates":
                              return (
                                <CoordinatesField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}

                                />
                              );


                            case "contentBuilder":
                              return (
                                <ContentBuilderField
                                  formData={values}
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}

                                />
                              );
                            case "textEditor":
                              return (
                                <TextEditorFieldProvider key={field.name}>
                                  <TextEditorField
                                    label={field.displayName}
                                    onChange={(value: any) =>
                                      handleChangeField(field.name, value)
                                    }
                                    defaultValue={values[field.name]}
                                    //readonly={mode == 'readonly' ? 'true' : 'false'}
                                    mode={mode}
                                    editable={field.editable}
                                    editPath={
                                      values?.id ?
                                        interpolateString(values, field.editPath) : field.editPath
                                    }

                                    name={field.name}
                                    onEdit={onFieldEdit}

                                  />
                                </TextEditorFieldProvider>
                              );
                            case "color":
                              return (
                                <ColorField
                                  label={field.displayName}
                                  onChange={(value: any) => {
                                    handleChangeField(field.name, value)
                                  }}
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "pastelColor":
                              return (
                                <PastelColorField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "markdown":
                              return (
                                <MarkdownField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "formBuilder":
                              return (
                                <FormBuilderField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }


                                  name={field.name}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "iconMaker":
                              return (
                                <IconMakerField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  //onlyWrappedIcons={field.onlyWrappedIcons}


                                  name={field.name}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "iconPicker":
                              return (
                                <IconPickerField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }
                                  onlyTypes={field.onlyTypes}
                                  onlyWith={field.onlyWith}
                                  //onlyWrappedIcons={field.onlyWrappedIcons}


                                  name={field.name}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "calculated":
                              return (
                                <CalculatedField
                                  label={field.displayName}
                                  defaultValue={values[field.name]}
                                  formula={field.formula}
                                  formData={values}
                                />
                              );
                            case "users":
                              return (
                                <UsersField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  subtitle={field.subtitle}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "userGroups":
                              return (
                                <UserGroupsField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );

                            case "billing":
                              return (
                                <BillingField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  transactionType={field.transactionType}
                                  documentType={field.documentType}
                                  name={field.name}
                                  onEdit={onFieldEdit}

                                />
                              );
                            case "payment":
                              return (
                                <PaymentField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}

                                  transactionType={field.transactionType}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "simplePayment":
                              return (
                                <SimplePaymentField
                                  label={field.displayName}
                                  onChange={(value: any) =>
                                    handleChangeField(field.name, value)
                                  }
                                  defaultValue={values[field.name]}
                                  //readonly={mode == 'readonly' ? 'true' : 'false'}
                                  mode={mode}
                                  editable={field.editable}
                                  editPath={
                                    values?.id ?
                                      interpolateString(values, field.editPath) : field.editPath
                                  }

                                  name={field.name}

                                  transactionType={field.transactionType}
                                  totalDueKey={field.totalDueKey}
                                  formData={values}
                                  onEdit={onFieldEdit}
                                />
                              );
                            case "separator":
                              return (
                                <div className="separator-field-container">
                                  {field.displayName}
                                </div>
                              );
                          }
                        })()}
                      </Grid>
                    )}
                  </Fragment>
                );
              })}
            </Grid>
          </div>
          {values["display_id"] && (
            <span className="display-id">{values["display_id"]}</span>
          )}
        </form>
      )}
    </div>
  );
};

export { DynamicForm };

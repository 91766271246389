import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import './IconButtonGroup.scss';
import colors from 'config/colors';

type Option = {
    name: string;
    icon: string;
};

type IconButtonGroupProps = {
    options: Option[];
    multiple?: boolean;
    defaultValues?: string[];
    onSelect?: (selectedOptions: Option[]) => void;
};

const IconButtonGroup: React.FC<IconButtonGroupProps> = ({ options = [], multiple = false, defaultValues = [], onSelect }) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

    useEffect(() => {
        const initialSelectedOptions = options.filter(option => defaultValues.includes(option.name));
        setSelectedOptions(initialSelectedOptions);
    }, [defaultValues, options]);

    const handleSelect = (option: Option) => {
        let newSelectedOptions: Option[];

        if (multiple) {
            if (selectedOptions.includes(option)) {
                newSelectedOptions = selectedOptions.filter(selectedOption => selectedOption.name !== option.name);
            } else {
                newSelectedOptions = [...selectedOptions, option];
            }
        } else {
            newSelectedOptions = selectedOptions.includes(option) ? [] : [option];
        }

        setSelectedOptions(newSelectedOptions);

        if (onSelect) {
            onSelect(newSelectedOptions);
        }
    };

    return (
        <div className="icon-button-group-container">
            {options.map(option => (
                <button
                    key={option.name}
                    onClick={() => handleSelect(option)}
                    style={{
                        border: 'none',
                        backgroundColor: selectedOptions.includes(option) ? colors.primary : '#f8f9fa',
                        color: selectedOptions.includes(option) ? '#fff' : colors.text,
                        padding: '6px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        margin: 2
                    }}
                >
                    <Icon name={option.icon} />
                </button>
            ))}
        </div>
    );
};

export default IconButtonGroup;

import React from "react";
import "./WorkflowEntriesCard.scss";
import CardPreview from "components/CardPreview/CardPreview";
const WorkflowEntriesCard = (props: any) => {
  const { title, config, data } = props;
  return (
    <div className="workflow-entries-card-container">
      <h2 className="card-title">{title}</h2>
      <div>
        <CardPreview
          type={config?.previewType}
          icon={config?.previewIcon}
          valueDefinitions={config?.previewValueDefinitions}
          data={data}
        />
      </div>
    </div>
  );
};

export { WorkflowEntriesCard };

import React, { useState, useEffect, useRef } from "react";
import {
  Fab,
  Button,
} from "@mui/material";
import "./ConfigurationImporterModal.scss";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReactJson from "react-json-view";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import Modal from "components/Modal/Modal";
import { Toast } from "components/Toast/Toast";
import Icon from "components/Icon/Icon";

interface ModalProps {
  isOpen: boolean;
  importPath: string | null;
  subtitle?: string;
  onClose: (refreshOnClose?: boolean) => void;
}
const ConfigurationImporterModal: React.FC<ModalProps> = ({
  isOpen,
  subtitle,
  importPath,
  onClose
}) => {
  //const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
  const [data, setData] = useState(null as any);
  const [processing, setProcessing] = useState(false);

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const onPreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target!.result as string;
        setData(JSON.parse(data));
        setDisabledSaveBtn(false);
      };
      reader.readAsText(file);
    }
  };
  const onSave = async () => {
    setProcessing(true);
    try {
      let url = `${urls.server}/api/${importPath}`;
      //const dataToSend:any = data;
      const res: any = await AxiosInterceptor.post(url, { data: data });
      const resData = await res?.data;
      const { message } = resData;
      onClose(true);
      setProcessing(false);
      setToastSeverity("success");
      setToastIsOpen(true);
      setToastMessage(message.toString());
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setData(null);
      setProcessing(false);
      setDisabledSaveBtn(true);
    }
  }, [isOpen]);
  const handleOnClose = () => {
    onClose();
  }
  return (
    <>
      <Modal
        title={"Subir JSON"}
        subtitle={subtitle || "Importar configuración"}
        className={'configuration-importer-modal-container'}
        isOpen={isOpen}
        onClose={handleOnClose}
      >
        <div className="upload-button-wrapper">
          {!data ? (
            <>
              <input
                ref={uploadInputRef}
                type="file"
                accept=".json"
                className="upload-input"
                onChange={onPreviewUpload}
              />
              <Button
                className="upload-button"
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current.click()
                }
                variant="contained"
              >
                <strong>Seleccionar</strong>
                <UploadFileIcon />
              </Button>
            </>
          ) : (
            <div className="json-preview">
              <Button
                onClick={() => {
                  setData(null);
                  setDisabledSaveBtn(true);
                }}
              >
                Volver a cargar
              </Button>
              <React.Fragment>
                <ReactJson src={data} />
              </React.Fragment>
            </div>
          )}
        </div>

        <Fab
          className="fab-btn"
          color="primary"
          aria-label="add"
          disabled={disabledSaveBtn || processing}
          onClick={onSave}
        >
          {<Icon name="upload" />}
        </Fab>


      </Modal>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export { ConfigurationImporterModal };

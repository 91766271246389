import "./RadioCell.scss";

const RadioCell = ({ data, options }: any) => {
  // Find the option object where the name matches the data
  const matchingOption = options.find((option: any) => option.name === data);

  // If a matching option is found, use its displayName, otherwise default to data
  const displayText = matchingOption ? matchingOption.displayName : "-";

  return <div className="radio-cell-container">{displayText}</div>;
};

export { RadioCell };

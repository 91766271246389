import React, { useState, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DynamicForm } from "../../DynamicForm/DynamicForm";
import "./FormModal.scss";
import { Fab } from "@mui/material";
import urls from "config/urls";
import AxiosInterceptor from "../../../AxiosInterceptor";
import { Toast } from "components/Toast/Toast";
import Icon from "components/Icon/Icon";
interface ModalProps {
  isOpen: boolean;
  fields: any[];
  title: string;
  createPath?: string;
  editPath?: string;
  viewPath?: string;
  // tableName?: string;
  //recordId?: number | null;
  readonly?: boolean;
  extraData?: any;
  //globalEdit?: boolean;
  mode?: 'create' | 'edit' | 'globalEdit' | 'save' | 'readonly'; // modo view y readonly cumplirian la misma función
  onClose: (refreshOnClose?: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FormModal: React.FC<ModalProps> = ({
  isOpen,
  fields = [],
  title = "",
  createPath,
  editPath,
  // tableName,
  viewPath,
  //recordId,
  //readonly,
  extraData = {},
  //globalEdit = false,
  mode,
  onClose,
}) => {
  const [processing, setProcessing] = useState(false);
  const [values, setValues] = useState({});
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error");
  const onSave = async () => {
    setProcessing(true);

    try {
      if (editPath) {
        let url = `${urls.server}/api/${editPath}`;

        const dataToSend = values;
        const res = await AxiosInterceptor.put(url, dataToSend);
        const data = await res?.data;
        const { message } = data;
        setToastMessage(message.toString());
        onClose(data);
        setToastIsOpen(true);
        setToastSeverity("success");
      } else {
        // if (tableName) tableName = tableName.replace(/_/g, "-");
        // let url = tableName
        //   ? `${urls.server}/api/${tableName}`
        //   : `${urls.server}/api/${createPath}`;
        const url = `${urls.server}/api/${createPath}`;
        const dataToSend = {
          ...values,
          //...extraData,
        };
        const res = await AxiosInterceptor.post(url, dataToSend);
        const data = await res?.data;
        const { message } = data;
        setToastMessage(message.toString());
        onClose(data);
        setToastIsOpen(true);
        setToastSeverity("success");
      }
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      setToastMessage(error.toString());
      setToastIsOpen(true);
      setToastSeverity("error");
    }
  };

  const handleFieldEdit = () => {
    setRefreshOnClose(true);
    console.log("se cambio");
    //if (res) setRefreshOnClose(true);
  };
  useEffect(() => {
    if (isOpen) {
      setRefreshOnClose(false);
      setToastIsOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => {

          if (refreshOnClose) {
            onClose(true);
          } else {
            onClose();
          }
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#f2f2f4",
          },
        }}
      >
        <div className="form-modal-body">
          <div>
            <div className="paper">
              <a
                onClick={() => {
                  if (refreshOnClose) {
                    onClose(true);
                  } else {
                    onClose();
                  }
                }}
              >
                <Icon name="cancel" />
              </a>
              <span></span>
              <span></span>
              <div>
                <DynamicForm
                  fields={fields}
                  onChange={(data: any) => {
                    setValues(data);

                  }}
                  title={title}
                  // recordId={recordId}
                  // tableName={tableName}
                  onFieldEdit={handleFieldEdit}
                  // readonly={readonly}
                  // globalEdit={globalEdit} //La edicion es global
                  viewPath={viewPath}
                  extraData={extraData}
                  mode={mode}
                />
              </div>
            </div>
          </div>
        </div>
        {(mode == 'create' || mode == 'globalEdit') && (
          <Fab
            color="primary"
            variant="extended"
            aria-label="save"
            disabled={processing}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
            }}
            onClick={onSave}
          >
            <Icon name="check" style={{ marginRight: 5 }} />

            {mode == 'create' ? "Guardar" : "Editar"}
          </Fab>
        )}


      </Dialog>
      <Toast
        isOpen={toastIsOpen}
        onClose={() => setToastIsOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>

  );
};

export { FormModal };

import { IconButton } from "@mui/material";
import React, { useState } from "react";

import AxiosInterceptor from "../../../AxiosInterceptor";
import urls from "config/urls";
import CircularProgress from "@mui/material/CircularProgress";
import "./LockToggleCell.scss";
import Icon from "components/Icon/Icon";
const LockToggleCell = (props: any) => {
  let { sendPath, value } = props;
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState(value);

  const handleClick = async () => {

    const url = `${urls.server}/api/${sendPath}`
    setProcessing(true);
    try {
      const res = await AxiosInterceptor.put(`${url}`, {
        data: !data,
      });
      const resData = await res?.data;
      setProcessing(false);
      setData((prev: boolean) => !prev);
    } catch (error: any) {
      setProcessing(false);
    }
  };

  return (
    <div className="lock-toggle-cell-container">
      {processing ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <IconButton
          color={data ? "primary" : "inherit"}
          aria-label="lock toogle btn"
          onClick={handleClick}
        >
          {data ? <Icon name="locked" /> : <Icon name="unlocked" />}
        </IconButton>
      )}
    </div>
  );
};

export { LockToggleCell };
